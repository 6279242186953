import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./Component/utils/PrivateRoute";
import UserDetailPage from "./Pages/Admin/user_detail/UserDetailPage";
import AccessRoles from "./Pages/Admin/roles_and_permission/AccessRoles";
import StrategyReportView from "./Pages/Admin/strategy_permission/StrategyReportView";
import ProfileView from "./Pages/profile_view/ProfileView";
import UserProfile from "./Pages/Admin/Dashboard/profile_details/UserProfile";
import StrategyPermission from "./Pages/Admin/strategy_permission/strategyPermission";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import VerifyEmail from "./Pages/VerifyEmail";
import PrivateRouteLoggedOut from "./Component/utils/PrivateRouteLoggedOut";
import TwoStepVerification from "./Pages/Login/Admin-login/TwoStepVerification";
import AdminLogin from "./Pages/Login/Admin-login/AdminLogin";
import AdminExpiredLink from "./Pages/Login/Admin-login/ExpiredLink_page";
import AdminForgotPassword from "./Pages/Login/Admin-login/ForgotPassword";
import AdminResetPassword from "./Pages/Login/Admin-login/ResetPassword";
import { history } from "./Component/utils/history";
import ViewVTReport from "./Pages/Admin/strategy_permission/ViewVTReport";
import PlansList from "./Pages/Admin/plans/PlansList";
import TradeView from "./Pages/Admin/trades/TradeView";
import TicketsList from "./Pages/Admin/Tickets/TicketsList";
import TicketDetail from "./Pages/Admin/Tickets/TicketDetail";

const Routers = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PrivateRouteLoggedOut>
            <AdminLogin />
          </PrivateRouteLoggedOut>
        }
      />
      <Route
        path="/forgot-password"
        element={
          // <PrivateRouteLoggedOut>
          <AdminForgotPassword />
          // </PrivateRouteLoggedOut>
        }
      />
      <Route
        path="/admin-reset-password"
        element={
          <PrivateRouteLoggedOut>
            <AdminResetPassword type={"reset"} />
          </PrivateRouteLoggedOut>
        }
      />
      <Route
        path="/admin-set-password"
        element={
          <PrivateRouteLoggedOut>
            <AdminResetPassword type={"set"} />
          </PrivateRouteLoggedOut>
        }
      />
      <Route
        path="/auth-verify-otp"
        // component={TwoStepVerification}
        element={
          <PrivateRouteLoggedOut>
            <TwoStepVerification />
          </PrivateRouteLoggedOut>
        }
      />
      <Route
        path="/auth-admin-verify-email"
        element={
          // <PrivateRouteLoggedOut>
          <VerifyEmail />
          // </PrivateRouteLoggedOut>
        }
      />
      <Route
        path="/trades"
        element={
          <PrivateRoute>
            <TradeView />
          </PrivateRoute>
        }
      />
      <Route
        path="/plans"
        element={
          <PrivateRoute>
            <PlansList />
          </PrivateRoute>
        }
      />
      <Route
        path="/traders"
        element={
          <PrivateRoute>
            <AdminDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/strategy/permission"
        element={
          <PrivateRoute>
            <StrategyPermission />
          </PrivateRoute>
        }
      />
      <Route path="/expired_link" element={<AdminExpiredLink />} />
      <Route
        path="/user/profile/:id"
        element={
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfileView />
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/strategy/:id"
        // component={AuthGuard(StrategyReport)}
        element={
          <PrivateRoute>
            <StrategyReportView />
          </PrivateRoute>
        }
      />
      <Route
        path="/access-roles"
        element={
          <PrivateRoute>
            <AccessRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets"
        element={
          <PrivateRoute>
            <TicketsList />
          </PrivateRoute>
        }
      />
      <Route
        path="/ticket/:id"
        // component={AuthGuard(StrategyList)}
        element={
          <PrivateRoute>
            <TicketDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-list"
        element={
          <PrivateRoute>
            <UserDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/view-report/:tab/:id"
        element={
          <PrivateRoute>
            <ViewVTReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to={`/traders`} state={{ from: history.location }} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default Routers;
