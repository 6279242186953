import { Switch } from "antd";

import React, { useEffect, useState } from "react";
import { ImLink } from "react-icons/im";
import { toast } from "react-toastify";
import { brokerList } from "../../../../Component/helpers/api/userDetails/userDetails";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import NoDataFoundV2 from "../../../../Component/NoDataFoundV2";
import { toastSpecs } from "../../../../Utils/utils";

export default function UserConnectedBroker() {
  let { id } = useParams();

  const [brokerInfo, setBrokerInfo] = useState([]);

  useEffect(() => {
    getBrokerList.mutate();
  }, []);

  const getBrokerList = useMutation({
    mutationFn: () => brokerList(id),
    onSuccess: (res) => {
      setBrokerInfo(res?.data?.data);
    },
    onError: (err) => {
      return toast.error("something went wrong server error", toastSpecs);
    },
  });

  console.log("brokerInfo", brokerInfo);

  return (
    <>
      <div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl">
        <div className="text-base font-semibold">Connected Brokers</div>

        <div className="divide-y divide-level-2 divide-dashed mt-4">
          {brokerInfo && brokerInfo.length > 0 ? (
            brokerInfo.map((item) => (
              <>
                {/* <div className="flex items-center border-[1px] border-dashed border-blue-2 bg-blue-2/10 rounded-xl overflow-hidden p-3.5 mt-3.5 ">
                  <div className="flex gap-2">
                    <div>
                      <ImLink
                        className={"stroke-blue-2 fill-blue-2 text-3xl"}
                      />
                    </div> */}
                {/* <div>
                      <div>
                        <p className="text-base text-color-primary font-medium">
                          We need your attention!
                        </p>
                      </div>
                      <div>
                        <p className="text-xs text-color-primary space-x-1">
                          <span>
                            By connecting an account, you hereby agree to our
                          </span>
                          <button className="text-blue-2 hover:text-blue-3">
                            privacy policy
                          </button>
                          <span>and</span>
                          <button className="text-blue-2 hover:text-blue-3">
                            terms of use
                          </button>
                        </p>
                      </div>
                    </div> */}
                {/* </div> */}
                {/* </div> */}
                <div className="broker py-3.5" key={item?._id}>
                  <div className="row align-items-center">
                    <div className="col-9 col-md-10">
                      <div className="d-flex align-items-center gap-3.5">
                        <div className="w-[65px] h-[65px] rounded-md bg-color-base-2 d-flex align-items-center">
                          <img src={item?.logo_img} alt={item?.broker_name} />
                        </div>
                        <div className="brokerName">
                          <h5 className="text-color-primary font-semibold">
                            {item?.broker_name}
                          </h5>
                          <p className="text-xs text-color-secondary/90">
                            Available Funds : {item?.funds ? item?.funds : 0}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-3 col-md-2 text-right">
                      <Switch
                        checked={item?.status === "verified" ? true : false}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="min-h-32 d-flex items-center justify-center">
              <div className="d-flex items-center justify-center gap-1">
                <NoDataFoundV2
                  icon={<ImLink />}
                  title={" User hasn't connected any broker yet"}
                />
              </div>
            </div>
          )}

          {/* <div className="broker py-3.5">
            <div className="row align-items-center">
              <div className="col-9 col-md-10">
                <div className="d-flex align-items-center gap-3.5">
                  <div className="w-[65px] h-[65px] rounded-md bg-color-base-2"></div>
                  <div className="brokerName">
                    <h5 className="text-color-primary font-semibold">
                      Angel Broking
                    </h5>
                    <p className="text-xs text-color-secondary/90">
                      Keep eye on on your Repositories
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-3 col-md-2 text-right">
                <Switch defaultChecked={"checked"} />
              </div>
            </div>
          </div>

          <div className="broker py-3.5">
            <div className="row align-items-center">
              <div className="col-9 col-md-10">
                <div className="d-flex align-items-center gap-3.5">
                  <div className="w-[65px] h-[65px] rounded-md bg-color-base-2"></div>
                  <div className="brokerName">
                    <h5 className="text-color-primary font-semibold">
                      Angel One
                    </h5>
                    <p className="text-xs text-color-secondary/90">
                      Integrate Projects Discussions
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-3 col-md-2 text-right">
                <Switch defaultChecked={"checked"} />
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="flex justify-end mt-2">
          <div>
            <button
              className={`${btnPrimaryClass} text-sm`}
              // className="saveBttn"
            >
              Save Changes
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}
