import { Link, useParams } from "react-router-dom";
import {
  useVTLTClosedOrderPNL,
  useVTLTOrderTradeList,
  useViewLTVTReportList,
} from "../../../../Component/Common/hooks/useViewLTVTReportList";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiDollarCircle } from "react-icons/bi";
import { MdOutlineInstallDesktop } from "react-icons/md";
import { RiTimerLine } from "react-icons/ri";
import moment from "moment";
import {
  DATEFORMAT,
  convertToTwoDigitDecimal,
  formatINR,
} from "../../../../Utils/utils";
import dayjs from "dayjs";
import useExportFiles from "../../../../Component/Common/hooks/useExportFiles";
import { getVTLTTradeBYID } from "../../../../Component/helpers/api/StrategyPermission/strategyPermissions";

const useViewVTLTReport = () => {
  const { id, tab } = useParams();
  const [toggleState, setToggleState] = useState("trades");
  const [isMobile, setIsMobile] = useState(false);
  const { exportFiles } = useExportFiles();

  const toggleTab = useCallback((index) => {
    setToggleState(index);
  }, []);

  //Responsiveness
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    // VTpage,
    // setVTPage,
    // VTlimit,
    // setVTlimit,
    // VTselectedDate,
    // setVTSelectedDate,
    VTReportList,
    refetchVTReport,
    isLoadingVTReport,
    // LTpage,
    // setLTPage,
    // LTlimit,
    // setLTlimit,
    // LTselectedDate,
    // setLTSelectedDate,
    LTReportList,
    refetchLTReport,
    isLoadingLTReport,
    isRefetchingReport,
  } = useViewLTVTReportList(id, tab);

  const {
    // VTselectedDatePNL,
    // setVTSelectedDatePNL,
    VTClosedOrderPNL,
    refetchVTClosedOrderPNL,
    isLoadingVTClosedOrderPNL,
    // LTselectedDatePNL,
    // setLTSelectedDatePNL,
    LTClosedOrderPNL,
    refetchLTClosedOrderPNL,
    isLoadingLTClosedOrderPNL,
    isRefetchingPNL,
  } = useVTLTClosedOrderPNL(id, tab);

  const {
    page,
    setPage,
    limit,
    setLimit,
    selectedDate,
    setSelectedDate,
    tradeData,
    total,
    refetchData,
    isLoading,
    isRefetchingTrade,
  } = useVTLTOrderTradeList(id, tab);

  const handleChangeDate = useCallback(
    (date, dateString) => {
      if (dateString[0] !== "" && dateString[1] !== "" && dateString) {
        setSelectedDate(dateString);
      } else {
        setSelectedDate("today");
      }
    },
    [setSelectedDate]
  );

  const strategyCardData = useMemo(() => {
    const PNL = tab === "VT" ? VTClosedOrderPNL?.data : LTClosedOrderPNL?.data;
    const item =
      tab === "VT"
        ? VTReportList?.data?.request_data
        : LTReportList?.data?.request_data;

    return [
      {
        icon: (
          <>
            <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
          </>
        ),
        title: "Strategy Name",
        subtitle: item?.strategyData?.name || "-",
      },
      {
        icon: (
          <>
            <RiTimerLine className="text-4xl text-blue-2" />
          </>
        ),
        title: "Created At",
        subtitle: moment(item?.created_at)?.format(DATEFORMAT),
      },
      {
        icon: (
          <>
            <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
          </>
        ),
        title: "Overall P&L",
        subtitle: PNL?.totalPnl || "-",
      },
      {
        icon: (
          <>
            <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
          </>
        ),
        title: "Overall Brokerage",
        subtitle: PNL?.totalBrokerage || "-",
      },

      {
        icon: (
          <>
            <BiDollarCircle className="text-4xl text-blue-2" />
          </>
        ),
        title: "Max Cap",
        subtitle:
          item?.vt_inputs?.investment || item?.lt_inputs?.investment || "N.A",
      },
    ];
  }, [
    LTClosedOrderPNL?.data,
    VTClosedOrderPNL?.data,
    tab,
    LTReportList?.data?.request_data,
    VTReportList?.data?.request_data,
  ]);

  const rangePresets = useMemo(
    () => [
      {
        label: "Last 7 Days",
        value: [dayjs().add(-7, "d"), dayjs()],
      },
      {
        label: "Last 14 Days",
        value: [dayjs().add(-14, "d"), dayjs()],
      },
      {
        label: "Last 30 Days",
        value: [dayjs().add(-30, "d"), dayjs()],
      },
      {
        label: "Last 90 Days",
        value: [dayjs().add(-90, "d"), dayjs()],
      },
    ],
    []
  );

  const insertNote = useCallback(() => {
    return getVTLTTradeBYID(id, 1, total, selectedDate, tab);
  }, [id, total, selectedDate, tab]);

  const exportOptions = useMemo(() => {
    return [
      // {
      //   key: "1",
      //   label: (
      //     <Link
      //       to={`#`}
      //       className="d-flex align-items-center hover:no-underline"
      //     >
      //
      //     </Link>
      //   ),
      //   onClick: () => {
      //     exportFiles("PDF", "Trades", insertNote);
      //   },
      //   disabled: isLoading || total === 0,
      // },
      {
        key: "2",
        label: (
          <span href="#" className="d-flex align-items-center">
            Save as CSV
          </span>
        ),
        onClick: () => {
          exportFiles({
            fileType: "CSV",
            fileName: "Trades",
            InvolkeAPI: insertNote,
            from: "StrategyTrade",
            addl: {
              reportName:
                tab === "VT"
                  ? VTReportList?.data?.request_data?.vt_inputs?.report_name
                  : LTReportList?.data?.request_data?.lt_inputs?.report_name,
              strategyName:
                tab === "VT"
                  ? VTReportList?.data?.request_data?.strategyData?.name
                  : LTReportList?.data?.request_data?.strategyData?.name,
            },
          });
        },
        disabled: isLoading || total === 0,
      },
      {
        key: "3",
        label: (
          <span href="#" className="d-flex align-items-center">
            Save as Excel
          </span>
        ),
        onClick: () => {
          exportFiles({
            fileType: "EXCEL",
            fileName: "Trades",
            InvolkeAPI: insertNote,
            from: "StrategyTrade",
            addl: {
              reportName:
                tab === "VT"
                  ? VTReportList?.data?.request_data?.vt_inputs?.report_name
                  : LTReportList?.data?.request_data?.lt_inputs?.report_name,
              strategyName:
                tab === "VT"
                  ? VTReportList?.data?.request_data?.strategyData?.name
                  : LTReportList?.data?.request_data?.strategyData?.name,
            },
          });
        },
        disabled: isLoading || total === 0,
      },
    ];
  }, [
    exportFiles,
    insertNote,
    isLoading,
    total,
    LTReportList?.data?.request_data?.lt_inputs?.report_name,
    LTReportList?.data?.request_data?.strategyData?.name,
    VTReportList?.data?.request_data?.strategyData?.name,
    VTReportList?.data?.request_data?.vt_inputs?.report_name,
    tab,
  ]);

  const values = useMemo(() => {
    return {
      page,
      setPage,
      limit,
      setLimit,
      selectedDate,
      setSelectedDate,
      tradeData,
      totalTraderData: total,
      ReportList: tab === "VT" ? VTReportList : LTReportList,
      refetchReport: tab === "VT" ? refetchVTReport : refetchLTReport,
      isLoadingReport: tab === "VT" ? isLoadingVTReport : isLoadingLTReport,
      requestData:
        tab === "VT"
          ? VTReportList?.data?.request_data
          : LTReportList?.data?.request_data,
      strategyCardData,
      toggleState,
      toggleTab,
      isMobile,
      handleChangeDate,
      rangePresets,
      refetchPNL:
        tab === "VT" ? refetchVTClosedOrderPNL : refetchLTClosedOrderPNL,
      isLoadingPNL:
        tab === "VT" ? isLoadingVTClosedOrderPNL : isLoadingLTClosedOrderPNL,
      refetchTrade: refetchData,
      isLoadingTrade: isLoading,
      isRefetchingTrade,
      isRefetchingPNL,
      isRefetchingReport,
      exportOptions,
    };
  }, [
    tab,
    page,
    setPage,
    isLoading,
    limit,
    setLimit,
    selectedDate,
    setSelectedDate,
    tradeData,
    total,
    LTReportList,
    VTReportList,
    isLoadingLTReport,
    isLoadingVTReport,
    refetchLTReport,
    refetchVTReport,
    strategyCardData,
    toggleState,
    toggleTab,
    isMobile,
    rangePresets,
    handleChangeDate,
    isLoadingLTClosedOrderPNL,
    isLoadingVTClosedOrderPNL,
    refetchData,
    refetchLTClosedOrderPNL,
    refetchVTClosedOrderPNL,
    isRefetchingTrade,
    isRefetchingPNL,
    isRefetchingReport,
    exportOptions,
  ]);

  return values;
};

export default useViewVTLTReport;
