import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectedUserData } from "../../../../../redux/UserDetails/userDetails.slice";
import useActivitiesList from "../../../../../Component/Common/hooks/useActivitiesList";
import { RiWindowsFill } from "react-icons/ri";
import moment from "moment";
import { DATEFORMAT } from "../../../../../Utils/utils";

const useUserActivities = () => {
  const userData = useSelector(selectedUserData);
  const { selectedUser } = userData;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  const { data, isLoading } = useActivitiesList(page, limit, selectedUser?._id);

  const activities = useMemo(() => {
    const modifiedData = [];
    data?.data?.data?.map((item) => {
      modifiedData.push({
        color: "green",
        children: (
          <>
            <p className="text-base font-medium">{`${item?.activity} ${moment(
              item?.created_at
            ).format(DATEFORMAT)}`}</p>
            {/* <p>Invoices have been paid to the company</p> */}
          </>
        ),
      });
      return item;
    });
    return modifiedData || [];
  }, [data]);

  const dataSource = useMemo(
    () => [
      {
        key: "1",
        browser: (
          <>
            <div className="flex items-center gap-1.5">
              <RiWindowsFill className="text-blue-2" />
              <span>Chrome on Windows</span>
            </div>
          </>
        ),
        device: (
          <>
            <div className="flex items-center gap-1.5">HP Spectre 360</div>
          </>
        ),
        location: (
          <>
            <div className="flex items-center gap-1.5">Switzerland</div>
          </>
        ),
        activities: (
          <>
            <div className="flex items-center gap-1.5">10, July 2021 20:07</div>
          </>
        ),
      },
      {
        key: "2",
        browser: (
          <>
            <div className="flex items-center gap-1.5">
              <RiWindowsFill className="text-blue-2" />
              <span>Chrome on Windows</span>
            </div>
          </>
        ),
        device: (
          <>
            <div className="flex items-center gap-1.5">HP Spectre 360</div>
          </>
        ),
        location: (
          <>
            <div className="flex items-center gap-1.5">Switzerland</div>
          </>
        ),
        activities: (
          <>
            <div className="flex items-center gap-1.5">10, July 2021 20:07</div>
          </>
        ),
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        title: "Browser",
        dataIndex: "browser",
        key: "browser",
      },
      {
        title: "Device",
        dataIndex: "device",
        key: "device",
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
      },
      {
        title: "Activities",
        dataIndex: "activities",
        key: "activities",
      },
    ],
    []
  );

  const values = useMemo(() => {
    return {
      activities,
      dataCount: data?.data?.count,
      //   loggedInUser,
      isLoading,
      //   selectedUser,
      dataSource,
      columns,
      setPage,
      setLimit,
    };
  }, [
    data?.data?.count,
    // loggedInUser,
    activities,
    isLoading,
    // selectedUser,
    dataSource,
    columns,
  ]);

  return values;
};

export default useUserActivities;
