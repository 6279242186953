import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import {
  userCreditList,
  userLiveDeployments,
  userPremiumStrategies,
  userVirtualDeployments,
} from "../../../../../redux/Trader/traderCredits.slice";
import { getActivePlans } from "../../../../../Component/helpers/api/Trade/tradeList";

const useUserCreditDetails = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const {
    data: creditsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["userCreditsDetails", id],
    queryFn: async () => {
      if (id) {
        return await getActivePlans(id);
      } else {
        return null;
      }
    },
  });

  const allData = useMemo(() => {
    dispatch(userCreditList(creditsData));
    dispatch(
      userPremiumStrategies({
        strategies_remaining:
          creditsData?.premium_strategies_subscribe?.remaining,
        strategies_total: creditsData?.premium_strategies_subscribe?.total,
        strategies_used: creditsData?.premium_strategies_subscribe?.used,
      })
    );
    dispatch(
      userVirtualDeployments({
        requests_remaining: creditsData?.virtual_deployments?.remaining,
        requests_total: creditsData?.virtual_deployments?.total,
        requests_used: creditsData?.virtual_deployments?.used,
      })
    );
    dispatch(
      userLiveDeployments({
        requests_remaining: creditsData?.live_deployments?.remaining,
        requests_total: creditsData?.live_deployments?.total,
        requests_used: creditsData?.live_deployments?.used,
      })
    );
    return {
      creditsData,
      isLoading,
      refetch,
    };
  }, [creditsData, dispatch, isLoading, refetch]);

  return allData;
};

export default useUserCreditDetails;
