import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import { useMemo } from "react";
import { HiViewGridAdd } from "react-icons/hi";
import { btnPrimaryClass } from "../../../Component/utils/DefaultClasses";
import { useDispatch } from "react-redux";
import { encodeDecodeAddRoleData } from "../../../Component/Common/hooks/useAddRole";
import { updateUserDetailsStore } from "../../../redux/AddRole/addRole.slice";

export default function AccessRoleCard({
  roleData,
  setAddRoleModal,
  setDeleteRole,
  setDeleteuserID,
  roleAccess,
}) {
  const dispatch = useDispatch();

  const totalUsers = useMemo(() => {
    return (
      Number(roleData?.total_admin_user) + Number(roleData?.total_trader_user)
    );
  }, [roleData]);

  const avatars = useMemo(() => {
    return [...roleData?.traderUsersData, ...roleData?.adminUsersData];
  }, [roleData]);

  return (
    <>
      <div className="relative flex justify-between p-3.5 border-[1px] shadow-md border-level-4 rounded-lg w-full">
        <div className="w-full">
          <div className="flex justify-between">
            <div>
              <span className="text-sm text-color-primary">
                Total {totalUsers} users
              </span>
            </div>
            <div>
              <Avatar.Group>
                {avatars?.length > 0 ? (
                  avatars?.map(
                    (avatar, index) =>
                      index < 4 &&
                      (index === 3 ? (
                        <>
                          <Avatar>+{avatars?.length - 3}</Avatar>
                        </>
                      ) : (
                        <Tooltip title={avatar?.first_name + avatar?.last_name}>
                          <Avatar
                            src={`https://eu.ui-avatars.com/api/?name=${
                              avatar?.first_name + avatar?.last_name
                            }&size=250`}
                          />
                        </Tooltip>
                      ))
                  )
                ) : (
                  <div className="w-[30px] h-[30px]"></div>
                )}
              </Avatar.Group>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-start">
              <span className="text-color-primary text-base xl:text-lg font-medium">
                {roleData?.role_name}
              </span>
              {roleAccess?.isEdit && (
                <button
                  className="text-xs text-blue-3"
                  onClick={() => {
                    let payload = {
                      data: encodeDecodeAddRoleData(roleData, "decode"),
                      selectionType: "edit",
                      role_name: roleData?.role_name,
                      role_id: roleData?._id,
                    };
                    dispatch(updateUserDetailsStore(payload));
                    setAddRoleModal(true);
                  }}
                >
                  Edit Role
                </button>
              )}
            </div>
            {roleData?.type !== "default" && (
              <button
                onClick={() => {
                  setDeleteuserID(roleData?._id);
                  setDeleteRole(true);
                }}
                className="cursor-pointer absolute bottom-3 right-4 bg-red-2 rounded-full text-whiteNew-0 p-0.5 text-xs px-1 w-[28px] h-[28px] "
              >
                <DeleteOutlined />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export function AddAccessRoleCard({ title }) {
  return (
    <>
      <div className="flex justify-between p-6 border-[1px] shadow-md border-level-4 rounded-xl w-full h-full">
        <div className="w-full">
          <div className="flex justify-between">
            <div>
              <div className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                <HiViewGridAdd className="" />
              </div>
            </div>
            <div>
              <button
                className={`${btnPrimaryClass} text-sm`}
                // onClick={() => setAddRoleModal(true)}
              >
                <PlusOutlined />
                Add New Role
              </button>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="flex flex-col items-start">
              <span className="text-color-primary text-sm">
                Add role, if it does not exist
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
