import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserPermission from "../../Common/hooks/useUserPermission";

const useLeftSideBar = () => {
  // SideBar Collapse
  const history = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = window.location.pathname;

  const { isPermittedTabs } = useUserPermission();

  const collpse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    if (isCollapsed) {
      document.body.classList.add("toggle-on");
    } else {
      document.body.classList.remove("toggle-on");
    }
    return () => {
      document.body.classList.remove("toggle-on");
    };
  }, [isCollapsed]);

  // const closeModal = (Component) => {
  // 	profileVisible ? setProfileVisibility(false) : setProfileVisibility(true);
  // };

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isActiveFunc = useCallback(() => {
    const matchIncludes = [
      "/strategy/permission",
      "/strategies",
      "/strategy",
      "/view-report",
    ];

    return matchIncludes.some((path) => location.startsWith(path));
  }, [location]);

  console.log("isActiveFunc", isActiveFunc());

  const logOut = useCallback(() => {
    localStorage.removeItem("adminToken");
    history("/");
  }, [history]);

  const isTraderActiveFunc = useCallback(() => {
    const matchIncludes = ["/traders", "/user/profile"];
    let mainPart = matchIncludes.some((path) =>
      window.location.pathname.startsWith(path)
    );
    return mainPart;
  }, []);

  const values = useMemo(() => {
    return {
      isCollapsed,
      isMobile,
      location,
      isPermitted: isPermittedTabs,
      collpse,
      isActiveFunc,
      logOut,
      isTraderActiveFunc,
    };
  }, [
    isCollapsed,
    isMobile,
    location,
    isPermittedTabs,
    collpse,
    isActiveFunc,
    logOut,
    isTraderActiveFunc,
  ]);
  return values;
};

export default useLeftSideBar;
