import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { userPermissionData } from "../../../redux/userPermissions/userPermissions.slice";

const useUserPermission = () => {
  const permissions = useSelector(userPermissionData);

  const userPermission = useMemo(() => {
    return permissions?.permissions || {};
  }, [permissions]);

  const isEditAccess = useCallback(
    (tab) => {
      const checkPermission = userPermission[tab];
      if (checkPermission && checkPermission?.length > 0) {
        return checkPermission?.includes("edit");
      } else {
        return false;
      }
    },
    [userPermission]
  );

  const isCreateAccess = useCallback(
    (tab) => {
      const checkPermission = userPermission[tab];
      if (checkPermission && checkPermission?.length > 0) {
        return checkPermission?.includes("create");
      } else {
        return false;
      }
    },
    [userPermission]
  );

  const isPermittedTabs = useCallback(
    (tabName) => {
      const checkPermission = userPermission[tabName];
      if (checkPermission && checkPermission?.length > 0) {
        return checkPermission?.includes("access");
      } else {
        return false;
      }
    },
    [userPermission]
  );

  return { userPermission, isEditAccess, isCreateAccess, isPermittedTabs };
};

export default useUserPermission;
