import { Checkbox, Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import {
	afterBorder,
	btnPrimaryClass,
	btnSecondayClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import Drawer from "../../../Component/Common/Drawer";
import usePlanDetailContent from "./hooks/usePlanDetailContent";
import CustomCurrencyInput from "../../../Component/Common/CustomCurrencyInput ";
import { useState } from "react";
import TableComponent from "../../../Component/TableComponent";
import NoDataFoundV2 from "../../../Component/NoDataFoundV2";
import { BsPersonLock } from "react-icons/bs";

export default function PlanDetailContent({ refetch }) {
	const {
		form,
		initialValues,
		selectedPlan,
		selectionType,
		isOpen,
		isLoading,
		fieldTouched,
		dataSource,
		columns,
		setIsFieldTouched,
		closeDrawer,
		onSubmit,
	} = usePlanDetailContent(refetch);

	const [toggleState, setToggleState] = useState("plan_detail");

	return (
		<>
			<Drawer
				title={
					<>
						<div className="flex justify-between items-center">
							<div className="flex flex-col">
								<span className="flex justify-center items-center gap-1.5">
									Plan Name:{" "}
									<span className="flex bg-blue-2/15 text-blue-2 p-0.5 px-2.5 rounded-md text-sm">
										{selectedPlan?.plan_name}
									</span>
								</span>
								{/* <span className="text-color-secondary/80 text-xs font-normal">
									24-May-2024 01:29:15 pm
								</span> */}
							</div>
							<div className="flex -mt-2"></div>
						</div>
					</>
				}
				onClose={closeDrawer}
				open={isOpen}
				className={``}
				rootClassName={"drawer-root-className"}
				width={"640px"}
				footer={
					toggleState === "plan_detail" ? (
						<>
							<div className="d-flex justify-content-end gap-2 ">
								<div className="">
									<button
										variant="primary"
										className={`${btnSecondayClass} m-0`}
										onClick={closeDrawer}
										disabled={isLoading}>
										Cancel
									</button>
								</div>
								<div className="" onClick={() => form.submit()}>
									<button
										disabled={
											isLoading || selectionType === "view" || !fieldTouched
										}
										className={`${btnPrimaryClass} m-0`}>
										Publish
									</button>
								</div>
							</div>
						</>
					) : null
				}>
				<div className="">
					<div
						className={`strtgy__table__box border-0 -mt-8 mb-2 -mx-6 relative ${afterBorder}`}>
						<ul className="flex border-0 gap-2">
							<li
								onClick={() => setToggleState("plan_detail")}
								className={toggleState === "plan_detail" ? "active" : "tabs"}>
								<span>Plan Details</span>
							</li>
							<li
								onClick={() => setToggleState("active_traders")}
								className={
									toggleState === "active_traders" ? "active" : "tabs"
								}>
								<span>Active Traders</span>
							</li>
							{/* <li
								onClick={() => setToggleState("security")}
								className={toggleState === "security" ? "active" : "tabs"}>
								<span>Security</span>
							</li> */}
						</ul>
					</div>
					{toggleState === "plan_detail" && (
						<Form
							layout="vertical"
							form={form}
							disabled={selectionType === "view" || isLoading}
							initialValues={initialValues}
							onFinish={onSubmit}
							onFieldsChange={() => !fieldTouched && setIsFieldTouched(true)}>
							<Form.Item
								// label="Email *"
								label={
									<span className="flex items-center">
										<span>Plan Name</span>
										<span className="text-red-500 ml-1">*</span>
									</span>
								}
								className="mb-2"
								name="plan_name">
								<Input
									className={`${formClass}`}
									placeholder="Plan Name"
									name="plan_name"
								/>
							</Form.Item>
							<Form.Item
								// label="Email *"
								label={
									<span className="flex items-center">
										<span>Plan Price</span>
										<span className="text-red-500 ml-1">*</span>
									</span>
								}
								className="mb-2"
								name="plan_price">
								{/* <Input
                className={`${formClass}`}
                placeholder="Plan Price"
                name="plan_price"
                type="number"
              /> */}
								<CustomCurrencyInput
									placeholder="Plan Price"
									prefix="₹ "
									thousandSeparator
									allowNegative={false}
									disabled={selectionType === "view" || isLoading}
									className={`${formClass}`}
								/>
							</Form.Item>
							<Form.List name="features">
								{(fields, { add, remove }) => (
									<>
										<div className={`mt-6 mb-3 relative pb-2.5 ${afterBorder}`}>
											<h6 className="text-lg font-medium">Features</h6>
										</div>
										<div className="space-y-2">
											{fields.map((field) => (
												<>
													<Row className={`items-center`}>
														<Col xs={5} lg={6}>
															<Form.Item
																// label="Email *"
																label={
																	<>
																		<span className="flex items-center gap-0.5">
																			Feature Name
																		</span>
																	</>
																}
																className="mb-0"
																name={[field.name, "name"]}>
																<Input
																	className={`${formClass}`}
																	placeholder="Feature Name"
																	name="feature_type"
																	disabled={true}
																/>
															</Form.Item>
														</Col>
														<Col xs={2} lg={1}>
															<div className="flex justify-center class-name mt-[27px]">
																<Form.Item
																	// label="Email *"
																	// label={
																	// 	<>
																	// 		<span className="flex items-center gap-0.5">
																	// 			Strategy Type
																	// 		</span>
																	// 	</>
																	// }
																	className="mb-0"
																	name={[field.name, "feature_available"]}
																	valuePropName="checked">
																	<Checkbox />
																</Form.Item>
															</div>
														</Col>
														<Col xs={5} lg={5}>
															<Form.Item
																// label="Email *"
																label={
																	<>
																		<span className="flex items-center gap-0.5">
																			Feature Value
																		</span>
																	</>
																}
																className="mb-0"
																name={[field.name, "value"]}
																//   rules={[
																//     {
																//       required: true,
																//       message: "Feature value is required.",
																//     },
																//   ]}
															>
																<Input
																	className={`${formClass}`}
																	placeholder="Feature Value"
																	name="value"
																/>
															</Form.Item>
														</Col>
													</Row>
												</>
											))}
										</div>
										{/* <div className="mt-3">
                    <button
                      className={`${btnPrimaryClass} text-xs`}
                      onClick={() => add()}
                      disabled={isLoading || selectionType === "view"}
                    >
                      Add More
                    </button>
                  </div> */}
									</>
								)}
							</Form.List>
						</Form>
					)}

					{toggleState === "active_traders" && dataSource?.length > 0 ? (
						<div>
							<TableComponent
								dataSource={dataSource}
								columns={columns}
								total={dataSource?.length}
								pageSize={10}
								pagination={dataSource?.length <= 10 ? false : true}
							/>
						</div>
					) : (
						toggleState === "active_traders" && (
							<NoDataFoundV2
								title={"Users not subscribed to this plan."}
								icon={<BsPersonLock />}
							/>
						)
					)}
				</div>
			</Drawer>
		</>
	);
}
