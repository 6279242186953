import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPlan: {},
  selectionType: "",
  isOpen: false,
};

const userPlanList = {
  selectedUserPlan: {},
  isOpen: false,
};

const slice = createSlice({
  name: "planList",
  initialState,
  reducers: {
    updateSelectedPlan: (state, { payload }) => {
      state.selectedPlan = payload?.selectedPlan;
      state.selectionType = payload?.selectionType;
      state.isOpen = payload?.isOpen;
    },
  },
});

const userPlanslice = createSlice({
  name: "UserplanList",
  initialState: userPlanList,
  reducers: {
    updateSelectedUserPlan: (state, { payload }) => {
      state.selectedUserPlan = payload?.selectedUserPlan;
      state.isOpen = payload?.isOpen;
    },
  },
});

let updatePlanReducer = slice.reducer;
let UserPlanReducer = userPlanslice.reducer;

export const { updateSelectedPlan } = slice.actions;
export const { updateSelectedUserPlan } = userPlanslice.actions;

const updateSelectedPlanDetails = (state) => state.updateSelectedPlanDetails;
const SelectedUserPlanDetails = (state) => state.SelectedUserPlanDetails;

export {
  updatePlanReducer,
  updateSelectedPlanDetails,
  UserPlanReducer,
  SelectedUserPlanDetails,
};
