import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import mydecodedTokenFunction from "../../../../Utils/decodedtoken";
import { apiHeader } from "../../../../Utils/utils";
import ENV from "../../../../Constants/env";

export async function getVirtualTrade(
  page = 1,
  limit = 10,
  start = "",
  end = "",
  key = ""
) {
  try {
    const res = await axios.get(`${API_URL.GET_VT_TRADE_LIST}`, {
      params: {
        page,
        limit,
        start,
        end,
        key,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching virtual trade data:", error);
    throw error;
  }
}

export async function getLiveTrade(
  page = 1,
  limit = 10,
  start = "",
  end = "",
  key = ""
) {
  try {
    const res = await axios.get(`${API_URL.GET_LT_TRADE_LIST}`, {
      params: {
        page,
        limit,
        start,
        end,
        key,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching virtual trade data:", error);
    throw error;
  }
}

export async function getActivePlans(id) {
  const res = await axios.get(`${API_URL.ACTIVE_PLANS}?user_id=${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
    },
  });

  return res.data;
}
