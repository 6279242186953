import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { afterBorderRight } from "../../../Component/utils/DefaultClasses";

export default function StrategyOverview({ data }) {
  return (
    <>
      <div>
        <div className="p-10">
          <Row>
            <Col lg={6} className={`${afterBorderRight} relative`}>
              <div className="flex flex-col gap-3 pr-6">
                <h3 className="text-xl">{data?.name}</h3>
                <p className="text-color-secondary text-sm">
                  {data?.description}
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="pl-10">
                <div className="flex items-center ">
                  <div className="flex gap-2 w-full max-w-[250px] items-center border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden p-3.5">
                    <div>
                      <div>
                        <span className="text-color-primary font-semibold flex items-center gap-1 text-2xl">
                          12
                        </span>
                        <span className="text-color-secondary text-sm flex items-center gap-1 -mt-1">
                          <span className="text-color-secondary/80">
                            <span>EMA PERIOD</span>
                          </span>
                          <span>
                            <AiOutlineInfoCircle className="text-xl" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
