import { useCallback, useMemo, useState } from "react";
import { useStrategyListPermission } from "../../../../Component/Common/hooks/useStrategyPermissionList";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Dropdown } from "antd";
import { MdKeyboardArrowDown } from "react-icons/md";
import mydecodedTokenFunction from "../../../../Utils/decodedtoken";
import useUpdateStrategy from "../../../../Component/Common/hooks/useUpdateStrategy";
import {
  DATEFORMAT,
  convertToTwoDigitDecimal,
  formatINR,
} from "../../../../Utils/utils";
import useUserPermission from "../../../../Component/Common/hooks/useUserPermission";

const useStrategyPermission = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [search, setsearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("all");

  const { data, refetch, isLoading, isRefetching } = useStrategyListPermission(
    currentPage,
    pageLimit,
    { search, categorySearch }
  );
  const { onUpdate, isLoading: isLoadingUpdate } = useUpdateStrategy(refetch);

  let adminid =
    mydecodedTokenFunction(localStorage.getItem("adminToken")) || "";

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: isCreateAccess("strategy_management"),
      isEdit: isEditAccess("strategy_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  const handleEdit = useCallback(
    (status, id) => {
      let payload = {
        status: status,
        strategy_id: id,
      };
      onUpdate(payload);
    },
    [onUpdate]
  );

  const listData = useCallback(
    (data) => {
      // console.log(data);
      var dataSource = [];
      data?.map((item) => {
        dataSource.push({
          key: item.index,
          id: item._id,
          enabled:
            (item?.statusType === "processing" ||
              item?.statusType === "rejected") &&
            item?.access_type
              ? false
              : true,
          strategy_name: (
            <Link
              to={`/strategy/${item._id}`}
              className={` ${
                (item?.statusType === "processing" ||
                  item?.statusType === "rejected") &&
                item?.access_type
                  ? "cursor-not-allowed hover:no-underline"
                  : "text-blue-2"
              }`}
            >
              {item?.name || "-"}
            </Link>
          ),
          user_name: (
            <Link to={"#"} className="text-dark pointer-events-none">
              {item?.userData
                ? `${item?.userData?.first_name} ${item?.userData?.last_name}`
                : item?.adminUserData
                ? `${item?.adminUserData?.first_name} ${item?.adminUserData?.last_name}`
                : "-"}
            </Link>
          ),
          instrument: <div className="">{item?.instrument} </div>,
          min_cap: (
            <div className="">
              {formatINR(convertToTwoDigitDecimal(item?.min_amount_required))}
            </div>
          ),
          createdAt: moment(item.created_at).format(DATEFORMAT),
          status: (
            <>
              <div className="flex">
                <span
                  className={`px-1.5 py-0.5 rounded-md text-sm capitalize ${
                    item?.statusType === "processing"
                      ? "bg-yellow-200 text-yellow-500"
                      : item?.statusType === "active"
                      ? "bg-green-2/15 text-green-2"
                      : "bg-red-2/15 text-red-2"
                  }`}
                  //onClick={() => ChangeStatus(item.status, item._id)}
                >
                  {item?.statusType !== "" &&
                  item?.statusType !== null &&
                  item?.statusType !== undefined
                    ? item?.statusType
                    : "-"}
                </span>
              </div>
            </>
          ),

          disabled:
            (item?.statusType === "processing" ||
              item?.statusType === "rejected") &&
            item?.access_type
              ? true
              : false,
          action: (
            <>
              <span className="flex">
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <span className="d-flex align-items-center">
                            View
                          </span>
                        ),
                        onClick: () => {
                          navigate(`/strategy/${item._id}`);
                        },
                        disabled:
                          isLoadingUpdate ||
                          item?.statusType === "processing" ||
                          item?.statusType === "rejected",
                      },
                      {
                        key: "2",
                        label: (
                          <span className="d-flex align-items-center">
                            {item?.status ? "Unpublish" : "Publish"}
                          </span>
                        ),
                        onClick: () => {
                          handleEdit(item?.status ? false : true, item?._id);
                        },
                        disabled:
                          item?.statusType === "processing" ||
                          item?.statusType === "rejected" ||
                          isLoadingUpdate,
                        hidden: !Access?.isEdit,
                      },
                    ],
                  }}
                  placement="bottomRight"
                  overlayClassName={"dropdownOverlay dowpdownDark"}
                  trigger={["click"]}
                  className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
                >
                  <div onClick={(e) => e.preventDefault()}>
                    Action
                    <MdKeyboardArrowDown />
                  </div>
                </Dropdown>
              </span>
            </>
          ),
        });
        return item;
      });

      return dataSource;
    },
    [handleEdit, isLoadingUpdate, navigate, Access?.isEdit]
  );

  // Table Header
  const columns = useMemo(
    () => [
      {
        title: "Strategy Name",
        dataIndex: "strategy_name",
      },
      {
        title: "Instrument",
        dataIndex: "instrument",
      },
      {
        title: "Min. Capital Required",
        dataIndex: "min_cap",
      },
      // {
      // 	title: "Risk",
      // 	dataIndex: "risk",
      // },
      // {
      // 	title: "Reports",
      // 	dataIndex: "reports",
      // },
      // {
      // 	title: "Subscribed",
      // 	dataIndex: "subscribed",
      // },
      {
        title: "User Name",
        dataIndex: "user_name",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
      },
      {
        title: "Status",
        dataIndex: "status",
      },

      {
        title: "Action",
        dataIndex: "action",
      },
    ],
    []
  );

  const values = useMemo(() => {
    return {
      data: data?.data?.data,
      dataCount: data?.data?.count,
      isRefetching,
      isLoading,
      currentPage,
      pageLimit,
      columns,
      adminid,
      categorySearch,
      setCategorySearch,
      setsearch,
      listData,
      setCurrentPage,
      setPageLimit,
      refetch,
    };
  }, [
    data,
    currentPage,
    pageLimit,
    isRefetching,
    columns,
    adminid,
    isLoading,
    categorySearch,
    listData,
    setCurrentPage,
    setPageLimit,
    refetch,
  ]);
  return values;
};

export default useStrategyPermission;
