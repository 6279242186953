import { ConfigProvider, Select } from "antd";
import { AttachmentCard } from "./SupportChatBody";
import useTicketRightSideBar from "../hooks/useTicketRightSideBar";

function SupportChatRightBar({ selectedTicket }) {
  const { onSubmit, options } = useTicketRightSideBar();

  return (
    <div className="w-[400px] shrink-0 flex flex-col gap-2">
      <div className="my-2 flex flex-row justify-between shadow-md rounded-lg items-center border-[1px] border-zinc-200 mt-3">
        {/* <span className="font-medium">Ticket Action</span>
                <DownOutlined /> */}
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Inter",
            },
          }}
        >
          <Select
            className="w-full h-[42px]"
            onChange={onSubmit}
            options={options}
            defaultValue={selectedTicket?.status}
          />
        </ConfigProvider>
      </div>

      <div>
        <div className="p-3 rounded-lg shadow-md border-[1px] border-level-2 space-y-2">
          <h3 className="font-medium mb-3">Visitor Information</h3>
          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-950">
            <span>Basic Details</span>
            {/* <span className="text-blue-500">Edit</span> */}
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>Email</span>
            <span className="text-blue-500">
              {selectedTicket?.userData?.email ?? "-"}
            </span>
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>Phone</span>
            <span className="text-blue-500">
              {selectedTicket?.userData?.phone_no ?? "-"}
            </span>
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>Location</span>
            <span className="text-blue-500">
              {selectedTicket?.userData?.location ?? "-"}
            </span>
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>Language</span>
            <span className="text-blue-500">-</span>
          </div>

          <div>
            <span className="bg-level-2 w-full h-[1px] my-3 flex flex-col"></span>
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-950">
            <span>Device Info</span>
            {/* <span className="text-blue-500">Edit</span> */}
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>IP</span>
            <span>{selectedTicket?.userData?.device_info?.ip ?? "-"}</span>
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>OS</span>
            <span>
              {selectedTicket?.userData?.device_info
                ? `${selectedTicket?.userData?.device_info?.osName} ${selectedTicket?.userData?.device_info?.osVersion}`
                : "-"}
            </span>
          </div>

          <div className="flex flex-row text-sm justify-between mb-2 text-zinc-600">
            <span>Browser</span>
            <span>
              {selectedTicket?.userData?.device_info?.browserName ?? "-"}
            </span>
          </div>
        </div>
      </div>

      <div>
        {selectedTicket?.attachments?.length > 0 && (
          <h3 className="font-medium mb-1 mt-2">File Shared</h3>
        )}
      </div>
      <div className="space-y-2">
        {selectedTicket?.attachments?.map((docs) => (
          <AttachmentCard doc={docs} key={docs.key} />
        ))}
      </div>
    </div>
  );
}

export default SupportChatRightBar;
