import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getUserDetails } from "../../../Component/helpers/api/userDetails/userDetails";
import { useDispatch } from "react-redux";
import { updateUserDataStore } from "../../../redux/UserDetails/userDetails.slice";
import { updateUserPermissions } from "../../../redux/userPermissions/userPermissions.slice";
import { getPermissions } from "../../../Utils/utils";
import useDecodedToken from "../../../Component/utils/useDecodedToken";

const useProfileView = () => {
  const token = useDecodedToken();
  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["emailVerify", token],
    queryFn: async () => {
      if (token) {
        return await getUserDetails();
      } else {
        return null;
      }
    },
  });

  const userDetails = useMemo(() => {
    dispatch(
      updateUserDataStore({
        data: data?.data?.data || {},
        roleManagement: data?.data?.data?.roleData,
      })
    );
    const permission = getPermissions(data?.data?.data?.roleData?.role_data);
    dispatch(updateUserPermissions(permission));
    return data?.data?.data || {};
  }, [data, dispatch]);

  const value = useMemo(() => {
    return {
      userDetails,
      isLoading,
      refetch,
    };
  }, [userDetails, isLoading, refetch]);

  return value;
};

export default useProfileView;
