import { useQuery } from "@tanstack/react-query";
import useDecodedToken from "../../utils/useDecodedToken";
import {
  getStaffUserActivities,
  getTraderUserActivities,
} from "../../helpers/api/userStaff/userStaff";
import { selectedUserData } from "../../../redux/UserDetails/userDetails.slice";
import { useSelector } from "react-redux";

const useActivitiesList = (page, limit, user_id) => {
  const decodedToken = useDecodedToken();
  const userData = useSelector(selectedUserData);
  const { loggedInUser } = userData;

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["ActivitiesList", decodedToken, page, limit, user_id],
    queryFn: async () => {
      if (decodedToken) {
        return loggedInUser
          ? await getTraderUserActivities(page, limit, user_id)
          : await getStaffUserActivities(page, limit, user_id);
      } else {
        return null;
      }
    },
  });

  return { data, refetch, isLoading };
};

export default useActivitiesList;
