import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { formClass } from "../utils/DefaultClasses";
import moment from "moment";
import { DATEFORMATFILTER } from "../../Utils/utils";

const { RangePicker } = DatePicker;

const CustomDatePicker = ({
  onChange,
  classname,
  presets = false,
  filterNotApplied = false,
}) => (
  <div className={`d-flex  ${classname}`}>
    <Space direction="vertical">
      <RangePicker
        onChange={onChange}
        presets={presets}
        className={`${formClass}`}
        popupClassName="cust_popup dark-ant-picker-dropdown"
        placeholder={[
          filterNotApplied ? "Start Date" : moment().format(DATEFORMATFILTER),
          filterNotApplied ? "End Date" : moment().format(DATEFORMATFILTER),
        ]}
        disabledDate={(current) => {
          return dayjs().add(0, "days") <= current;
        }}
      />
    </Space>
  </div>
);

export default CustomDatePicker;
