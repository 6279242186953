import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Leftbar from "../../../../Component/Admin/Leftbar";
import RightBar from "../../../../Component/RightBar";
import { toast } from "react-toastify";
import UserProfileCard from "../user_detail_compo/UserProfileCard";
import UserConnectedBroker from "../user_detail_compo/UserConnectedBroker";
import UserTabsDetail from "../user_detail_compo/UserTabsDetail";
import UserInvoice from "../user_detail_compo/UserInvoice";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { userDetail } from "../../../../Component/helpers/api/userDetails/userDetails";
import { useMutation } from "@tanstack/react-query";
import { toastSpecs } from "../../../../Utils/utils";
import useUserPermission from "../../../../Component/Common/hooks/useUserPermission";
import { userFeaturesData } from "../../../../redux/Trader/traderPlan.slice";
import { useDispatch } from "react-redux";

function UserProfile() {
  let { id } = useParams();
  const dispatch = useDispatch();

  // let token = localStorage.getItem("token");
  const [userData, setUserData] = useState({});
  const [activePlanDetails, setActivePlanDetails] = useState({});
  const [accountCreatedAt, setAccountCreatedAt] = useState("");

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: isCreateAccess("trader_management"),
      isEdit: isEditAccess("trader_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  useEffect(() => {
    getUser.mutate();
  }, []);

  const getUser = useMutation({
    mutationFn: () => userDetail(id),
    onSuccess: (res) => {
      setUserData(res?.data?.data);
      setAccountCreatedAt(res?.data?.data?.created_at);
      setActivePlanDetails(res?.data?.all_subscription_details[0]);
      console.log(
        "res?.data?.active_plan?.subscriptionPlanData?.features_data",
        res?.data?.active_plan?.subscriptionPlanData?.features_data
      );
      dispatch(
        userFeaturesData(
          res?.data?.active_plan?.subscriptionPlanData?.features_data
        )
      );
    },
    onError: (err) => {
      return toast.error("something went wrong server error", toastSpecs);
    },
  });

  return (
    <React.Fragment>
      <div className="">
        <div className="layout-row flex" id="main">
          <div className="">
            <Leftbar type="admin" />
          </div>
          <div className="left__bar w-full">
            <RightBar>
              <>
                <div className="headingSection userDetailsSection mb-3.5 d-flex justify-content-between align-items-center ">
                  <div className="d-flex align-items-center">
                    <div className="flex gap-2">
                      <div className="">
                        <div className="flex gap-2">
                          <div className="flex flex-col gap-0 -space-y-1">
                            <h2 className="strtgy__ttl">Account Overview</h2>
                            <Link
                              to={"/traders"}
                              className="sub__ttl d-flex gap-1 flex align-items-center mt-[2px]"
                            >
                              <HiOutlineArrowSmLeft className="text-lg" />
                              Go Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="row gy-4">
                    <div className="col-12 col-lg-12 col-xl-12 col-xxl-4">
                      <div className="space-y-9">
                        <UserProfileCard
                          refetch={getUser.mutate}
                          userData={userData}
                          activePlanDetails={activePlanDetails}
                        />
                        <UserConnectedBroker />
                      </div>
                    </div>

                    <div className="col-12 col-lg-12 col-xl-12 col-xxl-8">
                      <div className="space-y-6">
                        <UserTabsDetail
                          activePlanDetails={activePlanDetails}
                          accountCreatedAt={accountCreatedAt}
                          userData={userData}
                          refetch={getUser.mutate}
                        />
                        {/* <UserPaymentRecord /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </RightBar>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserProfile;
