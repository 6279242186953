import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import { apiHeader } from "../../../../Utils/utils";
import mydecodedTokenFunction from "../../../../Utils/decodedtoken";

export async function getStrategyListAPI(page, limit, dependencies) {
  console.log(dependencies, "dependencies");
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.GET_STRATEGY_LIST}?page=${page}&limit=${limit}&search_key=${dependencies?.search}&type=${dependencies?.categorySearch}`,
    json_header
  );

  return res;
}

export async function updateStrategyAPI(payload) {
  const { json_header } = apiHeader();

  const res = await axios.put(
    `${API_URL.UPDATE_PERMISSION}`,
    payload,
    json_header
  );

  return res;
}

export async function getStrategybyID(ID) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.GET_STRATEGY_BY_ID}/${ID}`,
    json_header
  );

  return res;
}

export async function getStrategySubscribedByID(ID, page, limit) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.GET_SUBSCRIBED_STRATEGY}=${ID}&page=${page}&limit=${limit}`,
    json_header
  );

  return res;
}

export async function getStrategyVirtualTradebyID(ID, page, limit, date) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${
      API_URL.GET_STRATEGY_VIRTUAL_Trade_BY_ID
    }/${ID}?page=${page}&limit=${limit}${
      date?.length > 0 ? `&start=${date[0]}&end=${date[1]}` : ""
    }`,
    json_header
  );

  return res;
}

export async function getStrategyLiveTradebyID(ID, page, limit, date) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${
      API_URL.GET_STRATEGY_Live_Trade_BY_ID
    }/${ID}?page=${page}&limit=${limit}${
      date?.length > 0 ? `&start=${date[0]}&end=${date[1]}` : ""
    }`,
    json_header
  );

  return res;
}

////VTLT LIST

export async function getVTLTReportsBYID(ID, page, limit, day, tab) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${
      tab === "VT" ? API_URL.VIRTUAL_TRADE : API_URL.LIVE_TRADE
    }/${ID}?page=${page}&limit=${limit}&day=${day}`,
    json_header
  );

  return res;
}

export async function getVTLTClosedOrderPNLBYID(ID, day, tab) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${
      tab === "VT" ? API_URL.VIRTUAL_TRADE : API_URL.LIVE_TRADE
    }/getpnl/${ID}?day=${day}`,
    json_header
  );

  return res;
}

export async function getVTLTorderBYID(ID, page, limit, day) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.VIRTUAL_TRADE}/get-request-placed-orders/${ID}?page=${page}&limit=${limit}&day=${day}`,
    json_header
  );

  return res;
}

export async function getVTLTTradeBYID(ID, page, limit, day, tab) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${
      tab === "VT" ? API_URL.VIRTUAL_TRADE : API_URL.LIVE_TRADE
    }/getcloseorders/${ID}?page=${page}&limit=${limit}&day=${
      typeof day === "string" ? day : "custom"
    }${typeof day === "string" ? "" : `&start=${day[0]}&end=${day[1]}`}`,
    json_header
  );

  return res;
}

export async function getpnlData(Date, selectedDate, tab) {
  let getuserBots = localStorage.getItem("token");
  const userid = mydecodedTokenFunction(localStorage.getItem("token"));
  try {
    let apiUrl = "";
    if (
      selectedDate[1] &&
      selectedDate[0] !== "" &&
      selectedDate[1] !== "" &&
      selectedDate[0]
    ) {
      apiUrl = `${
        tab === "live" ? API_URL.LIVE_TRADE : API_URL.VIRTUAL_TRADE
      }/get-user-closed-orders-pnl/${userid?.id}?day=custom&start=${
        selectedDate[0]
      }&end=${selectedDate[1]}`;
    } else {
      apiUrl = `${
        tab === "live" ? API_URL.LIVE_TRADE : API_URL.VIRTUAL_TRADE
      }/get-user-closed-orders-pnl/${userid?.id}?day=${Date}`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    return response?.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
