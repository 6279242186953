import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  createUserStaffDetails,
  updateUserStaffDetails,
} from "../../helpers/api/userStaff/userStaff";
import { useDispatch, useSelector } from "react-redux";
import { createStaffUser } from "../../../redux/createStaffUser/createStaffUser.slice";
import {
  UserRoleList,
  updateUserDetailsStore,
} from "../../../redux/AddRole/addRole.slice";
import { addRoleInitialData, toastSpecs } from "../../../Utils/utils";
import useRoleList from "./useRoleList";
import { userDetailsData } from "../../../redux/UserDetails/userDetails.slice";
import { CountryCodes } from "../../utils/CountryCodes";
import Image from "../Image";

const useAddUserModal = (closeModal) => {
  const [form] = Form.useForm();
  const userData = useSelector(createStaffUser);
  const { selectedUser = {}, selectionType = "" } = userData;
  const [AddRoleModal, setAddRoleModal] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailsData);
  const country_list = CountryCodes;

  const phones = country_list?.map(({ code, phone, label }) => ({
    key: code,
    value: `${code}+${phone}`,
    label: (
      <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800 text-[14px]">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {/* {code}  */}+{phone}
      </span>
    ),
  }));

  // console.log("userDetails", userDetails);

  const addUser = useCallback(() => {
    form.submit();
  }, [form]);

  const RoleListData = useSelector(UserRoleList);

  const { refetch: refetchRoleList } = useRoleList();

  //   console.log("RoleListData", RoleListData);

  //   const { data: roleList, refetch: refetchRoleList } = useQuery({
  //     queryKey: ["RoleList"],
  //     queryFn: async () => {
  //       if (decodedToken) {
  //         return await getRolesListAPI();
  //       } else {
  //         return null;
  //       }
  //     },
  //   });

  const closeRoleModal = useCallback(
    (refetch) => {
      setAddRoleModal(false);
      dispatch(
        updateUserDetailsStore({
          data: addRoleInitialData(),
          selectionType: "",
          role_name: "",
          role_id: "",
        })
      );
      refetch && refetchRoleList();
    },
    [dispatch, refetchRoleList]
  );

  const oNOpenRoleModal = useCallback(() => {
    setAddRoleModal(true);
  }, []);

  const roleListDatasOptions = useMemo(() => {
    const roles = RoleListData?.data?.defaultData
      ? [...RoleListData?.data?.defaultData]
      : [];
    // [...RoleListData?.data?.defaultData, ...RoleListData?.data?.data]
    if (roles?.length > 0) {
      return roles?.map((item) => {
        return {
          value: item?._id,
          label: item?.role_name,
          key: item?._id,
          disabled: item?.role_name === "Traders" ? true : false,
        };
      });
    } else {
      return [];
    }
  }, [RoleListData]);

  const insertNote = useCallback(() => {
    //   let payload = encodeDecodeAddRoleData(form.getFieldsValue(), "encode");
    // console.log("payload", form.getFieldsValue());
    const { email, assign_role, first_name, last_name, send_invite } =
      form.getFieldsValue();
    const payload = {
      role_id: assign_role,
      first_name: first_name,
      last_name: last_name,
      user_type: "staff",
      email: email,
      send_invite: send_invite,
    };
    if (selectionType === "edit") {
      return updateUserStaffDetails(payload, selectedUser?._id);
    } else {
      return createUserStaffDetails(payload);
    }
  }, [form, selectedUser?._id, selectionType]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      closeModal(true);
      return toast.success("User invited successfully.", toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  const checkUserAccess = useMemo(() => {
    let result = false;
    userDetails?.roleManagement?.role_data?.map((item) => {
      if (Object.keys(item)?.[0] === "user_management") {
        if (item?.user_management?.includes("create")) {
          result = true;
        }
      }
      return item;
    });
    return result;
  }, [userDetails?.roleManagement]);

  const values = useMemo(() => {
    return {
      form,
      userDetails: selectedUser,
      modalAccessType: selectionType,
      isLoading: mutation?.isPending,
      roleListDatasOptions,
      AddRoleModal,
      checkUserAccess,
      phones,
      closeRoleModal,
      addUser,
      onSubmit,
      oNOpenRoleModal,
    };
  }, [
    form,
    selectedUser,
    selectionType,
    mutation?.isPending,
    roleListDatasOptions,
    AddRoleModal,
    checkUserAccess,
    phones,
    closeRoleModal,
    addUser,
    onSubmit,
    oNOpenRoleModal,
  ]);

  return values;
};

export default useAddUserModal;
