import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  btnPrimaryClass,
  btnSecondayClass,
} from "../../../../Component/utils/DefaultClasses";
import moment from "moment";
import UserInvoice from "./UserInvoice";
import {
  restrictTheUser,
  userDetail,
} from "../../../../Component/helpers/api/userDetails/userDetails";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { DATEFORMAT, toastSpecs } from "../../../../Utils/utils";
import useUserPermission from "../../../../Component/Common/hooks/useUserPermission";

function OverviewCard({ activePlanDetails, userData, refetch }) {
  let { id } = useParams();

  const [deactivateUser, setDeactivateUser] = useState("");
  const [blockUser, setBlockUser] = useState("");

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: isCreateAccess("trader_management"),
      isEdit: isEditAccess("trader_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  useEffect(() => {
    setDeactivateUser(userData?.deactivate);
    setBlockUser(userData?.block);
  }, [userData]);

  const toShowplan = () => {
    const expirationDate = activePlanDetails
      ? new Date(activePlanDetails.expired_at)
      : null;
    //to chcek whether date is less than today's date
    const today = new Date();
    const isExpired = expirationDate && expirationDate < today;
    return isExpired;
  };

  const restrictUser = useMutation({
    mutationFn: (payload) => restrictTheUser(payload, id),
    onSuccess: (res) => {
      // console.log(res?.data?.data?);
      refetch();
      setBlockUser(res?.data?.data?.block);
      setDeactivateUser(res?.data?.data?.deactivate);
    },
    onError: (err) => {
      return toast.error("something went wrong server error", toastSpecs);
    },
  });

  const renderExpirationDate = () => {
    const isExpired = toShowplan();
    const expirationText = isExpired ? "Expired at" : "Active until";
    const expirationDate = moment(activePlanDetails?.expired_at).format(
      DATEFORMAT
    );

    // Conditionally apply CSS class based on whether the plan is expired
    const textColorClass = isExpired ? "text-red-500" : "";

    return (
      <>
        <div className={`text-base font-semibold ${textColorClass}`}>
          {expirationText} {expirationDate}
        </div>
        <span className="text-xs text-color-secondary/70 font-medium">
          We will send you a notification upon Subscription expiration
        </span>
      </>
    );
  };

  return (
    <>
      <div>
        <Row className="gy-4">
          <Col lg={7}>
            <div className="space-y-6">
              <div className="flex flex-col leading-normal">
                {renderExpirationDate()}
              </div>
            </div>
          </Col>
          {Access?.isEdit && (
            <Col lg={5}>
              <div className="space-y-6">
                <div className="flex justify-end items-center gap-2">
                  {!deactivateUser && (
                    <button
                      className={`${btnSecondayClass} text-sm`}
                      onClick={() =>
                        restrictUser.mutate({
                          block: !blockUser,
                          deactivate: deactivateUser,
                        })
                      }
                    >
                      {blockUser ? "Unblock User" : "Block User"}
                    </button>
                  )}

                  <button
                    className={`${btnPrimaryClass} text-sm ${
                      deactivateUser
                        ? "bg-blue-500 hover:bg-blue-600"
                        : "bg-red-500 hover:bg-red-500"
                    }`}
                    onClick={() =>
                      restrictUser.mutate({
                        block: blockUser,
                        deactivate: !deactivateUser,
                      })
                    }
                  >
                    {deactivateUser ? "Activate User" : "Deactivate User"}
                  </button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}

export default React.memo(OverviewCard);
