import Modal from "../Modal";
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from "../utils/DefaultClasses";
import { Checkbox, Form, Input } from "antd";
import { Transition } from "@headlessui/react";
import useAddRole from "./hooks/useAddRole";
import { SpinnerBtn } from "../utils/Icons";
import { getAddedRemovedItem } from "../../Utils/utils";

const CreateRoleModal = ({ props }) => {
  const CheckboxGroup = Checkbox.Group;
  const {
    AddRoleModal = false,
    closeModal = () => {},
    // modalAccessType = "",
  } = props;
  const {
    plainOptions,
    initialState,
    form,
    isLoading,
    modalAccessType,
    // userDetails,
    isAllFieldsChecked,
    checkIsIntermediate,
    onChangeCheckbox,
    handleCheckAllChange,
    addRoles,
    onSubmit,
  } = useAddRole(closeModal);

  // console.log("modalAccessType", modalAccessType);

  return (
    <Modal
      open={AddRoleModal}
      setIsOpen={closeModal}
      footer={
        <>
          {modalAccessType === "view" ? null : (
            <div className="flex justify-end items-center gap-2.5">
              <button
                htmlType="submit"
                type="button"
                className={`${btnSecondayClass} text-sm`}
                onClick={closeModal}
                disabled={isLoading}
                // disabled
              >
                Cancel
              </button>
              <button
                htmlType="submit"
                type="button"
                className={`${btnPrimaryClass} text-sm`}
                onClick={addRoles}
                disabled={isLoading}
                // disabled
              >
                {isLoading ? (
                  <SpinnerBtn className={"text-lg"} />
                ) : modalAccessType === "edit" ? (
                  "Edit Role"
                ) : (
                  "Add Role"
                )}
              </button>
            </div>
          )}
        </>
      }
      centered
      maxWidth={`w-full max-w-4xl`}
      preventClosingOutsideClick
      // bodyClass={"p-3.5"}
      bodyHeight={"60vh"}
      bodyClass={
        "w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full min-h-[67vh]"
      }
      header={
        <>
          <h3 className="font-medium text-lg">
            {modalAccessType === "edit"
              ? "Edit Role"
              : modalAccessType === "view"
              ? "View Role"
              : "Add New Role"}
          </h3>
        </>
      }
    >
      <Form
        className=""
        form={form}
        initialValues={{
          role_name: initialState?.role_name,
          administrators: initialState?.data,
        }}
        onFinish={onSubmit}
        disabled={isLoading || modalAccessType === "view"}
        layout="vertical"
      >
        <div className="px-3.5">
          <div className="row">
            <div className="col-12 col-md-12">
              <Form.Item
                label="Role Name"
                name="role_name"
                rules={[
                  {
                    required: true,
                    message: "Role name is required.",
                  },
                ]}
              >
                <Input
                  className={`${formClass}`}
                  placeholder="Enter Role Name"
                  name="role_name"
                  // value={signUpData.firstName}
                  // onChange={handelChange}
                />
              </Form.Item>
            </div>
            <div className="col-12 col-md-12">
              <div className="flex text-base font-medium">Role Permissions</div>
              <div className=" divide-y divide-level-2 w-full">
                <Form.Item
                  name="errorforlist"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        const checkIsSelected = (list) => {
                          return list?.some(
                            (data) => data?.isSelected === true
                          );
                        };

                        if (checkIsSelected(getFieldValue("administrators")))
                          return Promise.resolve();
                        return Promise.reject(
                          new Error(
                            "Please ensure you select at least one role before proceeding."
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Form.List name="administrators">
                    {(fields) =>
                      fields.map(({ index, name }) => (
                        <>
                          <div>
                            <div className="flex justify-between items-center gap-2 max-w-xl py-2.5">
                              <div>
                                <span className="text-sm text-color-secondary/90 font-medium">
                                  {initialState?.data?.[name].name}
                                </span>
                              </div>
                              <div className="w-full max-w-[216px]">
                                {/* <Form.Item {...field} name={[name, "name"]}> */}

                                <Checkbox
                                  indeterminate={checkIsIntermediate(name)}
                                  onChange={() => handleCheckAllChange(name)}
                                  checked={isAllFieldsChecked(name)}
                                >
                                  Select All
                                </Checkbox>
                                {/* </Form.Item> */}
                              </div>
                            </div>
                          </div>

                          <Transition
                            show={
                              form.getFieldValue("administrators")?.[name]
                                .isSelected
                            }
                            enter="transition-opacity duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <>
                              <div className="divide-y divide-level-2 w-full">
                                {form
                                  .getFieldValue("administrators")
                                  ?.[name].roles?.map((role) => (
                                    <div>
                                      <div className="flex justify-between items-center gap-2 max-w-xl py-2.5">
                                        <div>
                                          <span className="text-sm text-color-secondary/90 font-medium">
                                            {role.name}
                                          </span>
                                        </div>

                                        <div className="-mr-6">
                                          <CheckboxGroup
                                            options={plainOptions}
                                            value={role.value}
                                            onChange={(list) => {
                                              const {
                                                addedElement,
                                                removedElement,
                                              } = getAddedRemovedItem(
                                                role.value,
                                                list
                                              );

                                              const modifiedList = form
                                                .getFieldValue("administrators")
                                                ?.[name].roles?.map((item) => {
                                                  if (
                                                    item?.name === role?.name
                                                  ) {
                                                    return {
                                                      ...item,
                                                      value:
                                                        removedElement ===
                                                        "Access"
                                                          ? []
                                                          : (addedElement ===
                                                              "Edit" ||
                                                              addedElement ===
                                                                "Create") &&
                                                            !list?.includes(
                                                              "Access"
                                                            )
                                                          ? [...list, "Access"]
                                                          : list,
                                                    };
                                                  } else return item;
                                                });

                                              onChangeCheckbox(
                                                name,
                                                modifiedList
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </>
                          </Transition>
                        </>
                      ))
                    }
                  </Form.List>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateRoleModal;
