import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSearchQuery from "../../../../Component/Common/hooks/useSearchQuery";
import {
  adminEmailOTPSubmit,
  get2FAOTPBYEmail,
  resend2FAOTPBYEmail,
} from "../../../../Component/helpers/api/Auth/Auth";
import { getPermissions } from "../../../../Utils/utils";
import { updateUserPermissions } from "../../../../redux/userPermissions/userPermissions.slice";
import { useDispatch } from "react-redux";

const useTwoFactorAuth = (from, changeEmail) => {
  const [form] = Form.useForm();
  const [otpError, setOtpError] = useState();
  const [otp, setOTP] = useState();
  const navigate = useNavigate();
  const query = useSearchQuery();
  const dispatch = useDispatch();

  const insertNote = (otp) => {
    if (from === "changeEmail") {
      const payload = {
        otp: otp,
        new_email: changeEmail?.new_email,
      };
      return adminEmailOTPSubmit(payload);
    } else {
      const payload = {
        otp: otp,
        email: query.get("email"),
      };

      return get2FAOTPBYEmail(payload);
    }
  };

  const navigateToDashboard = useCallback(
    (permissionsData) => {
      if (permissionsData?.["trades_management"]?.includes("access")) {
        navigate("/trades");
      } else if (permissionsData?.["trader_management"]?.includes("access")) {
        navigate("/traders");
      } else if (permissionsData?.["strategy_management"]?.includes("access")) {
        navigate("/strategy/permission");
      } else if (permissionsData?.["user_management"]?.includes("access")) {
        navigate("/user-list");
      } else if (permissionsData?.["plans_management"]?.includes("access")) {
        navigate("/plans");
      } else if (
        permissionsData?.["roles_permission_management"]?.includes("access")
      ) {
        navigate("/access-roles");
      }
    },
    [navigate]
  );

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      if (res.data.status === true) {
        if (from === "changeEmail") {
          localStorage.removeItem("adminToken");
          localStorage.removeItem("allbots");
          navigate("/login");
        } else {
          const permission = getPermissions(res?.data?.roleData?.role_data);
          dispatch(updateUserPermissions(permission));
          navigateToDashboard(permission);
          localStorage.setItem("adminToken", res.data.access_token);
          localStorage.setItem("allbots", res.data.bot_list);
        }
      }
      // localStorage.removeItem('2FATotalAttempt');
    },
    onError: (err) => {
      setOtpError("Invalid OTP.");
    },
  });

  const insertResendNote = (otp) => {
    let payload;
    if (from === "changeEmail") {
      payload = {
        new_email: changeEmail?.new_email,
      };
    } else {
      payload = {
        email: query.get("email"),
      };
    }

    return resend2FAOTPBYEmail(payload);
  };

  const resendOTP = useMutation({
    mutationFn: insertResendNote,
    onSuccess: (user) => {},
    onError: () => {
      // setOtpError('Invalid OTP');
    },
  });

  const submitForm = useCallback(() => {
    let testOTP = otp ? otp?.length : 0;
    if (testOTP < 6) {
      setOtpError("OTP should be of 6 digit code.");
    } else {
      mutation.mutate(otp);
    }
    // form.resetFields();
  }, [otp, mutation]);

  const udateOTP = useCallback(
    (value) => {
      otpError && setOtpError();
      setOTP(value);
    },
    [otpError]
  );

  const value = useMemo(() => {
    return {
      form,
      otpError,
      udateOTP,
      setOtpError,
      submitForm,
      resendOTP,
      isLoading: mutation.isLoading,
    };
  }, [
    form,
    otpError,
    udateOTP,
    setOtpError,
    submitForm,
    resendOTP,
    mutation.isLoading,
  ]);

  return value;
};

export default useTwoFactorAuth;
