import React from "react";
import Leftbar from "../../../Component/Admin/Leftbar";
import { btnSecondayClass } from "../../../Component/utils/DefaultClasses";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import SupportChatHeader from "./support_chat_compo/SupportChatHeader";
import SupportChatRightBar from "./support_chat_compo/SupportChatRightBar";
import SupportChatBody from "./support_chat_compo/SupportChatBody";
import SupportChatBox from "./support_chat_compo/SupportChatBox";
import useTicketSupportDetail from "./hooks/useTicketSupportDetail";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function TicketDetail() {
  const navigate = useNavigate();
  const { selectedTicket, refetch, Pagination, isLoading } =
    useTicketSupportDetail();
  return (
    <div>
      <Leftbar type={"admin"} />
      <div className="left__bar">
        <div className="flex gap-2">
          <div className="flex gap-3 items-center">
            <div className="">
              <button
                className={`${btnSecondayClass} h-[35px] w-[35px] p-1 rounded-md`}
                onClick={() => navigate(-1)}
              >
                <MdOutlineKeyboardBackspace />
              </button>
            </div>
            {/* <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M366.05 146a46.7 46.7 0 0 1-2.42-63.42 3.87 3.87 0 0 0-.22-5.26l-44.13-44.18a3.89 3.89 0 0 0-5.5 0l-70.34 70.34a23.62 23.62 0 0 0-5.71 9.24 23.66 23.66 0 0 1-14.95 15 23.7 23.7 0 0 0-9.25 5.71L33.14 313.78a3.89 3.89 0 0 0 0 5.5l44.13 44.13a3.87 3.87 0 0 0 5.26.22 46.69 46.69 0 0 1 65.84 65.84 3.87 3.87 0 0 0 .22 5.26l44.13 44.13a3.89 3.89 0 0 0 5.5 0l180.4-180.39a23.7 23.7 0 0 0 5.71-9.25 23.66 23.66 0 0 1 14.95-15 23.62 23.62 0 0 0 9.24-5.71l70.34-70.34a3.89 3.89 0 0 0 0-5.5l-44.13-44.13a3.87 3.87 0 0 0-5.26-.22 46.7 46.7 0 0 1-63.42-2.32z"
                  ></path>
                  <path
                    fill="none"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="m250.5 140.44-16.51-16.51m60.53 60.53-11.01-11m55.03 55.03-11-11.01m60.53 60.53-16.51-16.51"
                  ></path>
                </svg>
              </span> */}
          </div>
          <div className="flex flex-row justify-between w-full">
            <div>
              <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                Ticket- #{selectedTicket?.ticket_number ?? "-"}
              </h4>
              <span className="flex text-xs text-color-secondary/70">
                Created on-{" "}
                {moment(selectedTicket?.created_at).format("DD-MM-YYYY HH:MM")}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <div className="mt-3 pr-2.5 w-full">
            <div className="border-[1px] border-level-2 w-full min-h-[calc(100svh-150px)] flex flex-col justify-between rounded-lg shadow">
              <SupportChatHeader selectedTicket={selectedTicket} />
              <SupportChatBody props={{ Pagination, isLoading, refetch }} />
              <SupportChatBox props={{ refetch, Pagination }} />
            </div>
          </div>
          <SupportChatRightBar selectedTicket={selectedTicket} />
        </div>
      </div>
    </div>
  );
}

export default TicketDetail;
