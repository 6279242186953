import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import { apiHeader } from "../../../../Utils/utils";

export async function updateUserStaffDetails(payload, userID) {
  const { json_header } = apiHeader();

  const res = await axios.put(
    `${API_URL.UPDATE_STAFF}/${userID}`,
    payload,
    json_header
  );
  return res;
}
// update trader data
export async function updatetraderStaffDetails(payload, userID) {
  const { json_header } = apiHeader();

  const res = await axios.put(
    `${API_URL.UPDATE_TRADER}/${userID}`,
    payload,
    json_header
  );
  return res;
}

export async function deleteUserStaffDetails(userID) {
  const { json_header } = apiHeader();

  const res = await axios.delete(
    `${API_URL.DELETE_STAFF}/${userID}`,
    json_header
  );
  return res;
}

export async function createUserStaffDetails(payload) {
  const { json_header } = apiHeader();

  const res = await axios.post(`${API_URL.ADD_STAFF}`, payload, json_header);
  return res;
}

export async function checkUserStaffavailablity(payload) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.CHECK_STAFF_EMAIL}/${payload}`,
    json_header
  );
  return res;
}

export async function checkAdminStaffavailablity(payload) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.CHECK_ADMIN_MAIL}/${payload}`,
    json_header
  );
  return res.data;
}

export async function getStaffUserActivities(page, limit, user_id) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.STAFF_USER_ACTIVITIES}?page=${page}&limit=${limit}&user_id=${user_id}`,
    json_header
  );
  return res;
}

export async function getTraderUserActivities(page, limit, user_id) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.TRADER_USER_ACTIVITIES}?page=${page}&limit=${limit}&user_id=${user_id}`,
    json_header
  );
  return res;
}
