import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import { Col, Row } from "react-bootstrap";
import { Dropdown, Form, Input, Skeleton, Tooltip } from "antd";
import {
	btnSecondayClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import { RiSearchLine } from "react-icons/ri";
import TableComponent from "../../../Component/TableComponent";
import { GrCubes } from "react-icons/gr";
import PlanListCard from "./PlanListCard";
import PlanDetailContent from "./PlanDetailContent";
import TransactionSummary from "./TransactionSummary";
import usePlanList from "./hooks/usePlanList";
import UpdatedTime from "../../../Component/UpdatedTime";
import CustomDatePicker from "../../../Component/Common/CustomDatePicker";
import { rangePresets } from "../../../Utils/utils";
import { MdOutlineUploadFile } from "react-icons/md";
import ReportPDF from "../../../Component/Common/ReportPDF";

export default function PlansList() {
	const [searchForm] = Form.useForm();
	const {
		isOpen,
		plansList,
		isLoadingPlansList,
		isOpenUserPlan,
		dataSource,
		columns,
		pagination,
		isRefetchingPlanList,
		isRefetchingUserPlan,
		setSearch,
		refetchPlansList,
		refetchUserPlan,
		handleChangeDate,
		exportOptions,
		PDFcolumns,
	} = usePlanList();

	return (
		<div className="">
			<div className="layout-row flex" id="main">
				<div className="">
					<Leftbar type="admin" />
				</div>
				<div className="left__bar w-full">
					<RightBar
						leftContent={
							<>
								<div className="d-flex align-items-center">
									{/* <div className="leftContent">
										Account Id: {adminid.id}
									</div> */}
									<div className="flex gap-2">
										<div className="">
											<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
												<GrCubes className="" />
											</span>
										</div>
										<div className="">
											<div className="flex gap-2">
												<div className="flex flex-col gap-0 -space-y-1">
													<h2 className="strtgy__ttl">Plan List</h2>
													<span className="text-sm flex">
														A role defines the access levels to the users in
														Admin Dashboard and Trader Dashboard.
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}
						rightContent={<></>}>
						<>
							{/* <div className="headingSection mb-4 mt-4">
											<h2 className="d-flex align-items-center">
												<span className="heading">List Of Users</span>
											</h2>
											<p className="subHead">
												You have total {data.length} Users.
											</p>
										</div> */}
							<div className=" mt-4">
								<div className="mb-4">
									<Row className="g-3">
										{isLoadingPlansList ? (
											<>
												{[...Array(3)]?.map((item) => (
													<Col lg={4} key={item}>
														<div className="relative flex justify-between p-3.5 border-[1px] shadow-md border-level-4 rounded-lg w-full">
															<Skeleton.Avatar
																active={true}
																size={"default"}
																shape={"circle"}
																className="absolute top-3 right-3"
															/>
															<Skeleton
																active={true}
																size={"default"}
																block={true}
																paragraph={{ rows: 1 }}
															/>
														</div>
													</Col>
												))}
											</>
										) : (
											plansList?.map((item) => (
												<Col lg={4} key={item?._id}>
													<PlanListCard
														item={item}
														refetch={refetchPlansList}
													/>
												</Col>
											))
										)}
									</Row>
								</div>
								<div className="">
									<div className="flex align-items-center justify-between">
										<div className="flex">
											<div className={`relative max-w-[220px]`}>
												<Form
													className="mt-3.5"
													layout="vertical"
													form={searchForm}>
													<Form.Item
														name="search"
														validateDebounce={1000}
														rules={[
															({ getFieldValue }) => ({
																validator: (_, value) => {
																	setSearch(value);
																	return Promise.resolve();
																},
															}),
														]}>
														<Input
															allowClear
															placeholder="Search"
															// onChange={onSearch}
															// onPressEnter={onSearch}
															className={`${formClass}`}
															// onSearch={() => setExpandSearch(!expandSearch)}
															suffix={
																<>
																	<span className="">
																		<RiSearchLine />
																	</span>
																</>
															}
														/>
													</Form.Item>
												</Form>
											</div>
										</div>
										<div className="w-full">
											<div className="d-flex align-items-center justify-content-end gap-2">
												<div>
													<CustomDatePicker
														presets={rangePresets}
														onChange={handleChangeDate}
													/>
												</div>
												<div className="py-0.5 hidden lg:flex">
													<span className="flex w-1 h-7 bg-level-2"></span>
												</div>
												<div className="">
													<Tooltip title={"Export"}>
														<Dropdown
															menu={{
																items: exportOptions,
															}}
															placement="bottomRight"
															overlayClassName={"dropdownOverlay dowpdownDark"}
															trigger={["hover"]}
															className={`${btnSecondayClass} w-[34px] h-[34px] relative`}>
															<div onClick={(e) => e.preventDefault()}>
																<MdOutlineUploadFile className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
															</div>
														</Dropdown>
													</Tooltip>
												</div>
												<UpdatedTime
													invokeFunction={[refetchPlansList, refetchUserPlan]}
													showOnlyRefreshIcon={true}
													isLoading={
														isRefetchingPlanList || isRefetchingUserPlan
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="mb-3">
								<TableComponent
									dataSource={dataSource}
									columns={columns}
									// pagination_callback={fetchStaffList}
									className="fs_tables"
									pagination={pagination.total <= 10 ? false : true}
									currentPage={pagination.page}
									setCurrentPage={pagination.setPage}
									pageSize={pagination.limit}
									setPageSize={pagination.setLimit}
									total={pagination.total}
								/>
							</div>
						</>
					</RightBar>
				</div>
			</div>

			<div>
				{isOpen && <PlanDetailContent refetch={refetchPlansList} />}
				{isOpenUserPlan && <TransactionSummary />}
			</div>
			<ReportPDF columns={PDFcolumns} />
		</div>
	);
}
