import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  roleManagement: {},
};

const selectedUser = {
  selectedUser: {},
  isPlanCovered: false,
  isOpen: false,
  isView: false,
  loggedInUser: false,
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateUserDataStore: (state, { payload }) => {
      state.data = payload?.data;
      state.roleManagement = payload?.roleManagement;
    },
    // openUserDetailsModal: (state, { payload }) => {
    //   state.isOpen = payload;
    // },
  },
});

const selectedUserSlice = createSlice({
  name: "SelectedUser",
  initialState: selectedUser,
  reducers: {
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload?.selectedUser;
      state.isPlanCovered = payload?.isPlanCovered;
      state.isOpen = payload?.isOpen;
      state.isView = payload?.isView;
      state.planDetail = payload?.planDetail;
      state.loggedInUser = payload?.loggedInUser || false;
    },
  },
});

let SelectedUserReducer = selectedUserSlice.reducer;
let userDetailsReducer = userDetailsSlice.reducer;

export const { updateUserDataStore } = userDetailsSlice.actions;
export const { setSelectedUser, openUserDetailsModal } =
  selectedUserSlice.actions;

const userDetailsData = (state) => state.userData;
const selectedUserData = (state) => state.selectedUserData;

export {
  userDetailsReducer,
  userDetailsData,
  SelectedUserReducer,
  selectedUserData,
};
