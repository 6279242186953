import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Dropdown, Form, Select } from "antd";
import { useCallback, useMemo, useState } from "react";
import {
  MdBlock,
  MdKeyboardArrowDown,
  MdOutlineAppBlocking,
} from "react-icons/md";
import {
  adminStaffUsers,
  deleteRolesAPI,
} from "../../../../Component/helpers/api/RolesPermissions/rolesPermissions";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedUser } from "../../../../redux/createStaffUser/createStaffUser.slice";
import { toast } from "react-toastify";
import { deleteUserStaffDetails } from "../../../../Component/helpers/api/userStaff/userStaff";
import {
  DATEFORMAT,
  addRoleInitialData,
  toastSpecs,
} from "../../../../Utils/utils";
import {
  UserRoleList,
  updateUserDetailsStore,
} from "../../../../redux/AddRole/addRole.slice";
import useRoleList from "../../../../Component/Common/hooks/useRoleList";
import { BsChevronDown, BsFillPatchCheckFill } from "react-icons/bs";
import {
  updateFilterCount,
  updateForm,
  updatePopover,
  updateisFiltered,
} from "../../../../redux/Filters/filterSort.slice";
import useUpdateStaffUser from "../../hooks/useUpdateStaff";
import { setSelectedUser } from "../../../../redux/UserDetails/userDetails.slice";
import moment from "moment";
import useUserPermission from "../../../../Component/Common/hooks/useUserPermission";
import useDecodedToken from "../../../../Component/utils/useDecodedToken";

const useAccessRole = () => {
  const [AddRoleModal, setAddRoleModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [deleteuser, setDeleteuser] = useState(false);
  const [deleteuserID, setDeleteuserID] = useState("");
  const [deleteRole, setDeleteRole] = useState(false);
  const initialState = useMemo(() => {
    return { status: "", role: "" };
  }, []);

  const [filters, setFilters] = useState(initialState);
  const [search, setsearch] = useState("");

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const userAccess = useMemo(() => {
    return {
      isCreate: isCreateAccess("user_management"),
      isEdit: isEditAccess("user_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  const roleAccess = useMemo(() => {
    return {
      isCreate: isCreateAccess("roles_permission_management"),
      isEdit: isEditAccess("roles_permission_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["adminStaffUsers", decodedToken, page, limit, search, filters],
    queryFn: async () => {
      if (decodedToken) {
        return await adminStaffUsers(
          page,
          limit,
          search,
          filters?.status,
          filters?.role
        );
      } else {
        return null;
      }
    },
  });

  const { updateuserData } = useUpdateStaffUser(refetch);

  const RoleListData = useSelector(UserRoleList);

  const { refetch: refetchRoleList, isLoading: isLoadingRolesList } =
    useRoleList();

  //   const { data: roleList, refetch: refetchRoleList } = useQuery({
  //     queryKey: ["RoleList"],
  //     queryFn: async () => {
  //       if (decodedToken) {
  //         return await getRolesListAPI();
  //       } else {
  //         return null;
  //       }
  //     },
  //   });

  const closeModal = useCallback(
    (refetch) => {
      setAddRoleModal(false);
      dispatch(
        updateUserDetailsStore({
          data: addRoleInitialData(),
          selectionType: "",
          role_name: "",
          role_id: "",
        })
      );
      refetch && refetchRoleList();
    },
    [dispatch, refetchRoleList]
  );

  const roleListDatas = useMemo(
    () =>
      RoleListData?.data?.defaultData
        ? [...RoleListData?.data?.defaultData, ...RoleListData?.data?.data]
        : [],
    [RoleListData]
  );

  //   console.log("roleListDatas", roleListDatas);

  const onCloseUserModal = useCallback(
    (needrefetch) => {
      setOpenUserModal(false);
      dispatch(updateSelectedUser({ selectedUser: {}, selectionType: "" }));
      needrefetch && refetch();
    },
    [refetch, dispatch]
  );

  const onCloseDeleteUser = useCallback(() => {
    setDeleteuser(false);
    setDeleteuserID("");
  }, []);

  const onCloseDeleteRole = useCallback(() => {
    setDeleteRole(false);
    setDeleteuserID("");
  }, []);

  const onChangeDropdown = useCallback(
    (data, action) => {
      dispatch(
        setSelectedUser({
          selectedUser: data,
          isPlanCovered: false,
          isOpen: true,
          isView: action === "view" ? true : false,
        })
      );
    },
    [dispatch]
  );

  const insertNote = useCallback(() => {
    return deleteUserStaffDetails(deleteuserID);
  }, [deleteuserID]);

  const Deletemutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      refetch();
      onCloseDeleteUser();
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmitDelete = useCallback(() => {
    Deletemutation.mutate();
  }, [Deletemutation]);

  const insertDeleteNote = useCallback(() => {
    return deleteRolesAPI(deleteuserID);
  }, [deleteuserID]);

  const DeleteRolemutation = useMutation({
    mutationFn: insertDeleteNote,
    onSuccess: (res) => {
      refetchRoleList();
      onCloseDeleteRole();
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmitDeleteRole = useCallback(() => {
    DeleteRolemutation.mutate();
  }, [DeleteRolemutation]);

  //   console.log("data", data);

  const dataSource = data?.data?.data?.map((item, index) => ({
    key: index,
    User: (
      <>
        <div className="flex items-center gap-1.5">
          <div className="relative">
            <div className="h-[40px] w-[40px] rounded-full overflow-hidden">
              <img
                src={`https://eu.ui-avatars.com/api/?name=${item.first_name}+${item.last_name}&size=250`}
                className="object-cover"
                alt="avatar"
              />
            </div>
            {(item.email_verify && item?.password?.length > 0) === true && (
              <span className="absolute -bottom-[1px] -right-[1px]">
                <BsFillPatchCheckFill className="text-blue-2" />
              </span>
            )}
          </div>

          <div className="flex flex-col gap-0 -space-y-1">
            <div>
              <span className="flex font-medium text-color-primary">
                <button
                  // to={`/user/profile/${item._id}`}
                  className="flex items-center gap-1.5"
                  onClick={() => {
                    dispatch(
                      setSelectedUser({
                        selectedUser: item,
                        isPlanCovered: false,
                        isOpen: true,
                        isView: false,
                      })
                    );
                  }}
                >
                  {item.name
                    ? item.name
                    : `${item.first_name} ${item.last_name}`}
                </button>
              </span>
            </div>
            <div>
              <span className="flex text-xs">{item.email}</span>
            </div>
          </div>
        </div>
      </>
    ),
    Role: (
      <>
        <span className="text-color-primary">{item?.roleData?.role_name}</span>
      </>
    ),
    added_at: (
      <>
        <span className="text-color-primary">
          {moment(item?.created_at).format(DATEFORMAT)}
        </span>
      </>
    ),
    added_by: (
      <>
        <span className="text-color-primary">
          {item.createdByData?.first_name} {item.createdByData?.last_name}
        </span>
      </>
    ),
    Status: (
      <>
        <span className="flex">
          <span
            className={`flex ${
              item.email_verify &&
              item?.password?.length > 0 &&
              !item?.deactivate &&
              !item.block
                ? "bg-blue-2/15 text-blue-2"
                : "bg-red-2/10 text-red-2"
            } rounded-md px-1.5`}
          >
            {item.block
              ? "Blocked"
              : item.deactivate
              ? "Deactivated"
              : item.email_verify && item?.password?.length > 0
              ? "Active"
              : "Inactive"}
          </span>
        </span>
      </>
    ),
    Action: (
      <>
        <span className="flex">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span className="d-flex align-items-center">
                      <EyeOutlined className="me-2" />
                      View
                    </span>
                  ),
                  onClick: () => {
                    onChangeDropdown(item, "view");
                  },
                },
                userAccess?.isEdit && {
                  key: "2",
                  label: (
                    <span className="d-flex align-items-center">
                      <EditOutlined className="me-2" />
                      Edit
                    </span>
                  ),
                  onClick: () => {
                    onChangeDropdown(item, "edit");
                  },
                },
                userAccess?.isEdit && {
                  key: "3",
                  label: (
                    <span href="#" className="d-flex align-items-center">
                      <MdBlock className="me-2" />
                      {item?.block ? "Unblock" : "Block"}
                    </span>
                  ),
                  onClick: () => {
                    const payload = {
                      block: !item?.block,
                    };

                    return updateuserData(payload, item?._id);
                  },
                },
                userAccess?.isEdit && {
                  key: "4",
                  label: (
                    <span href="#" className="d-flex align-items-center">
                      <MdOutlineAppBlocking className="me-2" />
                      {item?.deactivate ? "Activate" : "Deactivate"}
                    </span>
                  ),
                  onClick: () => {
                    const payload = {
                      deactivate: !item?.deactivate,
                    };

                    return updateuserData(payload, item?._id);
                  },
                },

                userAccess?.isEdit && {
                  key: "5",
                  label: (
                    <span href="#" className="d-flex align-items-center">
                      <DeleteOutlined className="me-2" />
                      Delete
                    </span>
                  ),
                  onClick: () => {
                    setDeleteuserID(item?._id);
                    return setDeleteuser(true);
                  },
                },
              ],
            }}
            placement="bottomRight"
            overlayClassName={"dropdownOverlay dowpdownDark"}
            trigger={["click"]}
            className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
          >
            <div onClick={(e) => e.preventDefault()}>
              Action
              <MdKeyboardArrowDown />
            </div>
          </Dropdown>
        </span>
      </>
    ),
  }));

  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "User",
        key: "User",
      },
      {
        title: "Role",
        dataIndex: "Role",
        key: "Role",
      },
      {
        title: "Added at",
        dataIndex: "added_at",
        key: "added_at",
      },
      {
        title: "Added by",
        dataIndex: "added_by",
        key: "added_by",
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
      },
    ],
    []
  );

  const filterHeadings = useMemo(() => {
    return ["Filter by status", "Filter by roles"];
  }, []);

  const lableValue = useCallback((lable, dropdownCount) => {
    return (
      <span className="flex justify-between items-center gap-2">
        <span>{lable}</span>
        {/* <span className="selectedValue bg-sky-600 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
          {dropdownCount}
        </span> */}
      </span>
    );
  }, []);

  const roleListDatasOptions = useMemo(() => {
    const roles = RoleListData?.data?.defaultData
      ? [...RoleListData?.data?.defaultData, ...RoleListData?.data?.data]
      : [];
    if (roles?.length > 0) {
      return roles?.map((item) => {
        return {
          value: item?._id,
          label: lableValue(item?.role_name, 0),
          key: item?._id,
          id: item?._id,
        };
      });
    } else {
      return [];
    }
  }, [RoleListData, lableValue]);

  const userStatusOptions = useMemo(() => {
    // const dropdownCount = eventList?.dropdown;
    return [
      {
        value: "active", // desc
        id: "0",
        label: lableValue("Active", 0),
        // onChange: () => {
        //   setFilters({ ...filters, event_mode: '' });
        //   // return onSearch(filters.text, filters.date, '');
        // },
      },
      {
        value: "inactive", // asc
        id: "1",
        // onChange: () => {
        //   setFilters({ ...filters, event_mode: 'single' });
        //   // return onSearch(filters.text, filters.date, 'single');
        // },
        label: lableValue("Inactive", 0),
      },
      {
        value: "block", // asc
        id: "3",
        // onChange: () => {
        //   setFilters({ ...filters, event_mode: 'single' });
        //   // return onSearch(filters.text, filters.date, 'single');
        // },
        label: lableValue("Block", 0),
      },
      {
        value: "deactivate", // asc
        id: "4",
        // onChange: () => {
        //   setFilters({ ...filters, event_mode: 'single' });
        //   // return onSearch(filters.text, filters.date, 'single');
        // },
        label: lableValue("Deactivate", 0),
      },
    ];
  }, [lableValue]);

  const filtersList = useMemo(() => {
    // const showVlaue = (value) => {
    //   console.log('value111', value);
    //   return value === 'tour' ? 'Tour Events' : value;
    // };
    const filtersdata = {
      filter1: (
        <Select
          // onDropdownVisibleChange={(value) => {
          //   console.log(value, 'value');
          // }}
          style={{ width: "100%" }}
          placeholder={"Select user status"}
          // value={showVlaue(filters?.event_mode)}
          // value={filters?.event_mode}
          onChange={(value) => {
            // console.log('here', value);
            // setFilters({ ...filters, status: value });
            dispatch(updateForm(true));
          }}
          // defaultValue={'All Events'}
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          className={`rounded-lg filterDrop`}
          options={userStatusOptions}
          size="middle"
        />
      ),
      filter2: (
        <Select
          style={{ width: "100%" }}
          placeholder={"Select user role"}
          // value={filters?.status}
          onChange={(value) => {
            // console.log('here', value);
            // setFilters({ ...filters, role: value });
            dispatch(updateForm(true));
          }}
          // value={tourCat}
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          className={`rounded-lg filterDrop`}
          options={roleListDatasOptions}
          size="middle"
        />
      ),
    };
    return Object.values(filtersdata);
  }, [roleListDatasOptions, userStatusOptions, dispatch]);

  const onFilter = useCallback(async () => {
    const { filter0, filter1 } = form.getFieldsValue();
    setFilters({ status: filter0, role: filter1 });
    dispatch(updatePopover(false));
    dispatch(updateisFiltered(true));
    dispatch(updateForm(true));
  }, [dispatch, form]);

  const onClear = useCallback(async () => {
    setFilters(initialState);
    dispatch(updatePopover(false));
    dispatch(updateisFiltered(false));
    dispatch(updateForm(false));
    form.resetFields();
    await dispatch(updateFilterCount(0));
    return await refetch();
  }, [initialState, refetch, dispatch, form]);

  const values = useMemo(() => {
    return {
      form,
      AddRoleModal,
      isRefetching,
      dataSource,
      columns,
      page,
      limit,
      totalCount: data?.data?.count,
      openUserModal,
      deleteuser,
      roleListDatas,
      deleteRole,
      filterHeadings,
      filtersList,
      isLoadingRolesList,
      isLoading,
      roleAccess,
      onClear,
      onFilter,
      setsearch,
      setDeleteRole,
      onSubmitDeleteRole,
      setDeleteuserID,
      refetchUser: refetch,
      setPage,
      setLimit,
      setAddRoleModal,
      closeModal,
      onCloseUserModal,
      onCloseDeleteUser,
      onCloseDeleteRole,
      onSubmitDelete,
      refetchRoleList,
    };
  }, [
    form,
    AddRoleModal,
    dataSource,
    isRefetching,
    columns,
    page,
    limit,
    deleteuser,
    isLoading,
    data?.data?.count,
    openUserModal,
    roleListDatas,
    deleteRole,
    filterHeadings,
    filtersList,
    isLoadingRolesList,
    roleAccess,
    setsearch,
    onClear,
    onFilter,
    onSubmitDeleteRole,
    closeModal,
    refetch,
    onCloseUserModal,
    onCloseDeleteUser,
    onCloseDeleteRole,
    onSubmitDelete,
    refetchRoleList,
  ]);

  return values;
};

export default useAccessRole;
