import React from "react";
import { btnSecondayClass } from "../utils/DefaultClasses";
import { Tooltip } from "antd";
import NotificationPopover from "../notification_dropdown/NotificationPopover";
import { useNavigate } from "react-router-dom";

export default function TopBar({ closeModal }) {
  const navigate = useNavigate();
  return (
    <>
      <div class="top__bar fixed top-0 w-full hidden lg:flex z-[2]">
        <div class="w-full flex justify-end bg-color-base-1 px-3.5">
          <div class="">
            <div className="flex items-center divide-x divide-level-2 divide-dashed">
              {/* <button
							className={`${btnSecondayClass} w-[28px] h-[28px] text-lg`}
							type="button">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								id="Layer_1"
								data-name="Layer 1"
								viewBox="0 0 24 24"
								width="1em"
								height="1em"
								className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
								data-mui-internal-clone-element="true">
								<path d="M15,24A12,12,0,1,1,19.058.71a2.93,2.93,0,0,1,.814,5.067c-5.1,3.92-5.088,8.539,0,12.446a2.93,2.93,0,0,1-.816,5.067A12.2,12.2,0,0,1,15,24ZM15,3a9,9,0,1,0,2.925,17.508c-6.511-5.065-6.489-11.969,0-17.016A9.322,9.322,0,0,0,15,3Z"></path>
							</svg>
						</button> */}
              <div className="px-1.5">
                {/* <button
									className={`text-gray-500 hover:text-blue-2 w-[34px] h-[34px] text-base relative`}
									onClick={() => closeModal("Notification")}
									type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										id="Isolation_Mode"
										data-name="Isolation Mode"
										viewBox="0 0 24 24"
										width="1em"
										height="1em"
										fill="currentColor"
										className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
										<path d="M23.608,17.013l-2.8-10.1A9.443,9.443,0,0,0,2.486,7.4L.321,17.14a2.5,2.5,0,0,0,2.441,3.042H6.905a5.285,5.285,0,0,0,10.154,0H21.2a2.5,2.5,0,0,0,2.409-3.169Zm-20.223.169,2.03-9.137a6.443,6.443,0,0,1,12.5-.326l2.628,9.463Z"></path>
									</svg>
								</button> */}
                <Tooltip title="Notification" placement="bottom">
                  <NotificationPopover />
                </Tooltip>
              </div>
              <div className="px-1.5">
                <Tooltip placement="bottom" title={"Profile"}>
                  <button
                    className={`text-gray-500 hover:text-blue-2 w-[34px] h-[34px] text-base relative`}
                    onClick={() => navigate("/profile")}
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Bold"
                      viewBox="0 0 24 24"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    >
                      <path d="M18.5,0H5.5A5.506,5.506,0,0,0,0,5.5v13A5.506,5.506,0,0,0,5.5,24h13A5.506,5.506,0,0,0,24,18.5V5.5A5.506,5.506,0,0,0,18.5,0ZM21,18.5A2.5,2.5,0,0,1,18.5,21H18V20A6,6,0,0,0,6,20v1H5.5A2.5,2.5,0,0,1,3,18.5V5.5A2.5,2.5,0,0,1,5.5,3h13A2.5,2.5,0,0,1,21,5.5Z"></path>
                      <circle cx="12" cy="8.5" r="3.5"></circle>
                    </svg>
                  </button>
                </Tooltip>
              </div>
              <div className="px-1.5">
                <Tooltip placement="bottom" title={"Logout"}>
                  <button
                    className={`text-gray-500 hover:text-blue-2 w-[34px] h-[34px] text-base relative`}
                    onClick={() => closeModal("Logout")}
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512.292 512.292"
                      fill="currentColor"
                      width="1em"
                      height="1em"
                    >
                      <g>
                        <path d="M256.061,0L256.061,0c17.673,0,32,14.327,32,32v106.667c0,17.673-14.327,32-32,32l0,0c-17.673,0-32-14.327-32-32V32   C224.061,14.327,238.387,0,256.061,0z"></path>
                        <path d="M330.727,105.387L330.727,105.387c-0.157,10.742,5.259,20.8,14.315,26.581c80.432,49.143,105.796,154.185,56.652,234.616   S247.51,472.38,167.078,423.237S61.282,269.052,110.426,188.62c14.042-22.982,33.324-42.315,56.269-56.418   c9.211-5.781,14.773-15.92,14.699-26.795l0,0c0.049-17.673-14.238-32.039-31.911-32.088c-6.07-0.017-12.02,1.693-17.155,4.931   C22.233,146.634-11.58,291.318,56.803,401.412s213.067,143.907,323.161,75.524s143.907-213.067,75.524-323.161   c-19.035-30.645-44.879-56.489-75.524-75.524c-14.997-9.461-34.824-4.973-44.285,10.024   C332.447,93.397,330.731,99.33,330.727,105.387z"></path>
                      </g>
                    </svg>
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
