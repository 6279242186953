import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import { Form, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
	btnPrimaryClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import TableComponent from "../../../Component/TableComponent";
import { BsPersonLock, BsPlus } from "react-icons/bs";
import PopoverDropdown from "../../../Component/PopoverDropdown";
import StrategyFilter from "../Dashboard/StrategyFilter";
import { RiSearchLine } from "react-icons/ri";
import useUserList from "../hooks/UserList";
import CreateUserModal from "../../../Component/Common/createUserModal";
import { updateSelectedUser } from "../../../redux/createStaffUser/createStaffUser.slice";
import { useDispatch } from "react-redux";
import DeleteConfirmation from "../../../Component/Common/DeleteConfirm";
import { useMemo } from "react";
import NoDataFoundV2 from "../../../Component/NoDataFoundV2";
import UserProfileDrawer from "../Dashboard/user_detail_compo/userProfileDrawer";
import UpdatedTime from "../../../Component/UpdatedTime";

export default function UserDetailPage() {
	//   let adminid = mydecodedTokenFunction(localStorage.getItem("token")) || "";
	//   let token = localStorage.getItem("token");
	const dispatch = useDispatch();
	const [searchForm] = Form.useForm();
	//   let expiredToken = isExpired(token) ? true : false;
	//   let history = useNavigate();
	//   const [AddRoleModal, setAddRoleModal] = useState(false);
	//   const [page, setPage] = useState(1);
	//   console.log(page, "asas");
	//   const [expandSearch, setExpandSearch] = useState(false);
	//   const [checkedList, setCheckedList] = useState(defaultCheckedList);

	//   const onChange = (list) => {
	//     setCheckedList(list);
	//   };
	//   const onCheckAllChange = (e) => {
	//     setCheckedList(e.target.checked ? plainOptions : []);
	//   };
	//   const checkAll = plainOptions.length === checkedList.length;
	//   const indeterminate =
	//     checkedList.length > 0 && checkedList.length < plainOptions.length;

	//   const handleAddUserSuccess = () => {
	//     // Call the fetchStaffList function from UserList component to refresh the data
	//     // fetchStaffList(page, 10); // Assuming 10 is the limit
	//   };

	// const [formData, setFormData] = useState({

	// 	email: "",

	// 	password: "",
	// 	user_type: "staff",

	// });

	// const handleInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData({
	// 		...formData,
	// 		[name]: value,
	// 	});
	// };
	//   const handlePageChange = (page) => {
	//     setPage(page); // Update the page state
	//   };

	// const getUserAdd = async (formData) => {
	// 	try {
	// 		const response = await axios.post(API_URL.ADD_STAFF, formData, {
	// 			headers: { Authorization: `Bearer ${token}` },
	// 		});
	// 		console.log("Response Data", response.data);

	// 		toast.success("User added successfully", toastSpecs);
	// 		setAddRoleModal(false);
	// 	} catch (error) {
	// 		console.log(error);
	// 		toast.error("Something went wrong, please try again later", toastSpecs);
	// 	}
	// };
	const {
		form,
		currentPage,
		datacount,
		totalCount,
		isRefetching,
		pageLimit,
		dataSource,
		columns,
		isLoading,
		openUserModal,
		deleteuser,
		filterHeadings,
		filtersList,
		Access,
		setCurrentPage,
		setPageLimit,
		onCloseUserModal,
		setOpenUserModal,
		onCloseDeleteUser,
		onSubmitDelete,
		refetch,
		onFilter,
		onClear,
		setsearch,
	} = useUserList();

	const userList = useMemo(() => {
		if (totalCount === 0 && !isLoading) {
			return (
				<>
					<NoDataFoundV2
						title="No user's Found."
						icon={<BsPersonLock />}
						subTitle={
							Access?.isCreate ? (
								<>Click 'Add User' to create new user.</>
							) : (
								<></>
							)
						}
						onSubmit={() => {
							const payload = {
								selectedUser: {},
								selectionType: "create",
							};
							dispatch(updateSelectedUser(payload));
							setOpenUserModal(true);
						}}
						buttonProps={
							Access?.isCreate
								? {
										primaryBtnText: "Invite User",
										primaryBtnIcon: (
											<>
												<span>
													<BsPlus className="text-lg" />
												</span>
											</>
										),
								  }
								: {}
						}
					/>
				</>
			);
		} else {
			return (
				<>
					<div className=" mt-4">
						{/* card bg-color-base-1 off__blue border-level-4 shadow-md shadow-level-4 rounded-lg w-full */}
						<div className="">
							<div className="flex align-items-center justify-between">
								{/* <div className="flex">
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "0",
                                label: (
                                  <span className="d-flex align-items-center">
                                    All
                                  </span>
                                ),
                              },
                              {
                                key: "1",
                                label: (
                                  <span className="d-flex align-items-center">
                                    Subscribed
                                  </span>
                                ),
                              },
                              {
                                key: "2",
                                label: (
                                  <span className="d-flex align-items-center">
                                    Created
                                  </span>
                                ),
                              },
                            ],
                          }}
                          placement="bottomLeft"
                          arrow
                          overlayClassName={"dropdownOverlay dowpdownDark"}
                          // trigger={["click"]}
                          // className="d-flex align-items-center allReports"
                          className={`${btnSecondayClass} p-[2px] px-2 text-sm min-w-[100px]`}
                        >
                          <span
                            className="w-full flex justify-between items-center"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <span>All</span>
                            <MdKeyboardArrowDown className="rightOutLined" />
                          </span>
                        </Dropdown>
                      </div> */}
								<div>
									<div className={`relative max-w-[220px]`}>
										{/* <Input.Group
															compact
															className="searchGroup border-r-0 h-[36px] -mt-[1px]">
															<Input.Search
																allowClear
																placeholder="Search"
																// onChange={onSearch}
																// onPressEnter={onSearch}
																onSearch={() => setExpandSearch(true)}
															/>
														</Input.Group> */}
										<Form
											className="mt-3.5"
											layout="vertical"
											form={searchForm}>
											<Form.Item
												name="search"
												validateDebounce={1000}
												rules={[
													({ getFieldValue }) => ({
														validator: (_, value) => {
															setsearch(value);
															return Promise.resolve();
														},
													}),
												]}>
												<Input
													allowClear
													placeholder="Search"
													// onChange={onSearch}
													// onPressEnter={onSearch}
													className={`${formClass}`}
													//   onSearch={() => setExpandSearch(true)}
													suffix={
														<>
															<span className="">
																<RiSearchLine />
															</span>
														</>
													}
												/>
											</Form.Item>
										</Form>
									</div>
								</div>
								<div className="">
									<div className="d-flex align-items-center justify-content-end gap-2">
										<div>
											<p className="subHead">Total Users: {totalCount}</p>
										</div>
										<div className="py-0.5 hidden lg:flex">
											<span className="flex w-1 h-7 bg-level-2"></span>
										</div>
										{/* <div
														className={`${
															expandSearch
																? "animateSearch active w-full"
																: "animateSearch w-auto min-w-[36px]"
														} max-w-[220px]`}>
														<Input.Group
															compact
															className="searchGroup border-r-0 h-[36px] -mt-[1px]">
															<Input.Search
																allowClear
																placeholder="Search"
																// onChange={onSearch}
																// onPressEnter={onSearch}
																onSearch={() => setExpandSearch(true)}
															/>
														</Input.Group>
													</div> */}
										{/* <div className="py-0.5 hidden lg:flex">
											<span className="flex w-1 h-7 bg-level-2"></span>
										</div> */}
										{Access?.isCreate && (
											<div className="">
												<button
													className={`${btnPrimaryClass} text-sm`}
													onClick={() => {
														const payload = {
															selectedUser: {},
															selectionType: "create",
														};
														dispatch(updateSelectedUser(payload));
														setOpenUserModal(true);
													}}>
													<PlusOutlined />
													Invite User
												</button>
											</div>
										)}
										<div className="flex">
											<PopoverDropdown
												content={
													<StrategyFilter
														props={{
															form,
															filtersList,
															filterHeadings,
															buttons: {
																onClear,
																onFilter,
															},
														}}
													/>
												}
											/>
										</div>
										<UpdatedTime
											invokeFunction={[refetch]}
											showOnlyRefreshIcon={true}
											isLoading={isRefetching}
										/>
										{/* <div className="">
														<button
															className={`${
																selectedRowsDatas.length !== 0
																	? btnPrimaryClass
																	: btnSecondayClass
															} p-2.5 text-sm`}
															onClick={() => setShowModal(true)}>
															<span className="">
																<DeleteOutlined className="" />
															</span>
														</button>
													</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					{datacount === 0 && !isLoading ? (
						<NoDataFoundV2 title="No user's Found." icon={<BsPersonLock />} />
					) : (
						<div className="mb-3">
							<TableComponent
								dataSource={dataSource}
								columns={columns}
								total={datacount}
								loading={isLoading}
								// pagination_callback={fetchStaffList}
								className="fs_tables"
								pagination={datacount < 10 ? false : true}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								pageSize={pageLimit}
								setPageSize={setPageLimit}
							/>
						</div>
					)}
				</>
			);
		}
	}, [
		columns,
		currentPage,
		dataSource,
		datacount,
		totalCount,
		isLoading,
		pageLimit,
		setCurrentPage,
		setPageLimit,
		dispatch,
		filterHeadings,
		filtersList,
		form,
		onClear,
		onFilter,
		searchForm,
		setOpenUserModal,
		setsearch,
		Access?.isCreate,
	]);

	// console.log(data, "daataaone");

	return (
		<>
			<div className="">
				<div className="layout-row flex" id="main">
					<div className="">
						<Leftbar type="admin" />
					</div>
					<div className="left__bar w-full">
						<RightBar
							leftContent={
								<>
									<div className="d-flex align-items-center">
										{/* <div className="leftContent">
										Account Id: {adminid.id}
									</div> */}
										<div className="flex gap-2">
											<div className="">
												<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
													<BsPersonLock />
												</span>
											</div>
											<div className="">
												<div className="flex gap-2">
													<div className="flex flex-col gap-0 -space-y-1">
														<h2 className="strtgy__ttl">Users List</h2>
														<span className="text-sm flex">
															A role provided access to predefined menus and
															features so that depending on assigned role an
															administrator.
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
							rightContent={<></>}>
							{userList}
						</RightBar>
					</div>
				</div>
				{openUserModal && (
					<CreateUserModal
						props={{
							openUser: openUserModal,
							closeModal: onCloseUserModal,
						}}
					/>
				)}
				{deleteuser && (
					<DeleteConfirmation
						props={{
							showModal: deleteuser,
							hideModal: onCloseDeleteUser,
							onSubmitDelete: onSubmitDelete,
						}}
					/>
				)}

				{<UserProfileDrawer refetch={refetch} />}
			</div>
		</>
	);
}
