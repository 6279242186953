import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedPlan,
  updateSelectedPlanDetails,
} from "../../../../redux/PlanList/planList.slice";
import { toast } from "react-toastify";
import { DATEFORMAT, toastSpecs } from "../../../../Utils/utils";
import { useMutation } from "@tanstack/react-query";
import { updateSelectedPlanAPI } from "../../../../Component/helpers/api/Plans/plans";
import moment from "moment";

const usePlanDetailContent = (refetch) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { selectedPlan, selectionType, isOpen } = useSelector(
    updateSelectedPlanDetails
  );
  const [fieldTouched, setIsFieldTouched] = useState(false);

  const initialValues = useMemo(() => {
    return {
      plan_name: selectedPlan?.plan_name,
      plan_price: selectedPlan?.price,
      features: selectedPlan?.features_data,
    };
  }, [selectedPlan]);

  const closeDrawer = useCallback(() => {
    setIsFieldTouched(false);
    dispatch(
      updateSelectedPlan({
        selectedPlan: {},
        selectionType: "",
        isOpen: false,
      })
    );
    form.resetFields();
  }, [dispatch, form]);

  const insertNote = useCallback(() => {
    //   let payload = encodeDecodeAddRoleData(form.getFieldsValue(), "encode");
    // console.log("payload", form.getFieldsValue());
    const { plan_name, plan_price, features } = form.getFieldsValue();

    const payload = {
      plan_name: plan_name,
      price: plan_price,
      status: true,
      features_data: features,
      plan_id: selectedPlan?._id,
    };

    // console.log("payload", payload);
    return updateSelectedPlanAPI(payload);
  }, [form, selectedPlan]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      refetch();
      closeDrawer();
      return toast.success("Updated successfully.", toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  // console.log("asdasd", selectedPlan);

  const dataSource = useMemo(
    () =>
      selectedPlan?.userSubscriptionDetailData?.map((item) => {
        return {
          key: item?._id,
          name: (
            <>
              <span>
                {item?.userData?.first_name} {item?.userData?.last_name}
              </span>
            </>
          ),
          email: (
            <>
              <span>{item?.userData?.email}</span>
            </>
          ),
          renew_date: (
            <>
              <span>
                {item?.expired_at && item?.expired_at !== "unlimited"
                  ? moment(item?.expired_at).format(DATEFORMAT)
                  : item?.expired_at === "unlimited"
                  ? "Unlimited"
                  : "-"}
              </span>
            </>
          ),
        };
      }),

    [selectedPlan?.userSubscriptionDetailData]
  );

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Renewal Date",
        dataIndex: "renew_date",
        key: "renew_date",
      },
    ],
    []
  );

  const value = useMemo(() => {
    return {
      form,
      initialValues,
      selectedPlan,
      selectionType,
      isOpen,
      isLoading: mutation?.isPending,
      fieldTouched,
      dataSource,
      columns,
      setIsFieldTouched,
      closeDrawer,
      onSubmit,
    };
  }, [
    form,
    initialValues,
    mutation?.isPending,
    selectedPlan,
    selectionType,
    isOpen,
    fieldTouched,
    dataSource,
    columns,
    closeDrawer,
    onSubmit,
  ]);
  return value;
};

export default usePlanDetailContent;
