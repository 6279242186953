import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedUser: {},
  selectionType: "",
};

const slice = createSlice({
  name: "Staffuser",
  initialState,
  reducers: {
    updateSelectedUser: (state, { payload }) => {
      state.selectedUser = payload?.selectedUser;
      state.selectionType = payload?.selectionType;
    },
  },
});

let createUserReducer = slice.reducer;

export const { updateSelectedUser } = slice.actions;
const createStaffUser = (state) => state.createStaffUser;

export { createUserReducer, createStaffUser };
