import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { DATEFORMAT } from "../../../Utils/utils";
// import { Link } from '@mui/material';

function UserDetailsHead({ userData, userid, overview, user_image }) {
  let navigate = useNavigate();
  // let history = unstable_HistoryRouter();
  return (
    <>
      {overview ? (
        <div className="user__profile mb-3">
          <h2 className="strtgy__ttl">Account Overview</h2>
          <span className="sub__ttl d-flex gap-2 flex align-items-center mt-2">
            <Link
              onClick={() => {
                navigate(-1);
              }}
            >
              <div className="d-flex flex-row justify-between items-center gap-x-3">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                </svg>
                Go Back
              </div>
            </Link>
            {/* <Link
            onClick={() => {
              // history.goBack();
            }}
          >
            Go Back
          </Link> */}
          </span>
        </div>
      ) : null}

      <div className="user__info__box">
        <div className="row">
          <div className="col-lg-2 ps-lg-0">
            <div className="user__dp">
              <img
                src={
                  userData?.user_image?.url ||
                  "https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg"
                }
                className="img-fluid"
                alt="fluid"
              />
            </div>
          </div>
          <div className="col-lg-10">
            <div className="user__info">
              <div>
                <h2 className="strtgy__ttl mt-2">
                  {" "}
                  {userData?.first_name !== undefined
                    ? userData?.name
                      ? userData?.name
                      : `${userData?.first_name} ${userData?.last_name}`
                    : "Test user"}
                </h2>
                <span>{userData?.email ? userData?.email : "-"}</span>
              </div>
            </div>
            <div className="dash_separate"></div>
            <div className="additional__info row">
              <div className="col-lg-4 sml__grd ps-0 pe-0">
                <div className="grd__ico bg__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path>
                  </svg>
                </div>
                <div className="grd__info">
                  <h4>Register At:</h4>
                  <span>{moment(userData?.created_at).format(DATEFORMAT)}</span>
                </div>
              </div>

              <div className="col-lg-4 sml__grd ps-0 pe-0">
                <div className="grd__ico bg__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 12h-4v3l-5-5 5-5v3h4v4z"></path>
                  </svg>
                </div>
                <div className="grd__info">
                  <h4>Last Login:</h4>
                  <span>
                    {userData?.last_login
                      ? moment(userData?.last_login).format(DATEFORMAT)
                      : "Not logged in yet"}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 sml__grd ps-0 pe-0">
                <div className="grd__ico bg__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"></path>
                  </svg>
                </div>
                <div className="grd__info">
                  <h4>User Id:</h4>
                  <span>{userid?.id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDetailsHead;
