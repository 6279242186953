import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { setPasswordLink } from "../../helpers/api/Auth/Auth";
import { toastSpecs } from "../../../Utils/utils";

const useSetPasswordLink = () => {
  const insertNote = useCallback((email) => {
    const payload = {
      email,
    };
    return setPasswordLink(payload);
  }, []);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {},
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const ResendLink = useCallback(
    (email) => {
      mutation?.mutate(email);
    },
    [mutation]
  );

  const value = useMemo(() => {
    return {
      ResendLink,
    };
  }, [ResendLink]);

  return value;
};

export default useSetPasswordLink;
