import React, { useState, useEffect, useCallback, useRef } from "react";
// import ProfitLossCard from "../Dashboard/user_detail_compo/ProfitLossCard";
import { Dropdown, Form, Input, Tooltip } from "antd";
import {
  btnSecondayClass,
  formClass,
} from "../../../Component/utils/DefaultClasses";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { RiSearchLine } from "react-icons/ri";
import TableComponent from "../../../Component/TableComponent";
import TradeStatsCard from "./TradeStatsCard";
import TradeDetailDrawer from "./TradeDetailDrawer";
import { MdOutlineUploadFile } from "react-icons/md";
import {
  getVirtualTrade,
  // getVirtualtrade,
} from "../../../Component/helpers/api/Trade/tradeList";
import {
  setSelectedVirtualTrade,
  setVirtualTrades,
} from "../../../redux/TradeDetail/tradeDetailLiveVirtual";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { HiOutlineRefresh } from "react-icons/hi";
import CustomDatePicker from "../../../Component/Common/CustomDatePicker";
import moment from "moment";
// import CustomCurrencyInput from "../../../Component/Common/CustomCurrencyInput ";
import {
  DATEFORMAT,
  convertToTwoDigitDecimal,
  formatINR,
} from "../../../Utils/utils";
import { setSelectedTrade } from "../../../redux/Trader/trader.slice";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import { SpinnerBtn } from "../../../Component/utils/Icons";
import ReportPDF from "../../../Component/Common/ReportPDF";
import useExportFiles from "../../../Component/Common/hooks/useExportFiles";

export default function VirtualTrade() {
  const [show, setShow] = useState(false);
  const { exportFiles } = useExportFiles();

  const handleClose = () => {
    setShow(false);
    dispatch(setSelectedVirtualTrade(null)); // Clear selected trade on close
  };
  const [page, setPage] = React.useState(1);
  const [traderscount, setTraderCount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [searchResults, setSearchResults] = useState([]);
  const [value, setValue] = useState("");

  const [tradersdata, setTradersData] = useState([]);
  console.log(tradersdata, "dataaa");
  const [selectedUserData, setSelectedUserData] = useState(null);
  // console.log(selectedUserData," selectedd")
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(new Date());
  const formattedTime = moment(currentTime).format("MMM D, YYYY hh:mm A");
  const [tableRefReady, setTableRefReady] = useState(false);
  const [key, setSearchKey] = useState("");

  // const {
  // 	// page,
  // 	// setPage,
  // 	limit,
  // 	setLimit,
  // 	selectedDate,
  // 	setSelectedDate,
  // 	tradeData,
  // 	total,
  // 	refetchData,
  // 	// isLoading,
  // 	isRefetchingTrade,
  //   } = useVTLTOrderTradeList(id, tab);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const onDateRangeChange = (dates, dateStrings) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(moment()); // Set startDate to current date
      setEndDate(moment()); // Set endDate to current date
    }
  };

  const GET_VIRTUAL_LIST = async (page, start, end, key) => {
    try {
      const formattedStart = start ? start.format("YYYY-MM-DD") : "";
      const formattedEnd = end ? end.format("YYYY-MM-DD") : "";

      const response = await getVirtualTrade(
        page,
        10,
        formattedStart,
        formattedEnd,
        key
      );
      setTradersData(response?.data);
      setIsLoading(false);
      setTraderCount(response?.count);
      dispatch(setVirtualTrades(response?.data));
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch traders.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (startDate && endDate) {
        await GET_VIRTUAL_LIST(page, startDate, endDate, key);
      }
    };

    fetchData();
  }, [page, startDate, endDate, key]);

  useEffect(() => {
    setSearchResults(tradersdata);
  }, [tradersdata]);

  const handleSearch = async (key) => {
    try {
      const response = await getVirtualTrade(
        1,
        10,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        key
      );
      console.log("Search results:", response.data);
      setTradersData(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  const handleSearchInputChange = (e) => {
    const key = e.target.value;
    setSearchKey(key);
    debouncedHandleSearch(key);
  };

  const tableRef = useRef(null);
  const setTableRef = (element) => {
    tableRef.current = element;
    if (element) {
      setTableRefReady(true);
    }
  };
  const exportToPdf = () => {
    console.log("tableRef.current:", tableRef.current);
    if (tableRef.current) {
      const tableContent = tableRef.current;
      const printWindow = window.open("", "", "height=800,width=800");
      printWindow.document.write(
        `<html><head><title>Table Export</title></head><body>`
      );
      printWindow.document.write(tableContent.outerHTML);
      printWindow.document.write(`</body></html>`);
      printWindow.document.close();
      printWindow.print();
    }
  };

  // const insertNote = useCallback(() => {
  //     return getVirtualTrade(id, 1, total, selectedDate, tab);
  //   }, [id,  onDateRangeChange, tab]);
  tradersdata.forEach((trader) => {
    console.log(trader?.vtRequestData?.vt_inputs?.report_name, "trader");
  });

  const dataSource = searchResults?.map((item, index) => ({
    key: "1",
    _id: (
      <>
        <span
          className="text-blue-2 cursor-pointer"
          onClick={() => {
            // Dispatch the setSelectedTrade action with the item data and isOpen: true
            dispatch(
              setSelectedTrade({
                selectedTrade: [
                  { heading: "Trade ID", value: item._id },
                  {
                    heading: "Username",
                    value: `${item?.userData?.first_name} ${item?.userData?.last_name}`,
                  },
                  { heading: "Strategy Name", value: item?.strategyData?.name },

                  {
                    heading: "Report Name",
                    value: item?.vtRequestData?.vt_inputs?.report_name,
                  },
                  {
                    heading: "Trade Date",
                    value: item?.entry_time
                      ? moment(item?.date).format("MMM DD, YYYY")
                      : "-",
                  },
                  { heading: "Day", value: item?.day },

                  { heading: "Symbol", value: item.stock },
                  { heading: "Qty", value: item?.quantity },
                  { heading: "Lot Size", value: item?.lot_size },
                  { heading: "Position Type", value: item?.position_type },
                  {
                    heading: "Entry Time",
                    value: item?.entry_time
                      ? moment(item.enty_time).format("MMM DD, YYYY hh:mm A")
                      : "-",
                  },

                  {
                    heading: "Entry Price",
                    value: formatINR(
                      convertToTwoDigitDecimal(item.entry_price)
                    ),
                  },
                  {
                    heading: "Exit Time",
                    value: item?.entry_time
                      ? moment(item.exit_time).format("MMM DD, YYYY hh:mm A")
                      : "-",
                  },
                  {
                    heading: "Exit Price",
                    value: formatINR(
                      convertToTwoDigitDecimal(item?.exit_price)
                    ),
                  },
                  {
                    heading: "Gross P&L",
                    value: formatINR(convertToTwoDigitDecimal(item?.pnl)),
                  },
                  {
                    heading: "Brokerage",
                    value: formatINR(convertToTwoDigitDecimal(item?.brokerage)),
                  },
                  {
                    heading: "Net P&L",
                    value: formatINR(convertToTwoDigitDecimal(item?.net_pnl)),
                  },
                ],
                isOpen: true,
              })
            );
          }}
        >
          {item._id ? `${item._id.slice(0, 5)}...${item._id.slice(-5)}` : "-"}
        </span>
      </>
    ),
    userData: (
      <>
        {item?.userData?.first_name || "-"} {item?.userData?.last_name || "-"}
      </>
    ),
    stock: (
      <>
        {item.stock
          ? `${item.stock.slice(0, 5)}...${item.stock.slice(-5)}`
          : "-"}
      </>
    ),
    strategyData: <>{item?.strategyData?.name || "-"}</>,
    entry_time: <>{moment(item?.entry_time).format(DATEFORMAT)}</>,
    entry_price: <>{formatINR(convertToTwoDigitDecimal(item.entry_price))}</>,
    exit_price: <>{formatINR(convertToTwoDigitDecimal(item.exit_price))}</>,
    exit_time: <>{moment(item?.exit_time).format(DATEFORMAT)}</>,
    net_pnl: (
      <span className={item.net_pnl > 0 ? "text-green-600" : "text-red-600"}>
        {formatINR(convertToTwoDigitDecimal(item.net_pnl))}{" "}
        {item.net_pnl > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
      </span>
    ),
  }));

  const tradeColumnsPDF = [
    {
      title: "Strategy Name",
      dataIndex: "userData",
      render: (text, record) => record?.strategyData?.name || "-",
    },
    {
      title: "User Name",
      dataIndex: "userData",
      render: (text, record) => {
        const { first_name, last_name } = record?.userData || {};
        return `${first_name || ""} ${last_name || ""}` || "-";
      },
    },
    {
      title: "Entry Time",
      dataIndex: "entry_time",
      render: (text, record) => (
        <>{moment.utc(record.entry_time).format(DATEFORMAT)}</>
      ),
    },
    {
      title: "Entry Price",
      dataIndex: "entry_price",
      render: (entry_price, record) => `${record.entry_price?.toFixed(2)}`,
    },
    {
      title: "Exit Price",
      dataIndex: "exit_price",
      render: (exit_price, record) => `${record.exit_price?.toFixed(2)}`,
    },
    {
      title: "Exit Time",
      dataIndex: "exit_time",
      render: (text, record) => (
        <>{moment.utc(record.exit_time).format(DATEFORMAT)}</>
      ),
    },
    {
      title: "Exit Type",
      dataIndex: "exit_type",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Lot Size",
      dataIndex: "lot_size",
    },
    {
      title: "Position",
      dataIndex: "position_type",
    },
    {
      title: "Points",
      dataIndex: "points",
    },
    {
      title: "Day",
      dataIndex: "day",
    },
    {
      title: "Brokerage",
      dataIndex: "brokerage",
      render: (brokerage, record) => brokerage?.toFixed(2),
    },
    {
      title: "P&L",
      key: "pnl",
      dataIndex: "pnl",
      render: (pnl, record) => `${record.pnl?.toFixed(2)}`,
    },
  ];

  const columns = [
    {
      title: "Trade Id",
      dataIndex: "_id",
      key: "1",
      size: "10",
    },
    {
      title: "Username",
      dataIndex: "userData",
      key: "2",
    },
    {
      title: "Strategy Name",
      dataIndex: "strategyData",
      key: "3",
    },
    {
      title: "Symbol",
      dataIndex: "stock",
      key: "4",
    },
    {
      title: "Entry Time",
      dataIndex: "entry_time",
      key: "5",
    },
    {
      title: "Entry Price",
      dataIndex: "entry_price",
      key: "6",
    },
    {
      title: "Exit Price",
      dataIndex: "exit_price",
      key: "7",
    },
    {
      title: "Exit Time",
      dataIndex: "exit_time",
      key: "8",
    },
    {
      title: "Net P&L",
      dataIndex: "net_pnl",
      key: "9",
    },
  ];
  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const insertNote = useCallback(() => {
    const formattedStart = startDate ? startDate.format("YYYY-MM-DD") : "";
    const formattedEnd = endDate ? endDate.format("YYYY-MM-DD") : "";
    return getVirtualTrade(1, traderscount, formattedStart, formattedEnd);
  }, [traderscount, startDate, endDate]);
  return (
    <>
      <div>
        <div className="row">
          <div className="col">
            <div className="flex gap-2 off__blue p-2.5 rounded-md">
              <div className="">
                <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="shrink-0"
                  >
                    <polyline points="16 16 12 12 8 16"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                    <polyline points="16 16 12 12 8 16"></polyline>
                  </svg>
                </span>
              </div>
              <div className="">
                <div className="flex gap-2">
                  <div>
                    <h2 className="strtgy__ttl">Virtual Trade Overview</h2>
                    <span className="text-sm -mt-1 flex">
                      <span>{formattedTime}</span>
                    </span>
                  </div>
                  <div>
                    <span className="badge__green flex items-center leading-tight">
                      Virtual
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-0 p-lg-0 mt-3">
          <div className="col">
            <TradeStatsCard
              title={
                <>
                  <span className="font-medium">Total no. of Virtual</span>
                </>
              }
              // time={"24-May-2024"}
              value={0}
            />
          </div>
          <div className="col">
            <TradeStatsCard
              title={
                <>
                  <span className="font-medium">Active Virtual</span>
                </>
              }
              // time={"24-May-2024"}
              value={0}
            />
          </div>
          <div className="col">
            <TradeStatsCard
              title={
                <>
                  <span className="font-medium">Inactive Virtual</span>
                </>
              }
              // time={"24-May-2024"}
              value={0}
            />
          </div>
          <div className="col">
            <TradeStatsCard
              title={
                <>
                  <span className="font-medium">Total Running Virtual</span>
                </>
              }
              // time={"24-May-2024"}
              value={0}
            />
          </div>
        </div>
      </div>
      <div className=" mt-4">
        <div className="flex align-items-center justify-between">
          <div className="flex">
            <div className={`relative max-w-[220px]`}>
              <Form
                className="mt-3.5"
                layout="vertical"
                // form={searchForm}
              >
                <Form.Item
                  name="search"
                  validateDebounce={1000}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        console.log(value, "value");
                        // setsearch(value);
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="Search"
                    onChange={handleSearchInputChange}
                    // onChange={onSearch}
                    // onPressEnter={onSearch}
                    className={`${formClass}`}
                    // onSearch={() => setExpandSearch(!expandSearch)}
                    suffix={
                      <>
                        <span className="">
                          <RiSearchLine />
                        </span>
                      </>
                    }
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="w-full">
            <div className="d-flex align-items-center justify-content-end gap-2">
              {/* <div>
													<p className="subHead">Total Users: 12</p>
												</div> */}
              {/* <div className="py-0.5 hidden lg:flex">
													<span className="flex w-1 h-7 bg-level-2"></span>
												</div> */}
              {/* <div
														className={`${
															expandSearch
																? "animateSearch active w-full"
																: "animateSearch w-auto min-w-[36px]"
														} max-w-[220px]`}
														> */}
              {/* <div className="py-0.5 hidden lg:flex">
														<span className="flex w-1 h-7 bg-level-2"></span>
													</div> */}
              <CustomDatePicker
                presets={rangePresets}
                onChange={onDateRangeChange}
                value={[startDate, endDate]}
                format="YYYY-MM-DD"
              />
              <div className="py-0.5 hidden lg:flex">
                <span className="flex w-1 h-7 bg-level-2"></span>
              </div>
              <div className="">
                <Tooltip title={"Export"}>
                  <Dropdown
                    menu={{
                      items: [
                        // {
                        // 	key: "1",
                        // 	label: (
                        // 	  <Link
                        // 		to={`#`}
                        // 		className="d-flex align-items-center hover:no-underline"
                        // 	  >
                        // 		Save as PDF
                        // 	  </Link>
                        // 	),
                        // 	onClick: () => {
                        // 		exportFiles("PDF", "Trades", insertNote);
                        // 	},
                        // 	disabled: isLoading || traderscount === 0,
                        //   },
                        {
                          key: "2",
                          label: (
                            <span
                              href="#"
                              className="d-flex align-items-center"
                            >
                              Save as CSV
                            </span>
                          ),
                          onClick: () => {
                            exportFiles({
                              fileType: "CSV",
                              fileName: "Transaction",
                              InvolkeAPI: insertNote,
                              from: "vtlt",
                            });
                          },

                          disabled: isLoading || traderscount === 0,
                        },
                        {
                          key: "3",
                          label: (
                            <span
                              href="#"
                              className="d-flex align-items-center"
                            >
                              Save as Excel
                            </span>
                          ),
                          onClick: () => {
                            //   let reportNames = [];
                            //   let strategyNames = [];
                            //   let userNames = [];

                            //   tradersdata.forEach(trader => {
                            // 	const reportName = trader?.vtRequestData?.vt_inputs?.report_name;
                            // 	const strategyName = trader?.strategyData?.name;
                            // 	const userName = trader?.userData?.first_name + " " + trader?.userData?.last_name;

                            // 	if (reportName) reportNames.push(reportName);
                            // 	if (strategyName) strategyNames.push(strategyName);
                            // 	if (userName) userNames.push(userName);
                            //   });

                            exportFiles({
                              fileType: "EXCEL",
                              fileName: "Trades",
                              InvolkeAPI: insertNote,
                              from: "vtlt",
                              // addl: {
                              //   reportNames: reportNames.join(", "),
                              //   strategyNames: strategyNames.join(", "),
                              //   userNames: userNames.join(", "),
                              // },
                            });
                          },
                          disabled: isLoading || traderscount === 0,
                        },
                      ],
                    }}
                    placement="bottomRight"
                    overlayClassName={"dropdownOverlay dowpdownDark"}
                    trigger={["hover"]}
                    className={`${btnSecondayClass} w-[36px] h-[36px] relative`}
                  >
                    <div onClick={(e) => e.preventDefault()}>
                      <MdOutlineUploadFile className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </div>
                  </Dropdown>
                </Tooltip>
              </div>
              <div className="">
                <Tooltip title={"Refresh"}>
                  <button
                    className={`${btnSecondayClass} w-[36px] h-[36px] relative`}
                    onClick={() => {
                      // Call the GET_VIRTUAL_LIST function to refresh the data
                      GET_VIRTUAL_LIST(
                        page,
                        moment().startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    <HiOutlineRefresh className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </Tooltip>
              </div>
              <div className="flex">
                {/* <PopoverDropdown
														content={
															<StrategyFilter
																props={{
																	form,
																	filtersList,
																	filterHeadings,
																	buttons: {
																		onClear,
																		onFilter,
																	},
																}}
															/>
														}
													/> */}
              </div>
              {/* <div className="">
														<button
															className={`${
																selectedRowsDatas.length !== 0
																	? btnPrimaryClass
																	: btnSecondayClass
															} p-2.5 text-sm`}
															onClick={() => setShowModal(true)}>
															<span className="">
																<DeleteOutlined className="" />
															</span>
														</button>
													</div> */}
            </div>
          </div>
        </div>
      </div>
      <div ref={setTableRef}>
        <TableComponent
          dataSource={dataSource}
          loading={isLoading}
          onChange={handleSearchInputChange}
          columns={columns}
          pagination={true}
          total={traderscount}
          pagination_callback={GET_VIRTUAL_LIST}
          setCurrentPage={setPage}
          currentPage={page}
        />
        <TradeDetailDrawer />
        <ReportPDF columns={tradeColumnsPDF} />
        {/* <TradeDetailDrawer handleClose={handleClose} show={show}  userData={selectedUserData} /> */}
      </div>
    </>
  );
}
