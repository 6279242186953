import {
	AiFillCloseCircle,
	AiOutlineCheck,
	AiOutlineCopy,
} from "react-icons/ai";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SubscriptionCard from "./SubscriptionCard";
import { Form, Input } from "antd";
import useUserOverview from "./hooks/useUserOverview";
import { formClass } from "../../../../Component/utils/DefaultClasses";
import moment from "moment";
import { DATEFORMAT } from "../../../../Utils/utils";

export default function UserOverview({ form, refetch }) {
  const {
    selectedUser,
    isTab,
    isLoading,
    isPlanCovered,
    subscriptionPlan,
    showDetail,
    emailCheckmark,
    isView,
    Access,
    toggle,
    setIsTabed,
    updateuserData,
    setShowDetail,
    setEmailCheckmark,
    planDetail,
  } = useUserOverview(refetch);

	return (
		<div>
			<div className="p-[18px] py-1.5 border-[1px] border-level-2 rounded-lg mb-2 relative">
				{Access?.isEdit && !isView && (
					<div className="absolute top-3 right-3 z-10">
						{isTab ? (
							<button className="btn_default" onClick={() => toggle(false)}>
								<svg
									stroke="currentColor"
									fill="currentColor"
									stroke-width="0"
									viewBox="0 0 24 24"
									height="1em"
									width="1em"
									xmlns="http://www.w3.org/2000/svg">
									<path fill="none" d="M0 0h24v24H0z"></path>
									<path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
								</svg>
							</button>
						) : (
							<div className="d-flex flex-row gap-2">
								<button
									className="btn_default"
									onClick={() => {
										form.resetFields();
										toggle(true);
									}}>
									<svg
										stroke="currentColor"
										fill="currentColor"
										stroke-width="0"
										viewBox="0 0 512 512"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg">
										<path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
									</svg>
									{/* Cancel */}
								</button>
								<button
									className="btn_default_active"
									style={{ padding: "10px" }}
									disabled={isLoading || isView}
									onClick={() => {
										const payload = {
											first_name: form.getFieldValue("first_name"),
											last_name: form.getFieldValue("last_name"),
										};

										setIsTabed(true);
										return updateuserData(payload, selectedUser?._id, true);
									}}>
									<svg
										stroke="currentColor"
										fill="currentColor"
										stroke-width="0"
										viewBox="0 0 512 512"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M272 64h-16c-4.4 0-8 3.6-8 8v72c0 4.4 7.6 8 12 8h12c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8z"></path>
										<path d="M433.9 130.1L382 78.2c-9-9-21.3-14.2-34.1-14.2h-28c-8.8 0-16 7.3-16 16.2v80c0 8.8-7.2 16-16 16H160c-8.8 0-16-7.2-16-16v-80c0-8.8-7.2-16.2-16-16.2H96c-17.6 0-32 14.4-32 32v320c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V164c0-12.7-5.1-24.9-14.1-33.9zM322 400.1c0 8.8-8 16-17.8 16H143.8c-9.8 0-17.8-7.2-17.8-16v-96c0-8.8 8-16 17.8-16h160.4c9.8 0 17.8 7.2 17.8 16v96z"></path>
									</svg>
									{/* Save */}
								</button>
							</div>
						)}
					</div>
				)}
				<div className="-mx-2.5">
					<div className="row">
						<div className="col-lg-12">
							<div className="flex gap-2">
								<div className="user__dp max-w-[80px] max-h-[80px] shrink-0 flex">
									<img
										src={
											selectedUser?.user_image
												? selectedUser?.user_image?.url
												: `https://eu.ui-avatars.com/api/?name=${encodeURIComponent(
														`${selectedUser?.first_name || ""} ${
															selectedUser?.last_name || ""
														}`.trim(),
												  )}&size=250`
										}
										className="img-fluid object-cover"
										alt="fluid"
									/>
								</div>

								<div className="flex justify-start flex-col">
									<div className="flex flex-col space-y-1">
										<div className="flex flex-row items-center gap-1">
											<h2 className="text-xl font-medium">
												{selectedUser?.first_name} {selectedUser?.last_name}
											</h2>
											<span
												className={`px-1.5 py-0.5 rounded-md text-sm ${
													selectedUser.block
														? "bg-red-2/15 text-red-2"
														: selectedUser.deactivate
														? "bg-red-2/15 text-red-2"
														: selectedUser.email_verify &&
														  selectedUser?.password?.length > 0
														? "bg-blue-2/15 text-blue-2"
														: "bg-red-2/15 text-red-2"
												}`}>
												{selectedUser.block
													? "Blocked"
													: selectedUser.deactivate
													? "Deactivated"
													: selectedUser.email_verify &&
													  selectedUser?.password?.length > 0
													? "Active"
													: "Inactive"}
											</span>
										</div>
										{!isPlanCovered && (
											<span className="text-color-secondary">
												{selectedUser?.email}
											</span>
										)}
										{isPlanCovered && (
											<div className="flex items-center">
												<span className=" font-medium py-0.5 text-xsflex justify-center items-center bg-green-2/15 text-green-2 rounded-md px-2.5 min-h-[25px] min-w-[80px] leading-tight">
													{/* {userData?.active_plan?.subscriptionPlanData?.features_data?.plan_name
} */}{" "}
													{/* {selectedUser?.signup_type} */}
													{planDetail?.subscriptionPlanData?.plan_name || planDetail?.subscriptionPlanData?.[0].plan_name}
												</span>
											</div>
										)}
									</div>
								</div>
							</div>
							<Form
								className=""
								layout="vertical"
								form={form}
								disabled={isLoading || isView}
								initialValues={{
									first_name: selectedUser?.first_name || undefined,
									last_name: selectedUser?.last_name || undefined,
									email: selectedUser?.email || undefined,
									phone_no: selectedUser?.phone || undefined,
								}}>
								<div className="flex flex-col gap-[14px] mt-6">
									<div className="accountDetails">
										<h6 className="font-medium text-[15px]">First Name</h6>

										{isTab ? (
											<>
												<p className="text-sm">
													{selectedUser?.first_name
														? selectedUser?.first_name
														: "-"}
												</p>
											</>
										) : (
											<>
												<div className="flex w-full max-w-[250px] mt-0.5">
													<Form.Item
														// label="First"
														name="first_name"
														rules={[
															{
																required: true,
																message: "First Name is Required.",
															},
														]}
														className="mb-0 w-full">
														<Input
															placeholder="First name"
															// value={datas?.first_name}
															name="first_name"
															className={`${formClass}`}
															// onChange={(e) => handleChangeName(e)}
														/>
													</Form.Item>
												</div>
											</>
										)}
									</div>
									<div className="accountDetails">
										<h6 className="font-medium text-[15px]">Last Name</h6>

										{isTab ? (
											<>
												<p className="text-sm">
													{selectedUser?.last_name
														? selectedUser?.last_name
														: "-"}
												</p>
											</>
										) : (
											<>
												<div className="flex w-full max-w-[250px] mt-0.5">
													<Form.Item
														// label="First"
														name="last_name"
														rules={[
															{
																required: true,
																message: "Last Name is Required.",
															},
														]}
														className="mb-0 w-full">
														<Input
															placeholder="Last name"
															// value={datas?.last_name}
															name="last_name"
															className={`${formClass}`}
															// onChange={(e) => handleChangeName(e)}
														/>
													</Form.Item>
												</div>
											</>
										)}
									</div>
									{isPlanCovered && (
										<div className="accountDetails">
											<div className="flex items-center gap-2">
												<h6 className="font-medium text-[15px]">
													Account Type
												</h6>
											</div>

											{isTab ? (
												<>
													<p className="text-sm">
														{selectedUser?.signup_type
															? selectedUser?.signup_type
															: "NA"}
													</p>
												</>
											) : (
												<>
													<div className="flex w-full max-w-[250px] mt-0.5">
														<Form.Item
															name="phone_number"
															className="mb-0 w-full">
															<Input
																placeholder={
																	selectedUser?.signup_type || "Account Type"
																}
																disabled={true}
																className={`${formClass}`}
															/>
														</Form.Item>
													</div>
												</>
											)}
										</div>
									)}

									<div className="accountDetails">
										<div className="flex items-center gap-2">
											<h6 className="font-medium text-[15px]">Email Id</h6>
											<div>
												{selectedUser.email_verify &&
												selectedUser?.password?.length > 0 ? (
													<span className="flex items-center gap-1 text-xs text-blue-2 bg-blue-2/10 px-1.5 py-0.5 rounded-md">
														<BsFillPatchCheckFill />
														Verified
													</span>
												) : (
													<span className="flex items-center gap-1 text-xs text-red-2 bg-red-2/10 px-1.5 py-0.5 rounded-md">
														<AiFillCloseCircle />
														Not Verified
													</span>
												)}
											</div>
										</div>

										{isTab ? (
											<>
												<p className="text-sm flex items-center gap-1.5">
													{selectedUser?.email ? selectedUser?.email : "-"}
													{emailCheckmark ? (
														<span className="text-green-2 cursor-pointer d-flex items-center align-middle gap-1 text-xs">
															<AiOutlineCheck />
															<span> Copied</span>
														</span>
													) : (
														<span className="text-blue-2 cursor-pointer">
															<CopyToClipboard text={selectedUser?.email}>
																<AiOutlineCopy
																	onClick={() => {
																		setEmailCheckmark(true);
																		setTimeout(() => {
																			setEmailCheckmark(false);
																		}, 1000);
																	}}
																/>
															</CopyToClipboard>
														</span>
													)}
												</p>
											</>
										) : (
											<>
												<div className="flex w-full max-w-[250px] mt-0.5">
													<Form.Item name="email" className="mb-0 w-full">
														<Input
															placeholder="Last name"
															// value={datas?.last_name}
															name="last_name"
															className={`${formClass}`}
															disabled={true}
															// onChange={(e) => handleChangeName(e)}
														/>
													</Form.Item>
												</div>
											</>
										)}
									</div>
									<div className="accountDetails">
										<div className="flex items-center gap-2">
											<h6 className="font-medium text-[15px]">Phone</h6>
											<span className="flex items-center gap-1 text-xs text-red-2 bg-red-2/10 px-1.5 py-0.5 rounded-md">
												<AiFillCloseCircle />
												Not Verified
											</span>
										</div>

										{isTab ? (
											<>
												<p className="text-sm">
													{selectedUser?.phone_no
														? selectedUser?.phone_no
														: "NA"}
												</p>
											</>
										) : (
											<>
												<div className="flex w-full max-w-[250px] mt-0.5">
													<Form.Item
														// label="First"
														name="phone_number"
														className="mb-0 w-full">
														<Input
															placeholder="Phone number"
															disabled={true}
															className={`${formClass}`}
															// onChange={(e) => handleChangeName(e)}
														/>
													</Form.Item>
												</div>
											</>
										)}
									</div>
								</div>
							</Form>
						</div>
						<div className="col-lg-12">
							<div className="dash_separate"></div>
							<div className="additional__info row">
								<div className="col-lg-6 sml__grd ps-0 pe-0">
									<div className="grd__ico bg__icon">
										<svg
											stroke="currentColor"
											fill="currentColor"
											stroke-width="0"
											viewBox="0 0 24 24"
											height="1em"
											width="1em"
											xmlns="http://www.w3.org/2000/svg">
											<path fill="none" d="M0 0h24v24H0z"></path>
											<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path>
										</svg>
									</div>
									<div className="grd__info">
										<h4>Last Password Change:</h4>
										<span>
											{selectedUser?.last_password_update
												? moment(selectedUser?.last_password_update).format(
														DATEFORMAT,
												  )
												: "Password not changed yet."}
										</span>
									</div>
								</div>

								<div className="col-lg-6 sml__grd ps-0 pe-0">
									<div className="grd__ico bg__icon">
										<svg
											stroke="currentColor"
											fill="currentColor"
											stroke-width="0"
											viewBox="0 0 24 24"
											height="1em"
											width="1em"
											xmlns="http://www.w3.org/2000/svg">
											<path fill="none" d="M0 0h24v24H0z"></path>
											<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 12h-4v3l-5-5 5-5v3h4v4z"></path>
										</svg>
									</div>
									<div className="grd__info">
										<h4>Last Login:</h4>
										<span>
											{selectedUser?.last_login
												? moment(selectedUser?.last_login).format(DATEFORMAT)
												: "Not logged in yet"}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isPlanCovered && (
				<>
					<div>
						<SubscriptionCard
							data={subscriptionPlan?.[0]}
							showDetail={showDetail}
						/>
					</div>
					<div className="flex justify-center">
						<button
							className="text-color-primaryColor1 hover:underline"
							onClick={() => {
								setShowDetail(!showDetail);
							}}>
							{showDetail ? "View less" : "View all features"}
						</button>
					</div>
				</>
			)}
		</div>
	);
}
