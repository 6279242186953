import { useState, useEffect } from "react";
import { btnSecondayClass } from "../../../../Component/utils/DefaultClasses";
import { PiStrategyBold } from "react-icons/pi";
import { Dropdown } from "antd";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import OverviewCard from "./OverviewCard";
import UserTradeProfile from "../../../../Component/Profile/UserTradeProfile";
import UserInvoice from "./UserInvoice";
import NoDataFoundV2 from "../../../../Component/NoDataFoundV2";
import { getStrategySubscribedByID } from "../../../../Component/helpers/api/StrategyPermission/strategyPermissions";
import TableComponent from "../../../../Component/TableComponent";
import { EyeOutlined } from "@ant-design/icons";
import { convertToTwoDigitDecimal, formatINR } from "../../../../Utils/utils";
import { MdKeyboardArrowDown } from "react-icons/md";
import StatsUsage from "../../../profile_view/UserComponents/stats_usage/StatsUsage";

export default function UserTabsDetail({
  activePlanDetails,
  accountCreatedAt,
  userData,
  refetch,
}) {
  const [tab, changeTab] = useState("overview");
  const [dataSubscribed, setDataSubscribed] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  };

  const { id } = useParams();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStrategySubscribedByID(id, page);
        setDataSubscribed(response?.data?.data || []);
        setPagination((prev) => ({
          ...prev,
          total: response?.data?.total || 0,
        }));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, page]);

  // const handleTableChange = (newPagination) => {
  //   setPagination({
  //     ...pagination,
  //     current: newPagination.current,
  //     pageSize: newPagination.pageSize,
  //   });
  // };

  const columnsStrategy = [
    {
      title: "Strategy Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link
          to={`/strategy/${record.key}`}
          className="text-blue-600 hover:underline"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
    },
    {
      title: "Min. Capital Required",
      dataIndex: "min_amount_required",
    },
    {
      title: "Risk",
      dataIndex: "risk_factor",
    },
    {
      title: "Strategy Category",
      dataIndex: "access_type",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const dataSource = dataSubscribed?.map((item) => ({
    key: item._id,
    name: item?.name,
    instrument: item?.instrument,
    min_amount_required: formatINR(
      convertToTwoDigitDecimal(item.min_amount_required)
    ),
    risk_factor: item?.risk_factor || "-",
    access_type: capitalizeFirstLetter(item?.access_type),
    action: (
      <>
        <span className="flex">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Link
                      to={`/strategy/${item._id}`}
                      className="d-flex align-items-center hover:no-underline"
                    >
                      <EyeOutlined className="me-2" />
                      View
                    </Link>
                  ),
                },
              ],
            }}
            className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
          >
            <div onClick={(e) => e.preventDefault()}>
              Action
              <MdKeyboardArrowDown />
            </div>
          </Dropdown>
        </span>
      </>
    ),
  }));

  const GET_SUBSCRIBED_STRATEGY = async (page1) => {
    await getStrategySubscribedByID(page1)
      .then((res) => setDataSubscribed(res?.data?.data))
      .catch((err) => console.log(err));
  };

  const dropDown = () => {
    return (
      <div className="flex items-center gap-2">
        <span className="flex">
          <Dropdown
            menu={{
              items: [
                {
                  key: "0",
                  label: (
                    <span className="d-flex align-items-center">
                      Current Day
                    </span>
                  ),
                },
                {
                  key: "1",
                  label: (
                    <span className="d-flex align-items-center">Last Day</span>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <span className="d-flex align-items-center">Last Week</span>
                  ),
                  onClick: () => {},
                },
                {
                  key: "3",
                  label: (
                    <span href="#" className="d-flex align-items-center">
                      Last Month
                    </span>
                  ),
                  onClick: () => {},
                },
              ],
            }}
            placement="bottomRight"
            overlayClassName={"dropdownOverlay dowpdownDark"}
            trigger={["click"]}
            className={`${btnSecondayClass} p-[2px] px-2 text-sm`}
          >
            <div onClick={(e) => e.preventDefault()}>
              <span>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
          </Dropdown>
        </span>
      </div>
    );
  };

  return (
    <div>
      <div className="flex gap-2 items-center mb-2.5">
        <button
          className={`py-2.5 pt-0 px-3.5 border-b-[3px] ${
            tab === "overview" ? "border-b-blue-2" : "border-b-transparent"
          }`}
          onClick={() => changeTab("overview")}
        >
          <span className={tab === "overview" ? "active" : ""}>Overview</span>
        </button>
        <button
          className={`py-2.5 pt-0 px-3.5 border-b-[3px] ${
            tab === "trade" ? "border-b-blue-2" : "border-b-transparent"
          }`}
          onClick={() => changeTab("trade")}
        >
          <span>Trade</span>
        </button>
        <button
          className={`py-2.5 pt-0 px-3.5 border-b-[3px] ${
            tab === "Strategy" ? "border-b-blue-2" : "border-b-transparent"
          }`}
          onClick={() => changeTab("Strategy")}
        >
          <span className={tab === "Strategy" ? "active" : ""}>Strategy</span>
        </button>
      </div>
      <div className="space-y-6">
        {tab === "overview" && (
          <>
            <div className="card bg-blue-2/10 border-transparent gray-100 p-6 rounded-xl space-y-5">
              <OverviewCard
                activePlanDetails={activePlanDetails}
                userData={userData}
                refetch={refetch}
              />
            </div>
            <StatsUsage />
            <UserInvoice />
          </>
        )}
        {tab === "trade" && (
          <UserTradeProfile
            dropDown={dropDown}
            accountCreatedAt={accountCreatedAt}
          />
        )}
        {tab === "Strategy" && (
          <div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl space-y-5">
            <div className="text-base font-semibold -mt-3">Strategy</div>
            <div className="m-0 min-h-[280px] items-center justify-content-center d-flex flex-col">
              {loading ? (
                <div></div>
              ) : dataSubscribed.length > 0 ? (
                <TableComponent
                  dataSource={dataSource}
                  columns={columnsStrategy}
                  pagination_callback={GET_SUBSCRIBED_STRATEGY}
                  setCurrentPage={setPage}
                  currentPage={page}
                  total={dataSubscribed?.count}
                  pagination={true}
                  // pagination={{
                  //   current: pagination.current,
                  //   pageSize: pagination.pageSize,
                  //   total: pagination.total,
                  //   onChange: handleTableChange,
                  // }}
                />
              ) : (
                <NoDataFoundV2
                  title={
                    "User hasn't subscribed or created any custom strategy yet"
                  }
                  subTitle="Once done list will be available over here"
                  icon={<PiStrategyBold />}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
