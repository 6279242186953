import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import useDecodedToken from "../../utils/useDecodedToken";
import { getPlansListAPI } from "../../helpers/api/Plans/plans";

const usePlanListAPI = () => {
  const decodedToken = useDecodedToken();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [search, setSearch] = useState("");

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["PlanList", decodedToken, page, limit, search],
    queryFn: async () => {
      if (decodedToken) {
        return await getPlansListAPI(page, limit, search);
      } else {
        return null;
      }
    },
  });

  return {
    page,
    setPage,
    limit,
    setLimit,
    search,
    setSearch,
    data,
    refetch,
    isLoading,
    isRefetching,
  };
};

export default usePlanListAPI;
