import React, { useState } from "react";
import { BiSolidCube } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
	btnPrimaryClass,
	btnSuccessClass,
} from "../../../../Component/utils/DefaultClasses";
import { AiOutlineCheck } from "react-icons/ai";
import moment from "moment";
import { useSelector } from "react-redux";

export default function SubscriptionCard({ data, showDetail }) {
	//   const activePlanDetails = useSelector((state) => state.selectedUserData.planDetail);

    console.log(data,"datata")
	return (
	  <div className="h-full group">
		<div className={`border-[1px] border-level-2 p-[15px] rounded-xl  bg-color-base-4 h-full relative overflow-hidden duration-300 ease-in-out`}>
		  <div className="absolute top-5 right-5 text-green-3 text-2xl">
			<BsCheckCircleFill />
		  </div>
  
		  <div className="space-y-4">
			<div className="space-y-1">
			  <div>
				<span>{data?.icon ? data?.icon : null}</span>
			  </div>
			  <div>
				<h1 className="text-2xl font-bold">{data?.title}</h1>
			  </div>
			</div>
			<div>
			  <div>
				<h2 className="text-2xl font-bold mb-0 flex items-center gap-1.5">
				  {data?.price} 
				</h2>
				<p className="mb-0 text-color-secondary font-medium">
				  {data?.subtitle}
				</p>
				<p className="mb-0 text-red-3 font-medium">
				  Active until: { data?.expired_at
				  ? moment(data?.expired_at).format('MMM DD, YYYY hh:mm A')
				  : '-'}
				</p>
			  </div>
			</div>
			<div className={`${showDetail ? "" : ""} w-full`}>
			  <div className={`${data?.selected ? `${btnSuccessClass} hover:bg-green-3/100` : btnPrimaryClass} w-full text-lg py-2.5 rounded-[12px]`}>
				{data?.selected ? "Active Plan" : data?.btnText}
			  </div>
			</div>
			<div style={{ marginBottom: `${showDetail ? "" : "-20px"}` }}>
			  {showDetail ? (
				<p className="font-semibold mb-1.5">{data?.plan_list_title}</p>
			  ) : null}
			  <div className={`${showDetail ? "accordion-content" : ""} showDetail overflow-hidden`}>
				<div className="space-y-2 pl-1.5">
				  {data?.plan_list.map(
					(items, index) =>
					  index <= items.length - 1 && (
						<div className="text-color-primary flex items-center gap-1.5" key={index}>
						  <span>
							<AiOutlineCheck className="text-green-3 font-medium" />
						  </span>
						  <span className="text-color-secondary font-medium">
							{items}
						  </span>
						</div>
					  ),
				  )}
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	);
  }
  
