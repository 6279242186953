import { Button, Checkbox, Form, Input, Radio } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import dark_logo from "../../../assets/images/logo_black.svg";
import API_URL from "../../../Constants/api-path";
import { toast } from "react-toastify";
import axios from "axios";
import { toastSpecs } from "../../../Utils/utils";

function ExpiredLink() {
  let email = JSON.parse(localStorage.getItem("EmailID"));
  const ExpiredLink = () => {
    if (email !== "") {
      axios
        .get(`${API_URL.ADMIN_RESEND_FORGOT_PASSWORD}/${email}`)
        .then((res) => {
          return toast.success(res.data.message, toastSpecs);
        })
        .catch((error) => {
          console.log(error);
          return toast.error(error.response.data.message, toastSpecs);
        });
    }
  };

  return (
    <React.Fragment>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-7 col-xl-6">
                <div className=" text-center mt-5">
                  <h2 className="mb-3">Reset Password Link Expired!</h2>
                  <p className="text-secondary">
                    Click on the below button to Re-Send Link.
                  </p>
                  <div className="mt-4 mb-4 text-center">
                    <Link
                      type="primary"
                      to={""}
                      onClick={ExpiredLink}
                      className="allBtns m-auto"
                    >
                      Re-Send Link
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ExpiredLink;
