import React, { useState, useEffect } from "react";
import TableComponent from "../../../../Component/TableComponent";
import NoDataFoundV2 from "../../../../Component/NoDataFoundV2";
import { TbInvoice } from "react-icons/tb";
import { getUserPlansListInvoice } from "../../../../Component/helpers/api/Plans/plans";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { convertToTwoDigitDecimal, formatINR } from "../../../../Utils/utils";

export default function UserInvoice() {
  const [invoiceTab, setInvoiceTab] = useState("thisYear");
  const { id: userId } = useParams(); 
  console.log(userId, "id");

  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  console.log(invoices?.data?.PlanData?.price, "invoice");

  const invoiceDataSource = invoices?.data?.map((invoice) => ({
    price: (
      <>
        {formatINR(convertToTwoDigitDecimal(invoice?.PlanData?.price))}
      </>
    ),
    id: (
      <>
        {invoice?.payment_details?.id}
      </>
    ),
    status: (
      <>
        {invoice?.payment_details?.status}
      </>
    ),
    plan_name: (
      <>
        {invoice?.PlanData?.plan_name}
      </>
    ),
    plan_type: (
      <>
        {invoice?.PlanData?.plan_type}
      </>
    ),
  }));

  const GET_INVOICE_LIST = async (page, searchTerm) => {
    try {
      const response = await getUserPlansListInvoice(page, 10, searchTerm, userId);
      setInvoices(response?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch invoices.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    GET_INVOICE_LIST(page, searchTerm);
  }, [page, searchTerm, userId]);

  const invoiceColumn = [
    { title: "Payment Id", dataIndex: "id" },
    { title: "Amount", dataIndex: "price" },
    { title: "Status", dataIndex: "status" },
    { title: "Plan Name", dataIndex: "plan_name" },
    { title: "Plan Type", dataIndex: "plan_type" },
  ];

  return (
    <>
      <h2>Invoices</h2>
      <div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl space-y-5 min-h-[400px]">
        <div className="d-flex align-items-center justify-content-between -mt-3">
          <div className="flex flex-col">
            <div className="text-base font-semibold pb-3.5">User Invoices</div>
          </div>
        </div>
        <div
          className={`m-0 d-flex flex-col ${
            invoices?.data?.length
              ? "items-start justify-content-start"
              : "items-center justify-content-center"
          }`}>
          {isLoading ? (
            <div></div> 
          ) : !invoices?.data?.length ? (
            <div className="text-center flex flex-col justify-center items-center h-full">
              <NoDataFoundV2
                title={`No invoices found for user.`}
                icon={<TbInvoice />}
                minHeight={"min-h-[280px]"}
                subTitle="Once the invoices are available, they will be visible here."
              />
            </div>
          ) : (
            <TableComponent
              className="paymentTbl"
              dataSource={invoiceDataSource}
              columns={invoiceColumn}
              pagination={{
                current: page,
                pageSize: 5,
                total: invoices?.total || 0,
                onChange: (page) => setPage(page),
              }}
              pageSize={5}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
