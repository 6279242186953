import { useCallback, useEffect, useMemo, useState } from "react";
import { BiSolidCube } from "react-icons/bi";
// import useSecuritySetting from "../../../../profile_view/hooks/useSecuritySetting";
import { useSelector } from "react-redux";
import { selectedUserData } from "../../../../../redux/UserDetails/userDetails.slice";
import useUpdateStaffUser from "../../../hooks/useUpdateStaff";
import useTradeUser from "../../../hooks/usetraderUser";
import useUserPermission from "../../../../../Component/Common/hooks/useUserPermission";
import { Form } from "react-router-dom";

const useUserOverview = (refetch) => {
  const [emailCheckmark, setEmailCheckmark] = useState(false);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("annually");
  const [showDetail, setShowDetail] = useState(false);
  const userData = useSelector(selectedUserData);
  const {
    selectedUser,
    isPlanCovered,
    isView,
    isOpen,
    loggedInUser,
    planDetail,
  } = userData;

  // console.log(planDetail?.subscriptionPlanData?.price, "planprice");

  const { updateuserData } = useUpdateStaffUser(refetch);
  const { updateuserData: updateLoggedInUser } = useTradeUser(refetch);

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: loggedInUser
        ? isCreateAccess("trader_management")
        : isCreateAccess("user_management"),
      isEdit: loggedInUser
        ? isEditAccess("trader_management")
        : isEditAccess("user_management"),
    };
  }, [isCreateAccess, isEditAccess, loggedInUser]);

  const expirationDate = useMemo(() => {
    return planDetail?.expired_at || "N/A";
  }, [planDetail]);

  const subscriptionPlan = useMemo(() => {
    if (!planDetail?.subscriptionPlanData) return [];

    return [
      {
        icon: <BiSolidCube className="text-6xl text-yellow-500 " />,
        selected: true,
        title: planDetail.subscriptionPlanData.plan_name || planDetail?.subscriptionPlanData?.plan_name || "Free",
        price: `₹${planDetail.subscriptionPlanData?.price ||  planDetail?.subscriptionPlanData?.[0]?.price || "0"}`,
        subtitle: `${
          subscriptionPeriod === "annually"
            ? "per month, billed annually"
            : "per month, billed monthly"
        }`,
        btnText: "Free",
        plan_list_title: "For Growing Trader",
        plan_list: [
          "Subscribe Strategy : 2 Cr. Available",
          "Custom/Create Strategy : N.A",
          "BackTest Run : 10 Cr. Available",
          "Virtual/Paper Trade Setup : 2 Cr. Available",
          "Live Trade Setup : N.A",
          "Broker Connection : N.A",
          "Trade Notify : N.A",
        ],
        expired_at: expirationDate,
      },
    ];
  }, [planDetail, subscriptionPeriod, expirationDate]);

  const [isTab, setIsTabed] = useState(true);

  const toggle = useCallback((values) => {
    setIsTabed(values);
  }, []);

  useEffect(() => {
    return () => {
      toggle(true);
    };
  }, [isOpen]);

  const values = useMemo(() => {
    return {
      selectedUser,
      isTab,
      isPlanCovered,
      subscriptionPlan,
      showDetail,
      subscriptionPeriod,
      emailCheckmark,
      isView,
      Access,
      toggle,
      setIsTabed,
      updateuserData: isPlanCovered ? updateLoggedInUser : updateuserData,
      setShowDetail,
      setSubscriptionPeriod,
      setEmailCheckmark,
      planDetail,
    };
  }, [
    selectedUser,
    isTab,
    isPlanCovered,
    subscriptionPlan,
    showDetail,
    emailCheckmark,
    subscriptionPeriod,
    isView,
    Access,
    planDetail,
    updateLoggedInUser,
    toggle,
    setShowDetail,
    updateuserData,
    setSubscriptionPeriod,
    setEmailCheckmark,
    planDetail,
  ]);

  return values;
};

export default useUserOverview;
