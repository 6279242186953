import { Checkbox, Form, Input, Select } from "antd";
import { BsChevronDown } from "react-icons/bs";
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from "../utils/DefaultClasses";
import Modal from "../Modal";
import useAddUserModal from "./hooks/useAddUserModal";
import { SpinnerBtn } from "../utils/Icons";
import CreateRoleModal from "./AddRoleModal";
import { EMAIL_REG } from "../../Utils/utils";
import { checkUserStaffavailablity } from "../helpers/api/userStaff/userStaff";

const CreateUserModal = ({ props }) => {
  const { openUser = false, closeModal = () => {} } = props;

  const {
    form,
    userDetails,
    modalAccessType,
    roleListDatasOptions,
    AddRoleModal,
    phones,
    closeRoleModal,
    addUser,
    isLoading,
    onSubmit,
  } = useAddUserModal(closeModal);

  // console.log("checkUserAccess", checkUserAccess);

  return (
    <Modal
      open={openUser}
      setIsOpen={() => closeModal()}
      footer={
        <>
          {modalAccessType === "view" ? null : (
            <div className="flex justify-end items-center gap-2.5">
              <button
                htmlType="submit"
                type="button"
                className={`${btnSecondayClass} text-sm`}
                onClick={() => closeModal(false)}
                // onClick={SignUp}
                // disabled
              >
                Cancel
              </button>
              <button
                htmlType="submit"
                type="button"
                className={`${btnPrimaryClass} text-sm`}
                onClick={addUser}
                disabled={isLoading}
              >
                {isLoading ? (
                  <SpinnerBtn className={"text-lg"} />
                ) : modalAccessType === "edit" ? (
                  "Edit User"
                ) : (
                  "Invite User"
                )}
              </button>
            </div>
          )}
        </>
      }
      centered
      maxWidth={`w-full max-w-xl`}
      preventClosingOutsideClick
      // bodyClass={"p-3.5"}
      // bodyHeight={"60vh"}
      bodyClass={
        "w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full"
      }
      header={
        <>
          <h3 className="font-medium text-lg">
            {modalAccessType === "view"
              ? "View User"
              : modalAccessType === "edit"
              ? "Edit User"
              : "Invite User"}
          </h3>
        </>
      }
    >
      <div className="">
        <div className="">
          <Form
            className=""
            layout="vertical"
            form={form}
            disabled={isLoading || modalAccessType === "view"}
            onFinish={onSubmit}
            initialValues={{
              assign_role: userDetails?.role_id,
              first_name: userDetails?.first_name,
              last_name: userDetails?.last_name,
              phone: userDetails?.phone_no,
              user_type: userDetails?.user_type,
              email: userDetails?.email,
              phone_code: userDetails?.phone_code || phones[0]?.value,
              send_invite: true,
            }}
          >
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Item
                  label={
                    <span className="flex items-center">
                      <span>First Name</span>
                      <span className="text-red-500 ml-1">*</span>
                    </span>
                  }
                  name="first_name"
                  className="mb-1"
                  rules={[
                    {
                      required: true,
                      message: "First name is required.",
                    },
                  ]}
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Enter your first name"
                    name="first_name"
                    //    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
              <div className="col-12 col-md-6">
                <Form.Item
                  label={
                    <span className="flex items-center">
                      <span>Last Name</span>
                      <span className="text-red-500 ml-1">*</span>
                    </span>
                  }
                  name="last_name"
                  className="mb-1"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required.",
                    },
                  ]}
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Enter your last name"
                    name="last_name"
                    //    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-12">
                <Form.Item
                  label={
                    <span className="flex items-center">
                      <span>Email</span>
                      <span className="text-red-500 ml-1">*</span>
                    </span>
                  }
                  name="email"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: "Email is required.",
                    },
                    ({ getFieldValue }) => ({
                      validator: async (_, value) => {
                        if (!value || !EMAIL_REG.test(value))
                          return Promise.resolve(); // Initial condition

                        // const res = await check_email_while_login(value);
                        if (modalAccessType === "create") {
                          const res = await checkUserStaffavailablity(value);
                          if (!res?.data?.status) {
                            return Promise.reject(
                              new Error(res?.data?.message)
                            );
                          } // Email registered as both
                        } else {
                          return Promise.resolve(); // Initial condition
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Enter your email"
                    name="email"
                    disabled={
                      isLoading ||
                      modalAccessType === "view" ||
                      modalAccessType === "edit"
                    }
                    //    onChange={handleInputChange}
                  />
                </Form.Item>
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Item
                    name="send_invite"
                    valuePropName="checked"
                    className="mb-0"
                  >
                    <Checkbox className="rememberMe" disabled={true}>
                      Send an Invite email
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
              {/* <div className="col-12 col-md-6">
								<Form.Item
									label="Country Code."
									name="phone_code"
									dependencies={["phone"]}
									//   rules={[
									//     {
									//       required: true,
									//       message: "Phone field required",
									//     },
									//   ]}
								>
									<Select
										placeholder={<span className="text-[#818181]">Select</span>}
										// popupClassName={`${antCustomSelect} antcstm`}
										className={` rounded-md`}
										options={phones}
										showSearch
										autoClearSearchValue
										suffixIcon={
											<>
												<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
													<BsChevronDown
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</span>
											</>
										}
										notFoundContent={
											<div className="w-full">
												<NoDataFoundV2
													title={
														<span className="text-sm text-whiteNew-0">
															No Data Found.
														</span>
													}
													minHeight={"min-h-[150px]"}
													icon={<RiListCheck2 />}
												/>
											</div>
										}
									/>
								</Form.Item>
							</div> */}
              {/* <div className="col-12 col-md-6">
								<Form.Item
									label="Phone No."
									name="phone"
									validateDebounce={1000}
									rules={[
										{
											max: 15,
											message: "Max. 15 characters allowed.",
										},
										({ getFieldValue }) => ({
											validator: async (_, value) => {
												if (!value) return Promise.resolve();

												if (
													isValidPhoneNumber(
														value,
														String(getFieldValue("phone_code")).split("+")[0],
													)
												) {
													return Promise.resolve();
													// return Promise.reject(
													//   new Error(
													//     "Phone number is already associated with other account."
													//   )
													// );
												}
												return Promise.reject(
													new Error(
														"Invalid phone number for the selected country code.",
													),
												);
											},
										}),
									]}
									dependencies={["phone_code"]}>
									<Input
										className={`${formClass}`}
										placeholder="Enter your phone number"
										name="phone_no"
										//    onChange={handleInputChange}
									/>
								</Form.Item>
							</div> */}
              {/* {modalAccessType !== "view" && modalAccessType !== "edit" ? (
								<>
									<div className="col-12 col-md-6">
										<Form.Item
											label="Create Password"
											name="password"
											rules={[
												{
													required: true,
													message: "Password field required.",
												},
												{
													min: 8,
													message: "Password must be at least 8 characters.",
												},
											]}
											hasFeedback>
											<Input.Password
												//    onChange={handleInputChange}
												placeholder="Enter password"
												name="password"
												className={`${formClass}`}
												style={{
													fontSize: "14px",
													fontWeight: "400",
													height: "40px",
													borderRadius: "0.375rem",
												}}
											/>
										</Form.Item>
									</div>
									<div className="col-12 col-md-6">
										<Form.Item
											type="password"
											label="Confirm Password"
											name="verfiy_password"
											dependencies={["password"]}
											hasFeedback
											rules={[
												{
													required: true,
													message: "Password field required.",
												},
												({ getFieldValue }) => ({
													validator(_, value) {
														if (!value || getFieldValue("password") === value) {
															return Promise.resolve();
														}

														return Promise.reject(
															new Error("Your password does not match."),
														);
													},
												}),
											]}>
											<Input.Password
												placeholder="Verify password"
												name="confirmPassword"
												className={`${formClass}`}
												style={{
													fontSize: "14px",
													fontWeight: "400",
													height: "40px",
													borderRadius: "0.375rem",
												}}
											/>
										</Form.Item>
									</div>
								</>
							) : (
								<></>
							)} */}
              <div className="col-12 col-md-12">
                <Form.Item
                  label={
                    <span className="flex items-center">
                      <span>Assign Role Name</span>
                      <span className="text-red-500 ml-1">*</span>
                    </span>
                  }
                  name="assign_role"
                  className="mb-1"
                  rules={[
                    {
                      required: true,
                      message: "Assign Role field required.",
                    },
                  ]}
                >
                  <Select
                    placeholder={
                      <>
                        <span className="text-slate-400">Select Role</span>
                      </>
                    }
                    size="lg"
                    // onChange={handleChange}
                    options={roleListDatasOptions}
                    className={"min-h-[38px] w-full"}
                    // dropdownRender={(menu) => (
                    //   <>
                    //     {menu}

                    //     {checkUserAccess && (
                    //       <button
                    //         type="text"
                    //         // icon={<PlusOutlined />}
                    //         onClick={oNOpenRoleModal}
                    //         className={`${btnPrimaryClass} w-full text-sm mt-1.5`}
                    //       >
                    //         <PlusOutlined />
                    //         Add Role
                    //       </button>
                    //     )}
                    //   </>
                    // )}
                    suffixIcon={
                      <>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                          <BsChevronDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </>
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {AddRoleModal && (
        <CreateRoleModal
          props={{
            AddRoleModal,
            closeModal: closeRoleModal,
          }}
        />
      )}
    </Modal>
  );
};

export default CreateUserModal;
