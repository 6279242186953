import Drawer from "../../../../Component/Common/Drawer";
import useProfileCard from "./hooks/useProfileCard";
import UserOverview from "./UserOverview";
import UserActivity from "./UserActivity";
import { restrictTheUser } from "../../../../Component/helpers/api/userDetails/userDetails";
import { setSelectedUser } from "../../../../redux/UserDetails/userDetails.slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const UserProfileDrawer = ({ refetch }) => {
  const dispatch = useDispatch();
  const {
    form,
    onCloseModal,
    selectedUser,
    toggleState,
    setToggleState,
    updateuserData,
    isOpen,
    isView,
    Access,
  } = useProfileCard(refetch);

  return (
    <Drawer 
      title={
        <>
          <div className="flex justify-between items-center mr-6 mt-[1px]">
            <span>Account Overview</span>
            <div className="flex">
              <button
                className={`${
                  selectedUser?.deactivate ? "bg-blue-2" : "bg-red-2"
                } text-whiteNew-0 p-1.5 px-2.5 rounded-lg m-0 text-xs`}
                disabled={isView || !Access?.isEdit}
                onClick={() => {
                  const payload = {
                    deactivate: !selectedUser?.deactivate,
                  };

                  if (window.location.pathname.includes("/user/profile")) {
                    return restrictTheUser(payload, selectedUser?._id).then(
                      (res) => {
                        refetch();
                        dispatch(
                          setSelectedUser({
                            selectedUser: res?.data?.data,
                            isPlanCovered: true,
                            isOpen: true,
                            // planDetail:res?.active_plan,
                          })
                        );
                      }
                    );
                  } else {
                    return updateuserData(payload, selectedUser?._id, true);
                  }
                }}
              >
                {selectedUser?.deactivate ? "Activate" : "Deactivate"}
              </button>
            </div>
          </div>
        </>
      }
      onClose={onCloseModal}
      open={isOpen}
      className={``}
      rootClassName={"drawer-root-className"}
      width={"640px"}
      footer={null}
    >
      <div>
        <div className="strtgy__table__box border-0 -mt-6 mb-2">
          <ul className="flex border-0 gap-2">
            <li
              onClick={() => setToggleState("overview")}
              className={toggleState === "overview" ? "active" : "tabs"}
            >
              <span>Overview</span>
            </li>
            <li
              onClick={() => setToggleState("activity")}
              className={toggleState === "activity" ? "active" : "tabs"}
            >
              <span>Activity</span>
            </li>
            {/* <li
								onClick={() => setToggleState("security")}
								className={toggleState === "security" ? "active" : "tabs"}>
								<span>Security</span>
							</li> */}
          </ul>
        </div>
        {toggleState === "overview" && (
          <>
            <UserOverview refetch={refetch} form={form} />
          </>
        )}
        {toggleState === "activity" && (
          <>
            <UserActivity />
          </>
        )}
        {/* <div className="small__container">
						<UserDetailFormModal
							userData={userData}
							// refetchUser={refetch}
							setIsModalVisible={setIsModalVisible}
							setIsEmailModalVisible={setIsEmailModalVisible}
						/>
					</div> */}
      </div>
    </Drawer>
  );
};

export default UserProfileDrawer;
