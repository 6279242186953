import { useState } from "react";
import { Space, Dropdown, Checkbox, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import TableComponent from "../../../Component/TableComponent";
import { DATEFORMAT } from "../../../Utils/utils";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

function StrategyDesktopTable({ tradeOrder, toggleState, pagination }) {
  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedKeys([...selectedKeys, id]);
    } else {
      setSelectedKeys(selectedKeys.filter((selectedId) => selectedId !== id));
    }
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Link
          to={`/view-report/${toggleState === "virtual" ? "VT" : "LT"}/${
            record._id
          }`}
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => Link}>
        Print
      </Menu.Item>
      <Menu.Item key="3">Delete</Menu.Item>
    </Menu>
  );

  const columns = [
    // {
    //   title: "Select",
    //   dataIndex: "_id",
    //   render: (text, record) => (
    //     <Checkbox
    //       onChange={(e) => handleCheckboxChange(record._id, e.target.checked)}
    //     />
    //   ),
    //   width: "10%",
    // },
    {
      title: "Report Name",
      dataIndex: "report_name",
      render: (text, record) => (
        <Link
          to={`/view-report/${toggleState === "virtual" ? "VT" : "LT"}/${
            record._id
          }`}
          className="primary__blue"
        >
          {record?.vt_inputs?.report_name ||
            record?.lt_inputs?.report_name ||
            "-"}
        </Link>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (text) => dayjs(text).format(DATEFORMAT),
    },
    // {
    //   title: "Max Cap",
    //   dataIndex: "max_cap",
    //   render: (text, record) =>
    //     record?.vt_inputs?.investment || record?.lt_inputs?.investment || "-",
    // },
    {
      title: "User Name",
      dataIndex: "userData",
      render: (userData) =>
        userData?.first_name
          ? `${userData?.first_name} ${userData?.last_name}`
          : "-",
    },
    {
      title: "No. of order",
      dataIndex: "orderCount",
      render: (orderCount) => orderCount,
    },
    {
      title: "Status",
      key: "tag",
      dataIndex: "current_status",
    },
    // {
    //   title: "Mode",
    //   dataIndex: "Mode",
    //   render: (text, record) => (
    //     <div className="flex items-center gap-1.5">
    //       {record?.status === true ||
    //       (toggleState === "live"
    //         ? creditList?.live_deployments?.remaining !== 0
    //         : creditList?.virtual_deployments?.remaining !== 0) ? (
    //         <Switch
    //           checked={record?.status}
    //           onChange={(checked) =>
    //             handleUpdate(checked, record?._id, record?.strategy_id)
    //           }
    //         />
    //       ) : (
    //         <div className="flex items-center gap-1.5">
    //           <div className="flex items-center gap-1.5 bg-blue-2/15 text-blue-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
    //             Premium Feature
    //           </div>
    //           <Tooltip
    //             title={"Please upgrade your account for premium features."}
    //           >
    //             <AiOutlineQuestionCircle />
    //           </Tooltip>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            overlayClassName={"dropdownOverlay dowpdownDark"}
          >
            <MoreOutlined style={{ cursor: "pointer" }} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [selectedKeys, setSelectedKeys] = useState([]);

  // console.log("setPageSize", pagination);
  // console.log("tradeOrder", tradeOrder);

  return (
    <div>
      <div className="strategy__tbl__data">
        <TableComponent
          columns={columns}
          dataSource={tradeOrder}
          pagination={pagination?.total <= 10 ? false : true}
          currentPage={pagination?.current}
          setCurrentPage={pagination?.setCurrent}
          pageSize={pagination?.pageSize}
          setPageSize={pagination?.setPageSize}
          // onChange={handleTableChange}
          rowKey="_id"
          total={pagination?.total}
        />
      </div>
      {/* <ModelDelete
        from={toggleState === "live" ? "live" : "virtual"}
        showModal={removeModel}
        hideModal={hideConfirmationModal}
        id={reportId}
        updateReportOnDel={updateReportOnDel}
        refetch={getVirtualTradeData}
        loading={deleteLoading}
      /> */}
    </div>
  );
}

export default StrategyDesktopTable;
