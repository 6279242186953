import { RadioGroup } from "@headlessui/react";

export default function RadioSelect({ props }) {
  const { plans, value, form } = props;
  return (
    <>
      {/* <div> */}
      <RadioGroup
        // value={value}
        defaultValue={value}
        // name={name}
        onChange={(e) => {
          form.setFieldValue("role", e);
          form.setFieldValue("email", undefined);
          form.setFieldValue("password", undefined);
          form.setFields([
            {
              name: "email",
              errors: null,
            },
            {
              name: "password",
              errors: null,
            },
          ]);
        }}
      >
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="flex flex-wrap flex-md-nowrap gap-3">
          {plans.map((plan, idx) => (
            <RadioGroup.Option
              key={idx}
              value={plan?.value}
              className={({ active, checked }) =>
                `${
                  active
                    ? "bg-transparent border-1 border-blue-3 bg-opacity-75"
                    : "bg-color-base-1"
                } ${
                  checked
                    ? "bg-transparent border-1 border-blue-3 bg-opacity-75"
                    : "bg-color-base-1"
                } relative flex cursor-pointer rounded-lg px-1.5 py-1.5 border-[1px] duration-150 ease-in`
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex w-full items-start justify-between">
                    <div className="flex items-center gap-2">
                      <div className="">
                        <span
                          className={`flex w-5 h-5 border-[1px] rounded-full p-[3px] duration-150 ease-in ${
                            checked ? "border-blue-3" : " border-level-2"
                          }`}
                        >
                          <span
                            className={`flex w-full h-full rounded-full duration-150 ease-in ${
                              checked ? "bg-blue-3" : ""
                            }`}
                          ></span>
                        </span>
                      </div>
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="div"
                          className={`font-medium duration-150 ease-in ${
                            checked ? "text-blue-3" : "bg-color-base-1"
                          }`}
                        >
                          {plan.name}
                        </RadioGroup.Label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {/* </div> */}
    </>
  );
}
