import React, { useCallback } from "react";
import TableComponent from "../Component/TableComponent";
import moment from "moment";
import {
  DATEFORMAT,
  convertToTwoDigitDecimal,
  formatINR,
  truncate2,
} from "./utils";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { setSelectedTrade } from "../redux/Trader/trader.slice";
import TradeDetailDrawer from "../Pages/Admin/trades/TradeDetailDrawer";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
// import ReportPDF from "../Component/Common/ReportPDF";

// const utc = require("dayjs/plugin/utc");
// dayjs.extend(utc);

const PortfolioClosedTable = ({
  from,
  tradeData,
  pagination,
  isLoadingTrade,
  additionalDetails,
}) => {
  const { total, page, setPage, limit, setLimit } = pagination;
  const dispatch = useDispatch();

  const modifyData = useCallback(
    (selectedTrade) => {
      return [
        {
          heading: "Trade Id",
          value: selectedTrade?._id,
        },
        {
          heading: "Strategy Name",
          value: additionalDetails?.strategyName,
        },
        {
          heading: "Report Name",
          value: additionalDetails?.reportName,
        },
        {
          heading: "Date",
          value: moment.utc(selectedTrade?.date).format(DATEFORMAT),
        },
        {
          heading: "Day",
          value: selectedTrade?.day || "-",
        },
        {
          heading: "Symbol",
          value: selectedTrade?.stock || "-",
        },
        {
          heading: "Quantity",
          value: selectedTrade?.quantity || "-",
        },
        {
          heading: "Lot Size",
          value: selectedTrade?.lot_size || "-",
        },
        {
          heading: "Position Type",
          value: selectedTrade?.position_type || "-",
        },
        {
          heading: "Entry Time",
          value: moment.utc(selectedTrade?.entry_time).format(DATEFORMAT),
        },
        {
          heading: "Entry Price",
          value: formatINR(
            convertToTwoDigitDecimal(selectedTrade?.entry_price)
          ),
        },
        {
          heading: "Exit Time",
          value: moment.utc(selectedTrade?.exit_time).format(DATEFORMAT),
        },
        {
          heading: "Exit Price",
          value: formatINR(convertToTwoDigitDecimal(selectedTrade?.exit_price)),
        },
        {
          heading: "Exit Type",
          value: selectedTrade?.exit_type || "-",
        },
        {
          heading: "Points",
          value: selectedTrade?.points || "-",
        },
        {
          heading: "P&L",
          value: formatINR(convertToTwoDigitDecimal(selectedTrade?.pnl)),
        },

        {
          heading: "Brokerage",
          value: formatINR(convertToTwoDigitDecimal(selectedTrade?.brokerage)),
        },
      ];
    },
    [additionalDetails]
  );

  const tradeColumns = [
    {
      title: "Trade Id",
      dataIndex: "_id",
      render: (text, record) => (
        <>
          <span
            className="text-blue-2 cursor-pointer"
            onClick={() => {
              dispatch(
                setSelectedTrade({
                  selectedTrade: modifyData(record),
                  isOpen: true,
                })
              );
            }}
          >
            <Tooltip title={text}>
              {" "}
              {text ? `${text.slice(0, 5)}...${text.slice(-5)}` : "-"}
            </Tooltip>
          </span>
        </>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "stock",
      render: (text, record) => (
        <>
          <span>
            <Tooltip title={text}>
              {" "}
              {text ? `${text.slice(0, 5)}...${text.slice(-5)}` : "-"}
            </Tooltip>
          </span>
        </>
      ),
    },
    {
      title: "Entry Time",
      dataIndex: "entry_time",
      render: (text) => <>{moment.utc(text).format(DATEFORMAT)}</>,
    },
    {
      title: "Entry Price",
      dataIndex: "entry_price",
      render: (entry_price) => formatINR(convertToTwoDigitDecimal(entry_price)),
    },
    {
      title: "Exit Price",
      dataIndex: "exit_price",
      render: (exit_price) => formatINR(convertToTwoDigitDecimal(exit_price)),
    },
    {
      title: "Exit Time",
      dataIndex: "exit_time",
      render: (text) => <>{moment.utc(text).format(DATEFORMAT)}</>,
    },

    {
      title: "Exit Type",
      dataIndex: "exit_type",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "P&L",
      key: "pnl",
      dataIndex: "pnl",
      render: (pnl) => (
        <span className={pnl > 0 ? "text-green-600" : "text-red-600"}>
          {formatINR(convertToTwoDigitDecimal(pnl))}{" "}
          {pnl > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </span>
      ),
    },
    // {
    //   title: "Brokerage",
    //   dataIndex: "brokerage",
    //   render: (brokerage) => formatINR(convertToTwoDigitDecimal(brokerage)),
    // },
  ];
  const closedColumns = [
    {
      title: "Trade Id",
      dataIndex: "tradingsymbol",
      key: "1",
    },
    {
      title: "Symbol",
      dataIndex: "tradingsymbol",
      key: "2",
    },
    {
      title: "Order Time",
      dataIndex: "order_timestamp",
      key: "2",
      render: (text) => moment.utc(text).format(DATEFORMAT),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "3",
    },

    {
      title: "Lot Size",
      dataIndex: "lot_size",
      key: "4",
    },

    {
      title: "Trigger Price",
      dataIndex: "trigger_price",
      key: "5",
    },
    {
      title: "Trade Action",
      dataIndex: "trade_action",
      key: "6",
    },

    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "7",
    },

    {
      title: "Exit Type",
      dataIndex: "exit_type",
      key: "8",
      render: (text) => (text ? text : "-"),
    },
  ];

  return (
    <div>
      <TableComponent
        columns={from === "closed" ? closedColumns : tradeColumns}
        pagination={total <= 10 ? false : true}
        currentPage={page}
        setCurrentPage={setPage}
        pageSize={limit}
        setPageSize={setLimit}
        dataSource={tradeData}
        loading={isLoadingTrade}
        // onChange={handleTableChange}
        total={total}
        emptyDataText="No trades are available for a selected filter, to view trade change the date filter."
      />
      <TradeDetailDrawer />
      {/* <ReportPDF columns={tradeColumnsPDF} /> */}
    </div>
  );
};

export default React.memo(PortfolioClosedTable);
