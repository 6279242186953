function truncate(str, n) {
	return str?.length > n ? str.substr(0, n - 1) + "..." : str;
}

function isHTML(str) {
	var a = document.createElement("div");
	a.innerHTML = str;

	for (var c = a.childNodes, i = c.length; i--; ) {
		if (c[i].nodeType === 1) return true;
	}

	return false;
}

function convertToPlain(html) {
	// Create a new div element
	var tempDivElement = document.createElement("div");

	// Set the HTML content with the given value
	tempDivElement.innerHTML = html;

	// Retrieve the text property of the element
	return tempDivElement.textContent || tempDivElement.innerText || "";
}

function decodeHTMLEntities(str) {
	let element = document.createElement("div");
	if (str && typeof str === "string") {
		// strip script/html tags
		str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
		str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
		element.innerHTML = str;
		str = element.textContent;
		element.textContent = "";
	}

	return str;
}

export function truncateHTML(msg) {
	if (isHTML(msg)) {
		// return $(msg).text();
		return convertToPlain(msg);
	} else {
		let str = msg;
		str = decodeHTMLEntities(msg);
		str = str.replace(/&nbsp;/g, "");
		return str;
	}
}

export default truncate;
export function shortenAddress(address, chars = 4) {
	return `${address?.slice(0, chars)}...${address?.slice(-chars)}`;
}
