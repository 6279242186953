import React from "react";
import { Card, NavItem } from "react-bootstrap";
function RightBar({ children, leftContent, rightContent }) {
	///console.log(props);
	return (
		<React.Fragment>
			<div className="w-full" id="content">
				<div className="">
					{leftContent || rightContent ? (
						<Card
							className="w-full border-0"
							// bg-color-base-1 off__blue border-level-4 rounded-lg shadow-md shadow-level-4
						>
							<Card.Body className="p-0">
								<div className="flex justify-between">
									<div className="">{leftContent ? leftContent : null}</div>
									<div className="paddingRight">
										{rightContent ? rightContent : null}
									</div>
								</div>
							</Card.Body>
						</Card>
					) : null}

					{/* {props.htmlContent} */}
					{children}
				</div>
			</div>
		</React.Fragment>
	);
}

export default RightBar;
