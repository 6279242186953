import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import { apiHeader } from "../../../../Utils/utils";

export async function updateSelectedPlanAPI(payload) {
  const { json_header } = apiHeader();

  const res = await axios.put(`${API_URL.UPDATE_PLAN}`, payload, json_header);

  return res;
}

export async function getPlansListAPI(page, limit, search) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.PLAN_LIST}?page=${page}&limit=${limit}&search_key=${search}`,
    json_header
  );

  return res;
}

export async function getUserPlansListAPI(page, limit, search, day) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${
      API_URL.USER_PLAN_LIST
    }?page=${page}&limit=${limit}&user_id=&search_key=${search}&day=${
      typeof day === "string" ? day : "custom"
    }${typeof day === "string" ? "" : `&start=${day[0]}&end=${day[1]}`}`,
    json_header
  );

  return res;
}


export async function getUserPlansListInvoice(page, limit, search, userId) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${API_URL.USER_PLAN_LIST}?page=${page}&limit=${limit}&user_id=${userId}&search_key=${search}`,
    json_header
  );

  return res;
}


