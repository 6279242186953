import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTicketMessageList } from "../../../../redux/Tickets/tickets.slice";
import { getTicketSupportReplies } from "../../../../Component/helpers/api/TicketSupport/ticket";
import { removeDuplicates } from "../../../../Utils/utils";

const useTicketSupportDetail = () => {
  const { id } = useParams();
  const decodedToken = localStorage.getItem("adminToken");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const dispatch = useDispatch();

  const { selectedTicket, ticketMessageList } = useSelector(
    (state) => state.selectedTickets
  );

  const queryClient = useQueryClient();
  // const chachedData = queryClient.find(["ticketSupportDetail"]);
  // console.log(
  //   queryClient.getQueryData([
  //     "ticketSupportDetail",
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2M2EyOGEyNGIxMGJkYmJmNmE4ZTNhOCIsInR5cGUiOiJhZG1pbiIsImlhdCI6MTcyMDU5MTE1NSwiZXhwIjoxNzIwNjc3NTU1fQ.EfllFlIJd4U_9BrfCZsHvFcyjxMnEzeYSvP6zFW43XI",
  //     1,
  //     5,
  //     "668b87b30c19ee80f9c77e05",
  //   ]),
  //   "queryClient"
  // );

  // const { data: cachedData } = queryClient.getQueryData([
  //   "ticketSupportDetail",
  //   decodedToken,
  //   page,
  //   limit,
  //   id,
  // ]);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["ticketSupportDetail", decodedToken, page, limit, id],
    queryFn: async () => {
      if (decodedToken) {
        return await getTicketSupportReplies(page, limit, id);
      } else {
        return null;
      }
    },
  });

  useEffect(() => {
    if (ticketMessageList?.length < data?.data?.count) {
      dispatch(
        setTicketMessageList({
          data: removeDuplicates([...ticketMessageList, ...data?.data?.data]),
          count: data?.data?.count,
        })
      );
    }
    if (ticketMessageList?.length === 0 && data?.data?.count === 0) {
      dispatch(
        setTicketMessageList({
          data: [],
          count: data?.data?.count,
        })
      );
    }
  }, [data]);

  const hasMore = useMemo(() => {
    return ticketMessageList?.length < data?.data?.count;
  }, [ticketMessageList, data?.data?.count]);

  const values = useMemo(() => {
    return {
      selectedTicket,
      ticketList: data?.data?.data ?? [],
      refetch,
      Pagination: {
        page,
        setPage,
        limit,
        setLimit,
        total: data?.data?.count,
        hasMore,
      },
      isLoading,
    };
  }, [selectedTicket, data, refetch, page, limit, hasMore, isLoading]);

  return values;
};

export default useTicketSupportDetail;
