import { createSlice } from "@reduxjs/toolkit";

const selectedTrade = {
  selectedTrade: [],
  isOpen: false,
};

const slice = createSlice({
  name: "Selected_trade",
  initialState: selectedTrade,
  reducers: {
    setSelectedTrade: (state, { payload }) => {
      state.selectedTrade = payload?.selectedTrade;
      state.isOpen = payload?.isOpen;
    },
  },
});

let selectedTradeReducer = slice.reducer;

export const { setSelectedTrade } = slice.actions;

const SelectedTradeDetails = (state) => state.SelectedTradeDetails;

export { selectedTradeReducer, SelectedTradeDetails };
