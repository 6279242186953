import React from "react";
import Drawer from "./Drawer";
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from "../utils/DefaultClasses";
import { Col, Row } from "react-bootstrap";
import { Form, Input, Radio } from "antd";
import useStrategyDrawer from "./hooks/useStrategyDrawer";
import moment from "moment";
import { DATEFORMAT } from "../../Utils/utils";
const { TextArea } = Input;

const StrategyDrawer = ({ refetch }) => {
  const {
    form,
    selectedStrategy,
    isOpen,
    initialValues,
    isLoading,
    fieldTouched,
    setIsFieldTouched,
    onClose,
    onSubmit,
  } = useStrategyDrawer(refetch);

  return (
    <Drawer
      title={
        <>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span>{selectedStrategy?.name}</span>
              <span className="text-color-secondary/80 text-xs font-normal">
                {moment(selectedStrategy?.updated_at)?.format(DATEFORMAT)}
              </span>
            </div>
            {/* <div className="flex -mt-2">
              <span className="flex bg-blue-2/15 text-blue-2 px-2.5 rounded-md text-sm font-normal">
                Published
              </span>
            </div> */}
          </div>
        </>
      }
      onClose={onClose}
      open={isOpen}
      className={`gotix-drawer `}
      rootClassName={"drawer-root-className"}
      width={"640px"}
      footer={
        <>
          <div className="d-flex justify-content-end gap-2  ">
            <div className="">
              <button
                variant="primary"
                className={`${btnSecondayClass} m-0`}
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
            <div className="">
              <button
                className={`${btnPrimaryClass} m-0`}
                onClick={() => form.submit()}
                disabled={isLoading || !fieldTouched}
              >
                Save & Publish
              </button>
            </div>
          </div>
        </>
      }
    >
      <div>
        {/* <Row>
          <Col lg={6}>
            <div className="space-y-4">
              <div className="flex gap-2">
                <div className="w-full max-w-[150px]">
                  <span className="text-color-secondary/80 font-medium">
                    Strategy Type
                  </span>
                </div>
                <div>
                  <span className="text-color-primary font-medium">
                    Premium
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="w-full max-w-[150px]">
                  <span className="text-color-secondary/80 font-medium">
                    Instrument
                  </span>
                </div>
                <div>
                  <span className="text-color-primary font-medium">
                    Premium
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="w-full max-w-[150px]">
                  <span className="text-color-secondary/80 font-medium">
                    Min Cap
                  </span>
                </div>
                <div>
                  <span className="text-color-primary font-medium">
                    Premium
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="space-y-4">
              <div className="flex gap-2">
                <div className="w-full max-w-[150px]">
                  <span className="text-color-secondary/80 font-medium">
                    Risk
                  </span>
                </div>
                <div>
                  <span className="text-color-primary font-medium">
                    Premium
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="w-full max-w-[150px]">
                  <span className="text-color-secondary/80 font-medium">
                    Growth
                  </span>
                </div>
                <div>
                  <span className="text-color-primary font-medium">
                    Premium
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="w-full max-w-[150px]">
                  <span className="text-color-secondary/80 font-medium">
                    Fee
                  </span>
                </div>
                <div>
                  <span className="text-color-primary font-medium">
                    Premium
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row> */}
        <div>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            initialValues={initialValues}
            disabled={isLoading}
            onFieldsChange={() => !fieldTouched && setIsFieldTouched(true)}
          >
            <Form.Item
              // label="Email *"
              label={
                <>
                  <span className="flex items-center gap-0.5">
                    Strategy Name
                  </span>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "Strategy name is required.",
                },
              ]}
              name="strategy_name"
            >
              <Input
                className={`${formClass}`}
                placeholder="Strategy Name"
                name="strategy_name"
              />
            </Form.Item>
            <Form.Item
              // label="Email *"
              label={
                <>
                  <span className="flex items-center gap-0.5">Description</span>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "Strategy description is required.",
                },
              ]}
              name="desc"
            >
              <TextArea
                className={`${formClass}`}
                rows={4}
                placeholder="Description"
                name="desc"
              />
            </Form.Item>
            <Row>
              <Col lg={6}>
                <Form.Item
                  // label="Email *"
                  label={
                    <>
                      <span className="flex items-center gap-0.5">
                        Instrument
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Instrument is required.",
                    },
                  ]}
                  name="instrument"
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Instrument"
                    name="instrument"
                  />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item
                  // label="Email *"
                  label={
                    <>
                      <span className="flex items-center gap-0.5">
                        Min. Cap
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Min. Cap is required.",
                    },
                  ]}
                  name="min_cap"
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Min. Cap"
                    name="min_cap"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Item
                  // label="Email *"
                  label={
                    <>
                      <span className="flex items-center gap-0.5">Fee</span>
                    </>
                  }
                  name="fee"
                >
                  <Input
                    className={`${formClass}`}
                    disabled={true}
                    placeholder="Fee"
                    name="fee"
                  />
                </Form.Item>
              </Col>
              {/* <Col lg={6}>
									<Form.Item
										// label="Email *"
										label={
											<>
												<span className="flex items-center gap-0.5">
													Min. Cap
												</span>
											</>
										}
										name="min_cap">
										<Input
											className={`${formClass}`}
											placeholder="Min. Cap"
											name="min_cap"
										/>
									</Form.Item>
								</Col> */}
            </Row>
            <Form.Item
              // label="Email *"
              label={
                <>
                  <span className="flex items-center gap-0.5">
                    Strategy Type
                  </span>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "Strategy Type is required.",
                },
              ]}
              name="strategy_type"
              hidden={selectedStrategy?.access_type === "created"}
            >
              <Radio.Group>
                <Radio value={"free"}>Free</Radio>
                <Radio value={"premium"}>Premium</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default StrategyDrawer;
