import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import { apiHeader } from "../../../../Utils/utils";

export async function adminStaffLogin(payload) {
  const res = await axios.post(`${API_URL.LOGINURL_ADMIN}`, payload);
  return res;
}

export async function get2FAOTPBYEmail(payload) {
  const res = await axios.post(`${API_URL.TWOFA_AUTH}`, payload);
  return res;
}

export async function resend2FAOTPBYEmail(payload) {
  const res = await axios.post(`${API_URL.RESENDTWOFA_AUTH}`, payload);
  return res;
}

export async function updateAdminEmail(payload) {
  const res = await axios.post(`${API_URL.UPDATE_ADMIN_EMAIL}`, payload);
  return res;
}

export async function adminEmailOTPSubmit(payload) {
  const res = await axios.post(`${API_URL.ADMIN_EMAIL_OTP_SUBMIT}`, payload);
  return res;
}

export async function forgotPasswordApi(payload) {
  const res = await axios.post(`${API_URL.ADMIN_FORGET_PASSWORD}`, payload);
  return res;
}

export async function resetPasswordApi(payload, token) {
  const res = await axios.post(
    `${API_URL.ADMIN_RESET_PASSWORD}/${token}`,
    payload
  );
  return res;
}

export async function setPasswordApi(payload) {
  const res = await axios.post(`${API_URL.SET_PASSWORD}`, payload);
  return res;
}

export async function logoutApi(payload) {
  const { json_header } = apiHeader();

  const res = await axios.post(`${API_URL.LOGOUTAPI}`, payload, json_header);

  return res;
}

export async function getTraderData(page = 1, limit = 10, search_key = "", status = "", login_type = "", signup_type = "") {
  try {
    const res = await axios.get(`${API_URL.GET_USER_LIST}`, {
      params: {
        page,
        limit,
        search_key, 
        status,
        login_type,
        signup_type,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });

    return res.data;
  } catch (error) {
    console.error("Error fetching trader data:", error);
    throw error; 
  }
}


export async function checkVerifiedUser(token, email) {
  const res = await axios.get(
    `${API_URL.CHECK_VERFIED_EMAIL}${token}?email=${email}`
  );
  return res;
}

export async function resendEmailVerifyLink(email) {
  const res = await axios.get(`${API_URL.VERIFY_EMAIL}/${email}`);
  return res;
}

export async function setPasswordLink(payload) {
  const res = await axios.post(`${API_URL.SET_PASSWORD_LINK}`, payload);
  return res;
}

export async function checkStaffEmailLogin(payload) {
  const { json_header } = apiHeader();

  const res = await axios.get(
    `${API_URL.CHECK_STAFF_EMAIL_LOGIN}/${payload}`,
    json_header
  );
  return res;
}
