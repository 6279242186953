import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import { apiHeader } from "../../../../Utils/utils";

export async function addRolesAPI(payload) {
  const { json_header } = apiHeader();

  const res = await axios.post(`${API_URL.ADD_ROLE}`, payload, json_header);

  return res;
}

export async function updateRolesAPI(payload) {
  const { json_header } = apiHeader();

  const res = await axios.put(`${API_URL.UPDATE_ROLE}`, payload, json_header);

  return res;
}

export async function getRolesListAPI() {
  const { json_header } = apiHeader();

  const res = await axios.get(`${API_URL.GET_ROLE}`, json_header);

  return res;
}

export async function deleteRolesAPI(id) {
  const { json_header } = apiHeader();

  const res = axios.delete(`${API_URL.UPDATE_ROLE}/${id}`, json_header);
  return res;
}

export async function adminStaffUsers(
  page,
  limit,
  search = "",
  status = "",
  role_id = ""
) {
  const { json_header } = apiHeader();

  const res = axios.get(
    `${API_URL.GET_STAFF_LIST}&page=${page}&limit=${limit}&search_key=${search}&status=${status}&role_id=${role_id}`,
    json_header
  );
  return res;
}
