import { tsvParse } from "d3-dsv";
import { deviceDetect } from "react-device-detect";
import { isExpired } from "react-jwt";
import mydecodedTokenFunction from "./decodedtoken";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";

export const EMAIL_REG =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

export const DATEFORMAT = "MMM DD, YYYY hh:mm A";
export const DATE = "MMM DD, YYYY";
export const TIMEFORMAT = "hh:mm A";
export const DATEFORMATFILTER = "YYYY-MM-DD";

const formatter = Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

export function formatINR(price) {
  if (isNaN(price)) {
    return formatter.format(Number(0));
  } else {
    return formatter.format(price);
  }
}

export function convertToTwoDigitDecimal(value) {
  const numberValue = parseFloat(value);
  if (isNaN(numberValue)) {
    return "-";
  } else {
    return numberValue.toFixed(2);
  }
}

function parseData(parse) {
  return function (d) {
    d.date = parse(d.date);
    d.open = +d.open;
    d.high = +d.high;
    d.low = +d.low;
    d.close = +d.close;
    d.volume = +d.volume;

    return d;
  };
}

export const toastSpecs = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
};

export function getData() {
  const promiseIntraDayDiscontinuous = fetch(
    "https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT_INTRA_DAY.tsv"
  )
    .then((response) => response.text())
    .then((data) =>
      tsvParse(
        data,
        parseData((d) => new Date(+d))
      )
    );
  return promiseIntraDayDiscontinuous;
}

export function useUserDeviceLocation() {
  const deviceData = deviceDetect();
  return deviceData;
}

export const addCustomError = (form, from, message) => {
  form.setFields([
    {
      name: from,
      errors: [message],
    },
  ]);
};

export function apiHeader() {
  let json_header = {
    headers: {
      "content-type": "application/json",
    },
  };

  // console.log(localStorage.getItem("token"));

  if (localStorage.getItem("adminToken")) {
    json_header = {
      ...json_header,
      headers: {
        ...json_header?.headers,
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    };
  }

  const form_header = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
    },
  };

  // const json_header = {
  //   headers: {
  //     'content-type': 'application/json',
  //     Authorization: `Bearer ${localStorage.getItem('token')}`,
  //   },
  // };

  return { form_header, json_header };
}

export const addRoleInitialData = () => {
  return [
    {
      name: "Administrator access",
      isSelected: false,
      roles: [
        {
          name: "User Management",
          key: "user_management",
          value: [],
        },
        {
          name: "Strategy Management",
          key: "strategy_management",
          value: [],
        },
        {
          name: "Roles and Permission Management",
          key: "roles_permission_management",
          value: [],
        },
        {
          name: "Trader Management",
          key: "trader_management",
          value: [],
        },
        {
          name: "Trades Management",
          key: "trades_management",
          value: [],
        },
        {
          name: "Plans Management",
          key: "plans_management",
          value: [],
        },
      ],
    },
    {
      name: "Trader access",
      isSelected: false,
      roles: [
        {
          name: "Virtual Trade",
          key: "virtual_trade",
          value: [],
        },
        {
          name: "Live Trade",
          key: "live_trade",
          value: [],
        },
        {
          name: "Strategy",
          key: "strategy",
          value: [],
        },
        {
          name: "Chart",
          key: "chart",
          value: [],
        },
        {
          name: "Broker",
          key: "broker",
          value: [],
        },
      ],
    },
  ];
};

export const getAddedRemovedItem = (previous, current) => {
  const addedElement = current.filter(
    (element) => !previous?.includes(element)
  )[0];

  const removedElement = previous.filter(
    (element) => !current?.includes(element)
  )[0];

  return { addedElement, removedElement };
};

export const checkTokenExpired = (token) => {
  return isExpired(token) ? false : mydecodedTokenFunction(token);
};

export const getPermissions = (permission) => {
  let obj = {};
  permission?.filter((permission) => {
    obj = { ...obj, [Object.keys(permission)]: Object.values(permission)?.[0] };
    return permission;
  });
  return obj;
};

export function calculateBrokerage(
  entryPrice,
  exitPrice,
  quantity,
  positionType,
  broker,
  marketType = "options"
) {
  if (broker === "Zerodha") {
    let gst = 0.18;
    let sebiCharges = 0.000001;
    let clearingCharge = 0;
    let sttCharges, exchangeCharges, stampDutyCharges, kiteBrokerage;
    let buyPrice;
    let sellPrice;

    if (marketType === "options") {
      sttCharges = 0.0625;
      exchangeCharges = 0.0505;
      stampDutyCharges = 0.00003;
      kiteBrokerage = 40;
    } else {
      sttCharges = 0.01;
      exchangeCharges = 0.002;
      stampDutyCharges = 0.00002;
      kiteBrokerage = 0.03;
    }
    if (positionType === "LONG") {
      buyPrice = entryPrice;
      sellPrice = exitPrice;
    } else {
      buyPrice = exitPrice;
      sellPrice = entryPrice;
    }

    const turnover = buyPrice * quantity + sellPrice * quantity;

    let brokerage;
    if (marketType === "options") {
      brokerage = kiteBrokerage;
    } else {
      brokerage = Math.min(
        40,
        Math.round(turnover * (kiteBrokerage / 100) * 100) / 100
      );
    }

    const stt = Math.round((quantity * sellPrice * sttCharges) / 100);

    exchangeCharges =
      Math.round((turnover / 100) * exchangeCharges * 100) / 100;

    sebiCharges = Math.round(turnover * sebiCharges * 100) / 100;

    const stampDuty = Math.round(quantity * buyPrice * stampDutyCharges);

    let totalCharges = brokerage + sebiCharges + exchangeCharges;

    gst = Math.round(totalCharges * gst * 100) / 100;

    totalCharges =
      Math.round(
        (brokerage +
          sebiCharges +
          exchangeCharges +
          stt +
          clearingCharge +
          stampDuty +
          gst) *
          100
      ) / 100;

    // Calculating Net PnL
    const netPL = (sellPrice - buyPrice) * quantity - totalCharges;

    return [totalCharges, netPL];
  } else if (broker === "Kotak") {
    // console.log('kotak');
    let gst = 0.18;
    let sebiCharges = 0.000001;
    let clearingCharge = 0;
    let brokerage = 0;
    let sttCharges, exchangeCharges, stampDutyCharges;
    let buyPrice;
    let sellPrice;
    if (marketType === "options") {
      sttCharges = 0.0625;
      exchangeCharges = 0.0505;
      stampDutyCharges = 0.00003;
    } else {
      sttCharges = 0.01;
      exchangeCharges = 0.002;
      stampDutyCharges = 0.00002;
    }
    if (positionType === "LONG") {
      buyPrice = entryPrice;
      sellPrice = exitPrice;
    } else {
      buyPrice = exitPrice;
      sellPrice = entryPrice;
    }

    const turnover = buyPrice * quantity + sellPrice * quantity;

    const stt = (quantity * sellPrice * sttCharges) / 100;

    exchangeCharges = (turnover / 100) * exchangeCharges;

    sebiCharges = turnover * sebiCharges;

    const stampDuty = quantity * buyPrice * stampDutyCharges;

    let totalCharges = brokerage + sebiCharges + exchangeCharges;

    gst = totalCharges * gst;

    totalCharges =
      brokerage +
      sebiCharges +
      exchangeCharges +
      stt +
      clearingCharge +
      stampDuty +
      gst;

    const netPL = (sellPrice - buyPrice) * quantity - totalCharges;

    return [totalCharges, netPL];
  } else {
    return [0, 0];
  }
}

export const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

export const exportToCSV = (data, filename) => {
  const tableHeaders = Object.keys(data[0]); // Assuming first row has headers

  // Combine headings and data into CSV string
  const csvContent = [
    tableHeaders.join(","), // Add headers as first row
    ...data.map((row) => Object.values(row).join(",")), // Add data rows
  ].join("\n");

  const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  saveAs(csvBlob, `${filename}.csv`);
};

export const exportToXLS = (data, filename) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFileXLSX(wb, `${filename}.xlsx`);
};

export const exportPDF = (filename) => {
  // Or use javascript directly:

  const doc = new jsPDF("landscape");

  doc.autoTable({
    html: "#StrategyTrades",
    margin: { left: 5, right: 5 },
    theme: "grid",

    styles: {
      overflow: "linebreak", // Handle overflow
      // Add custom style for page width
      tableWidth: "auto", // Set table width to auto to adjust based on content
      // cellPadding: 2, // Add padding between cells
      fontSize: 10, // Set font size
      minCellHeight: 10, // Set row height
    },
  });
  doc.save(`${filename}.pdf`);
};

export function truncate2(str, n) {
  return str?.substr(0, n) + "..." + str?.substr(-n);
}

export const rearrangeDataSource = (from, data, addl) => {
  if (from === "StrategyTrade") {
    return data?.map((item) => {
      return {
        "Trade Id": item?._id,
        "Request Id": item?.request_id,
        "User Id": item?.user_id,
        "Strategy id": item?.strategy_id,
        "Strategy Name": addl?.strategyName,
        "Report Name": item?.reportName,
        Symbol: item?.stock,
        "Entry Time": item?.entry_time,
        "Entry Price ": item?.entry_price,
        "Exit Price": item?.exit_price,
        "Exit Time": item?.exit_time,
        "Exit Type": item?.exit_type,
        Quantity: item?.quantity,
        "Lot Size": item?.lot_size,
        Position: item?.position_type,
        Points: item?.points,
        Day: item?.day,
        Brokerage: item?.brokerage,
        "P&L": item?.pnl,
      };
    });
  } else if (from === "vtlt") {
    return data?.map((item) => {
      return {
        // "Trade Id": item?._id,
        // "Request Id": item?.request_id,
        // "User Id": item?.user_id,
        // "Strategy id": item?.strategy_id,
        Username: `${item?.userData?.first_name} ${item?.userData?.last_name}`,
        "Strategy Name": item?.strategyData?.name,
        "Report Name": item?.vtRequestData?.vt_inputs?.report_name,
        "Trade Date": item?.date,
        Day: item?.day,
        Symbol: item?.stock,
        Quantity: item?.quantity,
        "Lot Size": item?.lot_size,
        Position: item?.position_type,
        "Entry Price ": item?.entry_price,
        "Entry Time": item?.entry_time,
        "Exit Price": item?.exit_price,
        "Exit Time": item?.exit_time,
        "Gross P&L ": item?.pnl,
        Brokerage: item?.brokerage,
        "NET P&L": item?.pnl,
      };
    });
  }
};

export const FileDownloader = async (url) => {
  // URL of the file to be downloaded
  // const fileUrl = url;
  const filename = url.split("/").pop();
  const filetype = url.split(".").pop();

  // const link = document.createElement("a");
  // link.href = fileUrl;
  // link.download = filename;
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  if (filetype === "pdf") {
    link.target = "_blank";
  }

  // Append to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download

  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

export const removeDuplicates = (arrayWithDuplicates) => {
  const uniqueArray = arrayWithDuplicates.reduce((accumulator, current) => {
    if (!accumulator.some((item) => item._id === current._id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  return uniqueArray.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
};

export async function getFileSize(url) {
  try {
    const response = await fetch(url, { method: "HEAD" });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentLength = response.headers.get("Content-Length");
    if (contentLength === null) {
      throw new Error("Content-Length header is missing");
    }

    const fileSizeInBytes = parseInt(contentLength, 10);
    return fileSizeInBytes;
  } catch (error) {
    console.error("Error fetching file size:", error);
  }
}
