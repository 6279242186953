import CurrencyFormat from "react-currency-input-field";

const CustomCurrencyInput = ({
  onChange,
  //   value,
  BetSelection,
  disabled,
  className,
  prefix,
  ...rest
}) => {
  return (
    <CurrencyFormat
      {...rest}
      //   value={value}
      disabled={disabled}
      className={className}
      allowNegative={false}
      prefix={prefix}
      //   fixedDecimalLength={2}
      thousandSeparator
      decimalsLimit={2}
      decimalScale={2}
      //   pattern="[0-9]*"
      onKeyPress={(event) => {
        if (
          event.key === "-" ||
          event.key === "+" ||
          event.key === "e" ||
          event.key === "Enter"
        ) {
          event.preventDefault();
          return false;
        }
      }}
      onWheel={(event) => event.currentTarget.blur()}
      onValueChange={(values) => {
        // console.log("her");
        onChange({
          target: {
            value: values,
          },
        });
        console.log("BetSelection");
        BetSelection && BetSelection(values);
      }}
    />
  );
};

export default CustomCurrencyInput;
