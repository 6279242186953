import { EyeOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import usePlanListAPI from "../../../../Component/Common/hooks/usePlanListAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedUserPlanDetails,
  updateSelectedPlanDetails,
  updateSelectedUserPlan,
} from "../../../../redux/PlanList/planList.slice";
import { useQuery } from "@tanstack/react-query";
import useDecodedToken from "../../../../Component/utils/useDecodedToken";
import { getUserPlansListAPI } from "../../../../Component/helpers/api/Plans/plans";
import moment from "moment";
import {
  DATEFORMAT,
  convertToTwoDigitDecimal,
  formatINR,
  truncate2,
} from "../../../../Utils/utils";
import { Link } from "react-router-dom";
import useExportFiles from "../../../../Component/Common/hooks/useExportFiles";
import useUserPermission from "../../../../Component/Common/hooks/useUserPermission";

const usePlanList = () => {
  const { isOpen } = useSelector(updateSelectedPlanDetails);
  const { isOpen: isOpenUserPlan } = useSelector(SelectedUserPlanDetails);
  const { exportFiles } = useExportFiles();

  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState("today");

  const {
    // page: planPage,
    // setPage: setPlanPage,
    // limit: planLimit,
    // setLimit: setPlanLimit,
    // search: planSearch,
    // setSearch: setPlanSearch,
    data: plansList,
    refetch: refetchPlansList,
    isLoading: isLoadingPlansList,
    isRefetching: isRefetchingPlanList,
  } = usePlanListAPI();

  const {
    data: userPlanData,
    refetch: refetchUserPlan,
    // isLoading: isLoadingUserPlan,
    isRefetching: isRefetchingUserPlan,
  } = useQuery({
    queryKey: ["UserPlanList", decodedToken, page, limit, search, selectedDate],
    queryFn: async () => {
      if (decodedToken) {
        return await getUserPlansListAPI(page, limit, search, selectedDate);
      } else {
        return null;
      }
    },
  });

  const handleChangeDate = useCallback(
    (date, dateString) => {
      if (dateString[0] !== "" && dateString[1] !== "" && dateString) {
        setSelectedDate(dateString);
      } else {
        setSelectedDate("today");
      }
    },
    [setSelectedDate]
  );

  // console.log("data", userPlanData);
  const PDFcolumns = useMemo(
    () => [
      {
        title: "Payment Id",
        dataIndex: "payment_id",
        key: "payment_id",
        render: (text, record) => record?.payment_details?.id || "-",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => record?.userData?.email,
      },
      {
        title: "Transaction Id",
        dataIndex: "transaction_id",
        key: "transaction_id",
        render: (text, record) => record?._id || "-",
      },

      {
        title: "Plan Name",
        dataIndex: "plan_name",
        key: "plan_name",
        render: (text, record) => record?.PlanData?.plan_name || "-",
      },
      {
        title: "Billing Amount",
        dataIndex: "billing_amount",
        key: "billing",
        render: (text, record) => record?.PlanData?.price || "-",
      },
      {
        title: "Renewal",
        dataIndex: "renewal",
        key: "renewal",
        render: (text, record) => (
          <>
            {moment(record?.userSubscriptionPlanData?.expired_at).format(
              DATEFORMAT
            )}
          </>
        ),
      },
    ],
    []
  );

  const dataSource = useMemo(
    () =>
      userPlanData?.data?.data?.map((item) => {
        return {
          key: item?._id,
          email: item?.userData?.email || "-",
          name: (
            <>
              {item?.first_name} {item?.last_name}
            </>
          ),
          plan_name: <>{item?.PlanData?.plan_name || "-"}</>,
          billing_amount: formatINR(
            convertToTwoDigitDecimal(item?.PlanData?.price)
          ),
          renewal: (
            <>
              {moment(item?.userSubscriptionPlanData?.expired_at).format(
                DATEFORMAT
              )}
            </>
          ),
          payment_id: (
            <>
              <div className="flex items-center gap-1.5">
                <div className="flex flex-col gap-0 -space-y-1">
                  <div
                    onClick={() => {
                      dispatch(
                        updateSelectedUserPlan({
                          selectedUserPlan: item,
                          isOpen: true,
                        })
                      );
                    }}
                  >
                    <span className="flex text-xs cursor-pointer hover:underline text-blue-2">
                      {truncate2(item?.payment_details?.id, 6)}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ),
          transaction_id: <>{truncate2(item?._id, 5)}</>,

          action: (
            <>
              <span className="flex">
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <span className="d-flex align-items-center">
                            <EyeOutlined className="me-2" />
                            View
                          </span>
                        ),
                        onClick: () => {
                          dispatch(
                            updateSelectedUserPlan({
                              selectedUserPlan: item,
                              isOpen: true,
                            })
                          );
                        },
                      },
                    ],
                  }}
                  placement="bottomRight"
                  overlayClassName={"dropdownOverlay dowpdownDark"}
                  trigger={["click"]}
                  className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
                >
                  <div onClick={(e) => e.preventDefault()}>
                    Action
                    <MdKeyboardArrowDown />
                  </div>
                </Dropdown>
              </span>
            </>
          ),
        };
      }),

    [userPlanData, dispatch]
  );

  const columns = useMemo(
    () => [
      {
        title: "Payment Id",
        dataIndex: "payment_id",
        key: "payment_id",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Transaction Id",
        dataIndex: "transaction_id",
        key: "transaction_id",
      },

      {
        title: "Plan Name",
        dataIndex: "plan_name",
        key: "plan_name",
      },
      {
        title: "Billing Amount",
        dataIndex: "billing_amount",
        key: "billing",
      },
      {
        title: "Renewal",
        dataIndex: "renewal",
        key: "renewal",
      },

      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ],
    []
  );

  const insertNote = useCallback(() => {
    return getUserPlansListAPI(
      page,
      userPlanData?.data?.totalRecords,
      search,
      selectedDate
    );
  }, [page, userPlanData?.data?.totalRecords, search, selectedDate]);

  const exportOptions = useMemo(() => {
    return [
      // {
      //   key: "1",
      //   label: (
      //     <Link
      //       to={`#`}
      //       className="d-flex align-items-center hover:no-underline"
      //     >
      //       Save as PDF
      //     </Link>
      //   ),
      //   onClick: () => {s
      //     exportFiles({
      //       fileType: "PDF",
      //       fileName: "Transaction",
      //       InvolkeAPI: insertNote,
      //     });
      //   },
      //   disabled:
      //     isRefetchingUserPlan || userPlanData?.data?.totalRecords === 0,
      // },
      {
        key: "2",
        label: (
          <span href="#" className="d-flex align-items-center">
            Save as CSV
          </span>
        ),
        onClick: () => {
          exportFiles({
            fileType: "CSV",
            fileName: "Transaction",
            InvolkeAPI: insertNote,
            from: "plan",
          });
        },
        disabled:
          isRefetchingUserPlan || userPlanData?.data?.totalRecords === 0,
      },
      {
        key: "3",
        label: (
          <span href="#" className="d-flex align-items-center">
            Save as Excel
          </span>
        ),
        onClick: () => {
          exportFiles({
            fileType: "EXCEL",
            fileName: "Transaction",
            InvolkeAPI: insertNote,
            from: "plan",
          });
        },
        disabled:
          isRefetchingUserPlan || userPlanData?.data?.totalRecords === 0,
      },
    ];
  }, [
    exportFiles,
    userPlanData?.data?.totalRecords,
    isRefetchingUserPlan,
    insertNote,
  ]);

  const values = useMemo(() => {
    return {
      isOpen,
      plansList: plansList?.data?.data,
      totalPlanList: plansList?.data?.count,
      isLoadingPlansList,
      refetchPlansList,
      isOpenUserPlan,
      dataSource,
      columns,
      PDFcolumns,
      setSearch,
      pagination: {
        page,
        setPage,
        limit,
        setLimit,
        total: userPlanData?.data?.totalRecords,
      },
      refetchUserPlan,
      isRefetchingPlanList,
      isRefetchingUserPlan,
      handleChangeDate,
      exportOptions,
    };
  }, [
    isOpen,
    userPlanData,
    page,
    limit,
    plansList,
    isLoadingPlansList,
    refetchPlansList,
    isOpenUserPlan,
    dataSource,
    columns,
    PDFcolumns,
    refetchUserPlan,
    isRefetchingPlanList,
    isRefetchingUserPlan,
    handleChangeDate,
    exportOptions,
  ]);

  return values;
};

export default usePlanList;
