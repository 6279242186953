import { useQuery } from "@tanstack/react-query";
import {
  getStrategyListAPI,
  getStrategyLiveTradebyID,
  getStrategyVirtualTradebyID,
  getStrategybyID,
} from "../../helpers/api/StrategyPermission/strategyPermissions";
import useDecodedToken from "../../utils/useDecodedToken";
import { useCallback, useState } from "react";

export const useStrategyListPermission = (page, limit, dependencies) => {
  const decodedToken = useDecodedToken();

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [
      "StrategyPermissionList",
      decodedToken,
      page,
      limit,
      ...Object.values(dependencies),
    ],
    queryFn: async () => {
      if (decodedToken) {
        return await getStrategyListAPI(page, limit, dependencies);
      } else {
        return null;
      }
    },
  });

  return { data, refetch, isLoading, isRefetching };
};

export const useStrategyByID = (id) => {
  const decodedToken = useDecodedToken();
  const [virtualPage, setVirtualPage] = useState(1);
  const [virtualLimit, setVirtualLimit] = useState(10);
  const [livePage, setLivePage] = useState(1);
  const [liveLimit, setLiveLimit] = useState(10);
  const [liveTradeDateFilter, setLiveTradeDateFilter] = useState([]);
  const [virtualTradeDateFilter, setVirtualTradeDateFilter] = useState([]);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["StrategybyID", id],
    queryFn: async () => {
      if (decodedToken && id) {
        return await getStrategybyID(id);
      } else {
        return null;
      }
    },
  });

  const {
    data: virtualData,
    refetch: refetchVirtualData,
    isLoading: isLoadingVirtualData,
    isRefetching: isRefetchingVirtual,
  } = useQuery({
    queryKey: [
      "StrategyVirtualTrade",
      id,
      virtualPage,
      virtualLimit,
      virtualTradeDateFilter,
    ],
    queryFn: async () => {
      if (decodedToken && id) {
        return await getStrategyVirtualTradebyID(
          id,
          virtualPage,
          virtualLimit,
          virtualTradeDateFilter
        );
      } else {
        return null;
      }
    },
  });

  const {
    data: liveData,
    refetch: refetchLiveData,
    isLoading: isLoadingLiveData,
    isRefetching: isRefetchingLive,
  } = useQuery({
    queryKey: [
      "StrategyLiveTrade",
      id,
      livePage,
      liveLimit,
      liveTradeDateFilter,
    ],
    queryFn: async () => {
      if (decodedToken && id) {
        return await getStrategyLiveTradebyID(
          id,
          livePage,
          liveLimit,
          liveTradeDateFilter
        );
      } else {
        return null;
      }
    },
  });

  const handleChangeLiveDate = useCallback(
    (date, dateString) => {
      if (dateString[0] !== "" && dateString[1] !== "" && dateString) {
        setLiveTradeDateFilter(dateString);
      } else {
        setLiveTradeDateFilter([]);
      }
    },
    [setLiveTradeDateFilter]
  );

  const handleChangeVirtualDate = useCallback(
    (date, dateString) => {
      if (dateString[0] !== "" && dateString[1] !== "" && dateString) {
        setVirtualTradeDateFilter(dateString);
      } else {
        setVirtualTradeDateFilter([]);
      }
    },
    [setVirtualTradeDateFilter]
  );

  return {
    data,
    refetch,
    isLoading,
    virtualData,
    refetchVirtualData,
    isLoadingVirtualData,
    liveData,
    refetchLiveData,
    isLoadingLiveData,
    virtualPage,
    setVirtualPage,
    virtualLimit,
    setVirtualLimit,
    livePage,
    setLivePage,
    liveLimit,
    setLiveLimit,
    isRefetchingLive,
    isRefetchingVirtual,
    handleChangeLiveDate,
    handleChangeVirtualDate,
  };
};
