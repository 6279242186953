import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from "../utils/DefaultClasses";
import { Form, Input } from "antd";
import Modal from "../Modal";
import OtpComponent from "./OtpComponent";
import OtpTimer from "./OtpTimer";
import { EMAIL_REG } from "../../Utils/utils";
import useUpdateEmail from "./hooks/useUpdateEmail";
import { SpinnerBtn } from "../utils/Icons";

const UpdateEmail = ({ props }) => {
  const { openModal = false, setOpenModal = () => {} } = props;

  const {
    form,
    showEmailVerify,
    setShowEmailVerify,
    isLoading,
    updateEmail,
    otpForm,
    otpError,
    udateOTP,
    submitForm,
    resendOTP,
    isLoadingOTP,
  } = useUpdateEmail();

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setShowEmailVerify(false);
  }, [setOpenModal, setShowEmailVerify]);

  const getEmailmodal = () => {
    if (!showEmailVerify) {
      return (
        <div className="col-12">
          <Form
            form={form}
            className="gap-3 space-y-3"
            layout="vertical"
            onFinish={updateEmail}
            // validateMessages={customValidationRules}
          >
            <Form.Item
              label={
                <>
                  <span>Current Email Address</span>
                </>
              }
              name="old_email"
              rules={[
                {
                  required: true,
                  message: "Please enter your current email.",
                },
                {
                  pattern: EMAIL_REG,
                  message: "Invalid email.",
                },

                // ({ getFieldValue }) => ({
                //   validator: async (_, value) => {
                //     if (!value || !EMAIL_REG.test(value))
                //       return Promise.resolve(); // Initial condition

                //     // const res = await check_email_while_login(value);
                //     const res = await checkEmail(value);
                //     if (!res?.data?.status) {
                //       return Promise.reject(new Error(res?.data?.message));
                //     } // Email registered as both
                //   },
                // }),
              ]}
              className="mb-0"
            >
              <Input
                className={`${formClass} form-control`}
                placeholder="Current Email Address"
                name="old_email"
              />
            </Form.Item>

            <Form.Item
              label="New Email Address"
              name="new_email"
              depen
              dependencies={["old_email"]}
              rules={[
                {
                  required: true,
                  message: "Please enter your new email.",
                },
                {
                  pattern: EMAIL_REG,
                  message: "Invalid email.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("old_email") === value) {
                      return Promise.reject(
                        new Error(
                          "Current email and new email should not be same."
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
                // ({ getFieldValue }) => ({
                //   validator: async (_, value) => {
                //     if (!value || !EMAIL_REG.test(value))
                //       return Promise.resolve(); // Initial condition

                //     // const res = await check_email_while_login(value);
                //     const res = await checkEmail(value);
                //     if (!res?.data?.status) {
                //       return Promise.reject(new Error(res?.data?.message));
                //     } // Email registered as both
                //   },
                // }),
              ]}
              className="mb-0"
            >
              <Input
                className={`${formClass} form-control`}
                placeholder="New Email Address"
                name="new_email"
              />
            </Form.Item>

            <div className="d-flex align-items-center justify-end mt-4 gap-2">
              <button
                onClick={() => closeModal()}
                type="button"
                className={`${btnSecondayClass}`}
              >
                Cancel
              </button>
              <button
                className={`${btnPrimaryClass}`}
                htmlType="submit"
                disabled={isLoading}
                // onClick={updateEmailID}
                // loading={ButtonLoader}
              >
                {isLoading ? (
                  <SpinnerBtn className={"text-lg"} />
                ) : (
                  "Send Verification Email"
                )}
              </button>
            </div>
          </Form>
          {/* <small className="mt-3 d-block text-red-2 text-[10px]">
            <InfoCircleOutlined className="me-2" />
            Wether you verify your email or not, from nect login you have to use
            your new email address.
          </small> */}
        </div>
      );
    } else {
      return (
        <>
          <div className="pt-2 mb-4 flex flex-col">
            <p class="text-[13px] text-color-secondary/90">
              Please check your new email for the 6-digit authentication code.
            </p>
          </div>
          <div>
            <Form form={otpForm} layout="vertical" onFinish={submitForm}>
              <div>
                <p class="text-[14px] text-color-primary mb-0.5">
                  Type your 6 digit security code
                </p>
              </div>
              <div>
                <OtpComponent props={{ udateOTP }} />
                {otpError !== "" ? (
                  <>
                    <div className="ant-form-item-explain ant-form-item-explain-connected mt-1">
                      <div
                        className="ant-form-item-explain-error"
                        style={{ marginTop: "0px" }}
                      >
                        {otpError}
                      </div>
                    </div>
                  </>
                ) : null}{" "}
              </div>
              <div className="text-center mt-4 mb-1">
                <button
                  type="primary"
                  htmlType="submit"
                  className={`${btnPrimaryClass} w-full`}
                  disabled={isLoadingOTP}
                >
                  {isLoading ? (
                    <SpinnerBtn className={"text-lg"} />
                  ) : (
                    "Verify your account"
                  )}
                </button>
              </div>
              <div className="text-center">
                <OtpTimer
                  minutes={1}
                  seconds={30}
                  text="Resend OTP Link in"
                  ButtonText="Resend OTP"
                  // isButton={false}
                  background="none"
                  textColor="unset"
                  buttonColor="#cc8411"
                  className={`w-full`}
                  // isError={mutation.isError}
                  resend={() => {
                    updateEmail();
                    // resendOTP.mutate();
                  }}
                  // darkTheme={darkTheme}
                />
              </div>
            </Form>
          </div>
        </>
      );
    }
  };

  return (
    <Modal
      open={openModal}
      setIsOpen={closeModal}
      maxWidth={`w-full max-w-lg`}
      preventClosingOutsideClick
      // fullScreen="pl-0 pe-0 "
      header={
        <div className="flex flex-col justify-center py-2">
          <div className="w-full flex items-center">
            <span className="text-color-primary text-base font-medium">
              {!showEmailVerify
                ? "Enter Your Valid Email Address"
                : "Enter Security Code"}
            </span>
          </div>
        </div>
      }
      footer={null}
      // bodyHeight={'70vh'}
      bodyClass={
        "w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full"
      }
    >
      <div className="">{getEmailmodal()}</div>
    </Modal>
  );
};

export default UpdateEmail;
