import React, { useState, useEffect } from "react";
import Time from "../Pages/Admin/Dashboard/Time";
import { btnSecondayClass } from "./utils/DefaultClasses";

const UpdatedTime = ({
	invokeFunction,
	isLoading,
	showOnlyRefreshIcon = false,
	justText = false,
}) => {
	console.log("isLoading", isLoading);
	// Calculate Refresh Time
	const [GetTime, setGetTime] = useState(Date());
	//   const [loading, setloading] = useState(false);
	const [updatedTime, setUpdatedTime] = useState(
		Calculate_days_hours(new Date(GetTime), new Date(Date.now())),
	);

	function Calculate_days_hours(date1, date2) {
		var diff = date2.getTime() - date1.getTime();
		var days = Math.floor(diff / (1000 * 60 * 60 * 24));
		var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((diff % (1000 * 60)) / 1000);
		///return { days, hours, minutes, seconds };
		if (days !== 0) {
			return days + " " + "days ago ";
		} else if (hours !== 0) {
			return hours + " " + "hours ago";
		} else if (minutes !== 0) {
			return minutes + " " + "min. ago";
		} else if (seconds < 60) {
			return "Updated Just now";
		}
	}

	const showTime = () => {
		setUpdatedTime(
			Calculate_days_hours(new Date(GetTime), new Date(Date.now())),
		);
	};
	useEffect(() => {
		let interval = setInterval(() => {
			showTime();
		}, 3000);
		return () => {
			clearInterval(interval);
		};
	}, [GetTime]);

	return (
		<div className="d-flex align-items-center">
			{!showOnlyRefreshIcon && (
				<>
					<div>
						<p className="mb-0">{updatedTime}</p>
					</div>
					{/* <div>
						<div className="rightContent">{<Time />}</div>
					</div> */}
				</>
			)}
			{!justText === true && (
				<div>
					<button
						className={`${btnSecondayClass} p-1.5 w-[34px] h-[34px] text-sm`}
						onClick={() => {
							// setloading(true);
							setGetTime(Date());
							invokeFunction?.map((involke) => involke());
							setTimeout(() => {
								setUpdatedTime(
									Calculate_days_hours(new Date(), new Date(Date.now())),
								);
								//   setloading(false);
							}, 3000);
						}}>
						{/* <svg
            width="20"
            height="20"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.886 11.7487C23.8654 11.7487 23.8449 11.7487 23.8243 11.7487C23.2749 11.7641 22.823 12.2057 22.8076 12.7654C22.6587 17.536 19.3362 21.598 14.7248 22.6507C10.7501 23.5545 6.67792 22.0242 4.24383 18.866L7.27874 19.5028C7.84361 19.6209 8.40335 19.2563 8.52145 18.6914C8.63956 18.1266 8.2801 17.5668 7.71009 17.4487L2.37462 16.3293C1.52731 16.2009 1.16271 16.8582 1.1319 17.1458L0.0226992 22.5172C-0.0954104 23.082 0.269189 23.6366 0.839196 23.7548C1.40407 23.8729 1.95867 23.5083 2.07678 22.9383L2.64165 20.2115C5.57898 23.9602 10.442 25.7729 15.1972 24.6894C20.7381 23.4261 24.7281 18.5477 24.9079 12.827C24.9181 12.2519 24.4662 11.7692 23.886 11.7487Z"
              fill="#BCB6B6"
            />
            <path
              d="M24.3532 1.56074C23.7883 1.42722 23.2234 1.77641 23.095 2.34129L22.4583 5.05267C19.6236 1.22695 14.812 -0.714157 10.0311 0.24099C4.45427 1.35533 0.3307 6.12593 0.00204767 11.8414C-0.0338987 12.4217 0.407728 12.9147 0.988006 12.9506C1.00855 12.9506 1.02909 12.9506 1.04963 12.9506C1.59909 12.9506 2.06126 12.5193 2.09721 11.9647C2.36937 7.19919 5.79969 3.22454 10.4368 2.30021C14.4371 1.49911 18.4631 3.14238 20.815 6.36728L17.8007 5.64836C17.2358 5.51484 16.6709 5.86403 16.5374 6.42377C16.4039 6.98864 16.7531 7.55351 17.3128 7.68703L22.6175 8.95029C23.4596 9.10435 23.8448 8.45218 23.8807 8.16974L25.1337 2.824C25.2672 2.25912 24.918 1.69425 24.3532 1.56074Z"
              fill="#BCB6B6"
            />
          </svg> */}
						<span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
							{isLoading ? (
								<i class="fa-solid fa-sync fa-spin"></i>
							) : (
								<i class="fa-solid fa-sync"></i>
							)}
						</span>
					</button>
				</div>
			)}
		</div>
	);
};

export default UpdatedTime;
