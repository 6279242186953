import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { updateUserDetails } from "../../../Component/helpers/api/userDetails/userDetails";
import { toast } from "react-toastify";
import { toastSpecs } from "../../../Utils/utils";

const useSecuritySetting = (refetchUser, userData, setIsTabed) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue(
      "active_logs",
      userData?.save_activity_logs || undefined
    );
    form.setFieldValue(
      "unusual_activity",
      userData?.unusual_activity || undefined
    );
    form.setFieldValue("auth_2fa", userData?.auth_2fa || undefined);
  }, [userData]);

  const insertNote = useCallback((payload) => {
    return updateUserDetails(payload);
  }, []);

  const updateSecurityMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      refetchUser();
      setIsTabed(true);
      // form.resetFields();
      if (res.data.message === "User data update successfully.") {
        return toast.success("updated successfully.", toastSpecs);
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const updateuserData = useCallback(
    (payload) => {
      updateSecurityMutation?.mutate(payload);
    },
    [updateSecurityMutation]
  );

  const value = useMemo(() => {
    return {
      form,
      isLoading: updateSecurityMutation?.isPending,
      updateuserData,
    };
  }, [form, updateSecurityMutation?.isPending, updateuserData]);

  return value;
};

export default useSecuritySetting;
