import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import { Form, Input, Skeleton } from "antd";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import {
	btnPrimaryClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import TableComponent from "../../../Component/TableComponent";
import { Col, Row } from "react-bootstrap";
import PopoverDropdown from "../../../Component/PopoverDropdown";
import StrategyFilter from "../Dashboard/StrategyFilter";
import CreateRoleModal from "../../../Component/Common/AddRoleModal";
import AccessRoleCard from "./AccessRoleCard";
import { RiSearchLine } from "react-icons/ri";
import useAccessRole from "./hooks/useAccessRole";
import CreateUserModal from "../../../Component/Common/createUserModal";
import DeleteConfirmation from "../../../Component/Common/DeleteConfirm";
import { useMemo } from "react";
import NoDataFoundV2 from "../../../Component/NoDataFoundV2";
import { BsPersonLock } from "react-icons/bs";
import UserProfileDrawer from "../Dashboard/user_detail_compo/userProfileDrawer";
import UpdatedTime from "../../../Component/UpdatedTime";

export default function AccessRoles() {
	const [searchForm] = Form.useForm();
	const {
		form,
		AddRoleModal,
		dataSource,
		isRefetching,
		columns,
		page,
		limit,
		totalCount,
		openUserModal,
		deleteuser,
		roleListDatas,
		deleteRole,
		filtersList,
		filterHeadings,
		isLoadingRolesList,
		isLoading,
		refetchUser,
		roleAccess,
		onClear,
		onFilter,
		setsearch,
		setDeleteRole,
		onSubmitDeleteRole,
		setDeleteuserID,
		setPage,
		setLimit,
		setAddRoleModal,
		closeModal,
		onCloseUserModal,
		onCloseDeleteUser,
		onCloseDeleteRole,
		onSubmitDelete,
		refetchRoleList,
	} = useAccessRole();

	const userList = useMemo(() => {
		if (totalCount === 0 && !isLoading) {
			return (
				<>
					<NoDataFoundV2 title="No user's Found." icon={<BsPersonLock />} />
				</>
			);
		} else {
			return (
				<>
					<div className="mb-3">
						<TableComponent
							dataSource={dataSource}
							columns={columns}
							total={totalCount}
							loading={isLoading}
							// pagination_callback={fetchStaffList}
							className="fs_tables"
							pagination={totalCount < 10 ? false : true}
							currentPage={page}
							setCurrentPage={setPage}
							pageSize={limit}
							setPageSize={setLimit}
						/>
					</div>
				</>
			);
		}
	}, [
		columns,
		dataSource,
		isLoading,
		limit,
		page,
		setLimit,
		setPage,
		totalCount,
	]);

	return (
		<>
			<div className="">
				<div className="layout-row flex" id="main">
					<div className="">
						<Leftbar type="admin" />
					</div>
					<div className="left__bar w-full">
						<RightBar
							leftContent={
								<>
									<div className="d-flex align-items-center">
										{/* <div className="leftContent">
										Account Id: {adminid.id}
									</div> */}
										<div className="flex gap-2">
											<div className="">
												<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
													<SettingOutlined className="" />
												</span>
											</div>
											<div className="">
												<div className="flex gap-2">
													<div className="flex flex-col gap-0 -space-y-1">
														<h2 className="strtgy__ttl">Roles List</h2>
														<span className="text-sm flex">
															A role defines the access levels to the users in
															Admin Dashboard and Trader Dashboard.
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
							rightContent={<></>}>
							<>
								{/* <div className="headingSection mb-4 mt-4">
											<h2 className="d-flex align-items-center">
												<span className="heading">List Of Users</span>
											</h2>
											<p className="subHead">
												You have total {data.length} Users.
											</p>
										</div> */}
								<div className=" mt-4">
									<div className="mb-4">
										<Row className="g-3">
											{isLoadingRolesList ? (
												<>
													{[...Array(3)]?.map((item) => (
														<Col lg={4} key={item}>
															<div className="relative flex justify-between p-3.5 border-[1px] shadow-md border-level-4 rounded-lg w-full">
																<Skeleton.Avatar
																	active={true}
																	size={"default"}
																	shape={"circle"}
																	className="absolute top-3 right-3"
																/>
																<Skeleton
																	active={true}
																	size={"default"}
																	block={true}
																	paragraph={{ rows: 1 }}
																/>
															</div>
														</Col>
													))}
												</>
											) : (
												roleListDatas?.map((item) => (
													<Col lg={4} key={item?._id}>
														<AccessRoleCard
															roleData={item}
															setAddRoleModal={setAddRoleModal}
															setDeleteRole={setDeleteRole}
															setDeleteuserID={setDeleteuserID}
															roleAccess={roleAccess}
														/>
													</Col>
												))
											)}
										</Row>
									</div>
									{/* card bg-color-base-1 off__blue border-level-4 shadow-md shadow-level-4 rounded-lg w-full */}
									<div className="">
										<div className="flex align-items-center justify-between">
											<div className="flex">
												<div className={`relative max-w-[220px]`}>
													{/* <Input.Group
															compact
															className="searchGroup border-r-0 h-[36px] -mt-[1px]">
															<Input.Search
																allowClear
																placeholder="Search"
																// onChange={onSearch}
																// onPressEnter={onSearch}
																onSearch={() => setExpandSearch(true)}
															/>
														</Input.Group> */}
													<Form
														className="mt-3.5"
														layout="vertical"
														form={searchForm}>
														<Form.Item
															name="search"
															validateDebounce={1000}
															rules={[
																({ getFieldValue }) => ({
																	validator: (_, value) => {
																		console.log(value, "value");
																		setsearch(value);
																		return Promise.resolve();
																	},
																}),
															]}>
															<Input
																allowClear
																placeholder="Search"
																// onChange={onSearch}
																// onPressEnter={onSearch}
																className={`${formClass}`}
																// onSearch={() => setExpandSearch(!expandSearch)}
																suffix={
																	<>
																		<span className="">
																			<RiSearchLine />
																		</span>
																	</>
																}
															/>
														</Form.Item>
													</Form>
												</div>
											</div>
											<div className="w-full">
												<div className="d-flex align-items-center justify-content-end gap-2">
													<div>
														<p className="subHead">Total Users: {totalCount}</p>
													</div>
													<div className="py-0.5 hidden lg:flex">
														<span className="flex w-1 h-7 bg-level-2"></span>
													</div>{" "}
													{/* <div
														className={`${
															expandSearch
																? "animateSearch active w-full"
																: "animateSearch w-auto min-w-[36px]"
														} max-w-[220px]`}
														> */}
													{/* <div className="py-0.5 hidden lg:flex">
														<span className="flex w-1 h-7 bg-level-2"></span>
													</div> */}
													{roleAccess?.isCreate && (
														<div className="">
															<button
																className={`${btnPrimaryClass} text-sm`}
																onClick={() => {
																	setAddRoleModal(true);
																}}>
																<PlusOutlined />
																Add New Role
															</button>
														</div>
													)}
													<div className="flex">
														<PopoverDropdown
															content={
																<StrategyFilter
																	props={{
																		form,
																		filtersList,
																		filterHeadings,
																		buttons: {
																			onClear,
																			onFilter,
																		},
																	}}
																/>
															}
														/>
													</div>
													<UpdatedTime
														invokeFunction={[refetchUser, refetchRoleList]}
														showOnlyRefreshIcon={true}
														isLoading={isRefetching}
													/>
													{/* <div className="">
														<button
															className={`${
																selectedRowsDatas.length !== 0
																	? btnPrimaryClass
																	: btnSecondayClass
															} p-2.5 text-sm`}
															onClick={() => setShowModal(true)}>
															<span className="">
																<DeleteOutlined className="" />
															</span>
														</button>
													</div> */}
												</div>
											</div>
										</div>
									</div>
								</div>
								{userList}
							</>
						</RightBar>
					</div>
				</div>
			</div>
			{AddRoleModal && (
				<CreateRoleModal
					props={{
						AddRoleModal,
						closeModal,
					}}
				/>
			)}
			{openUserModal && (
				<CreateUserModal
					props={{
						openUser: openUserModal,
						closeModal: onCloseUserModal,
					}}
				/>
			)}

			{(deleteuser || deleteRole) && (
				<DeleteConfirmation
					props={{
						showModal: deleteuser || deleteRole,
						hideModal: deleteRole ? onCloseDeleteRole : onCloseDeleteUser,
						onSubmitDelete: deleteRole ? onSubmitDeleteRole : onSubmitDelete,
					}}
				/>
			)}
			{<UserProfileDrawer refetch={refetchUser} />}
		</>
	);
}
