import Modal from "../Modal";
import { Form } from "antd";
import Input from "antd/es/input/Input";
import { AiOutlineEye } from "react-icons/ai";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from "../utils/DefaultClasses";
import { useUpdatePassword } from "./hooks/useUpdatePassword";
import { SpinnerBtn } from "../utils/Icons";

const UpdatePassword = ({ props }) => {
  const { openModal = false, setOpenModal = () => {} } = props;
  const { form, isLoading, updateuserData } = useUpdatePassword();

  return (
    <Modal
      open={openModal}
      setIsOpen={setOpenModal}
      maxWidth={`w-full max-w-lg`}
      preventClosingOutsideClick
      // fullScreen="pl-0 pe-0 "
      header={
        <div className="flex flex-col justify-center py-2">
          <div className="w-full flex items-center">
            <span className="text-color-primary text-base font-medium">
              Change Password
            </span>
          </div>
        </div>
      }
      footer={null}
      // bodyHeight={'70vh'}
      bodyClass={
        "w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full"
      }
    >
      <div className="space-y-4">
        <div>
          <div className="row justify-content-center">
            <div className="col-12">
              <Form
                className="space-y-3"
                layout="vertical"
                form={form}
                onFinish={() => {
                  const { old_password, new_password } = form.getFieldsValue();
                  const payload = {
                    new_password: new_password,
                    old_password: old_password,
                  };
                  return updateuserData(payload, "password");
                }}
                disabled={isLoading}
              >
                <Form.Item
                  label="Current Password"
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required.",
                    },
                  ]}
                  className="mb-0"
                >
                  <Input.Password
                    placeholder="Current password"
                    name="old_password"
                    // onChange={onChangePassword}
                    iconRender={(visible) =>
                      visible ? (
                        <span>
                          <AiOutlineEye className="text-color-primary text-base" />
                        </span>
                      ) : (
                        <span>
                          <EyeInvisibleOutlined className="text-color-primary text-base" />
                        </span>
                      )
                    }
                    className={`${formClass}`}
                    // style={{
                    //   fontSize: '14px',
                    //   fontWeight: '400',
                    //   height: '40px',
                    //   borderRadius: '0.375rem',
                    // }}
                  />
                </Form.Item>
                {/* {oldPassError ? (
                    <>
                      <div className="ant-form-item-explain ant-form-item-explain-connected">
                        <div
                          className="ant-form-item-explain-error"
                          style={{ marginTop: "0px" }}
                        >
                          {oldPassError}
                        </div>
                      </div>
                    </>
                  ) : null} */}

                <Form.Item
                  label="New Password"
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: "New Password is required.",
                    },
                  ]}
                  className="mb-0"
                >
                  <Input.Password
                    placeholder="New password"
                    name="new_password"
                    // onChange={onChangePassword}
                    // style={{
                    //   fontSize: '14px',
                    //   fontWeight: '400',
                    //   height: '40px',
                    //   borderRadius: '0.375rem',
                    // }}
                    iconRender={(visible) =>
                      visible ? (
                        <span>
                          <AiOutlineEye className="text-color-primary text-base" />
                        </span>
                      ) : (
                        <span>
                          <EyeInvisibleOutlined className="text-color-primary text-base" />
                        </span>
                      )
                    }
                    className={`${formClass}`}
                  />
                </Form.Item>
                {/* {passwordError ? (
                    <>
                      <div className="ant-form-item-explain ant-form-item-explain-connected">
                        <div
                          className="ant-form-item-explain-error"
                          style={{ marginTop: "0px" }}
                        >
                          {passwordError}
                        </div>
                      </div>
                    </>
                  ) : null} */}
                <Form.Item
                  label="Verify Password"
                  name="verify_password"
                  dependencies={["new_password"]}
                  rules={[
                    {
                      required: true,
                      message: "Password field required.",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error("Your password does not match.")
                        );
                      },
                    }),
                  ]}
                  className="mb-0"
                >
                  <Input.Password
                    placeholder="Verify password"
                    name="verify_password"
                    // onChange={onChangePassword}
                    // style={{
                    //   fontSize: '14px',
                    //   fontWeight: '400',
                    //   height: '40px',
                    //   borderRadius: '0.375rem',
                    // }}
                    iconRender={(visible) =>
                      visible ? (
                        <span>
                          <AiOutlineEye className="text-color-primary text-base" />
                        </span>
                      ) : (
                        <span>
                          <EyeInvisibleOutlined className="text-color-primary text-base" />
                        </span>
                      )
                    }
                    className={`${formClass}`}
                  />
                </Form.Item>
                <div className="d-flex justify-end items-center gap-2 mt-4">
                  <button
                    onClick={() => {}}
                    type="button"
                    className={`${btnSecondayClass}`}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type="primary"
                    htmlType="submit"
                    className={`${btnPrimaryClass}`}
                    disabled={isLoading}
                    // onClick={updatedPass}
                  >
                    {isLoading ? (
                      <SpinnerBtn className={"text-lg"} />
                    ) : (
                      "Update Password"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdatePassword;
