import { useCallback, useEffect, useMemo } from "react";
import { Form } from "antd";
import {
  btnPrimaryClass,
  btnSecondayClass,
} from "../../../Component/utils/DefaultClasses";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDefaultSortCount,
  updateFilterCount,
  updateForm,
  updateSortingForm,
  updateisFiltered,
  updateisSorted,
} from "../../../redux/Filters/filterSort.slice";

export default function StrategyFilter({ props }) {
  const {
    filtersList = [],
    filterHeadings = [],
    buttons = {},
    isSorting = false,
    form: propsForm,
  } = props || {};
  const { onClear, onFilter } = buttons;
  // const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isFormUpdate, isSortingFormUpdate } = useSelector(
    (state) => state?.Popover
  );
  const dispatch = useDispatch();

  const antdForm = useMemo(() => {
    if (propsForm) return propsForm;
    return form;
  }, [form, propsForm]);

  const updateFilterCountData = useCallback(() => {
    // console.log('form.getFieldsValue()', antdForm.getFieldsValue());
    return (
      typeof antdForm.getFieldsValue() &&
      Object.values(antdForm.getFieldsValue()).filter((val) =>
        Boolean(val || val === 0 || val === "" || val === null)
      ).length
    );
  }, [antdForm]);

  useEffect(() => {
    return () => {
      dispatch(updateFilterCount(0));
      dispatch(updateisFiltered(false));
      dispatch(updateDefaultSortCount(1));
      dispatch(updateisSorted(false));
    };
  }, [dispatch]);

  return (
    <div>
      <div>
        <Form
          form={antdForm}
          // onFinish={handleSubmit}
          className="w-full"
          autoComplete="off"
          // onFieldsChange={() => dispatch(updateForm(true))}
          // ariaautocomplete="off"
        >
          <div className="space-y-4">
            {filtersList?.map((filter, index) => (
              <div className="relative" key={index}>
                <div className="flex justify-start items-center gap-2 mb-1 text-color-secondary">
                  <div className="font-medium mb-0">
                    {filterHeadings?.[index]}
                  </div>
                </div>
                <Form.Item
                  noStyle
                  className="mb-0"
                  key={index}
                  name={`filter${index}`}
                >
                  {filter}
                </Form.Item>
              </div>
            ))}
          </div>
        </Form>
      </div>
      <div className="flex justify-end items-center gap-2 mt-[28px]">
        {((isFormUpdate && !isSorting) ||
          (isSortingFormUpdate && isSorting)) && (
          <div>
            <button
              className={`${btnSecondayClass}`}
              type="button"
              onClick={() => {
                // form.isFieldsTouched()
                //   ? onClear()
                //   : dispatch(updatePopover(false));
                isSorting
                  ? dispatch(updateSortingForm(false))
                  : dispatch(updateForm(false));
                onClear();
                antdForm.resetFields();
              }}
            >
              Clear
            </button>
          </div>
        )}

        <div>
          <button
            className={`${btnPrimaryClass}`}
            type="button"
            // disabled={!form.isFieldsTouched()}
            onClick={() => {
              // form.isFieldsTouched() && onFilter();
              !isSorting &&
                dispatch(updateFilterCount(updateFilterCountData()));
              isSorting
                ? dispatch(updateSortingForm(false))
                : dispatch(updateForm(false));
              onFilter();
            }}
          >
            {isSorting ? "Sort" : "Filter"}
          </button>
        </div>
      </div>
    </div>
  );
}
