import { Navigate } from "react-router-dom";
import { history } from "./history";
import useDecodedToken from "./useDecodedToken";
import CopyRightFooter from "../Common/CopyRightFooter";

function PrivateRoute({ children }) {
	const decodedtoken = useDecodedToken();

	return decodedtoken ? (
		<>
			<div className="mb-[40px]">
				{children}
				<div className="shrink-0 flex justify-start fixed bottom-0  bg-color-base-1 dark:bg-color-base-1 shadow-base dark:border-t dark:border-level-2 left-0 right-0">
					<div className="left__bar mt-0 py-2.5">
						<CopyRightFooter />
					</div>
				</div>
			</div>
		</>
	) : (
		<Navigate to={`/login`} state={{ from: history.location }} />
	);
}

export default PrivateRoute;
