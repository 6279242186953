import React from "react";

export default function TradeStatsCard({ title, time, value }) {
	return (
		<>
			<div className="flex justify-between p-2.5 bg-blue-2/10 border-[1px] border-dashed border-level-2 rounded-lg w-full">
				<div>
					<div className="d-flex d-flex align-items-center mb-2 ps-0">
						{/* <div
							className={`me-2 fs-18 ${
								value < 0 ? "primary__red" : value > 0 ? "primary__green" : ""
							}`}>
							{value < 0 ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="14"
									fill="currentColor"
									className="bi bi-arrow-down"
									viewBox="0 0 16 16">
									<path
										fillRule="evenodd"
										d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
									/>
								</svg>
							) : value > 0 ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-arrow-up"
									viewBox="0 0 16 16">
									<path
										fillRule="evenodd"
										d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
									/>
								</svg>
							) : null}
						</div> */}
						<div
							className={`me-2 fs-18 text-blue-2 font-semibold ${
								value < 0 ? "primary__red" : value > 0 ? "text-blue-2" : ""
							}`}>
							{value}
						</div>
					</div>
					<div>
						<h3 className="text-sm">{title}</h3>
						<span className="me-2 px-0 py-0 sub__ttl">{time}</span>
					</div>
				</div>
				{/* <div>
					<div className="d-flex d-flex align-items-center mb-2 ps-0">
						<div className={"me-2 primary__red fs-18"}>
							{value < 0 ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="14"
									fill="currentColor"
									className="bi bi-arrow-down"
									viewBox="0 0 16 16">
									<path
										fillRule="evenodd"
										d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
									/>
								</svg>
							) : value > 0 ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-arrow-up"
									viewBox="0 0 16 16">
									<path
										fillRule="evenodd"
										d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
									/>
								</svg>
							) : null}
						</div>
						<div className={"primary__red fw-bold fs-18"}>{value}</div>
					</div>
				</div> */}
			</div>
		</>
	);
}
