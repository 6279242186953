import { Skeleton, Timeline } from "antd";
import { useMemo } from "react";
import TableComponent from "../../../../Component/TableComponent";
import useUserActivities from "./hooks/useUserActivities";
import NoDataFoundV2 from "../../../../Component/NoDataFoundV2";
import { AiOutlineHistory } from "react-icons/ai";

export default function UserActivity() {
  const { activities, isLoading, dataSource, columns, dataCount } =
    useUserActivities();

  //   console.log("loggedInUser", loggedInUser);

  const timeLine = useMemo(() => {
    // if (!selectedUser?.save_activity_logs && loggedInUser)
    //   return (
    //     <NoDataFoundV2
    //       title={
    //         <>
    //           <span>
    //             To check out the activities, enable Save My Activity Logs.{" "}
    //           </span>
    //         </>
    //       }
    //       icon={<AiOutlineHistory />}
    //     />
    //   );

    if (isLoading)
      return [...Array(6)]?.map(() => (
        <Skeleton.Input
          active={true}
          size={"default"}
          shape={"default"}
          block={true}
        />
      ));
    if (dataCount === 0 && !isLoading)
      return (
        <>
          <NoDataFoundV2
            title="No Activities's Found."
            icon={<AiOutlineHistory />}
          />
        </>
      );
    return (
      <>
        <div className="p-[18px] relative max-h-[400px] overflow-y-auto scrollCss">
          <Timeline items={activities} />
        </div>
        <div className="space-y-3">
          <div>
            <h6 className="text-lg">Recent Devices</h6>
          </div>
          <div>
            <TableComponent dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </>
    );
  }, [
    activities,
    dataCount,
    isLoading,
    // selectedUser?.save_activity_logs,
    dataSource,
    columns,
    // loggedInUser,
  ]);

  return (
    <>
      <div className="space-y-6">{timeLine}</div>
    </>
  );
}
