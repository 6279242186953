import { useCallback } from "react";
import Drawer from "../../../Component/Common/Drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedUserPlanDetails,
  updateSelectedUserPlan,
} from "../../../redux/PlanList/planList.slice";
import moment from "moment";
import {
  DATEFORMAT,
  convertToTwoDigitDecimal,
  formatINR,
} from "../../../Utils/utils";

export default function TransactionSummary() {
  const dispatch = useDispatch();
  const { isOpen, selectedUserPlan } = useSelector(SelectedUserPlanDetails);

  const handleSummaryClose = useCallback(() => {
    dispatch(
      updateSelectedUserPlan({
        selectedUserPlan: {},
        isOpen: false,
      })
    );
  }, [dispatch]);

  return (
    <Drawer
      title={
        <>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="flex justify-center items-center gap-1.5 font-medium">
                <span className="font-semibold">Transaction Summary</span>
              </span>
              {/* <span className="text-color-secondary/80 text-xs font-normal">
									24-May-2024 01:29:15 pm
								</span> */}
            </div>
            <div className="flex -mt-2"></div>
          </div>
        </>
      }
      onClose={handleSummaryClose}
      open={isOpen}
      className={``}
      rootClassName={"drawer-root-className"}
      width={"540px"}
      footer={null}
    >
      <div className="">
        <div className="w-full space-y-6">
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Email</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">
                  {selectedUserPlan?.userData?.email || "-"}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Name</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">
                  {selectedUserPlan?.userData?.first_name}{" "}
                  {selectedUserPlan?.userData?.last_name}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Plan Name</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">
                  {selectedUserPlan?.PlanData?.plan_name || "-"}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Billing Amount</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">
                  {formatINR(
                    convertToTwoDigitDecimal(selectedUserPlan?.PlanData?.price)
                  )}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Renewal</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">
                  {moment(
                    selectedUserPlan?.userSubscriptionPlanData?.expired_at
                  ).format(DATEFORMAT)}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Payment Id</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">
                  {selectedUserPlan?.payment_details?.id}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <span className="text-gray-900">Transaction Id</span>
            </div>
            <div className="col-md-8">
              <span className="text-gray-700 font-medium">
                <span className="">{selectedUserPlan?._id}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
