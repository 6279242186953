import React, { useState } from "react";
import ProfitLossCard from "../Dashboard/user_detail_compo/ProfitLossCard";
import { Dropdown, Form, Input, Tooltip } from "antd";
import {
	btnSecondayClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import { RiSearchLine } from "react-icons/ri";
import TableComponent from "../../../Component/TableComponent";
import TradeStatsCard from "./TradeStatsCard";
import TradeDetailDrawer from "./TradeDetailDrawer";
import { MdOutlineUploadFile } from "react-icons/md";
import CustomDatePicker from "../../../Component/Common/CustomDatePicker";
import dayjs from "dayjs";
import { HiOutlineRefresh } from "react-icons/hi";
import { Link } from "react-router-dom";

export default function BacktestTrade() {
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const dataSource = [
		{
			key: "1",
			Strategy: (
				<>
					<span
						className="text-blue-2 cursor-pointer"
						onClick={() => {
							setShow(true);
						}}>
						SYSS
					</span>
				</>
			),
			ReportName: 32,
			Symbol: "NIFTY",
			OrderTime: "27-May-2024 7:20:32 PM",
			Qty: 4,
			LotSize: 32,
		},
	];

	const columns = [
		{
			title: "Strategy",
			dataIndex: "Strategy",
			key: "Strategy",
		},
		{
			title: "Report Name",
			dataIndex: "ReportName",
			key: "ReportName",
		},
		{
			title: "Symbol",
			dataIndex: "Symbol",
			key: "Symbol",
		},
		{
			title: "Order Time",
			dataIndex: "OrderTime",
			key: "OrderTime",
		},
		{
			title: "Qty",
			dataIndex: "Qty",
			key: "Qty",
		},
		{
			title: "Lot Size",
			dataIndex: "LotSize",
			key: "LotSize",
		},
	];
	const rangePresets = [
		{
			label: "Last 7 Days",
			value: [dayjs().add(-7, "d"), dayjs()],
		},
		{
			label: "Last 14 Days",
			value: [dayjs().add(-14, "d"), dayjs()],
		},
		{
			label: "Last 30 Days",
			value: [dayjs().add(-30, "d"), dayjs()],
		},
		{
			label: "Last 90 Days",
			value: [dayjs().add(-90, "d"), dayjs()],
		},
	];
	return (
		<>
			<div>
				<div className="row">
					<div className="col">
						<div className="flex gap-2 off__blue p-2.5 rounded-md">
							<div className="">
								<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
									<svg
										stroke="currentColor"
										fill="none"
										strokeWidth="2"
										viewBox="0 0 24 24"
										strokeLinecap="round"
										strokeLinejoin="round"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg"
										className="shrink-0">
										<polyline points="16 16 12 12 8 16"></polyline>
										<line x1="12" y1="12" x2="12" y2="21"></line>
										<path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
										<polyline points="16 16 12 12 8 16"></polyline>
									</svg>
								</span>
							</div>
							<div className="">
								<div className="flex gap-2">
									<div>
										<h2 className="strtgy__ttl">Backtest Overview</h2>
										<span className="text-sm -mt-1 flex">
											27-May-2024 7:20:32 PM
										</span>
									</div>
									<div>
										<span className="badge__green flex items-center leading-tight">
											Backtest
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row p-0 p-lg-0 mt-3">
					<div className="col">
						<TradeStatsCard
							title={
								<>
									<span className="font-medium">Total No. of Backtest</span>
								</>
							}
							time={"24-May-2024"}
							value={0}
						/>
					</div>
					<div className="col">
						<TradeStatsCard
							title={
								<>
									<span className="font-medium">Successful Backtest</span>
								</>
							}
							time={"24-May-2024"}
							value={0}
						/>
					</div>
					<div className="col">
						<TradeStatsCard
							title={
								<>
									<span className="font-medium">Failure Backtest</span>
								</>
							}
							time={"24-May-2024"}
							value={0}
						/>
					</div>
					<div className="col">
						<TradeStatsCard
							title={
								<>
									<span className="font-medium">Total No. of Reports</span>
								</>
							}
							time={"24-May-2024"}
							value={0}
						/>
					</div>
				</div>
			</div>
			<div className=" mt-4">
				<div className="flex align-items-center justify-between">
					<div className="flex">
						<div className={`relative max-w-[220px]`}>
							<Form
								className="mt-3.5"
								layout="vertical"
								// form={searchForm}
							>
								<Form.Item
									name="search"
									validateDebounce={1000}
									rules={[
										({ getFieldValue }) => ({
											validator: (_, value) => {
												console.log(value, "value");
												// setsearch(value);
												return Promise.resolve();
											},
										}),
									]}>
									<Input
										allowClear
										placeholder="Search"
										// onChange={onSearch}
										// onPressEnter={onSearch}
										className={`${formClass}`}
										// onSearch={() => setExpandSearch(!expandSearch)}
										suffix={
											<>
												<span className="">
													<RiSearchLine />
												</span>
											</>
										}
									/>
								</Form.Item>
							</Form>
						</div>
					</div>
					<div className="w-full">
						<div className="d-flex align-items-center justify-content-end gap-2">
							{/* <div>
													<p className="subHead">Total Users: 12</p>
												</div> */}
							{/* <div className="py-0.5 hidden lg:flex">
													<span className="flex w-1 h-7 bg-level-2"></span>
												</div> */}
							{/* <div
														className={`${
															expandSearch
																? "animateSearch active w-full"
																: "animateSearch w-auto min-w-[36px]"
														} max-w-[220px]`}
														> */}
							{/* <div className="py-0.5 hidden lg:flex">
														<span className="flex w-1 h-7 bg-level-2"></span>
													</div> */}
							<div>
								<CustomDatePicker presets={rangePresets} />
							</div>
							<div className="">
								<Tooltip title={"Export"}>
									<Dropdown
										menu={{
											items: [
												{
													key: "1",
													label: (
														<Link
															to={`#`}
															className="d-flex align-items-center hover:no-underline">
															Save as PDF
														</Link>
													),
												},
												{
													key: "2",
													label: (
														<span
															href="#"
															className="d-flex align-items-center">
															Save as CSV
														</span>
													),
													onClick: () => {},
												},
												{
													key: "3",
													label: (
														<span
															href="#"
															className="d-flex align-items-center">
															Save as Excel
														</span>
													),
													onClick: () => {},
												},
											],
										}}
										placement="bottomRight"
										overlayClassName={"dropdownOverlay dowpdownDark"}
										trigger={["hover"]}
										className={`${btnSecondayClass} w-[36px] h-[36px] relative`}>
										<div onClick={(e) => e.preventDefault()}>
											<MdOutlineUploadFile className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
										</div>
									</Dropdown>
								</Tooltip>
							</div>
							<div className="">
								<Tooltip title={"Refresh"}>
									<button
										className={`${btnSecondayClass} w-[36px] h-[36px] relative`}
										// onClick={() => {
										// 	setAddRoleModal(true);
										// }}
									>
										<HiOutlineRefresh className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
									</button>
								</Tooltip>
							</div>
							<div className="flex">
								{/* <PopoverDropdown
														content={
															<StrategyFilter
																props={{
																	form,
																	filtersList,
																	filterHeadings,
																	buttons: {
																		onClear,
																		onFilter,
																	},
																}}
															/>
														}
													/> */}
							</div>
							{/* <div className="">
														<button
															className={`${
																selectedRowsDatas.length !== 0
																	? btnPrimaryClass
																	: btnSecondayClass
															} p-2.5 text-sm`}
															onClick={() => setShowModal(true)}>
															<span className="">
																<DeleteOutlined className="" />
															</span>
														</button>
													</div> */}
						</div>
					</div>
				</div>
			</div>
			<div className="">
				<TableComponent dataSource={dataSource} columns={columns} />
				<TradeDetailDrawer handleClose={handleClose} show={show} />
			</div>
		</>
	);
}
