import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { updateUserStaffDetails } from "../../../Component/helpers/api/userStaff/userStaff";
import { setSelectedUser } from "../../../redux/UserDetails/userDetails.slice";
import { useDispatch } from "react-redux";
import { toastSpecs } from "../../../Utils/utils";

const useUpdateStaffUser = (refetchUser) => {
  const insertNote = useCallback((data) => {
    const { payload, id } = data;
    return updateUserStaffDetails(payload, id);
  }, []);
  const dispatch = useDispatch();

  const updateSecurityMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res, data) => {
      refetchUser();
      const { isModalOpen } = data;
      isModalOpen &&
        dispatch(
          setSelectedUser({
            selectedUser: res?.data?.data,
            isPlanCovered: false,
            isOpen: true,
          })
        );
      // setIsTabed(true);
      // form.resetFields();
      if (res.data.message === "User data update successfully.") {
        return toast.success("updated successfully.", toastSpecs);
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const updateuserData = useCallback(
    (payload, id, isModalOpen) => {
      updateSecurityMutation?.mutate({ payload, id, isModalOpen });
    },
    [updateSecurityMutation]
  );

  const value = useMemo(() => {
    return {
      isLoading: updateSecurityMutation?.isPending,
      updateuserData,
    };
  }, [updateSecurityMutation?.isPending, updateuserData]);

  return value;
};

export default useUpdateStaffUser;
