import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  exportPDF,
  exportToCSV,
  exportToXLS,
  rearrangeDataSource,
  toastSpecs,
} from "../../../Utils/utils";
import { useDispatch } from "react-redux";
import { updateReportPDF } from "../../../redux/ReportPdf.js/reportPDF.slice";

function useExportFiles() {
  const dispatch = useDispatch();
  const insertNote = useCallback((data) => {
    const { InvolkeAPI } = data;
    return InvolkeAPI();
  }, []);

  const exportMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res, data) => {
      const { fileType, fileName, from, addl } = data;
      if (fileType === "PDF") {
        dispatch(
          updateReportPDF(
            res?.data?.orders_data || res?.data?.data || res?.data
          )
        );
        setTimeout(() => {
          exportPDF(fileName);
        }, 1000);
      }
      if (from === "plan" && fileType !== "PDF") {
        const dataSource = (
          res?.data?.orders_data ||
          res?.data?.data ||
          res?.data
        )?.map((item) => {
          return {
            payment_id: item?.payment_details?.id,
            email: item?.userData?.email || "-",
            transaction_id: item?._id,
            plan_name: item?.PlanData?.plan_name,
            billing_amount: item?.PlanData?.price,
            renewal: item?.userSubscriptionPlanData?.expired_at,
          };
        });
        return fileType === "EXCEL"
          ? exportToXLS(dataSource, fileName)
          : fileType === "CSV" && exportToCSV(dataSource, fileName);
      } else {
        return fileType === "EXCEL"
          ? exportToXLS(
              rearrangeDataSource(
                from,
                res?.data?.orders_data || res?.data?.data || res?.data,
                addl
              ),
              fileName
            )
          : fileType === "CSV" &&
              exportToCSV(
                rearrangeDataSource(
                  from,
                  res?.data?.orders_data || res?.data?.data || res?.data,
                  addl
                ),
                fileName
              );
      }

      // : exportPDF1(res?.data?.orders_data || res?.data?.data, fileName);
    },
    onError: (err) => {
      // queryClient.removeQueries(["adminStaffUsers"]);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });
  const exportFiles = (data) => {
    exportMutation.mutate(data);
  };

  return { exportFiles, isLoadingExportCSV: exportMutation?.isPending };
}

export default useExportFiles;
