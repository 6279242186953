import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  checkVerifiedUser,
  resendEmailVerifyLink,
} from "../../helpers/api/Auth/Auth";
import useSearchQuery from "./useSearchQuery";
import { checkTokenExpired, toastSpecs } from "../../../Utils/utils";
import { Link } from "react-router-dom";

const useResendEmailVerifyLink = () => {
  const query = useSearchQuery();
  const decodedToken = checkTokenExpired(query.get("token"));
  const [isExpired, setIsExpired] = useState(false);
  const queryClient = useQueryClient();

  let { data, isLoading } = useQuery({
    queryKey: ["emailVerify"],
    queryFn: async () => {
      if (query.get("token")) {
        return await checkVerifiedUser(query.get("token"), query.get("email"));
      } else {
        return null;
      }
    },
  });

  // console.log("decodedToken", decodedToken);

  const isverified = useMemo(() => {
    return data?.data?.email_verify || data?.data?.status || false;
  }, [data]);

  const isPasswordVerified = useMemo(() => {
    return data?.data?.password_set || false;
  }, [data]);

  const userID = useMemo(() => {
    return data?.data?.userID || false;
  }, [data]);

  // console.log('decodedToken', decodedToken);

  const insertNote = useCallback((email) => {
    return resendEmailVerifyLink(email);
  }, []);

  const message = useMemo(() => {
    if (!query.get("token") && !isverified && !decodedToken)
      return {
        title: "Check Your Email ✉️",
        Description: (
          <>
            An account activation link sent to your email address:{" "}
            {<span className="font-bold">{query.get("email")}</span>} Please
            check your email and click the link to activate your account.
          </>
        ),
        button: "counter",
        backtologin: false,
      };
    if (isverified && !isPasswordVerified && !decodedToken)
      return {
        title: "Email Already Verified ✅",
        Description: "Let's set password to continue.",
        button: "setPassword",
        backtologin: true,
      };
    // if (isverified && !decodedToken)
    //   return {
    //     title: "Email Already Verified ✅",
    //     Description: "Please login to continue.",
    //     button: "login",
    //     backtologin: true,
    //   };
    if (!isverified && !decodedToken)
      return {
        title: "Email verification Link has Expired❗",
        Description: `Your link has expired. Please click on the 'Resend
                          Email' button to receive a new one.`,
        button: "resend_email",
        backtologin: false,
      };
    if (isverified && decodedToken && !isPasswordVerified)
      return {
        title: "Email is verified ✅",
        Description: `Great! Your account is verified. Let's set password to continue.`,
        button: isPasswordVerified ? "setPassword" : "login",
        backtologin: false,
      };
    if (isverified && isPasswordVerified)
      return {
        title: "Email verified and password set successfully ✅",
        Description: `Let's login to continue.`,
        button: "login",
        backtologin: false,
      };
  }, [isverified, decodedToken, query, isPasswordVerified]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {},
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  // const updateQueryParam = (key, value) => {
  //   const searchParams = new URLSearchParams(location.search);

  //   // Set or update the parameter
  //   searchParams.set(key, value);

  //   // Update the URL without reloading
  //   navigate(`${location.pathname}?${searchParams.toString()}`, {
  //     replace: true,
  //   });
  // };

  // console.log('updateQueryParam', updateQueryParam('/auth-verify-email'));

  const ResendLink = useCallback(
    (email) => {
      !isExpired && setIsExpired(true);
      mutation?.mutate(email);
    },
    [mutation, isExpired]
  );

  const backToLogin = useCallback(() => {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Link className={`text-blue-2 hover:text-blue-2`} to="/">
          <i className="fa fa-unlock me-1" aria-hidden="true"></i> Back To Login
        </Link>
      </div>
    );
  }, []);

  const value = useMemo(() => {
    return {
      query,
      isExpired,
      message,
      isLoading,
      queryClient,
      isPasswordVerified,
      userID,
      ResendLink,
      setIsExpired,
      backToLogin,
    };
  }, [
    query,
    isExpired,
    isLoading,
    message,
    isPasswordVerified,
    queryClient,
    userID,
    ResendLink,
    setIsExpired,
    backToLogin,
  ]);

  return value;
};

export default useResendEmailVerifyLink;
