import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Form } from "antd";
import { toast } from "react-toastify";
import { toastSpecs } from "../../../../Utils/utils";
import mydecodedTokenFunction from "../../../../Utils/decodedtoken";
import { sendTicketMessage } from "../../../../Component/helpers/api/TicketSupport/ticket";

const useTicketMessageEditor = (refetch, pagination) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const fileList = Form.useWatch("attachments", form);
  const message = Form.useWatch("message", form);
  const userid = mydecodedTokenFunction(localStorage.getItem("adminToken"));

  const closeModal = () => {
    form.resetFields();
  };

  const insertNote = useCallback(() => {
    const { message, attachments } = form.getFieldsValue();

    const formData = new FormData();
    formData.append("description", message);
    attachments.map((item) =>
      formData.append("attachments", item.originFileObj)
    );
    formData.append("user_id", userid?.id);
    formData.append("ticket_id", id);
    formData.append("replies_by", "admin");

    // console.log('attachments', attachments, formData);
    return sendTicketMessage(formData);
  }, [form, userid, id]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      if (pagination?.page > 1) {
        pagination.setPage(1);
      } else {
        refetch();
      }
      closeModal();
      //   return toast.success('Ticket created successfully.', toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    // console.log('onSubmit', form.getFieldsValue());
    mutation.mutate();
  }, [mutation]);

  const values = useMemo(() => {
    return {
      form,
      fileList,
      message,
      onSubmit,
      isLoading: mutation.isPending,
    };
  }, [form, fileList, message, onSubmit, mutation.isPending]);

  return values;
};

export default useTicketMessageEditor;
