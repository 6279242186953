import { Form, Input } from "antd";
import React, { useCallback, useMemo } from "react";
import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import ReportEmptyIcon from "../../../assets/images/ReportEmptyIcon.svg";
import UpdatedTime from "../../../Component/UpdatedTime";
import TableComponent from "../../../Component/TableComponent";
import { RiSearchLine } from "react-icons/ri";
import { formClass } from "../../../Component/utils/DefaultClasses";
import useStrategyPermission from "./hooks/useStrategyPermission";
import NoDataFoundV2 from "../../../Component/NoDataFoundV2";

function StrategyPermission() {
	const [searchForm] = Form.useForm();

	const {
		data,
		dataCount,
		isLoading,
		currentPage,
		pageLimit,
		adminid,
		columns,
		isRefetching,
		categorySearch,
		setCategorySearch,
		setsearch,
		listData,
		setCurrentPage,
		setPageLimit,
		refetch,
	} = useStrategyPermission();

	const getButtonClass = useCallback(
		(buttonName) => {
			const baseClasses =
				"px-3.5 py-1.5 font-medium duration-150 ease-in-out min-w-[110px]";
			const activeClasses = "bg-blue-2/15 text-blue-2";
			const inactiveClasses =
				"hover:bg-color-base-2 text-color-secondary hover:text-color-primary";

			return categorySearch === buttonName
				? `${baseClasses} ${activeClasses}`
				: `${baseClasses} ${inactiveClasses}`;
		},
		[categorySearch],
	);

	// table Content

	// const itemRender: PaginationProps["itemRender"] = (
	//   _,
	//   type,
	//   originalElement
	// ) => {
	//   if (type === "prev") {
	//     return <ArrowLeftOutlined />;
	//   }
	//   if (type === "next") {
	//     return <ArrowRightOutlined />;
	//   }
	//   return originalElement;
	// };

	console.log("listData(data)", listData(data));

	const strategyList = useMemo(() => {
		if (dataCount === 0 && !isLoading) {
			return (
				<>
					<NoDataFoundV2
						title="No strategies Found."
						icon={
							<svg
								stroke="currentColor"
								fill="currentColor"
								strokeWidth="0"
								viewBox="0 0 256 256"
								height="1em"
								width="1em"
								xmlns="http://www.w3.org/2000/svg"
								className="shrink-0">
								<path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
							</svg>
						}
					/>
				</>
			);
		} else {
			return (
				<div className="">
					<div className="flex align-items-center justify-between mt-4">
						<div className="w-full">
							{/* <Dropdown
                            overlay={allUsersMenu}
                            placement="bottomLeft"
                            arrow
                            overlayClassName="allReportsWrapper"
                          >
                            <Button className="d-flex align-items-center allReports">
                              All Users{" "}
                              <CaretRightOutlined className="rightOutLined" />
                            </Button>
                          </Dropdown> */}
							<div className={`relative flex items-center gap-3 mb-3`}>
								<Form className="" layout="vertical" form={searchForm}>
									<Form.Item
										name="search"
										validateDebounce={1000}
										rules={[
											({ getFieldValue }) => ({
												validator: (_, value) => {
													setsearch(value);
													return Promise.resolve();
												},
											}),
										]}
										className="mb-0">
										<Input
											allowClear
											placeholder="Search"
											// onChange={onSearch}
											// onPressEnter={onSearch}
											className={`${formClass} min-h-[38px]`}
											//   onSearch={() => setExpandSearch(true)}
											suffix={
												<>
													<span className="">
														<RiSearchLine />
													</span>
												</>
											}
										/>
									</Form.Item>
								</Form>
								<div className="">
									<div className="flex">
										<div className="flex items-center rounded-md overflow-hidden border-[1px] border-level-2 divide-x divide-level-2 font-normal">
											<button
												className={getButtonClass("all")}
												onClick={() => {
													setCategorySearch("all");
												}}>
												All
											</button>
											<button
												className={getButtonClass("admin")}
												onClick={() => {
													setCategorySearch("admin");
												}}>
												Admin
											</button>
											<button
												className={getButtonClass("user")}
												onClick={() => setCategorySearch("user")}>
												User
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="w-full">
							<div className="d-flex align-items-center justify-content-end gap-2">
								<div>
									<p className="subHead">Total Strategy: {dataCount}</p>
								</div>
								{/* <div className="py-0.5 hidden lg:flex">
									<span className="flex w-1 h-7 bg-level-2"></span>
								</div> */}

								<div className="py-0.5 hidden lg:flex">
									<span className="flex w-1 h-7 bg-level-2"></span>
								</div>
								<UpdatedTime
									invokeFunction={[refetch]}
									isLoading={isRefetching}
									showOnlyRefreshIcon={true}
								/>
							</div>
						</div>
					</div>
					<div className="">
						<div className="tableSection mb-3">
							<TableComponent
								dataSource={listData(data)}
								columns={columns}
								total={dataCount}
								loading={isLoading}
								// pagination_callback={fetchStaffList}
								className="fs_tables"
								pagination={dataCount < 10 ? false : true}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								pageSize={pageLimit}
								dataTablesRow={(record) =>
									!record.enabled && "disabled-row dataTablesRow"
								}
								setPageSize={setPageLimit}
								// rowClassName={(record) =>
								//   record.disabled
								//     ? "cursor-not-allowed hover:no-underline hover:text-gray-400"
								//     : ""
								// }
								locale={{
									emptyText: (
										<div className="mt-5 mb-5">
											<img
												style={{ width: "100%", maxWidth: "100px" }}
												src={ReportEmptyIcon}
												alt="Empty Icon"
											/>
											<h5 className="mt-4">
												No Users Found. Please Generate New Report.
											</h5>
										</div>
									),
								}}
							/>
						</div>
					</div>
				</div>
			);
		}
	}, [
		columns,
		data,
		dataCount,
		isLoading,
		currentPage,
		pageLimit,
		searchForm,
		getButtonClass,
		setCategorySearch,
		setsearch,
		listData,
		setCurrentPage,
		setPageLimit,
	]);

	return (
		<React.Fragment>
			<div className="">
				<div className="layout-row flex" id="main">
					<div className=" ">
						<Leftbar type="admin" />
					</div>
					<div className="left__bar w-full">
						<RightBar
							leftContent={
								<>
									<div className="d-flex align-items-center">
										{/* <div className="leftContent">
													Account Id: {adminid.id}
												</div> */}
										<div className="flex gap-2">
											<div className="">
												<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
													<svg
														stroke="currentColor"
														fill="currentColor"
														strokeWidth="0"
														viewBox="0 0 256 256"
														height="1em"
														width="1em"
														xmlns="http://www.w3.org/2000/svg"
														className="shrink-0">
														<path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
													</svg>
												</span>
											</div>
											<div className="">
												<div className="flex gap-2">
													<div className="flex flex-col gap-0 -space-y-1">
														<h2 className="strtgy__ttl">List Of Strategy</h2>
														<span className="text-sm">
															Account Id: {adminid.id}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
							rightContent={
								<>
									<UpdatedTime
										invokeFunction={[refetch]}
										isLoading={isRefetching}
										justText={true}
									/>
								</>
							}>
							<>
								{/* <div className="headingSection mb-4 mt-4">
											<h2 className="d-flex align-items-center">
												<span className="heading">List Of Strategy</span>
											</h2>
											<p className="subHead">
												You have total {data1?.countData} strategy.
											</p>
										</div> */}
								{strategyList}
							</>
						</RightBar>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default StrategyPermission;
