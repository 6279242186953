import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import {
  updateUserStaffDetails,
  updatetraderStaffDetails,
} from "../../../Component/helpers/api/userStaff/userStaff";
import { setSelectedUser } from "../../../redux/UserDetails/userDetails.slice";
import { useDispatch } from "react-redux";
import { toastSpecs } from "../../../Utils/utils";

const useTradeUser = (refetchUser) => {
  const insertNote = useCallback((data) => {
    const { payload, id } = data;
    return updatetraderStaffDetails(payload, id);
  }, []);
  const dispatch = useDispatch();

  const updateSecurityMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      console.log("res", res);
      refetchUser();
      dispatch(
        setSelectedUser({
          selectedUser: res?.data?.data,
          isPlanCovered: true,
          isOpen: true,
          planDetail: res?.data?.active_plan?.[0],
        })
      );
      if (res.data.message === "User data update successfully.") {
        return toast.success("updated successfully.", toastSpecs);
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const updateuserData = useCallback(
    (payload, id) => {
      updateSecurityMutation?.mutate({ payload, id });
    },
    [updateSecurityMutation]
  );

  const value = useMemo(() => {
    return {
      isLoading: updateSecurityMutation?.isPending,
      updateuserData,
    };
  }, [updateSecurityMutation?.isPending, updateuserData]);

  return value;
};

export default useTradeUser;
