import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  profileDetailsOfUser: {},
  // plan_type: '',
  // make_userRestrict: false,
  featuresPlanData: [],
  showBillingDetails: {},
  // remindLaterStrip: false,
  showSubscription: false,
};

export const profileSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    completeUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    // userPlan: (state, action) => {
    //   if (action.payload === 'free' || action.payload === 'signup_trial')
    //     state.plan_type = 'free_plan';
    // },
    userFeaturesData: (state, { payload }) => {
      //   console.log("payload", payload);
      state.featuresPlanData = payload;
    },
    userBillingDeatils: (state, { payload }) => {
      state.showBillingDetails = payload;
    },
    userProfileDetails: (state, { payload }) => {
      state.profileDetailsOfUser = payload;
    },
    // userRemainder: (state, { payload }) => {
    //   state.remindLaterStrip = payload;
    // },
    showPlansModal: (state, { payload }) => {
      state.showSubscription = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  completeUserDetails,
  // userPlan,
  userFeaturesData,
  userProfileDetails,
  userBillingDeatils,
  // userRemainder,
  showPlansModal,
} = profileSlice.actions;

const profileDetailsReducer = profileSlice.reducer;

export default profileDetailsReducer;
