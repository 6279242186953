import { Checkbox, Form, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import {
	btnPrimaryClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import { Logo, SpinnerBtn } from "../../../Component/utils/Icons";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import RadioSelect from "../../../Component/RadioSelect";
import useLogin from "./hooks/useLogin";
import { EMAIL_REG } from "../../../Utils/utils";
import { checkStaffEmailLogin } from "../../../Component/helpers/api/Auth/Auth";

function AdminLogin() {
	const { form, userDetails, isLoading, plans, setEmailErrors, loginHandle } =
		useLogin();

	return (
		<React.Fragment>
			<div className="bg-color-base-2/30 dark:bg-color-base-4">
				<section className="container-xxl">
					<div className="min-h-screen flex justify-center items-center relative z-10">
						<div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
							<Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden">
								<Card.Body className="p-[2rem]">
									<div className="flex justify-center mb-4 mt-2">
										<div className="appBrand max-w-[180px]">
											<Logo />
										</div>
									</div>
									<div className="pt-2 mb-4 flex flex-col">
										<h2 className="mb-1 text-lg text-color-primary">
											Hello! Welcome Back 👋
										</h2>
										<p class="text-[13px] text-color-secondary/90">
											Please sign-in to your admin account and start the
											adventure
										</p>
									</div>
									<div>
										<Form
											className=""
											layout="vertical"
											form={form}
											onFinish={loginHandle}
											disabled={isLoading}
											initialValues={{
												email: userDetails?.email || undefined,
												password: userDetails?.password || undefined,
												remember: false,
												role: userDetails?.role || plans[0]?.value || undefined,
											}}>
											<Form.Item
												// label=""
												name="role"
												rules={[
													{
														required: true,
														message: "Please select login type.",
													},
												]}>
												{/* <div className="mb-2"> */}
												<RadioSelect
													props={{
														value: userDetails?.role || plans[0]?.value,
														plans,
														// name: "role",
														form,
													}}
												/>
												{/* </div> */}
											</Form.Item>
											<Form.Item
												// label="Email *"
												label={
													<>
														<span className="flex items-center gap-0.5">
															Email
															<span className="text-red-2">*</span>
														</span>
													</>
												}
												name="email"
												rules={[
													{
														required: true,
														message: "Email is Required.",
													},
													({ getFieldValue }) => ({
														validator: async (_, value) => {
															if (!value || !EMAIL_REG.test(value))
																return Promise.resolve(); // Initial condition

															// const res = await check_email_while_login(value);
															if (getFieldValue("role") !== "admin") {
																const res = await checkStaffEmailLogin(value);
																if (!res?.data?.status) {
																	return Promise.reject([
																		setEmailErrors(res?.data?.message),
																	]);
																} // Email registered as both
															}
															return Promise.resolve();
														},
													}),
												]}>
												<Input
													className={`${formClass}`}
													placeholder="Email"
													name="email"
													type="email"
												/>
												{/* {EmailError ? (
                          <>
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div
                                className="ant-form-item-explain-error"
                                style={{ marginTop: "0px" }}
                              >
                                {EmailError}
                              </div>
                            </div>
                          </>
                        ) : null} */}
											</Form.Item>

											<Form.Item
												// label="Password *"
												label={
													<>
														<span className="flex items-center gap-0.5">
															Password
															<span className="text-red-2">*</span>
														</span>
													</>
												}
												name="password"
												className="mb-0"
												rules={[
													{
														required: true,
														message: "Password is Required.",
													},
												]}>
												<Input.Password
													className={`${formClass}`}
													placeholder="Password"
													name="password"
													type="password"
													iconRender={(visible) =>
														visible ? (
															<span>
																<FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
															</span>
														) : (
															<span>
																<FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
															</span>
														)
													}
												/>
												{/* {PassError ? (
                          <>
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div
                                className="ant-form-item-explain-error"
                                style={{ marginTop: "0px" }}
                              >
                                {PassError}
                              </div>
                            </div>
                          </>
                        ) : null} */}
											</Form.Item>
											<div className="d-flex align-items-center justify-content-between">
												<Form.Item
													name="remember"
													valuePropName="checked"
													className="mb-0">
													<Checkbox className="rememberMe">
														Remember me
													</Checkbox>
												</Form.Item>
												<Form.Item
													valuePropName="checked"
													className="mb-0"
													shouldUpdate>
													{() => {
														return (
															<Form.Item
																name="forgotLink"
																valuePropName="checked"
																className="mb-0"
																hidden={form.getFieldValue("role") === "admin"}>
																<Link
																	className="forgotPassword"
																	to="/forgot-password">
																	Forgot Password?
																</Link>
															</Form.Item>
														);
													}}
												</Form.Item>
											</div>
											<div className="text-center mt-3.5">
												<Form.Item>
													<button
														className={`${btnPrimaryClass} w-full`}
														htmlType="submit"
														// onClick={loginHandle}
														disabled={isLoading}
														//style={{ width: "100%" }}
													>
														{isLoading ? (
															<SpinnerBtn className={"text-lg"} />
														) : (
															"LOG IN"
														)}
													</button>
												</Form.Item>
												{/* <div className="text-color-primary">
													<Link
														to={"/signup"}
														className={`text-blue-2 hover:text-blue-2`}>
														Staff Login
													</Link>
												</div> */}
											</div>
										</Form>
									</div>
								</Card.Body>
							</Card>
						</div>
						{/* <div className="absolute bottom-3 right-3">
            <a href="#" className="text-color-primary hover:text-blue-3">
              Need Help?
            </a>
          </div> */}
					</div>
				</section>
			</div>
			{/* <section className="loginSection">
				<div className="logoOverlay d-none d-lg-block">
					<img src={logo} />
				</div>
				<div className="container">
					<div className="loginPage">
						<div className="row justify-content-center">
							<div className="col-12"></div>
							<div className="col-12 col-lg-4 col-xl-3">
								<div className="d-lg-none text-center">
									<img className="mobile_logo" src={dark_logo} />
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xl-4">
								<div className="text-center mt-5">
									<h1>Admin Login</h1>
								</div>

								<Form className="mt-5" layout="vertical">
									<Form.Item label="EMAIL" name="email">
										<Input
											className="form-control"
											placeholder="Enter your mail"
											name="email"
											type="email"
											value={email}
											onChange={handleEmail}
											defaultValue={value ? value.email : ""}
										/>
										{EmailError ? (
											<>
												<div className="ant-form-item-explain ant-form-item-explain-connected">
													<div
														className="ant-form-item-explain-error"
														style={{ marginTop: "0px" }}>
														{EmailError}
													</div>
												</div>
											</>
										) : null}
									</Form.Item>

									<Form.Item label="PASSWORD" name="password">
										<Input
											className="form-control"
											placeholder="Enter your password"
											name="password"
											type="password"
											value={password}
											onChange={handlePassword}
											defaultValue={value ? value.password : ""}
										/>
										{PassError ? (
											<>
												<div className="ant-form-item-explain ant-form-item-explain-connected">
													<div
														className="ant-form-item-explain-error"
														style={{ marginTop: "0px" }}>
														{PassError}
													</div>
												</div>
											</>
										) : null}
									</Form.Item>
									<div className="d-flex align-items-center justify-content-between">
										<Form.Item
											name="remember"
											valuePropName="checked"
											className="mb-0">
											<Checkbox
												className="rememberMe"
												onClick={() =>
													rememberme
														? setRememberMe(false)
														: setRememberMe(true)
												}>
												Remember me
											</Checkbox>
										</Form.Item>
										<a className="forgotPassword" href="/forgot-password">
											Forgot Password?
										</a>
									</div>
									<div className="text-center mt-5">
										<Form.Item>
											<Button
												type="primary"
												htmlType="submit"
												className="allBtns m-auto"
												onClick={loginHandle}
												loading={loading}
												//style={{ width: "100%" }}
											>
												{loading ? "      " : "LOG IN"}
											</Button>
										</Form.Item>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</React.Fragment>
	);
}

export default AdminLogin;
