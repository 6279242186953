import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { addRoleInitialState } from "../../../redux/AddRole/addRole.slice";
import { Form } from "antd";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import {
  addRolesAPI,
  updateRolesAPI,
} from "../../helpers/api/RolesPermissions/rolesPermissions";
import { addRoleInitialData, toastSpecs } from "../../../Utils/utils";

const useAddRole = (closeModal) => {
  const plainOptions = useMemo(() => ["Access", "Edit", "Create"], []);
  const initialState = useSelector(addRoleInitialState);
  const [form] = Form.useForm();

  const isAllFieldsChecked = useCallback(
    (index, isData) => {
      const { administrators } = form.getFieldsValue();
      const mainData = administrators || initialState?.data;
      const Roledata = isData || mainData?.[index]?.roles;

      return Roledata?.every((item) => item.value?.length === 3);
    },
    [form, initialState]
  );

  const checkIsIntermediate = useCallback(
    (index, isData) => {
      const { administrators } = form.getFieldsValue();
      const mainData = administrators || initialState?.data;
      const Roledata = isData || mainData?.[index]?.roles;
      const isIndeterminate = Roledata?.some((item) => item.value?.length > 0);
      const checkedAll = Roledata?.every((item) => item.value?.length === 3);
      if (isIndeterminate && !checkedAll) return true;
      return false;
    },
    [form, initialState]
  );

  const onChangeCheckbox = useCallback(
    (index, modifiedList) => {
      const { administrators } = form.getFieldsValue();
      const initialValues = administrators?.map((data, idx) => {
        if (index === idx) {
          if (
            checkIsIntermediate(index, modifiedList) === false &&
            isAllFieldsChecked(index, modifiedList) === false
          ) {
            return {
              ...data,
              roles: modifiedList,
              isSelected: false,
            };
          }
          return {
            ...data,
            roles: modifiedList,
          };
        } else {
          return data;
        }
      });

      form.setFieldValue("administrators", initialValues);
    },
    [form, checkIsIntermediate, isAllFieldsChecked]
  );

  const handleCheckAllChange = useCallback(
    (index) => {
      const { administrators } = form.getFieldsValue();
      const updateRoles = (item, status) => {
        return item.map((role) => {
          return { ...role, value: status ? ["Access", "Edit", "Create"] : [] };
        });
      };
      const data = administrators?.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            isSelected: !item?.isSelected,
            roles: updateRoles(item?.roles, !item?.isSelected),
          };
        } else {
          return {
            ...item,
            isSelected: false,
            roles: updateRoles(item?.roles, false),
          };
        }
      });
      form.setFields([{ name: "errorforlist", errors: [] }]);
      form.setFieldValue("administrators", data);
    },
    [form]
  );

  const addRoles = useCallback(() => {
    form.submit();
  }, [form]);

  const insertNote = useCallback(() => {
    let payload = encodeDecodeAddRoleData(form.getFieldsValue(), "encode");
    // console.log("paylasad", payload);
    if (initialState?.selectionType === "edit") {
      payload = { ...payload, role_id: initialState?.role_id };
      return updateRolesAPI(payload);
    } else {
      return addRolesAPI(payload);
    }
  }, [form, initialState]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      closeModal(true);
      return toast.success(
        initialState?.selectionType === "edit"
          ? "Updated successfully."
          : "Role added successfully.",
        toastSpecs
      );
    },
    onError: (err) => {
      console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  const values = useMemo(() => {
    return {
      plainOptions,
      initialState,
      form,
      isLoading: mutation?.isPending,
      modalAccessType: initialState?.selectionType,
      isAllFieldsChecked,
      checkIsIntermediate,
      onChangeCheckbox,
      handleCheckAllChange,
      addRoles,
      onSubmit,
    };
  }, [
    plainOptions,
    initialState,
    form,
    mutation?.isPending,
    isAllFieldsChecked,
    checkIsIntermediate,
    onChangeCheckbox,
    handleCheckAllChange,
    addRoles,
    onSubmit,
  ]);

  return values;
};

export default useAddRole;

export const encodeDecodeAddRoleData = (data, type) => {
  if (type === "encode") {
    const { role_name, administrators } = data;
    const getAccessFor = () => {
      return administrators?.filter((item) => item?.isSelected)?.[0];
    };
    const modifuRoles = (roles) => {
      const updatedArray = [];
      roles?.map((item) => {
        updatedArray?.push({
          [item?.key]:
            item.value?.length > 0
              ? item.value.map((role) => role.toLowerCase())
              : item.value,
        });
        return item;
      });
      return updatedArray;
    };
    const encodeData = {
      role_name: role_name,
      access_for:
        getAccessFor()?.name === "Administrator access"
          ? "administrator"
          : getAccessFor()?.name === "Trader access"
          ? "trader"
          : "",
      role_data: modifuRoles(getAccessFor()?.roles),
    };
    return encodeData;
  } else {
    const { role_name, role_data, access_for, _id } = data;

    const updateRolesValue = () => {
      return role_data.map((item) => ({
        name: updateRoleName(Object.keys(item)[0]),
        key: Object.keys(item)[0],
        value: item[Object.keys(item)[0]].map(
          (value) => value.charAt(0).toUpperCase() + value.slice(1)
        ),
      }));
    };

    const updatedData = {
      name:
        access_for === "administrator"
          ? "Administrator access"
          : access_for === "trader"
          ? "Trader access"
          : "",
      isSelected: true,
      roles: updateRolesValue(),
      role_name: role_name,
      role_id: _id,
    };

    const decodeData = addRoleInitialData()?.map((item) => {
      let access =
        access_for === "administrator"
          ? "Administrator access"
          : access_for === "trader" && "Trader access";
      if (item?.name === access) {
        return updatedData;
      } else {
        return item;
      }
    });

    return decodeData;
  }
};

export const updateRoleName = (name) => {
  switch (name) {
    case "user_management":
      return "User Management";
    case "strategy_management":
      return "Strategy Management";
    case "roles_permission_management":
      return "Roles and Permission Management";
    case "trader_management":
      return "Trader Management";
    case "trades_management":
      return "Trades Management";
    case "plans_management":
      return "Plans Management";
    case "virtual_trade":
      return "Virtual Trade";
    case "live_trade":
      return "Live Trade";
    case "strategy":
      return "Strategy";
    case "chart":
      return "Chart";
    case "broker":
      return "Broker";
    default:
      return "";
  }
};
