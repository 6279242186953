import React from "react";
import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import {
	btnPrimaryClass,
	formClass,
} from "../../../Component/utils/DefaultClasses";
import { Logo, SpinnerBtn } from "../../../Component/utils/Icons";
import { Card } from "react-bootstrap";
import useForgotPassword from "./hooks/useForgotPassword";
import { EMAIL_REG } from "../../../Utils/utils";
import { checkStaffEmailLogin } from "../../../Component/helpers/api/Auth/Auth";

function ForgotPassword() {
	const { form, isLoading, submitForm, setEmailErrors } = useForgotPassword();

	return (
		<React.Fragment>
			<div className="bg-color-base-2/30 dark:bg-color-base-4">
				<section className="container-xxl">
					<div className="min-h-screen flex justify-center items-center relative z-10">
						<div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
							<Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden relative z-10">
								<Card.Body className="p-[2rem]">
									<div className="flex justify-center mb-4 mt-2">
										<div className="appBrand max-w-[180px]">
											<Logo />
										</div>
									</div>
									<div className="pt-2 mb-4 flex flex-col">
										<h2 className="mb-1 text-lg text-color-primary">
											Forgot Password? 🔒
										</h2>
										<p class="text-[13px] text-color-secondary/90">
											Enter your email and we'll send you instructions to reset
											your password.
										</p>
									</div>
									<div>
										<Form
											form={form}
											layout="vertical"
											onFinish={() =>
												submitForm(
													form.getFieldValue("email"),
													`/admin-reset-password?email=${form.getFieldValue(
														"email",
													)}`,
												)
											}>
											<Form.Item
												label="Email"
												name="email"
												// value={email}
												rules={[
													{
														required: true,
														message: "Email field required.",
													},
													({ getFieldValue }) => ({
														validator: async (_, value) => {
															if (!value || !EMAIL_REG.test(value))
																return Promise.resolve(); // Initial condition

															// const res = await check_email_while_login(value);
															if (getFieldValue("role") !== "admin") {
																const res = await checkStaffEmailLogin(value);
																if (
																	!res?.data?.status &&
																	res?.data?.message ===
																		"Email ID does not exist in our platform."
																) {
																	return Promise.reject([
																		setEmailErrors(res?.data?.message),
																	]);
																} // Email registered as both
															}
															return Promise.resolve();
														},
													}),
												]}>
												<Input
													className={`${formClass}`}
													placeholder="Email"
													// onChange={(e) => {
													//   setEmailError("");
													//   return setEmail(e.target.value);
													// }}
												/>
												{/* {EmailError ? (
                          <>
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div
                                className="ant-form-item-explain-error"
                                style={{ marginTop: "0px" }}
                              >
                                {EmailError}
                              </div>
                            </div>
                          </>
                        ) : null} */}
											</Form.Item>
											<div className="text-center mt-3.5">
												<Form.Item>
													<Button
														type="primary"
														htmlType="submit"
														className={`${btnPrimaryClass} w-full`}
														disabled={isLoading}>
														{isLoading ? (
															<SpinnerBtn className={"text-lg"} />
														) : (
															"Send Reset Link"
														)}
													</Button>
												</Form.Item>
											</div>

											<div className="d-flex justify-content-center">
												<Link
													className={`text-blue-2 hover:text-blue-2`}
													to="/">
													<i
														className="fa fa-unlock me-1"
														aria-hidden="true"></i>{" "}
													Back To Login
												</Link>
											</div>
										</Form>
									</div>
								</Card.Body>
							</Card>
						</div>
						{/* <div className="absolute bottom-3 right-3">
            <a href="#" className="text-color-primary hover:text-blue-3">
              Need Help?
            </a>
          </div> */}
					</div>
				</section>
			</div>
			{/* <section className="loginSection">
				<div className="logoOverlay d-none d-lg-block">
					<img src={logo} />
				</div>
				<div className="container">
					<div className="loginPage">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="d-flex align-items-center justify-content-end">
									<a href="#" className="ndHl me-3">
										Need Help?
									</a>
								</div>
							</div>
							<div className="col-12 col-lg-4 col-xl-3">
								<div className="d-lg-none text-center">
									<img className="mobile_logo" src={dark_logo} />
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xl-4">
								<div className="forgotForm">
									<Form
										form={form}
										className="mt-5"
										layout="vertical"
										onFinish={submitForm}>
										<Form.Item label="EMAIL" name="email" value={email}>
											<Input
												className="form-control"
												placeholder="Enter your mail"
												onChange={(e) => {
													setEmailError("");
													return setEmail(e.target.value);
												}}
											/>
											{EmailError ? (
												<>
													<div className="ant-form-item-explain ant-form-item-explain-connected">
														<div
															className="ant-form-item-explain-error"
															style={{ marginTop: "0px" }}>
															{EmailError}
														</div>
													</div>
												</>
											) : null}
										</Form.Item>
										<div className="text-center mt-5">
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													className="allBtns"
													onClick={forgetPassword}
													loading={loading}>
													{loading ? "        " : "Continue"}
												</Button>
											</Form.Item>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</React.Fragment>
	);
}

export default ForgotPassword;
