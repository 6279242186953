import { Navigate } from "react-router-dom";
import { history } from "./history";
import useDecodedToken from "./useDecodedToken";
import CopyRightFooter from "../Common/CopyRightFooter";

function PrivateRouteLoggedOut({ children }) {
	const decodedtoken = useDecodedToken();

	return (
		<>
			<div>
				{children}
				<div className="shrink-0 flex justify-center 2xl:-mt-6">
					<CopyRightFooter />
				</div>
			</div>
		</>
	);
}

export default PrivateRouteLoggedOut;
