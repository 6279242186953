import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedStrategy: {},
  isOpen: false,
};

const selectedStrategySlice = createSlice({
  name: "selectedStrategy",
  initialState,
  reducers: {
    setSelectedStrategy: (state, { payload }) => {
      state.selectedStrategy = payload?.selectedStrategy;
      state.isOpen = payload?.isOpen;
    },
  },
});

let selectedStrategyReducer = selectedStrategySlice.reducer;

export const { setSelectedStrategy } = selectedStrategySlice.actions;

const selectedStrategyData = (state) => state.selectedStrategyData;

export { selectedStrategyReducer, selectedStrategyData };
