import React from "react";
// import { afterBorder } from '../../../components/utils/defaultClasses';

export default function SupportChatHeader({ selectedTicket }) {
  return (
    <>
      {/* <div className={`p-3.5 relative ${afterBorder}`}> */}
      <div className={`p-3.5 relative`}>
        <div className="space-y-1">
          <h4 className="font-medium">{selectedTicket?.title ?? "-"}</h4>
          <div className="flex items-center gap-2">
            <span
              className={`${
                selectedTicket.status === "In Progress"
                  ? "bg-blue-2/15 text-blue-2 "
                  : selectedTicket.status === "Closed"
                  ? "text-orange-400 bg-orange-100"
                  : selectedTicket.status === "Open"
                  ? "bg-green-3/20 text-green-3"
                  : "bg-red-2/15 text-red-2"
              } rounded-md px-1.5 text-sm`}
            >
              {selectedTicket?.status ?? "-"}
            </span>
            <span className="bg-orange-400/20 text-orange-600 rounded-md px-1.5 text-sm">
              {selectedTicket?.category ?? "-"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
