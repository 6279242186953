import { useEffect, useState } from "react";

export function Delete({ className, ondel, id, confirmDelete }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 23 28"
      fill="currentColor"
      onClick={() => {
        //console.log("here")
        ondel(id);
        confirmDelete(true);
      }}
    >
      <path
        d="M11.482 0C13.9813 0 16.0184 1.97895 16.1118 4.45538L16.1151 4.63309H21.9568C22.5131 4.63309 22.964 5.08403 22.964 5.64029C22.964 6.16178 22.5677 6.5907 22.0598 6.64228L21.9568 6.64748H21.0778L19.7054 24.8333C19.5706 26.6195 18.082 28 16.2907 28H6.67336C4.88205 28 3.39342 26.6195 3.25861 24.8333L1.88547 6.64748H1.00719C0.485702 6.64748 0.0567784 6.25115 0.00519995 5.74327L0 5.64029C0 5.1188 0.396331 4.68987 0.904215 4.63829L1.00719 4.63309H6.84892C6.84892 2.07431 8.92323 0 11.482 0ZM19.0578 6.64748H3.90622L5.26729 24.6817C5.3228 25.4172 5.93576 25.9856 6.67336 25.9856H16.2907C17.0283 25.9856 17.6412 25.4172 17.6967 24.6817L19.0578 6.64748ZM14.1007 10.8777C14.6222 10.8777 15.0511 11.274 15.1027 11.7819L15.1079 11.8849V20.7482C15.1079 21.3045 14.657 21.7554 14.1007 21.7554C13.5792 21.7554 13.1503 21.3591 13.0987 20.8512L13.0935 20.7482V11.8849C13.0935 11.3286 13.5445 10.8777 14.1007 10.8777ZM8.86331 10.8777C9.3848 10.8777 9.81372 11.274 9.8653 11.7819L9.8705 11.8849V20.7482C9.8705 21.3045 9.41957 21.7554 8.86331 21.7554C8.34182 21.7554 7.91289 21.3591 7.86132 20.8512L7.85612 20.7482V11.8849C7.85612 11.3286 8.30705 10.8777 8.86331 10.8777ZM11.482 2.01439C10.0856 2.01439 8.94449 3.10736 8.86745 4.48449L8.86331 4.63309H14.1007C14.1007 3.18682 12.9283 2.01439 11.482 2.01439Z"
        // fill="#D8D7D7"
      />
    </svg>
  );
}

export function PlusIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className={className}
    >
      <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
    </svg>
  );
}

export function CrossIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className={className}
    >
      <path
        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RightArrow({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className={className}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
    </svg>
  );
}

export function Calendar({ className }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38.8999 2.8H33.9999V0.7C33.9999 0.3129 33.687 0 33.2999 0H28.3999C28.0128 0 27.6999 0.3129 27.6999 0.7V2.8H12.2999V0.7C12.2999 0.3129 11.987 0 11.5999 0H6.6999C6.3128 0 5.9999 0.3129 5.9999 0.7V2.8H1.0999C0.712802 2.8 0.399902 3.1129 0.399902 3.5V11.2V41.3C0.399902 41.6871 0.712802 42 1.0999 42H38.8999C39.287 42 39.5999 41.6871 39.5999 41.3V11.2V3.5C39.5999 3.1129 39.287 2.8 38.8999 2.8ZM29.0999 1.4H32.5999V3.5V5.6H29.0999V3.5V1.4ZM7.3999 1.4H10.8999V3.5V5.6H7.3999V3.5V1.4ZM1.7999 4.2H5.9999V6.3C5.9999 6.6871 6.3128 7 6.6999 7H11.5999C11.987 7 12.2999 6.6871 12.2999 6.3V4.2H27.6999V6.3C27.6999 6.6871 28.0128 7 28.3999 7H33.2999C33.687 7 33.9999 6.6871 33.9999 6.3V4.2H38.1999V10.5H1.7999V4.2ZM1.7999 40.6V11.9H38.1999V40.6H1.7999Z" />
      <path d="M25.6 16.1001H20.7H19.3H14.4H13H6.69995V22.4001V23.8001V28.7001V30.1001V36.4001H13H14.4H19.3H20.7H25.6H27H33.3V30.1001V28.7001V23.8001V22.4001V16.1001H27H25.6ZM20.7 17.5001H25.6V22.4001H20.7V17.5001ZM25.6 28.7001H20.7V23.8001H25.6V28.7001ZM14.4 23.8001H19.3V28.7001H14.4V23.8001ZM14.4 17.5001H19.3V22.4001H14.4V17.5001ZM8.09995 17.5001H13V22.4001H8.09995V17.5001ZM8.09995 23.8001H13V28.7001H8.09995V23.8001ZM13 35.0001H8.09995V30.1001H13V35.0001ZM19.3 35.0001H14.4V30.1001H19.3V35.0001ZM25.6 35.0001H20.7V30.1001H25.6V35.0001ZM31.9 35.0001H27V30.1001H31.9V35.0001ZM31.9 28.7001H27V23.8001H31.9V28.7001ZM31.9 17.5001V22.4001H27V17.5001H31.9Z" />
    </svg>
  );
}

export function UpArrow({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      className={className}
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="17 8 12 3 7 8"></polyline>
      <line x1="12" y1="3" x2="12" y2="15"></line>
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    </svg>
  );
}

export function DownArrow({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      className={className}
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  );
}

export function CheckLine({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className={className}
    >
      <path
        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
        fill="rgba(255,255,255,1)"
      />
    </svg>
  );
}

export function BsInfoCircle({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
    </svg>
  );
}

export function BsBell({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 34 45"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M19.2418 2.27848C19.2418 1.02012 18.2381 0 17 0C15.7618 0 14.7582 1.02012 14.7582 2.27848V3.98734H13.5086C8.52041 3.98734 4.39191 7.92914 4.08055 12.989L3.58509 21.0399C3.3962 24.1095 2.38225 27.0677 0.653694 29.592C-0.908122 31.8728 0.482287 35.023 3.19695 35.3541L10.835 36.2857V38.7342C10.835 42.1947 13.5951 45 17 45C20.4048 45 23.1649 42.1947 23.1649 38.7342V36.2857L30.803 35.3541C33.5178 35.023 34.9081 31.8728 33.3463 29.592C31.6176 27.0677 30.6037 24.1095 30.4149 21.0399L29.9195 12.989C29.6081 7.92914 25.4796 3.98734 20.4914 3.98734H19.2418V2.27848ZM13.5086 7.40506C10.2961 7.40506 7.63721 9.94368 7.43668 13.2024L6.94124 21.2532C6.71392 24.9471 5.49382 28.5065 3.41377 31.5442C3.3009 31.7092 3.40138 31.9368 3.5976 31.9607L11.9767 32.9826C15.3135 33.3895 18.6865 33.3895 22.0232 32.9826L30.4023 31.9607C30.5985 31.9368 30.6992 31.7092 30.5862 31.5442C28.5062 28.5065 27.286 24.9471 27.0587 21.2532L26.5633 13.2024C26.3626 9.94368 23.7039 7.40506 20.4914 7.40506H13.5086ZM17 41.5823C15.4522 41.5823 14.1977 40.3072 14.1977 38.7342V37.0253H19.8022V38.7342C19.8022 40.3072 18.5477 41.5823 17 41.5823Z"
      />
    </svg>
  );
}

export function EditableBttn({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 22 21"
      fill="currentColor"
      className={className}
    >
      <path d="M9.86084 6.22003L7.82355 8.25732C7.07297 9.0079 6.3181 9.75848 5.57181 10.5176C5.39596 10.6978 5.263 10.938 5.21153 11.1739C4.94132 12.4134 4.67969 13.6529 4.41806 14.8925L4.27223 15.5787C4.20361 15.9132 4.29368 16.2478 4.521 16.4751C4.69685 16.651 4.94132 16.7496 5.19866 16.7496C5.27158 16.7496 5.34449 16.741 5.41741 16.7282L6.17227 16.5695C7.3732 16.3164 8.57842 16.0591 9.77935 15.8103C10.0581 15.7503 10.294 15.6259 10.4956 15.4243C13.8668 12.0488 17.238 8.67764 20.6092 5.31076C20.8579 5.06199 20.9952 4.76605 21.0295 4.40148C21.0338 4.34143 21.0295 4.28139 21.0166 4.22563C20.9952 4.13985 20.978 4.04978 20.9566 3.964C20.9094 3.74526 20.8579 3.4965 20.755 3.25631C20.1245 1.8152 19.078 0.785828 17.6454 0.206809C17.3581 0.0910052 17.0535 0.0524039 16.7876 0.0180917L16.7147 0.00951359C16.3287 -0.0376657 15.977 0.0910052 15.6725 0.399815C13.7424 2.33846 11.7995 4.28139 9.86084 6.22003ZM16.5732 1.04317C16.5818 1.04317 16.586 1.04317 16.5946 1.04317L16.6675 1.05175C16.8906 1.07748 17.0964 1.10322 17.2594 1.17184C18.426 1.64363 19.2838 2.48428 19.8028 3.66806C19.8629 3.80531 19.9015 3.98116 19.9401 4.16558C19.9529 4.23421 19.9701 4.30283 19.983 4.37146C19.9658 4.44866 19.9358 4.50013 19.8757 4.55589C16.5003 7.92278 13.1291 11.2982 9.7579 14.6694C9.70215 14.7252 9.65068 14.7509 9.57348 14.7681C8.36826 15.0211 7.16733 15.2742 5.96211 15.5272L5.32734 15.6602L5.44743 15.0983C5.70906 13.8631 5.97069 12.6236 6.23661 11.3883C6.24519 11.3497 6.2795 11.2897 6.3181 11.2468C7.06439 10.4919 7.81068 9.74132 8.56126 8.99503L10.5986 6.95774C12.5415 5.01481 14.4844 3.07188 16.4231 1.12466C16.5045 1.05604 16.5432 1.04317 16.5732 1.04317Z" />
      <path d="M1.66843 4.67592H9.15708C9.44873 4.67592 9.68463 4.44002 9.68463 4.14837C9.68463 3.85672 9.44873 3.62082 9.15708 3.62082H1.66843C0.746291 3.62082 0 4.3714 0 5.28925V19.3315C0 20.2537 0.75058 21 1.66843 21H15.7064C16.6286 21 17.3749 20.2494 17.3749 19.3315V12.1474C17.3749 11.8558 17.139 11.6199 16.8473 11.6199C16.5557 11.6199 16.3198 11.8558 16.3198 12.1474V19.3315C16.3198 19.6704 16.041 19.9492 15.7021 19.9492H1.66843C1.3296 19.9492 1.05081 19.6704 1.05081 19.3315V5.29354C1.05081 4.95471 1.3296 4.67592 1.66843 4.67592Z" />
    </svg>
  );
}
export function WarningIcon({ className }) {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 256 256"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M225.86,102.82c-3.77-3.94-7.67-8-9.14-11.57-1.36-3.27-1.44-8.69-1.52-13.94-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52-3.56-1.47-7.63-5.37-11.57-9.14C146.28,23.51,138.44,16,128,16s-18.27,7.51-25.18,14.14c-3.94,3.77-8,7.67-11.57,9.14C88,40.64,82.56,40.72,77.31,40.8c-9.76.15-20.82.31-28.51,8S41,67.55,40.8,77.31c-.08,5.25-.16,10.67-1.52,13.94-1.47,3.56-5.37,7.63-9.14,11.57C23.51,109.72,16,117.56,16,128s7.51,18.27,14.14,25.18c3.77,3.94,7.67,8,9.14,11.57,1.36,3.27,1.44,8.69,1.52,13.94.15,9.76.31,20.82,8,28.51s18.75,7.85,28.51,8c5.25.08,10.67.16,13.94,1.52,3.56,1.47,7.63,5.37,11.57,9.14C109.72,232.49,117.56,240,128,240s18.27-7.51,25.18-14.14c3.94-3.77,8-7.67,11.57-9.14,3.27-1.36,8.69-1.44,13.94-1.52,9.76-.15,20.82-.31,28.51-8s7.85-18.75,8-28.51c.08-5.25.16-10.67,1.52-13.94,1.47-3.56,5.37-7.63,9.14-11.57C232.49,146.28,240,138.44,240,128S232.49,109.73,225.86,102.82ZM120,80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z"></path>
    </svg>
  );
}
export function SpinnerBtn({ className }) {
  return (
    <>
      <i
        className={`fas fa-circle-notch fa-spin ${
          className ? className : "text-md"
        }`}
      ></i>
    </>
  );
}
export function StrategyImage({ className }) {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        style={{ enableBackground: "new 0 0 500 500" }}
      >
        <g id="Background_Complete">
          <g>
            <g>
              <rect
                y="382.398"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width={500}
                height="0.25"
              />
              <path
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                d="M237.014,337.8H43.915c-3.147,0-5.708-2.561-5.708-5.708V60.66c0-3.147,2.561-5.708,5.708-5.708
				h193.099c3.146,0,5.707,2.561,5.707,5.708v271.432C242.721,335.239,240.16,337.8,237.014,337.8z M43.915,55.203
				c-3.01,0-5.458,2.448-5.458,5.458v271.432c0,3.01,2.448,5.458,5.458,5.458h193.099c3.009,0,5.457-2.448,5.457-5.458V60.66
				c0-3.009-2.448-5.458-5.457-5.458H43.915z"
              />
              <path
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                d="M453.31,337.8H260.212c-3.147,0-5.707-2.561-5.707-5.708V60.66c0-3.147,2.561-5.708,5.707-5.708
				H453.31c3.148,0,5.708,2.561,5.708,5.708v271.432C459.019,335.239,456.458,337.8,453.31,337.8z M260.212,55.203
				c-3.009,0-5.457,2.448-5.457,5.458v271.432c0,3.01,2.448,5.458,5.457,5.458H453.31c3.01,0,5.458-2.448,5.458-5.458V60.66
				c0-3.009-2.448-5.458-5.458-5.458H260.212z"
              />
            </g>
            <g>
              <rect
                x="51.279"
                y="391.922"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width="25.997"
                height="0.25"
              />
              <rect
                x="145.153"
                y="389.208"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width="45.027"
                height="0.25"
              />
              <rect
                x="78.277"
                y="401.208"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width="60.792"
                height="0.25"
              />
              <rect
                x="429.487"
                y="399.528"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width="19.235"
                height="0.25"
              />
              <rect
                x="365.291"
                y="399.528"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width="55.292"
                height="0.25"
              />
              <rect
                x="257.047"
                y="395.306"
                // style={{ fill: '#EBEBEB' }}
                className="fill-level-2"
                width="74.467"
                height="0.25"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect
                  x="222.815"
                  y="274.51"
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  width="42.049"
                  height="107.889"
                />
                <rect
                  x="72.351"
                  y="274.51"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 295.1656 656.9081)"
                  // style={{ fill: '#F5F5F5' }}
                  className="fill-component-gray-2"
                  width="150.465"
                  height="107.889"
                />
                <rect
                  x="81.571"
                  y="349.921"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 188.2566 721.8099)"
                  className="fill-color-base-1"
                  width="25.115"
                  height="21.968"
                />
                <rect
                  x="81.571"
                  y="319.889"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 188.2566 661.7457)"
                  // style={{ fill: '#EBEBEB' }}
                  className="fill-level-2"
                  width="25.115"
                  height="21.968"
                />
                <rect
                  x="71.911"
                  y="288.437"
                  style={{ opacity: "0.7", fill: "#E6E6E6" }}
                  width="192.953"
                  height="4.332"
                />
              </g>
              <g>
                <rect
                  x="222.815"
                  y="272.418"
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  width="44.37"
                  height="17.475"
                />
                <rect
                  x="70.03"
                  y="272.418"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 292.845 562.3096)"
                  // style={{ fill: '#F5F5F5' }}
                  className="fill-component-gray-2"
                  width="152.785"
                  height="17.475"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="216.246"
                  y="239.436"
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  width="32.677"
                  height="33.253"
                />
                <rect
                  x="102.458"
                  y="239.436"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 318.7046 512.1246)"
                  // style={{ fill: '#F5F5F5' }}
                  className="fill-component-gray-2"
                  width="113.788"
                  height="33.253"
                />
                <rect
                  x="102.117"
                  y="244.449"
                  style={{ opacity: "0.7", fill: "#E6E6E6" }}
                  width="146.806"
                  height="2.686"
                />
              </g>
              <g>
                <rect
                  x="216.246"
                  y="237.81"
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  width="34.48"
                  height="7.77"
                />
                <rect
                  x="100.655"
                  y="237.81"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 316.9012 483.3903)"
                  // style={{ fill: '#F5F5F5' }}
                  className="fill-component-gray-2"
                  width="115.591"
                  height="7.77"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="287.409"
                  y="220.522"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 606.8085 602.9208)"
                  className="fill-color-base-2"
                  width="31.991"
                  height="161.876"
                />
                <polygon
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  points="294.258,382.398 287.409,382.398 287.409,370.92 301.44,370.92 				"
                />
                <rect
                  x="394.123"
                  y="220.522"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 820.2379 602.9208)"
                  className="fill-color-base-2"
                  width="31.992"
                  height="161.876"
                />
                <rect
                  x="287.409"
                  y="220.522"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 687.5097 600.6029)"
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  width="112.692"
                  height="159.558"
                />
                <rect
                  x="292.722"
                  y="227.594"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 687.5097 498.9454)"
                  className="fill-color-base-2"
                  width="102.065"
                  height="43.757"
                />
                <path
                  className="fill-color-base-2"
                  d="M350.463,248.541c0,3.705-3.003,6.708-6.708,6.708c-3.705,0-6.708-3.003-6.708-6.708
					c0-3.705,3.003-6.708,6.708-6.708C347.46,241.833,350.463,244.837,350.463,248.541z"
                />
                <polygon
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  points="393.251,382.398 400.101,382.398 400.101,370.92 386.069,370.92 				"
                />
                <rect
                  x="292.722"
                  y="278.423"
                  // style={{ fill: '#E6E6E6' }}
                  className="fill-color-base-2"
                  width="102.065"
                  height="94.586"
                />
                <circle
                  style={{ fill: "#FAFAFA" }}
                  cx="380.691"
                  cy="292.52"
                  r="6.708"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="185.238"
                  y="-27.507"
                  transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 387.7407 -113.713)"
                  className="fill-color-base-2"
                  width="130.977"
                  height="329.042"
                />
                <rect
                  x="183.785"
                  y="-27.507"
                  transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 386.287 -112.2593)"
                  // style={{ fill: '#F0F0F0' }}
                  className="fill-color-base-2"
                  width="130.977"
                  height="329.042"
                />
                <rect
                  x="93.126"
                  y="79.898"
                  className="fill-color-base-1"
                  width="312.296"
                  height="114.231"
                />
              </g>
              <polygon
                className="fill-color-base-2"
                points="129.544,127.497 118.556,146.53 140.533,146.53 			"
              />
              <rect
                x="168.607"
                y="128.042"
                className="fill-color-base-2"
                width="17.945"
                height="17.945"
              />
              <polygon
                className="fill-color-base-2"
                points="225.615,125.537 213.548,134.304 218.157,148.491 233.074,148.491 237.683,134.304 			"
              />
              <polygon
                className="fill-color-base-2"
                points="279.995,126.025 267.306,126.025 260.962,137.014 267.306,148.003 279.995,148.003 
				286.339,137.014 			"
              />
              <polygon
                className="fill-color-base-2"
                points="321.686,124.953 311.766,129.731 309.315,140.466 316.181,149.074 327.192,149.074 
				334.057,140.466 331.607,129.731 			"
              />
              <polygon
                className="fill-color-base-2"
                points="374.577,125.291 364.866,125.291 357.999,132.158 357.999,141.87 364.866,148.737 
				374.577,148.737 381.444,141.87 381.444,132.158 			"
              />
            </g>
          </g>
        </g>
        <g id="Background_Simple" style={{ display: "none" }}>
          <g style={{ display: "inline" }}>
            <path
              style={{ fill: "#407BFF" }}
              d="M243.603,327.371C159.159,315.009,63.76,275.8,73.804,179.666
			c9.112-87.219,107.515-131.674,185.062-78.391c33.448,22.982,39.587,63.311,77.505,81.69
			c37.904,18.372,93.03,19.875,93.189,75.254C429.805,343.967,299.172,335.506,243.603,327.371z"
            />
            <path
              className="fill-color-base-1/90"
              d="M243.603,327.371C159.159,315.009,63.76,275.8,73.804,179.666
			c9.112-87.219,107.515-131.674,185.062-78.391c33.448,22.982,39.587,63.311,77.505,81.69
			c37.904,18.372,93.03,19.875,93.189,75.254C429.805,343.967,299.172,335.506,243.603,327.371z"
            />
          </g>
        </g>
        <g id="Shadow_1_">
          <ellipse
            id="_x3C_Path_x3E__332_"
            // style={{ fill: '#F5F5F5' }}
            className="fill-component-gray-2"
            cx={250}
            cy="416.238"
            rx="193.889"
            ry="11.323"
          />
        </g>
        <g id="To_Do_List">
          <g>
            <g>
              <path
                style={{ fill: "#407BFF" }}
                d="M239.408,129.506h164.653c3.513,0,6.209,2.874,5.991,6.387l-13.423,216.331
				c-0.218,3.513-3.271,6.387-6.784,6.387H225.192c-3.513,0-6.209-2.874-5.991-6.387l13.424-216.331
				C232.842,132.38,235.895,129.506,239.408,129.506z"
              />
              <path
                style={{ opacity: "0.4" }}
                d="M239.408,129.506h164.653c3.513,0,6.209,2.874,5.991,6.387l-13.423,216.331
				c-0.218,3.513-3.271,6.387-6.784,6.387H225.192c-3.513,0-6.209-2.874-5.991-6.387l13.424-216.331
				C232.842,132.38,235.895,129.506,239.408,129.506z"
              />
            </g>
            <path
              style={{ fill: "#407BFF" }}
              d="M235.626,129.506h164.654c3.513,0,6.209,2.874,5.991,6.387l-13.423,216.331
			c-0.218,3.513-3.271,6.387-6.784,6.387H221.409c-3.513,0-6.209-2.874-5.991-6.387l13.424-216.331
			C229.06,132.38,232.113,129.506,235.626,129.506z"
            />
            <polygon
              style={{ opacity: "0.4" }}
              points="224.343,346.93 384.605,348.852 397.606,139.331 237.225,139.331 		"
            />
            <path
              className="fill-color-base-1"
              d="M219.661,344.81c54.1,0,108.199,0,162.298,0c8.945-81.906,11.689-126.125,12.909-208.031
			c-54.099,0-108.199,0-162.298,0C231.35,218.685,228.606,262.903,219.661,344.81z"
            />
            <g>
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M375.485,193.753H347.54c-0.569,0-1.113-0.235-1.503-0.65
				c-0.391-0.415-0.592-0.973-0.557-1.541l1.734-27.944c0.068-1.088,0.97-1.936,2.06-1.936h27.945c0.569,0,1.113,0.235,1.503,0.65
				c0.391,0.415,0.592,0.973,0.557,1.541l-1.734,27.944C377.477,192.905,376.575,193.753,375.485,193.753z M349.735,189.626h23.81
				l1.479-23.817h-23.811L349.735,189.626z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M372.844,236.319h-27.944c-0.569,0-1.113-0.235-1.503-0.65
				c-0.391-0.415-0.592-0.973-0.557-1.541l1.733-27.945c0.068-1.088,0.97-1.936,2.06-1.936h27.945c0.569,0,1.113,0.235,1.503,0.65
				c0.391,0.415,0.592,0.973,0.557,1.541l-1.734,27.945C374.835,235.472,373.934,236.319,372.844,236.319z M347.095,232.192h23.81
				l1.479-23.818h-23.811L347.095,232.192z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M370.203,278.885h-27.945c-0.569,0-1.113-0.235-1.503-0.65
				c-0.391-0.415-0.592-0.973-0.557-1.541l1.734-27.945c0.068-1.088,0.97-1.936,2.06-1.936h27.944c0.569,0,1.113,0.235,1.503,0.65
				c0.391,0.415,0.592,0.973,0.557,1.541l-1.733,27.945C372.194,278.037,371.293,278.885,370.203,278.885z M344.453,274.758h23.811
				l1.478-23.818h-23.81L344.453,274.758z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M367.561,321.45h-27.944c-0.569,0-1.113-0.235-1.503-0.65
				c-0.391-0.415-0.592-0.973-0.557-1.541l1.733-27.945c0.068-1.088,0.97-1.936,2.06-1.936h27.945c0.569,0,1.113,0.235,1.503,0.65
				c0.391,0.415,0.592,0.973,0.557,1.541l-1.734,27.945C369.553,320.603,368.651,321.45,367.561,321.45z M341.813,317.323h23.81
				l1.478-23.818H343.29L341.813,317.323z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M259.882,174.062h63.289c1.921,0,3.591-1.572,3.71-3.493l0,0
				c0.119-1.921-1.355-3.493-3.276-3.493h-63.289c-1.921,0-3.591,1.572-3.71,3.493l0,0
				C256.486,172.49,257.961,174.062,259.882,174.062z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M271.03,188.359h51.254c1.921,0,3.591-1.572,3.71-3.493l0,0
				c0.119-1.921-1.355-3.493-3.276-3.493h-51.254c-1.921,0-3.591,1.572-3.71,3.493l0,0
				C267.634,186.787,269.108,188.359,271.03,188.359z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M256.787,223.938h63.289c1.921,0,3.396,1.572,3.276,3.493l0,0
				c-0.119,1.921-1.789,3.493-3.71,3.493h-63.289c-1.921,0-3.396-1.572-3.276-3.493l0,0
				C253.197,225.51,254.866,223.938,256.787,223.938z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M269.709,209.641h51.254c1.921,0,3.396,1.572,3.276,3.493l0,0
				c-0.119,1.921-1.789,3.493-3.71,3.493h-51.254c-1.921,0-3.396-1.572-3.276-3.493l0,0
				C266.118,211.213,267.788,209.641,269.709,209.641z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M254.6,259.193h63.289c1.921,0,3.591-1.572,3.71-3.493l0,0
				c0.119-1.921-1.355-3.493-3.276-3.493h-63.289c-1.921,0-3.591,1.572-3.71,3.493l0,0
				C251.204,257.621,252.678,259.193,254.6,259.193z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M291.536,273.49h25.465c1.921,0,3.591-1.572,3.71-3.493l0,0
				c0.119-1.921-1.355-3.493-3.276-3.493H291.97c-1.921,0-3.591,1.572-3.71,3.493l0,0
				C288.141,271.918,289.615,273.49,291.536,273.49z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M265.747,273.49h13.43c1.921,0,3.591-1.572,3.71-3.493l0,0
				c0.119-1.921-1.355-3.493-3.276-3.493h-13.43c-1.921,0-3.591,1.572-3.71,3.493l0,0
				C262.352,271.918,263.826,273.49,265.747,273.49z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M251.958,301.759h63.289c1.921,0,3.591-1.572,3.71-3.493v0
				c0.119-1.921-1.355-3.493-3.276-3.493h-63.289c-1.921,0-3.591,1.572-3.71,3.493v0
				C248.563,300.187,250.037,301.759,251.958,301.759z"
              />
              <path
                style={{ opacity: "0.3", fill: "#407BFF" }}
                d="M263.106,316.055h51.254c1.921,0,3.591-1.572,3.71-3.493l0,0
				c0.119-1.921-1.355-3.493-3.276-3.493h-51.254c-1.921,0-3.591,1.572-3.71,3.493l0,0
				C259.71,314.483,261.185,316.055,263.106,316.055z"
              />
              <path
                style={{ fill: "#407BFF" }}
                d="M360.113,186.105c-0.401,0-0.807-0.117-1.162-0.36l-10.768-7.355
				c-0.94-0.643-1.183-1.926-0.539-2.867c0.642-0.94,1.923-1.184,2.867-0.539l9.199,6.284c3.357-4.051,12.94-15.036,20.91-18.59
				c1.036-0.463,2.26,0.003,2.725,1.045c0.464,1.04-0.004,2.261-1.045,2.725c-8.701,3.878-20.446,18.721-20.565,18.87
				C361.331,185.832,360.727,186.105,360.113,186.105z"
              />
              <path
                style={{ fill: "#407BFF" }}
                d="M355.097,227.844c-0.517,0-1.032-0.193-1.432-0.578l-9.373-9.035
				c-0.82-0.791-0.845-2.097-0.054-2.917c0.792-0.82,2.098-0.843,2.917-0.054l7.979,7.691c3.945-3.528,15.017-12.935,23.357-15.216
				c1.099-0.302,2.233,0.348,2.534,1.446c0.301,1.099-0.347,2.233-1.446,2.534c-9.122,2.494-22.93,15.436-23.068,15.566
				C356.114,227.656,355.605,227.844,355.097,227.844z"
              />
            </g>
            <path
              style={{ opacity: "0.4" }}
              d="M337.57,141.735l3.044,0.03c3.372,0.033,6.149-2.696,6.173-6.065l0.039-5.576
			c0.003-0.347-0.267-0.618-0.615-0.618h-8.478c-0.375,0-0.693,0.315-0.696,0.69l-0.076,10.921
			C336.959,141.461,337.225,141.731,337.57,141.735z"
            />
            <g>
              <g>
                <path
                  style={{ fill: "#407BFF" }}
                  d="M291.551,119.388h51.062c3.168,0,5.41,2.592,4.982,5.759l-1.536,11.377
					c-0.428,3.168-3.369,5.76-6.537,5.76h-51.062c-3.168,0-5.41-2.592-4.982-5.76l1.536-11.377
					C285.441,121.98,288.383,119.388,291.551,119.388z"
                />
                <path
                  className="fill-color-base-1/60"
                  d="M291.551,119.388h51.062c3.168,0,5.41,2.592,4.982,5.759l-1.536,11.377
					c-0.428,3.168-3.369,5.76-6.537,5.76h-51.062c-3.168,0-5.41-2.592-4.982-5.76l1.536-11.377
					C285.441,121.98,288.383,119.388,291.551,119.388z"
                />
              </g>
              <path
                className="fill-color-base-1/50"
                d="M341.287,124.89h-22.952c-0.854,0-1.547-0.692-1.547-1.547
				c0-0.854,0.692-1.547,1.547-1.547h22.952c0.854,0,1.547,0.692,1.547,1.547C342.834,124.197,342.142,124.89,341.287,124.89z"
              />
              <path
                className="fill-color-base-1/50"
                d="M310.642,124.89h-2.422c-0.854,0-1.547-0.692-1.547-1.547
				c0-0.854,0.692-1.547,1.547-1.547h2.422c0.854,0,1.547,0.692,1.547,1.547C312.189,124.197,311.496,124.89,310.642,124.89z"
              />
            </g>
          </g>
        </g>
        <g id="Character_1_">
          <g>
            <g>
              <polygon
                style={{ fill: "#407BFF" }}
                points="169.462,346.654 179.331,348.537 202.662,238.816 188.172,236.051 			"
              />
              <polygon
                style={{ fill: "#263238" }}
                points="204.152,162.636 215.968,158.755 216.787,154.462 205.776,154.123 			"
              />
              <polygon
                style={{ fill: "#407BFF" }}
                points="203.854,160.139 217.313,155.761 203.482,234.523 188.991,231.758 			"
              />
              <rect
                x="189.05"
                y="233.102"
                transform="matrix(-0.9823 -0.1874 0.1874 -0.9823 344.0817 503.1089)"
                style={{ fill: "#263238" }}
                width="13.553"
                height="4.37"
              />
              <path
                style={{ fill: "#407BFF" }}
                d="M171.236,359.641c-0.124,0.65,0.148,1.249,0.607,1.336c0.46,0.088,0.933-0.368,1.057-1.018
				c0.124-0.65-0.148-1.249-0.607-1.336C171.833,358.534,171.36,358.991,171.236,359.641z"
              />
              <polygon
                style={{ fill: "#263238" }}
                points="170.56,359.723 173.498,360.284 179.331,348.537 169.462,346.654 			"
              />
              <path
                style={{ fill: "#263238" }}
                d="M188.339,211.817c-0.176,0-0.355-0.038-0.525-0.117c-0.623-0.29-0.893-1.031-0.602-1.654
				c6.196-13.284,11.228-41.043,11.904-47.645c0.199-1.947,1.442-3.64,3.243-4.415l3.27-1.409c0.632-0.272,1.364,0.018,1.636,0.649
				c0.272,0.632-0.019,1.364-0.65,1.636l-3.271,1.41c-0.973,0.419-1.644,1.332-1.751,2.383c-0.71,6.933-5.707,34.684-12.124,48.443
				C189.257,211.551,188.808,211.817,188.339,211.817z"
              />
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M142.273,158.792c-2.492,4.265-5.01,8.794-7.371,13.236c-1.173,2.238-2.31,4.486-3.405,6.736
					c-1.053,2.259-2.104,4.517-3.004,6.779l-0.17,0.41c0.001,0.007-0.015-0.017-0.021,0.01c-0.022,0.023-0.049,0.101-0.072,0.21
					c-0.052,0.217-0.071,0.568-0.067,0.978c0.012,0.829,0.176,1.87,0.422,2.936c0.517,2.148,1.281,4.47,2.196,6.757
					c0.891,2.307,1.912,4.619,2.971,6.932c1.034,2.316,2.194,4.646,3.292,6.902l-4.749,3.244c-3.539-4.153-6.66-8.465-9.418-13.269
					c-1.343-2.418-2.647-4.911-3.644-7.765c-0.497-1.435-0.946-2.945-1.201-4.716c-0.127-0.888-0.185-1.849-0.119-2.932
					c0.035-0.541,0.103-1.111,0.238-1.722c0.055-0.294,0.157-0.624,0.245-0.932l0.162-0.511c1.637-5.332,3.739-10.343,6.057-15.212
					c2.348-4.88,4.859-9.516,7.785-14.182L142.273,158.792z"
                />
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M136.351,209.251l4.466,3.478l-6.824,5.636c0,0-3.115-3.011-2.634-6.69L136.351,209.251z"
                />
                <polygon
                  style={{ fill: "#FFC3BD" }}
                  points="142.638,217.809 138.092,223.66 133.993,218.366 140.818,212.729 				"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M168.042,117.108c0.123,0.605,0.544,1.031,0.939,0.951c0.396-0.08,0.617-0.636,0.494-1.242
					c-0.123-0.606-0.544-1.031-0.939-0.951C168.14,115.947,167.919,116.503,168.042,117.108z"
                />
                <path
                  style={{ fill: "#ED847E" }}
                  d="M168.53,118.15c0,0,2.087,3.213,3.945,4.59c-0.868,1.217-2.782,1.131-2.782,1.131L168.53,118.15z
					"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M165.142,114.197c-0.088,0-0.177-0.032-0.246-0.096c-0.147-0.136-0.156-0.366-0.02-0.513
					c1.363-1.475,3.016-1.213,3.087-1.201c0.197,0.034,0.33,0.221,0.297,0.418c-0.034,0.197-0.224,0.329-0.417,0.297l0,0
					c-0.054-0.009-1.347-0.198-2.434,0.978C165.337,114.157,165.24,114.197,165.142,114.197z"
                />
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M150.795,125.624c1.099,5.604,2.195,15.871-1.726,19.611c0,0,1.532,5.685,11.945,5.685
					c11.45,0,5.472-5.684,5.472-5.684c-6.249-1.492-6.088-6.129-4.999-10.483L150.795,125.624z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M146.854,145.657c-0.833-1.978,0.951-7.976,2.849-8.563c2.089-0.646,10.029-0.285,13.97,1.364
					c2.803,2.84,3.612,8.738,3.612,8.738L146.854,145.657z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M114.147,407.525c0.768,0.166,1.537,0.21,1.907-0.105c0.155-0.132,0.312-0.386,0.176-0.843
					c-0.072-0.24-0.228-0.42-0.467-0.534c-1.034-0.494-3.411,0.475-3.512,0.517c-0.066,0.027-0.11,0.091-0.112,0.162
					c-0.003,0.072,0.038,0.138,0.102,0.169C112.605,407.066,113.376,407.358,114.147,407.525z M115.394,406.301
					c0.078,0.017,0.15,0.04,0.213,0.07c0.144,0.069,0.234,0.17,0.276,0.31c0.097,0.327-0.024,0.429-0.064,0.463
					c-0.412,0.35-1.941,0.028-3.019-0.404C113.531,406.482,114.713,406.153,115.394,406.301z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M112.282,406.905c0.029,0.006,0.06,0.005,0.089-0.003c0.866-0.253,2.719-1.569,2.741-2.438
					c0.004-0.158-0.051-0.443-0.464-0.587c-0.268-0.094-0.538-0.071-0.804,0.065c-1.024,0.522-1.671,2.644-1.697,2.734
					c-0.019,0.064-0.002,0.133,0.045,0.18C112.217,406.881,112.248,406.898,112.282,406.905z M114.45,404.197
					c0.026,0.006,0.053,0.013,0.079,0.022c0.224,0.078,0.221,0.188,0.22,0.235c-0.013,0.514-1.235,1.546-2.132,1.964
					c0.224-0.612,0.736-1.82,1.392-2.155C114.159,404.188,114.304,404.165,114.45,404.197z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M169.443,408.81c0.983,0,1.933-0.135,2.251-0.577c0.111-0.154,0.199-0.418-0.015-0.793
					c-0.12-0.21-0.314-0.353-0.575-0.424c-1.275-0.347-3.952,1.179-4.065,1.244c-0.065,0.038-0.101,0.112-0.089,0.187
					c0.012,0.075,0.07,0.134,0.144,0.149C167.582,408.692,168.527,408.81,169.443,408.81z M170.658,407.324
					c0.128,0,0.246,0.013,0.351,0.042c0.167,0.045,0.284,0.128,0.355,0.254c0.128,0.225,0.081,0.337,0.035,0.401
					c-0.356,0.495-2.293,0.507-3.717,0.305C168.393,407.961,169.763,407.324,170.658,407.324z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M167.129,408.598c0.025,0,0.05-0.005,0.074-0.016c0.925-0.415,2.756-2.072,2.616-2.938
					c-0.033-0.203-0.177-0.456-0.676-0.507c-0.372-0.038-0.709,0.067-1.006,0.311c-0.974,0.8-1.181,2.864-1.189,2.951
					c-0.006,0.064,0.022,0.127,0.075,0.164C167.055,408.587,167.092,408.598,167.129,408.598z M168.998,405.493
					c0.036,0,0.072,0.002,0.109,0.006c0.326,0.033,0.347,0.162,0.354,0.204c0.084,0.52-1.16,1.822-2.104,2.389
					c0.098-0.603,0.371-1.837,1.011-2.362C168.561,405.571,168.768,405.493,168.998,405.493z"
                />
                <polygon
                  style={{ fill: "#FFC3BD" }}
                  points="158.706,408.417 166.366,408.417 167.129,390.678 159.469,390.678 				"
                />
                <polygon
                  style={{ fill: "#FFC3BD" }}
                  points="103.426,404.871 111.118,406.491 116.545,389.969 108.854,388.348 				"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M111.949,405.74l-8.411-1.821c-0.306-0.066-0.617,0.089-0.748,0.373l-2.92,6.335
					c-0.303,0.657,0.098,1.428,0.808,1.569c2.947,0.585,4.411,0.722,8.11,1.523c2.276,0.493,7.413,1.744,10.555,2.424
					c3.074,0.665,4.226-2.338,3-2.898c-5.495-2.511-7.791-4.721-9.278-6.781C112.797,406.092,112.397,405.837,111.949,405.74z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M166.316,407.53h-8.365c-0.313,0-0.584,0.218-0.652,0.523l-1.513,6.809
					c-0.157,0.706,0.379,1.375,1.102,1.363c3.018-0.051,7.378-0.228,11.171-0.228c4.435,0,2.712,0.242,7.913,0.242
					c3.145,0,4.018-3.179,2.701-3.467c-5.992-1.311-5.333-1.451-10.511-4.644C167.598,407.78,166.977,407.53,166.316,407.53z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M135.354,146.967c-7.179,1.646-13.099,19.974-13.099,19.974l14.417,10.989
						c0,0,5.291-4.989,7.646-14.157C146.787,154.167,142.695,145.284,135.354,146.967z"
                  />
                </g>
                <path
                  style={{ opacity: "0.2" }}
                  d="M141.507,160.445c-3.9,3.858-5.172,10.886-5.474,16.996l0.636,0.49c0,0,5.297-4.995,7.654-14.16
					c0.146-0.573,0.271-1.147,0.375-1.71L141.507,160.445z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M178.268,147.362c0,0,8.153,9.209,0.454,66.209c-12.345,0-34.964,0-40.971,0
						c-0.282-6.289,3.669-36.97-2.396-66.604c0,0,7.023-1.409,13.715-1.732c5.235-0.252,12.814-0.419,17.416,0.001
						C172.553,145.789,178.268,147.362,178.268,147.362z"
                  />
                </g>
                <g>
                  <polygon
                    style={{ opacity: "0.2" }}
                    points="167.127,390.683 166.736,399.826 159.072,399.826 159.464,390.683 					"
                  />
                  <polygon
                    style={{ opacity: "0.2" }}
                    points="108.85,388.352 116.545,389.974 113.744,398.489 106.049,396.868 					"
                  />
                </g>
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M147.587,119.929c1.968,7.343,2.747,11.741,7.278,14.794c6.816,4.593,15.302-0.678,15.568-8.461
					c0.239-7.006-3.019-17.837-10.9-19.317C151.77,105.487,145.619,112.586,147.587,119.929z"
                />
                <g>
                  <path
                    style={{ fill: "#FFC3BD" }}
                    d="M154.907,133.873c-0.033,0-0.065,0-0.098,0c-2.403,0.003-4.664-1.135-6.066-3.086
						c-2.304-3.206-5.531-8.774-5.901-15.204c2.917-3.083,13.892-5.112,18.778-4c4.073,0.927,3.48,12.521-0.371,18.783
						C159.9,132.559,157.48,133.863,154.907,133.873z"
                  />
                  <path
                    style={{ opacity: "0.8", fill: "#263238" }}
                    d="M154.907,133.873c-0.033,0-0.065,0-0.098,0c-2.403,0.003-4.664-1.135-6.066-3.086
						c-2.304-3.206-5.531-8.774-5.901-15.204c2.917-3.083,13.892-5.112,18.778-4c4.073,0.927,3.48,12.521-0.371,18.783
						C159.9,132.559,157.48,133.863,154.907,133.873z"
                  />
                </g>
                <path
                  style={{ fill: "#263238" }}
                  d="M147.286,118.194c-3.451-0.247-5.403-2.418-6.028-4.278c-0.873-2.597,0.056-8.083,5.278-8.25
					c5.222-0.167,5.778-3.139,4.167-3.75c3.417,0.25,2.333,2.639,2.333,2.639c0.917,0.055,1.778-2.445,6.972-2.25
					c5.194,0.194,7.778-0.917,7.111-2.083c2.472,0.5,1.778,3.5-0.306,4.306c0,0,4.944,3.75,6.111,1.694
					c0.833,1.5-0.806,3.294-7.056,2.48c2.493,3.485-3.408,2.703-5.922,4.286C157.433,114.572,150.737,118.441,147.286,118.194z"
                />
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M160.081,119.585c0.5,1.928,1.766,3.585,3.091,4.571c1.993,1.483,3.482,0.023,3.341-2.232
					c-0.128-2.031-1.318-5.249-3.517-5.851C160.829,115.48,159.509,117.381,160.081,119.585z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M164.188,213.57c0,0-8.693,58.539-13.465,80.47c-5.226,24.016-33.249,103.474-33.249,103.474
					l-13.712-2.969c0,0,19.596-64.752,23.982-98.328c3.369-25.79,10.008-82.647,10.008-82.647H164.188z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M120.756,392.937c0.058,0.013-2.556,4.959-2.556,4.959l-15.427-3.34l1.3-4.638L120.756,392.937z"
                />
                <path
                  style={{ opacity: "0.3" }}
                  d="M158.694,234.428c-10.052,1.971-8.988,39.456-7.779,58.735
					c2.899-13.628,7.142-39.852,10.041-58.464C160.289,234.365,159.538,234.261,158.694,234.428z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M178.721,213.57c0,0,1.055,57.272,0.222,80.632c-0.867,24.297-9.353,103.854-9.353,103.854
					h-12.976c0,0-0.476-78.137-1.054-101.999c-0.629-26.014-3.77-82.486-3.77-82.486H178.721z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M172.087,392.887c0.06,0-0.732,5.387-0.732,5.387H155.57l-0.427-4.808L172.087,392.887z"
                />
                <path
                  style={{ fill: "#407BFF" }}
                  d="M178.455,146.346c2.011,0.838,3.939,2.327,4.518,7.273c0.557,4.754-0.522,46.382-1.827,59.675
					c-0.09,0.922-0.762,1.784-1.686,1.824c-8.194,0.355-33.564-3.073-42.125-1.566c-0.982,0.173-1.861-0.919-1.809-1.92
					c0.673-12.954-0.296-33.847-1.88-47.125c-1.034-8.666-1.041-17.735,6.237-19.277
					C145.263,144.09,170.635,143.088,178.455,146.346z"
                />
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M181.629,157.854c0.457,2.195,0.954,4.59,1.501,6.87c0.561,2.298,1.119,4.59,1.756,6.836
					c0.624,2.248,1.299,4.461,2.068,6.57c0.75,2.101,1.602,4.146,2.551,5.791c0.459,0.81,0.954,1.539,1.337,1.96
					c0.186,0.213,0.337,0.345,0.373,0.391c0.005,0.012-0.013,0.087,0.198,0.152c0.354,0.153,1.135,0.284,2.027,0.254
					c0.898-0.028,1.913-0.144,2.961-0.318c4.21-0.767,8.86-2.322,13.261-3.903l2.911,4.96c-4.162,3.227-8.473,5.904-13.727,7.84
					c-1.321,0.477-2.716,0.886-4.259,1.151c-1.545,0.253-3.257,0.431-5.313,0.083c-1-0.157-2.172-0.541-3.25-1.104
					c-1.031-0.565-1.848-1.207-2.547-1.845c-1.392-1.286-2.348-2.566-3.205-3.828c-3.219-5.043-4.918-9.925-6.511-14.869
					c-0.753-2.47-1.443-4.936-2.016-7.422c-0.584-2.506-1.124-4.919-1.551-7.56L181.629,157.854z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M164.779,154.852c-6.222,12.512,8.561,26.652,8.561,26.652l16.48-8.605
						c0,0-1.741-10.681-5.629-19.321C179.387,142.904,168.358,147.655,164.779,154.852z"
                  />
                </g>
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M208.139,183.196l5.59-3.673l2.325,8.362c0,0-2.509,2.926-7.085-0.264L208.139,183.196z"
                />
                <path
                  style={{ fill: "#FFC3BD" }}
                  d="M218.598,182.903l-0.178,1.754c-0.086,0.847-0.495,1.628-1.142,2.181l-1.224,1.046l-2.325-8.362
					l0.715-0.185C216.692,178.756,218.832,180.593,218.598,182.903z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
