import { useCallback } from "react";
import mydecodedTokenFunction from "../../../Utils/decodedtoken";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { logoutApi } from "../../helpers/api/Auth/Auth";
import { useDispatch } from "react-redux";
import { updateUserRoleListStore } from "../../../redux/AddRole/addRole.slice";
import { updateUserPermissions } from "../../../redux/userPermissions/userPermissions.slice";
import { toastSpecs } from "../../../Utils/utils";

function useLogout() {
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");
  const userid = mydecodedTokenFunction(token);
  const dispatch = useDispatch();
  // const queryClient = useQueryClient();

  const insertNote = useCallback((userid) => {
    const payload = {
      user_id: userid?.id,
    };
    return logoutApi(payload);
  }, []);

  const logoutMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: () => {
      localStorage.removeItem("adminToken");
      // queryClient.removeQueries(["adminStaffUsers"]);
      dispatch(updateUserRoleListStore({}));
      navigate("/");
      dispatch(updateUserPermissions({}));
    },
    onError: (err) => {
      dispatch(updateUserPermissions({}));
      // queryClient.removeQueries(["adminStaffUsers"]);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });
  const logoutUser = () => {
    logoutMutation.mutate(userid);
  };

  return { logoutUser, isLoading: logoutMutation?.isPending };
}

export default useLogout;
