import { Link, useNavigate } from "react-router-dom";
import StrategyViewCard from "./StrategyViewCard";
import StrategyOverview from "./StrategyOverview";
import { btnSecondayClass } from "../../../Component/utils/DefaultClasses";
import Leftbar from "../../../Component/Admin/Leftbar";
import useStrategyReport from "./hooks/useStrategyReport";
import moment from "moment";
import { Form, Skeleton, Switch, Tooltip } from "antd";
import { MdAutoGraph, MdOutlineAttachFile } from "react-icons/md";
import { StatsUsageCardSmall } from "./stats_usage/StatsUsageCard";
import StrategyDrawer from "../../../Component/Common/StrategyDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedStrategyData,
  setSelectedStrategy,
} from "../../../redux/strategy/strategy.slice";
import NoDataFoundV2 from "../../../Component/NoDataFoundV2";
import {
  DATEFORMAT,
  FileDownloader,
  rangePresets,
  truncate2,
} from "../../../Utils/utils";
import { HiOutlineRefresh } from "react-icons/hi";
import CustomDatePicker from "../../../Component/Common/CustomDatePicker";

const StrategyReportView = () => {
  let history = useNavigate();
  const { isOpen } = useSelector(selectedStrategyData);

  const dispatch = useDispatch();
  const {
    form,
    data,
    isLoading,
    toggleState,
    strategyCardData,
    showVirtualTrade,
    showLiveTrade,
    refetch,
    toggleTab,
    isLoadingUpdate,
    initialValues,
    handleChange,
    refetchLiveData,
    refetchVirtualData,
    isRefetchingLive,
    isRefetchingVirtual,
    Access,
    handleChangeLiveDate,
    handleChangeVirtualDate,
  } = useStrategyReport();

  return (
    <>
      <Leftbar type={"admin"} />
      <div className="left__bar">
        <div className="row bg__white p-2 mt-3 rounded align-items-center">
          <div className="col-lg-6 col-6">
            <div className="flex items-center gap-2">
              <div className="">
                <div className="flex">
                  <Link
                    // to={'/strategies'}
                    // className="btn__back"
                    className={`${btnSecondayClass} rounded-full py-2.5`}
                    onClick={() => {
                      history(-1);
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="">
                {isLoading ? (
                  <>
                    <Skeleton.Input
                      active={true}
                      size={"medium"}
                      shape={"default"}
                      block={true}
                      width={5} //   paragraph={{ rows: 1 }}
                    />
                  </>
                ) : (
                  <div>
                    <div className="flex items-center gap-1.5">
                      <>
                        <h2 className="strtgy__ttl capitalize">{data?.name}</h2>
                        <span
                          className={`${
                            data?.statusType === "processing"
                              ? "bg-yellow-200 text-yellow-500 p-1 rounded-md"
                              : data?.statusType === "active"
                              ? "badge__green"
                              : "bg-red-2/15 text-red-2 p-1 rounded-md"
                          } flex m-0 capitalize"`}
                        >
                          <small>{data?.statusType || "-"}</small>
                        </span>
                        {data?.access_type && (
                          <div className="-mb-2 -mt-2.5">
                            <Tooltip title={"Enable / Disable"}>
                              <Form
                                layout="vertical"
                                form={form}
                                initialValues={initialValues}
                                disabled={isLoadingUpdate}
                              >
                                <Form.Item name="status" className="mb-0">
                                  <Switch
                                    //   defaultChecked={data?.status}
                                    disabled={isLoadingUpdate}
                                    onChange={() => {
                                      handleChange(
                                        data?.status ? false : true,
                                        data?._id
                                      );
                                    }}
                                    size="small"
                                  />
                                </Form.Item>
                              </Form>
                            </Tooltip>
                          </div>
                        )}
                      </>
                    </div>
                    <span class="primary__text__mute fs-7 time_update m-0 flex">
                      {/* 15-May-2024<span class="ml-2">4:23:50 PM</span> */}
                      Last update:{" "}
                      {moment(data?.updated_at)?.format(DATEFORMAT)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="flex justify-end items-center gap-2">
              {!data?.access_type && (
                <>
                  <div>
                    <div className="flex items-center gap-1 border-[1px] rounded-lg overflow-hidden pl-1.5 mr-1.5">
                      <MdOutlineAttachFile className="rotate-[45deg] text-blue-2" />
                      <span className="flex items-center gap-2 text-xs">
                        <Tooltip title={data?.document_link?.split("/")?.pop()}>
                          <span>
                            {truncate2(
                              data?.document_link?.split("/")?.pop(),
                              6
                            ) || "-"}
                          </span>
                        </Tooltip>
                        <span className="flex items-center">
                          <a
                            href={data?.document_link}
                            className="font-medium text-blue-2 p-1.5 border-l border-level-2"
                          >
                            download
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* <div className="flex">
                    <span className="h-[32px] w-[1px] bg-level-2"></span>
                  </div> */}
                </>
              )}

              {data?.access_type && (
                <div>
                  <StatsUsageCardSmall
                    icon={
                      <>
                        <MdAutoGraph className="text-sm text-color-secondary/80" />
                      </>
                    }
                    percent={data?.subscriberCount}
                    title={"Subscribed users"}
                    subTitle={data?.subscriberCount}
                    // infoText={"No users are Subscribed"}
                    size={35}
                  />
                </div>
              )}

              {Access?.isEdit && data?.access_type && (
                <>
                  <div>
                    <button
                      className={`${btnSecondayClass} relative px-3.5 text-sm`}
                      onClick={() => {
                        dispatch(
                          setSelectedStrategy({
                            selectedStrategy: data,
                            isOpen: true,
                          })
                        );
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="row">
            <div className="col-12 px-0 py-2.5">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3">
                {strategyCardData?.map((item, index) => (
                  <div>
                    <StrategyViewCard
                      icon={item?.icon}
                      title={item?.title}
                      subtitle={item?.subtitle}
                      isLoading={isLoading}
                      extra={
                        <>
                          {item?.title === "Strategy Type" && (
                            <span className="flex bg-blue-2/15 p-2.5 border-[1px] border-dashed border-blue-2 text-blue-3 text-lg rounded-full relative w-[35px] h-[35px]">
                              {/* <MdOutlinePerson2 /> */}

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Bold"
                                viewBox="0 0 24 24"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                              >
                                <path d="M18.5,0H5.5A5.506,5.506,0,0,0,0,5.5v13A5.506,5.506,0,0,0,5.5,24h13A5.506,5.506,0,0,0,24,18.5V5.5A5.506,5.506,0,0,0,18.5,0ZM21,18.5A2.5,2.5,0,0,1,18.5,21H18V20A6,6,0,0,0,6,20v1H5.5A2.5,2.5,0,0,1,3,18.5V5.5A2.5,2.5,0,0,1,5.5,3h13A2.5,2.5,0,0,1,21,5.5Z"></path>
                                <circle cx="12" cy="8.5" r="3.5"></circle>
                              </svg>
                            </span>
                          )}
                        </>
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row strtgy__table__box mt-3">
          <div className="col-5 col-lg-6 ps-0">
            <ul className="strtgy__tbl">
              <li
                onClick={() => toggleTab("overview")}
                className={toggleState === "overview" ? "active" : "tabs"}
              >
                <span>Overview</span>
              </li>
              {data?.access_type && (
                <>
                  <li
                    onClick={() => toggleTab("backtest")}
                    className={toggleState === "backtest" ? "active" : "tabs"}
                  >
                    <span>Back Testing</span>
                  </li>
                  <li
                    onClick={() => toggleTab("virtual")}
                    className={toggleState === "virtual" ? "active" : "tabs"}
                  >
                    <span>Virtual Trade</span>
                  </li>
                  <li
                    onClick={() => toggleTab("live")}
                    className={toggleState === "live" ? "active" : "tabs"}
                  >
                    <span>Live Trade</span>
                  </li>
                </>
              )}
            </ul>
            {/* <div className="strtgy__table__drpdwn">
							<Dropdown>
								<Dropdown.Toggle id="dropdown-basic">
									{toggleState === "virtual" ? (
										<span>Virtual Trade</span>
									) : (
										<span>Live Trade</span>
									)}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										href=""
										onClick={() => toggleTab("virtual")}
										className={toggleState === "virtual" ? "active" : "tabs"}>
										Virtual Trade
									</Dropdown.Item>
									<Dropdown.Item
										href=""
										onClick={() => toggleTab("live")}
										className={toggleState === "live" ? "active" : "tabs"}>
										Live Trade
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div> */}
          </div>
          <div className="col-7 col-lg-6 d-flex justify-content-end align-items-center gap-2">
            {" "}
            {toggleState === "virtual" && (
              <CustomDatePicker
                onChange={handleChangeVirtualDate}
                presets={rangePresets}
                filterNotApplied={true}
              />
            )}
            {toggleState === "live" && (
              <CustomDatePicker
                onChange={handleChangeLiveDate}
                presets={rangePresets}
                filterNotApplied={true}
              />
            )}
            <div>
              <Tooltip
                title={
                  isRefetchingLive || isRefetchingVirtual
                    ? "Refreshing"
                    : "Refresh"
                }
              >
                <button
                  className={`${btnSecondayClass} w-[36px] h-[36px] relative`}
                  // disabled={isLoadingPNL || isLoadingReport || isLoadingTrade}
                  onClick={() => {
                    refetchLiveData();
                    refetchVirtualData();
                  }}
                >
                  {isRefetchingLive || isRefetchingVirtual ? (
                    <i class="fa-solid fa-sync fa-spin"></i>
                  ) : (
                    <HiOutlineRefresh className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  )}
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="tbl_data row mt-3">
          <div
            className={
              toggleState === "overview" ? "tab-content-active" : "tabs-content"
            }
          >
            <div className="bg-color-base-1 dark:bg-color-base-1 border-[1px] border-t-[0px] border-level-4 rounded-b-lg">
              <StrategyOverview data={data} />
            </div>
          </div>
          <div
            className={
              toggleState === "backtest" ? "tab-content-active" : "tabs-content"
            }
          >
            <NoDataFoundV2 title={"You don’t have back testing running."} />
            {/* <div className="empty__bx bg-color-base-1">
              <h3>You don’t have back testing running</h3>
              <button
                // onClick={() => {
                //   setBackTestingModalShow(true);
                // }}
                className="btn__add__strategy flex justify-center items-center"
              >
                <svg
                  stroke="currentColor"
                  fill="#4074F9"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  ></path>
                </svg>
              </button>
              <h4>Click on the + button to setup Virtual</h4>
            </div> */}
          </div>
          <div
            className={
              toggleState === "virtual" ? "tab-content-active" : "tabs-content"
            }
          >
            {showVirtualTrade}
          </div>

          <div
            className={
              toggleState === "live" ? "tab-content-active" : "tabs-content"
            }
          >
            {showLiveTrade}
          </div>
        </div>
        {/* <div>
					<StrategyNotFound title={"Your strategy in under process."} />
				</div> */}
      </div>
      {isOpen && <StrategyDrawer refetch={refetch} />}
    </>
  );
};

export default StrategyReportView;
