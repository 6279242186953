import {
  RoleListReducer,
  addRoleReducer,
} from "../redux/AddRole/addRole.slice";
import { popOverReducer } from "../redux/Filters/filterSort.slice";
import {
  UserPlanReducer,
  updatePlanReducer,
} from "../redux/PlanList/planList.slice";
import { updateReportPDFReducer } from "../redux/ReportPdf.js/reportPDF.slice";
import ticketSliceReducer from "../redux/Tickets/tickets.slice";
import { selectedTradeReducer } from "../redux/Trader/trader.slice";
import userCreditsDetailsReducer from "../redux/Trader/traderCredits.slice";
import profileDetailsReducer from "../redux/Trader/traderPlan.slice";
import {
  SelectedUserReducer,
  userDetailsReducer,
} from "../redux/UserDetails/userDetails.slice";
import { createUserReducer } from "../redux/createStaffUser/createStaffUser.slice";
import { selectedStrategyReducer } from "../redux/strategy/strategy.slice";
import { userPermissionReducer } from "../redux/userPermissions/userPermissions.slice";

export const reducers = {
  userData: userDetailsReducer,
  addRole: addRoleReducer,
  createStaffUser: createUserReducer,
  roleList: RoleListReducer,
  Popover: popOverReducer,
  selectedUserData: SelectedUserReducer,
  userPermission: userPermissionReducer,
  selectedStrategyData: selectedStrategyReducer,
  updateSelectedPlanDetails: updatePlanReducer,
  SelectedUserPlanDetails: UserPlanReducer,
  SelectedTradeDetails: selectedTradeReducer,
  updateReportPdf: updateReportPDFReducer,
  profileDetails: profileDetailsReducer,
  userCreditsDetails: userCreditsDetailsReducer,
  selectedTickets: ticketSliceReducer,
};
