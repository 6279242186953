import React from "react";
import { HashLoader } from "react-spinners";

export function DataLoader({ message, minHeight }) {
	return (
		<>
			<div
				className={`w-full flex justify-center items-center ${
					minHeight ? minHeight : ""
				}`}>
				<div>
					<div
						className={`min-w-[90px] disabled:cursor-wait flex justify-center items-center text-lg text-neutral-800 rounded-full gap-2 animatedBttn shiny-border p-0.5 overflow-hidden relative border-1 border-level-1 dark:border-transparent `}>
						<span
							className={`dark:bg-neutral-800 dark:bg-opacity-95 dark:backdrop-blur-2xl bg-whiteNew-0 flex justify-center items-center gap-2 relative z-10 rounded-full py-1.5 px-3.5 w-full h-full`}>
							<span>
								<HashLoader size={22} className="hashLoader" />
							</span>
							<span className={`text-color-primary font-medium`}>
								{message ? message : "Loading..."}
							</span>
						</span>
					</div>
				</div>
			</div>
			{/* <PageLoader2 /> */}
		</>
	);
}
