import axios from "axios";
import API_URL from "../../../../Constants/api-path";
import mydecodedTokenFunction from "../../../../Utils/decodedtoken";
import { apiHeader } from "../../../../Utils/utils";
import ENV from "../../../../Constants/env";

export async function updateUserDetails(payload) {
  const userid = mydecodedTokenFunction(localStorage.getItem("adminToken"));

  const { json_header } = apiHeader();
  const res = await axios.put(
    `${API_URL.UPDATE_ADMIN_USER}/${userid.id}`,
    payload,
    json_header
  );
  return res;
}

export async function getUserDetails() {
  const { json_header } = apiHeader();
  const userid = mydecodedTokenFunction(localStorage.getItem("adminToken"));

  const res = await axios.get(
    `${API_URL.GET_ADMIN_BY_ID}/${userid.id}`,
    json_header
  );

  return res;
}


export async function userDetail(id) {
  const { json_header } = apiHeader();
  console.log("json_header", json_header);
  if (json_header?.Authorization) {
    const res = await axios.get(`${API_URL.GET_USER_BY_ID}/${id}`, json_header);
    return res;
  } else {
    json_header.Authorization = `Bearer ${localStorage.getItem("adminToken")}`;
    const res = await axios.get(`${API_URL.GET_USER_BY_ID}/${id}`, json_header);
    return res;
  }
}

export async function brokerList(id) {
  const { json_header } = apiHeader();
  console.log("json_header", json_header);
  if (json_header?.Authorization) {
    const res = await axios.get(
      `${API_URL.GET_BROKERS_LIST}/${id}`,
      json_header
    );
    return res;
  } else {
    json_header.Authorization = `Bearer ${localStorage.getItem("adminToken")}`;
    const res = await axios.get(
      `${API_URL.GET_BROKERS_LIST}/${id}`,
      json_header
    );
    return res;
  }
}

// /virtual-trade/get-user-closed-orders-pnl/650c1f0a529ca85444baec66?day=today

export async function getPortfolio(type, id) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${ENV.BASE_URL}/${type}/${API_URL.PORTFOLIO_DETAILS}/${id}?day=today`,
    json_header
  );
  return res;
}

export async function getVirtualTrades(id) {
  const { json_header } = apiHeader();
  let payload = { dashboard_user_id: id };
  const res = await axios.post(
    `${ENV.BASE_URL}/virtual-trade/list?page=1&limit=10`,
    payload,
    json_header
  );
  return res;
}

export async function getLiveTrades(id) {
  const { json_header } = apiHeader();
  let payload = { dashboard_user_id: id };

  const res = await axios.post(
    `${ENV.BASE_URL}/live-trade//list?page=1&limit=10`,
    payload,
    json_header
  );
  return res;
}

export async function restrictTheUser(payload, id) {
  const { json_header } = apiHeader();
  const res = await axios.post(
    `${API_URL.RESTRICATION_USER}/${id}`,
    payload,
    json_header
  );
  return res;
}
