import axios from "axios";
import { apiHeader } from "../../../../Utils/utils";
import API_URL from "../../../../Constants/api-path";

export async function getAllTickets(page, limit, search) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${API_URL.TICKET_LIST}?page=${page}&limit=${limit}&search_key=${search}&user_id=`,
    json_header
  );
  return res;
}

// export async function createTicketeSupport(payload) {
//   const { form_header } = apiHeader();
//   const res = await axios.post(
//     `${config.base_url}${config.create_ticket}`,
//     payload,
//     form_header
//   );
//   return res;
// }

// export async function onDeleteTickets(payload) {
//   const { json_header } = apiHeader();
//   const res = await axios.post(
//     `${config.base_url}${config.ticket_delete}`,
//     payload,
//     json_header
//   );
//   return res;
// }

export async function getTicketSupportReplies(page, limit, id) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${API_URL.TICKET_Detail}?page=${page}&limit=${limit}&ticket_id=${id}`,
    json_header
  );
  return res;
}

export async function sendTicketMessage(payload) {
  const { form_header } = apiHeader();
  const res = await axios.post(
    `${API_URL.TICKET_MESSAGE}`,
    payload,
    form_header
  );
  return res;
}

export async function updateTicketStatus(payload) {
  const { form_header } = apiHeader();
  const res = await axios.put(
    `${API_URL.UPDATE_TICKET_STATUS}`,
    payload,
    form_header
  );
  return res;
}
