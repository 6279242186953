import { Pagination, Table } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MdEventNote } from "react-icons/md";
import { DataLoader } from "./DataLoader";
import NoDataFoundV2 from "./NoDataFoundV2";
// import { DataLoader } from './Loaders';
// import { usePageSize } from '../../hooks/usePageSize';
export default function TableComponent({
  columns,
  ref,
  dataSource,
  pagination,
  bordered,
  total,
  currentPage,
  setCurrentPage = () => {},
  loading = false,
  rowSelection,
  darkTheme,
  scroll,
  xScroll,
  yScroll,
  length = 0,
  custom = false,
  dataTablesRow,
  position = "bottomRight",
  size = "medium",
  onChange = () => {},
  showSorterTooltip,
  icon = <MdEventNote />,
  pageSize = 10,
  setPageSize = () => {},
  showSizeChanger = false,
  pageSizeOptions = [10, 25, 50, 100, 200],
  emptyDataText = "",
  expandable,
  method = "transact",
  loaderText,
  components = undefined,
  from,
  style,
}) {
  //   const { updatePagination } = usePageSize();
  return (
    <>
      <div className="w-full">
        <div className="relative block w-full max-w-[100vw] card_table">
          <Table
            dataSource={dataSource}
            size={size}
            columns={columns}
            onChange={onChange}
            rowClassName={dataTablesRow ? dataTablesRow : "dataTablesRow"}
            rowSelection={!rowSelection ? false : { ...rowSelection }}
            ref={ref}
            // expandedRowClassName={
            //   expandedRowClassName
            //     ? expandedRowClassName
            //     : 'expandedDataTablesRow'
            // }
            expandable={expandable}
            pagination={
              !pagination
                ? false
                : {
                    total: total,
                    showSizeChanger: showSizeChanger,
                    current: currentPage,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    },
                    pageSizeOptions: pageSizeOptions,
                    pageSize: pageSize,
                    position: [position],
                    onShowSizeChange: (page, pageSize) => {
                      const payload = {
                        type: from,
                        page_size: pageSize,
                      };
                      //   updatePagination.mutate(payload);
                    },
                  }
            }
            style={style}
            locale={{
              emptyText:
                dataSource && dataSource?.length === 0 && !loading ? (
                  <Row className="justify-content-center text-center">
                    <Col md={12} className="">
                      <NoDataFoundV2
                        title={
                          emptyDataText !== ""
                            ? emptyDataText
                            : "No data found."
                        }
                        icon={icon}
                        minHeight={"min-h-[200px] py-2"}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="justify-content-center text-center">
                    <Col md={12} className="">
                      <NoDataFoundV2
                        title={emptyDataText !== "" ? emptyDataText : " "}
                        icon={<></>}
                        minHeight={"min-h-[200px] py-2"}
                      />
                    </Col>
                  </Row>
                ),
            }}
            // locale={{
            //   emptyText: true,
            // }}
            // loading={loading}
            loading={{
              indicator: (
                <div className=" w-full h-full min-w-[300px] absolute left-1/2 -translate-x-1/2">
                  <DataLoader
                    message={loaderText ? loaderText : "Loading Data"}
                  />
                </div>
              ),
              spinning: !dataSource || loading,
            }}
            className="dataTables"
            bordered={bordered ? bordered : false}
            // scroll={
            //   scroll
            //     ? scroll
            //     : { x: xScroll ? xScroll : '', y: yScroll ? yScroll : '' }
            // }
            scroll={scroll}
            showSorterTooltip={showSorterTooltip}
            components={components}
            id="table"
          />
        </div>
        {custom && (
          <div className="w-full flex justify-end">
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              total={length || 0}
              defaultCurrent={currentPage}
              pageSize={10} //! need to add it dynamically
            />
          </div>
        )}
      </div>
    </>
  );
}
