import { useQuery } from "@tanstack/react-query";
import mydecodedTokenFunction from "../../../Utils/decodedtoken";
import { getRolesListAPI } from "../../helpers/api/RolesPermissions/rolesPermissions";
import { useDispatch } from "react-redux";
import { updateUserRoleListStore } from "../../../redux/AddRole/addRole.slice";
import { useEffect } from "react";
import useDecodedToken from "../../utils/useDecodedToken";

const useRoleList = () => {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["RoleList", decodedToken],
    queryFn: async () => {
      if (decodedToken) {
        return await getRolesListAPI();
      } else {
        return null;
      }
    },
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(updateUserRoleListStore(data?.data));
    }
  }, [data, dispatch]);

  return { data, refetch, isLoading };
};

export default useRoleList;
