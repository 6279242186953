import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import {
  getPermissions,
  toastSpecs,
  useUserDeviceLocation,
} from "../../../../Utils/utils";
import { adminStaffLogin } from "../../../../Component/helpers/api/Auth/Auth";
import useResendEmailVerifyLink from "../../../../Component/Common/hooks/useResendEmailVerifyLink";
import { updateUserPermissions } from "../../../../redux/userPermissions/userPermissions.slice";
import { useDispatch } from "react-redux";
import useSetPasswordLink from "../../../../Component/Common/hooks/useSetPasswordLink";

const useLogin = () => {
  let userDetails = secureLocalStorage.getItem("Admin_Details");
  const [showpassword, setShowPassword] = useState();
  const [form] = Form.useForm();
  const deviceData = useUserDeviceLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ResendLink } = useResendEmailVerifyLink();
  const { ResendLink: ResendSetPasswordLink } = useSetPasswordLink();

  const plans = useMemo(
    () => [
      {
        name: "Admin Login",
        value: "admin",
      },
      {
        name: "Staff Login",
        value: "staff",
      },
    ],
    []
  );
  //   const { ResendLink } = useResendEmailVerifyLink();

  const insertNote = useCallback(() => {
    const { email, password, role } = form.getFieldsValue();

    let payload = {
      email: email,
      password: password,
      device_info: deviceData,
      user_type: role,
    };

    return adminStaffLogin(payload);
  }, [form, deviceData]);

  const addCustomError = (from, message) => {
    form.setFields([
      {
        name: from,
        errors: [message],
      },
    ]);
  };

  const navigateTo = useCallback(
    (to) => {
      navigate(to);
      const { email, password, remember, role } = form.getFieldsValue();
      if (remember) {
        secureLocalStorage.setItem("Admin_Details", {
          email: email,
          password: password,
          role: role,
        });
      }
      //   secureLocalStorage.setItem("toGetPass", {
      //     // email: email,
      //     password: password,
      //   });
    },
    [form, navigate]
  );

  const navigateToDashboard = useCallback(
    (permissionsData) => {
      navigateTo();
      if (permissionsData?.["trades_management"]?.includes("access")) {
        navigate("/trades");
      } else if (permissionsData?.["trader_management"]?.includes("access")) {
        navigate("/traders");
      } else if (permissionsData?.["strategy_management"]?.includes("access")) {
        navigate("/strategy/permission");
      } else if (permissionsData?.["user_management"]?.includes("access")) {
        navigate("/user-list");
      } else if (permissionsData?.["plans_management"]?.includes("access")) {
        navigate("/plans");
      } else if (
        permissionsData?.["roles_permission_management"]?.includes("access")
      ) {
        navigate("/access-roles");
      }
    },
    [navigate, navigateTo]
  );

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      const { email } = form.getFieldsValue();

      if (res.data.message === "Invalid password") {
        addCustomError("password", "Invalid Password.");
      } else if (res.data.message === "Please verify your email.") {
        addCustomError(
          "email",
          <div className="d-flex gap-0.5" onClick={() => ResendLink(email)}>
            Email id is not verified.
            <Link
              className={`text-blue-2 hover:text-blue-2`}
              to={`/auth-admin-verify-email?email=${email}`}
            >
              Resend verification link.
            </Link>
          </div>
        );
      } else if (res.data.message === "Please set your password.") {
        addCustomError(
          "password",
          <div
            className="d-flex gap-0.5"
            onClick={() => ResendSetPasswordLink(email)}
          >
            Please set your password.
            <Link
              className={`text-blue-2 hover:text-blue-2`}
              to={`/admin-set-password?email=${email}`}
              // to={`/auth-admin-verify-email?email=${email}`}
            >
              Resend the password setup link.
            </Link>
          </div>
        );
      } else if (res.data.message === "OTP sent to the registered email.") {
        navigateTo(`/auth-verify-otp?email=${email}`);
      } else if (res.data.message === "Email not registered") {
        addCustomError("email", "Email id is not registered");
      } else {
        addCustomError("email", res.data.message);
      }

      if (res.data.status) {
        const permission = getPermissions(res?.data?.roleData?.role_data);
        dispatch(updateUserPermissions(permission));
        navigateToDashboard(permission);
        // navigate("/trades");
        localStorage.setItem("adminToken", res.data.token);
        localStorage.setItem("allbots", res.data.bot_list);
      }
    },
    onError: (err) => {
      console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const setEmailErrors = useCallback(
    (message) => {
      const { email } = form.getFieldsValue();

      if (message === "Email is not verified.") {
        return (
          <div className="d-flex gap-0.5" onClick={() => ResendLink(email)}>
            Email id is not verified.
            <Link
              className={`text-blue-2 hover:text-blue-2`}
              to={`/auth-admin-verify-email?email=${email}`}
            >
              Resend verification link.
            </Link>
          </div>
        );

        // return "";
      } else if (message === "Password not set yet.") {
        return (
          <div
            className="d-flex gap-0.5"
            onClick={() => ResendSetPasswordLink(email)}
          >
            Please set your password.
            <Link
              className={`text-blue-2 hover:text-blue-2`}
              to={`/admin-set-password?email=${email}`}
              // to={`/auth-admin-verify-email?email=${email}`}
            >
              Resend the password setup link.
            </Link>
          </div>
        );
      } else if (message === "Email ID does not exist in our platform.") {
        return "Email id is not registered";
      } else {
        return message;
      }
    },
    [ResendLink, ResendSetPasswordLink, form]
  );

  const loginHandle = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  // console.log('mutation', mutation);

  const value = useMemo(() => {
    return {
      form,
      userDetails,
      showpassword,
      isLoading: mutation.isPending,
      plans,
      setEmailErrors,
      setShowPassword,
      loginHandle,
    };
  }, [
    form,
    userDetails,
    showpassword,
    mutation.isPending,
    plans,
    setEmailErrors,
    setShowPassword,
    loginHandle,
  ]);

  return value;
};

export default useLogin;
