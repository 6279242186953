import { useCallback } from "react";
import Drawer from "../../../Component/Common/Drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedTradeDetails,
  setSelectedTrade,
} from "../../../redux/Trader/trader.slice";

export default function TradeDetailDrawer() {
  const { selectedTrade, isOpen } = useSelector(SelectedTradeDetails);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(
      setSelectedTrade({
        selectedTrade: [],
        isOpen: false,
      })
    );
  }, [dispatch]);

  return (
    <Drawer
      title={
        <>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="flex justify-center items-center gap-1.5 font-medium">
                <span className="font-semibold">Trade Summary</span>
              </span>
              {/* <span className="text-color-secondary/80 text-xs font-normal">
									24-May-2024 01:29:15 pm
								</span> */}
            </div>
            <div className="flex -mt-2"></div>
          </div>
        </>
      }
      onClose={handleClose}
      open={isOpen}
      className={``}
      rootClassName={"drawer-root-className"}
      width={"540px"}
      footer={null}
    >
      <div className="">
        {selectedTrade?.map((item) => (
          <div className="w-full space-y-6 mt-3" key={item?.heading}>
            <div className="row">
              <div className="col-md-4">
                <span className="text-gray-900">{item?.heading}</span>
              </div>
              <div className="col-md-8">
                <span className="text-gray-700 font-medium">
                  <span className="">{item?.value}</span>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
}
