import React, { useState } from "react";
import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import { afterBorder } from "../../../Component/utils/DefaultClasses";
import BacktestTrade from "./BacktestTrade";
import LiveTrade from "./LiveTrade";
import VirtualTrade from "./VirtualTrade";

export default function TradeView() {
  const [tab, changeTab] = useState("live");

  return (
    <>
      <div className="">
        <div className="layout-row flex" id="main">
          <div className="">
            <Leftbar type="admin" />
          </div>
          <div className="left__bar w-full">
            <RightBar
              leftContent={
                <>
                  {/* <div className="d-flex align-items-center">
										<div className="flex gap-2">
											<div className="">
												<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
													<GrCubes className="" />
												</span>
											</div>
											<div className="">
												<div className="flex gap-2">
													<div className="flex flex-col gap-0 -space-y-1">
														<h2 className="strtgy__ttl">Plan List</h2>
														<span className="text-sm flex">
															A role defines the access levels to the users in
															Admin Dashboard and Trader Dashboard.
														</span>
													</div>
												</div>
											</div>
										</div>
									</div> */}
                  <div
                    className={`flex gap-2 items-center ${afterBorder} after:bg-level-4 after:z-[1]`}
                  >
                    {/* <button
											className={`py-2.5 pt-0 px-3.5 border-b-[3px] ${
												tab === "backtesting"
													? "border-b-blue-2"
													: "border-b-transparent"
											}`}
											onClick={() => changeTab("backtesting")}>
											<span>Back Testing</span>
										</button> */}
                    <button
                      className={`py-2.5 pt-0 px-3.5 border-b-[3px] ${
                        tab === "live"
                          ? "border-b-blue-2"
                          : "border-b-transparent"
                      }`}
                      onClick={() => changeTab("live")}
                    >
                      <span>Live Trade</span>
                    </button>

                    <button
                      className={`py-2.5 pt-0 px-3.5 border-b-[3px] ${
                        tab === "virtual"
                          ? "border-b-blue-2"
                          : "border-b-transparent"
                      }`}
                      onClick={() => changeTab("virtual")}
                    >
                      <span>Virtual Trade</span>
                    </button>
                  </div>
                </>
              }
              rightContent={
                <>
                  {/* <UpdatedTime
									invokeFunction={[refetchUser, refetchRoleList]}
									showOnlyRefreshIcon={true}
									isLoading={isRefetching}
								/> */}
                </>
              }
            >
              <>
                <div className="mt-3.5">
                  {tab === "backtesting" ? <BacktestTrade /> : null}
                  {tab === "live" ? <LiveTrade /> : null}
                  {tab === "virtual" ? <VirtualTrade /> : null}
                </div>
              </>
            </RightBar>
          </div>
        </div>
      </div>
    </>
  );
}
