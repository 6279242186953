import { Avatar, Form, Switch, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { updateSelectedPlan } from "../../../redux/PlanList/planList.slice";
import useUpdatePlans from "../../../Component/Common/hooks/useUpdatePlans";
import { useMemo } from "react";
import useUserPermission from "../../../Component/Common/hooks/useUserPermission";

export default function PlanListCard({ item, refetch }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { onUpdate, isLoading } = useUpdatePlans(refetch);
  const initialValues = useMemo(() => {
    return { status: item.status };
  }, [item]);

  const totalUsers = useMemo(() => {
    return Number(item?.userSubscriptionCount);
  }, [item]);

  const avatars = useMemo(() => {
    return [...item?.userSubscriptionDetailData];
  }, [item]);

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: isCreateAccess("plans_management"),
      isEdit: isEditAccess("plans_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  return (
    <div className="relative flex justify-between p-3.5 border-[1px] shadow-md border-level-4 rounded-lg w-full">
      <div className="w-full">
        <div className="flex justify-between">
          <div>
            <span className="text-sm text-color-primary">
              Total {totalUsers} users
            </span>
          </div>
          <div>
            <Avatar.Group>
              {avatars?.length > 0 ? (
                avatars?.map(
                  (avatar, index) =>
                    index < 4 &&
                    (index === 3 ? (
                      <>
                        <Avatar>+{avatars?.length - 3}</Avatar>
                      </>
                    ) : (
                      <Tooltip
                        title={
                          avatar?.userData?.first_name +
                          " " +
                          avatar?.userData?.last_name
                        }
                      >
                        <Avatar
                          src={`https://eu.ui-avatars.com/api/?name=${
                            avatar?.userData?.first_name +
                            avatar?.userData?.last_name
                          }&size=250`}
                        />
                      </Tooltip>
                    ))
                )
              ) : (
                <div className="w-[30px] h-[30px]"></div>
              )}
            </Avatar.Group>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col items-start">
            <span className="text-color-primary text-base xl:text-lg font-medium">
              {item?.plan_name}
            </span>
            <div className="flex items-center gap-1.5">
              <button
                className="text-xs text-blue-3"
                onClick={() => {
                  dispatch(
                    updateSelectedPlan({
                      selectedPlan: item,
                      selectionType: "view",
                      isOpen: true,
                    })
                  );
                }}
              >
                View
              </button>
              {Access?.isEdit && (
                <>
                  <span className="flex h-[15px] w-[1px] bg-level-2"></span>
                  <button
                    className="text-xs text-blue-3"
                    onClick={() => {
                      dispatch(
                        updateSelectedPlan({
                          selectedPlan: item,
                          selectionType: "edit",
                          isOpen: true,
                        })
                      );
                    }}
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
          </div>
          {Access?.isEdit && (
            <div className="absolute bottom-0 right-4">
              <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                disabled={isLoading}
              >
                <Form.Item name="status">
                  <Switch
                    checkedChildren={<>Active</>}
                    unCheckedChildren={<>Inactice</>}
                    disabled={isLoading}
                    onChange={() => {
                      const payload = {
                        status: !item?.status,
                        plan_id: item?._id,
                      };
                      onUpdate(payload);
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
