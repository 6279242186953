import { Form } from "antd";
import { useCallback, useMemo } from "react";
import useResetPasswordLink from "../../../../Component/Common/hooks/useResetPasswordLink";

const useForgotPassword = () => {
  const [form] = Form.useForm();
  const { ResendLink, isLoading } = useResetPasswordLink();

  const setEmailErrors = useCallback((message) => {
    if (message === "Email ID does not exist in our platform.") {
      return "Email id is not registered";
    }
  }, []);

  const value = useMemo(() => {
    return { form, isLoading, submitForm: ResendLink, setEmailErrors };
  }, [form, isLoading, ResendLink, setEmailErrors]);

  return value;
};

export default useForgotPassword;
