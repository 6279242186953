import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import { afterBorder, btnSecondayClass } from "./utils/DefaultClasses";
// import $ from 'jquery';
export default function Modal({
	open,
	setIsOpen,
	children,
	maxWidth,
	fullScreen,
	bgColor,
	backdrop,
	bgImg,
	headerColor,
	footerColor,
	header = null,
	footer = null,
	reset = null,
	bodyHeight,
	bodyClass,
	modalClass,
	darkTheme,
	preventClosingOutsideClick,
	afterHeaderStrip,
	zIndex = null,
	closeBtnPos,
	disableHeaderClose,
}) {
	function closeModal() {
		setIsOpen(false);
		if (reset !== null) {
			reset();
		}
		// $('html, .sticky-header').css('width', '100%');
		// $('html').css('overflow', 'auto');
		// $('html').removeAttr('overflow');
		// $('html').removeAttr('width');
	}

	const handleOutsideClick = (event) => {
		if (event.target === event.currentTarget) {
			// Click occurred outside the modal, prevent closing
			return;
		}
		// Otherwise, let the default behavior of closing the modal proceed
		setIsOpen(false);
	};

	// if (open) {
	//   // $('html').removeProp('overflow');
	//   $('html').css('overflow', 'hidden');
	//   $('html, .sticky-header').css('width', 'calc(100% - 15px)');
	// }
	// if (open === false) {
	//   $('html').css('width', '100%');
	// } else {
	//   $('html').css('width', '100%');
	// }
	return (
		<React.Fragment>
			<Transition
				appear
				show={open}
				as={Fragment}
				onClose={(e) =>
					preventClosingOutsideClick ? handleOutsideClick(e) : setIsOpen(false)
				}>
				<Dialog
					className={`${
						modalClass
							? modalClass
							: "relative z-[999] modal_visible do-not-overide-change"
					}`}
					style={{ zIndex: zIndex }}
					onClose={() => {}}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div
							className={`fixed inset-0 bg-neutral-950 bg-opacity-20 ${
								backdrop ? backdrop : "backdrop-blur-sm"
							} `}
						/>
					</Transition.Child>

					<div className="fixed inset-0 max-h-[100%] overflow-y-auto">
						<div
							className={`flex min-h-full items-center justify-center text-center ${
								fullScreen ? "p-0 overflow-y-auto" : "p-4"
							} `}>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95">
								<div className="flex justify-center w-full">
									<Dialog.Panel
										className={`w-full relative ${
											maxWidth ? maxWidth : "max-w-md"
										} ${
											fullScreen ? `${fullScreen} min-h-screen` : "rounded-xl"
										} bg-color-base-1 border-[1px] border-level-4 p-0`}>
										<div
											className={`${
												closeBtnPos ? closeBtnPos : "top-2 right-2"
											} absolute z-10`}>
											<button
												type="button"
												onClick={closeModal}
												disabled={disableHeaderClose}
												// className={`${
												//   darkTheme
												//     ? 'dark:text-whiteNew-0 text-zinc-800 dark:bg-neutral-700 bg-zinc-300 dark:hover:bg-neutral-800 hover:bg-neutral-200 dark:hover:border-neutral-800 hover:border-neutral-200 dark:border-neutral-700 border-neutral-200'
												//     : 'bg-color-base-1 border-gray-200 hover:border-gray-200 hover:shadow-lg shadow-slate-500 focus:ring-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none'
												// } duration-150 ease-in w-[30px] h-[30px] flex gap-2 rounded-md relative p-0 border-1 `}
												className={`${btnSecondayClass} rounded-lg w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}>
												<AiOutlineClose className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
											</button>
										</div>
										<div
											className={`rounded-lg overflow-hidden ${
												bgColor ? bgColor : "bg-color-base-1"
											}  transform text-left align-middle transition-all `}
											style={{ backgroundImage: `url(${bgImg ? bgImg : ""})` }}>
											{bgImg && (
												<div className="absolute inset-0 bg-neutral-900 bg-opacity-80 w-full h-full backdrop-blur-lg"></div>
											)}
											{header !== null && (
												<div className="relative">
													<div
														className={`py-2 px-3 w-full modalHeader border-b border-solid ${
															headerColor
																? headerColor
																: "bg-color-base-1 border-level-2"
														} bg-color-base-1 border-level-2 text-color-primary`}>
														<div className="py-1.5">{header}</div>
													</div>
													{afterHeaderStrip ? (
														<>
															<div
																className={`flex flex-wrap items-center justify-between gap-3 w-full relative ${afterBorder}`}>
																{afterHeaderStrip}
															</div>
														</>
													) : null}
												</div>
											)}
											<div
												className={`${bodyClass ? bodyClass : "p-6"} p-3.5`}
												style={{ maxHeight: bodyHeight ? bodyHeight : null }}>
												{children}
											</div>
											{footer && (
												<div
													className={`p-2.5 px-3.5 w-full ${
														// footerColor
														//   ? footerColor
														//   : 'bg-color-base-1 border-level-2 border-t border-solid'
														""
													} bg-color-base-1 border-level-4 border-t border-solid`}>
													{footer}
												</div>
											)}
										</div>
									</Dialog.Panel>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</React.Fragment>
	);
}
