import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedStrategyData,
  setSelectedStrategy,
} from "../../../redux/strategy/strategy.slice";
import { toast } from "react-toastify";
import { toastSpecs } from "../../../Utils/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStrategyAPI } from "../../helpers/api/StrategyPermission/strategyPermissions";

const useStrategyDrawer = (refetch) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { selectedStrategy, isOpen } = useSelector(selectedStrategyData);
  const queryClient = useQueryClient();
  const [fieldTouched, setIsFieldTouched] = useState(false);

  const refetchData = () => {
    queryClient.invalidateQueries(["StrategyPermissionList"]);
  };

  const onClose = useCallback(() => {
    form.resetFields();
    setIsFieldTouched(false);
    dispatch(
      setSelectedStrategy({
        selectedStrategy: {},
        isOpen: false,
      })
    );
  }, [form, dispatch]);

  const initialValues = useMemo(() => {
    return {
      strategy_name: selectedStrategy?.name,
      desc: selectedStrategy?.description,
      instrument: selectedStrategy?.instrument,
      min_cap: selectedStrategy?.min_amount_required,
      fee: selectedStrategy?.fee,
      strategy_type: selectedStrategy?.access_type,
    };
  }, [selectedStrategy]);

  console.log("initialValues", initialValues);

  const insertNote = useCallback(() => {
    //   let payload = encodeDecodeAddRoleData(form.getFieldsValue(), "encode");
    // console.log("payload", form.getFieldsValue());
    const { strategy_name, desc, instrument, min_cap, fee, strategy_type } =
      form.getFieldsValue();
    const payload = {
      name: strategy_name,
      description: desc,
      access_type: strategy_type,
      strategy_id: selectedStrategy?._id,
      instrument: instrument,
      min_amount_required: min_cap,
      fee: fee,
      status: true,
    };
    return updateStrategyAPI(payload);
  }, [form, selectedStrategy]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      refetch();
      onClose();
      refetchData();
      return toast.success("Updated successfully.", toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    console.log("form", form.getFieldsValue());
    mutation.mutate();
  }, [form, mutation]);

  const values = useMemo(() => {
    return {
      form,
      selectedStrategy,
      isOpen,
      initialValues,
      isLoading: mutation?.isPending,
      fieldTouched,
      setIsFieldTouched,
      onClose,
      onSubmit,
    };
  }, [
    form,
    selectedStrategy,
    isOpen,
    initialValues,
    mutation?.isPending,
    fieldTouched,
    onClose,
    onSubmit,
  ]);

  return values;
};

export default useStrategyDrawer;
