import { useCallback, useEffect, useMemo, useState } from "react";
import { useStrategyByID } from "../../../../Component/Common/hooks/useStrategyPermissionList";
import { useParams } from "react-router-dom";
import moment from "moment";
import { RiTimerLine } from "react-icons/ri";
import { MdOutlineAutoGraph, MdOutlineInstallDesktop } from "react-icons/md";
import { HiMiniAdjustmentsVertical } from "react-icons/hi2";
import { BiDollarCircle } from "react-icons/bi";
import StrategyDesktopTable from "../StrategyDesktopTable";
import StrategyMobileTable from "../StrategyMobileTable";
import useUpdateStrategy from "../../../../Component/Common/hooks/useUpdateStrategy";
import { Form } from "antd";
import NoDataFoundV2 from "../../../../Component/NoDataFoundV2";
import { DATEFORMAT } from "../../../../Utils/utils";
import useUserPermission from "../../../../Component/Common/hooks/useUserPermission";

const useStrategyReport = () => {
  const { id } = useParams();
  const {
    data,
    refetch,
    isLoading,
    virtualData,
    refetchVirtualData,
    // isLoadingVirtualData,
    liveData,
    refetchLiveData,
    // isLoadingLiveData,
    virtualPage,
    setVirtualPage,
    virtualLimit,
    setVirtualLimit,
    livePage,
    setLivePage,
    liveLimit,
    setLiveLimit,
    isRefetchingLive,
    isRefetchingVirtual,
    handleChangeLiveDate,
    handleChangeVirtualDate,
  } = useStrategyByID(id);

  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: isCreateAccess("strategy_management"),
      isEdit: isEditAccess("strategy_management"),
    };
  }, [isCreateAccess, isEditAccess]);

  const { onUpdate, isLoading: isLoadingUpdate } = useUpdateStrategy(
    refetch,
    true
  );
  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    form.setFieldValue("status", data?.data?.data?.status || false);
    return { status: data?.data?.data?.status || false };
  }, [data, form]);

  const handleChange = useCallback(
    (status, id) => {
      let payload = {
        status: status,
        strategy_id: id,
      };
      onUpdate(payload);
    },
    [onUpdate]
  );

  const strategyCardData = useMemo(() => {
    const item = data?.data?.data;
    return [
      {
        icon: (
          <>
            <MdOutlineInstallDesktop className="text-4xl text-blue-2 " />
          </>
        ),
        title: "Strategy Category",
        subtitle: !item?.access_type ? "Created" : item?.access_type || "-",
      },
      {
        icon: (
          <>
            <RiTimerLine className="text-4xl text-blue-2" />
          </>
        ),
        title: "Created At",
        subtitle: moment(item?.created_at)?.format(DATEFORMAT),
      },
      {
        icon: (
          <>
            <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
          </>
        ),
        title: "Instrument",
        subtitle: item?.instrument || "-",
      },
      {
        icon: (
          <>
            <HiMiniAdjustmentsVertical className="text-4xl text-blue-2" />
          </>
        ),
        title: "Min Cap",
        subtitle: item?.min_amount_required || "-",
      },
      {
        icon: (
          <>
            <RiTimerLine className="text-4xl text-blue-2" />
          </>
        ),
        title: "Risk",
        subtitle: item?.risk_factor || "-",
      },
      {
        icon: (
          <>
            <MdOutlineAutoGraph className="text-4xl text-blue-2" />
          </>
        ),
        title: "Growth",
        subtitle: item?.growth_factor || "-",
      },
      {
        icon: (
          <>
            <BiDollarCircle className="text-4xl text-blue-2" />
          </>
        ),
        title: "Fee",
        subtitle: 100,
      },
    ];
  }, [data]);

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [toggleState, setToggleState] = useState("overview");
  const toggleTab = useCallback((index) => {
    setToggleState(index);
  }, []);

  //   console.log("mainData", data);

  // live trade tab
  const showLiveTrade = useMemo(() => {
    if (liveData?.data?.count === 0) {
      return (
        <NoDataFoundV2 title={"You don’t have Live Running."} />

        // <div className="empty__bx bg-color-base-1">
        //   <h3>You don’t have Live Running</h3>
        //   <button
        //     // onClick={() => {
        //     //   setBackTestingModalShow(true);
        //     // }}
        //     className="btn__add__strategy flex justify-center items-center"
        //   >
        //     <svg
        //       stroke="currentColor"
        //       fill="#4074F9"
        //       strokeWidth="0"
        //       viewBox="0 0 16 16"
        //       height="1em"
        //       width="1em"
        //       xmlns="http://www.w3.org/2000/svg"
        //     >
        //       <path
        //         fillRule="evenodd"
        //         d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
        //       ></path>
        //     </svg>
        //   </button>
        //   <h4>Click on the + button to setup Live</h4>
        // </div>
      );
    } else {
      return (
        <div className="col ps-0 pe-0 mt-3">
          <div>
            {/* {isMobile === false ? ( */}
            <StrategyDesktopTable
              tradeOrder={liveData?.data?.data}
              pagination={{
                current: livePage,
                setCurrent: setLivePage,
                pageSize: liveLimit,
                setPageSize: setLiveLimit,
                total: liveData?.data?.count,
              }}
              toggleState={toggleState}
            />
            {/* // ) : (
            //   <></>
            //   //   <StrategyMobileTable
            //   // tradeOrder={liveData}
            //   // toggleState={toggleState}
            //   // getVirtualTradeData={getLiveTradeData}
            //   // pagination={livePagination}
            //   // handleTableChange={handleliveTradeTable}
            //   //   />
            // )} */}
          </div>
        </div>
      );
    }
  }, [
    // isMobile,
    liveData?.data?.count,
    liveData?.data?.data,
    liveLimit,
    livePage,
    toggleState,
    setLiveLimit,
    setLivePage,
  ]);

  //virtual tade tab
  const showVirtualTrade = useMemo(() => {
    if (virtualData?.data?.count === 0) {
      return (
        <NoDataFoundV2 title={"You don’t have Virtual Running."} />
        // <div className="empty__bx bg-color-base-1">
        //   <h3>You don’t have Virtual Running</h3>
        //   <button
        //     // onClick={() => {
        //     //   setBackTestingModalShow(true);
        //     // }}
        //     className="btn__add__strategy flex justify-center items-center"
        //   >
        //     <svg
        //       stroke="currentColor"
        //       fill="#4074F9"
        //       strokeWidth="0"
        //       viewBox="0 0 16 16"
        //       height="1em"
        //       width="1em"
        //       xmlns="http://www.w3.org/2000/svg"
        //     >
        //       <path
        //         fillRule="evenodd"
        //         d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
        //       ></path>
        //     </svg>
        //   </button>
        //   <h4>Click on the + button to setup Virtual</h4>
        // </div>
      );
    } else {
      return (
        <div className="col ps-0 pe-0 mt-3 mb-5 mb-lg-2">
          <div>
            {/* {isMobile === false ? ( */}
            <StrategyDesktopTable
              tradeOrder={virtualData?.data?.data}
              pagination={{
                current: virtualPage,
                setCurrent: setVirtualPage,
                pageSize: virtualLimit,
                setPageSize: setVirtualLimit,
                total: virtualData?.data?.count,
              }}
              toggleState={toggleState}
            />
            {/* // ) : ( */}
            {/* //   <></> */}
            {/* //   <StrategyMobileTable */}
            {/* // tradeOrder={virtualData}
              // toggleState={toggleState}
              // getVirtualTradeData={getVirtualTradeData}
              // pagination={virtualPagination}
              // handleTableChange={handleVirtualTradeTable}
              // paginationCall={paginationCall}
              //   />
            // )} */}
          </div>
        </div>
      );
    }
  }, [
    // isMobile,
    setVirtualLimit,
    setVirtualPage,
    virtualData?.data?.count,
    virtualData?.data?.data,
    virtualLimit,
    virtualPage,
    toggleState,
  ]);

  const values = useMemo(() => {
    return {
      form,
      data: data?.data?.data,
      isLoading,
      toggleState,
      strategyCardData,
      showLiveTrade,
      refetchLiveData,
      refetchVirtualData,
      showVirtualTrade,
      isLoadingUpdate,
      initialValues,
      isRefetchingLive,
      isRefetchingVirtual,
      Access,
      handleChange,
      refetch,
      toggleTab,
      handleChangeLiveDate,
      handleChangeVirtualDate,
    };
  }, [
    form,
    data,
    strategyCardData,
    isLoadingUpdate,
    toggleState,
    isLoading,
    showLiveTrade,
    showVirtualTrade,
    initialValues,
    isRefetchingLive,
    isRefetchingVirtual,
    Access,
    refetchLiveData,
    refetchVirtualData,
    handleChange,
    refetch,
    toggleTab,
    handleChangeLiveDate,
    handleChangeVirtualDate,
  ]);
  return values;
};

export default useStrategyReport;
