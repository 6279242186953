import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Form, Input, Select, Switch } from "antd";
import useSecuritySetting from "../hooks/useSecuritySetting";
import { BsChevronDown } from "react-icons/bs";

function UserDetailsTab({
  userData,
  refetchUser,
  setIsModalVisible,
  setIsEmailModalVisible,
}) {
  const [isTab, setIsTabed] = useState(true);

  const { form, updateuserData, isLoading } = useSecuritySetting(
    refetchUser,
    userData,
    setIsTabed
  );

  // console.log("form", form.getFieldsValue());

  const toggle = () => {
    if (isTab) {
      form.setFieldValue("first_name", userData?.first_name || undefined);
      form.setFieldValue("last_name", userData?.last_name || undefined);
      form.setFieldValue("email", userData?.email || undefined);
      form.setFieldValue("phone_number", userData?.phone_no || undefined);
    }
    setIsTabed(!isTab);
  };

  // console.log("userData", userData);
  return (
    <>
      <Form
        className=""
        layout="vertical"
        form={form}
        disabled={isLoading}
        initialValues={{
          active_logs: userData?.save_activity_logs || undefined,
          unusual_activity: userData?.unusual_activity || undefined,
          auth_2fa: userData?.auth_2fa || undefined,
          first_name: userData?.first_name || undefined,
          last_name: userData?.last_name || undefined,
          email: userData?.email || undefined,
          phone_number: userData?.phone_no || undefined,
        }}
      >
        <Tabs
          defaultActiveKey="PersonalDetails"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="PersonalDetails" title="Personal Details">
            <div className="User__Details__Card">
              <div className="Details__Card__head">
                <h2 className="strtgy__ttl">Personal Details</h2>
                {isTab ? (
                  <button className="btn_default" onClick={toggle}>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                    </svg>
                    Edit Profile
                  </button>
                ) : (
                  <div className="d-flex flex-row gap-2">
                    <button
                      className="btn_default"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
                      </svg>
                      Cancel
                    </button>
                    <button
                      className="btn_default_active"
                      disabled={isLoading}
                      onClick={() => {
                        const payload = {
                          first_name: form.getFieldValue("first_name"),
                          last_name: form.getFieldValue("last_name"),
                        };

                        return updateuserData(payload);
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M272 64h-16c-4.4 0-8 3.6-8 8v72c0 4.4 7.6 8 12 8h12c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8z"></path>
                        <path d="M433.9 130.1L382 78.2c-9-9-21.3-14.2-34.1-14.2h-28c-8.8 0-16 7.3-16 16.2v80c0 8.8-7.2 16-16 16H160c-8.8 0-16-7.2-16-16v-80c0-8.8-7.2-16.2-16-16.2H96c-17.6 0-32 14.4-32 32v320c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V164c0-12.7-5.1-24.9-14.1-33.9zM322 400.1c0 8.8-8 16-17.8 16H143.8c-9.8 0-17.8-7.2-17.8-16v-96c0-8.8 8-16 17.8-16h160.4c9.8 0 17.8 7.2 17.8 16v96z"></path>
                      </svg>
                      Save
                    </button>
                  </div>
                )}
              </div>
              <div className="Details__Card__Body">
                {isTab && (
                  <div>
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <label>First Name</label>
                      </div>
                      <div className="col-lg-8">
                        <span>
                          {userData?.first_name
                            ? userData?.name
                              ? userData?.name
                              : userData?.first_name
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="row separate__between"></div>
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <label>Last Name</label>
                      </div>
                      <div className="col-lg-8">
                        <span>
                          {userData?.last_name
                            ? userData?.name
                              ? userData?.name
                              : `${userData?.last_name}`
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="row separate__between"></div>
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <label>Email Id</label>
                      </div>
                      <div className="col-lg-8">
                        <span>{userData?.email ? userData?.email : "-"}</span>
                      </div>
                    </div>
                    <div className="row separate__between"></div>
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <label>Phone Number</label>
                      </div>
                      <div className="col-lg-8">
                        <span>
                          {userData?.phone_no ? userData?.phone_no : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${isTab ? "hidden" : ""}`}>
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <label>First Name</label>
                    </div>
                    <div className="col-lg-8">
                      <Form.Item
                        // label="First"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "First Name is Required.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="First name"
                          // value={datas?.first_name}
                          name="first_name"
                          // onChange={(e) => handleChangeName(e)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row separate__between"></div>
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <label>Last Name</label>
                    </div>
                    <div className="col-lg-8">
                      <Form.Item
                        // label="First"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Last Name is Required.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Last name"
                          // value={datas?.last_name}
                          name="last_name"
                          // onChange={(e) => handleChangeName(e)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row separate__between"></div>
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <label>Email Id</label>
                    </div>
                    <div className="col-lg-8">
                      <Form.Item
                        // label="First"
                        name="email"
                      >
                        <Input
                          placeholder="Email id"
                          // defaultValue={userData?.email ? userData?.email : "-"}
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row separate__between"></div>
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <label>Phone Number</label>
                    </div>
                    <div className="col-lg-8">
                      <Form.Item
                        // label="First"
                        name="phone_number"
                      >
                        <Input placeholder="Phone number" disabled={true} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="SecuritySetting" title="Security Setting">
            <div className="User__Details__Card">
              <div className="Details__Card__head">
                <h2 className="strtgy__ttl">Security Setting</h2>
              </div>
              <div className="Details__Card__Body">
                <span className="mt-3 mb-3 d-flex primary__text__blue">
                  These settings are helps you keep your account Secure.
                </span>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex setting_grd">
                      <p>Save My Activity Logs</p>
                      <span>
                        Save your all activity logs includong unsual activity
                        detected.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <Form.Item
                      // label=""
                      name="active_logs"
                    >
                      <Switch
                        // defaultChecked={activeLogs}
                        onChange={(checked) => {
                          const payload = {
                            save_activity_logs: checked,
                          };
                          return updateuserData(payload);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div class="row separate__between"></div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex setting_grd">
                      <p>Email me if encounter unusual activity</p>
                      <span>
                        You will get email notification whenever encounter
                        invalid login activity.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <Form.Item
                      // label=""
                      name="unusual_activity"
                    >
                      <Switch
                        onChange={(checked) => {
                          const payload = {
                            unusual_activity: checked,
                          };
                          return updateuserData(payload);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div class="row separate__between"></div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex setting_grd">
                      <p>Current Email: {userData?.email}</p>
                      <span>
                        Update your current email address to new email address.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <button
                      className="btn_default"
                      onClick={() => setIsEmailModalVisible(true)}
                    >
                      Change Email
                    </button>
                  </div>
                </div>

                <div class="row separate__between"></div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex setting_grd">
                      <p>Change Password</p>
                      <span>
                        Set a unique password to protect your account.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <button
                      className="btn_default"
                      onClick={() => setIsModalVisible(true)}
                    >
                      Change Password
                    </button>
                  </div>
                </div>

                <div class="row separate__between"></div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex setting_grd">
                      <p>
                        2FA Authentication{" "}
                        {/* <small style={{ color: "#f00" }}>
                          {userData?.auth_2fa ? "Disable" : "Enable"}
                        </small> */}
                      </p>
                      <span>
                        Enhance security with an extra login verification step.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <Form.Item
                      // label=""
                      name="auth_2fa"
                    >
                      <Switch
                        onChange={(checked) => {
                          const payload = {
                            auth_2fa: checked,
                          };
                          return updateuserData(payload);
                        }}
                      />
                    </Form.Item>
                    {/* <button
                      className="btn_default"
                      onClick={() => {
                        const payload = {
                          auth_2fa: userData?.auth_2fa ? false : true,
                        };
                        return updateuserData(payload);
                      }}
                      disabled={isLoading}
                    >
                      {userData?.auth_2fa ? "Disable" : "Enable"}
                    </button> */}
                  </div>
                </div>
                <div class="row separate__between"></div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="d-flex setting_grd">
                          <p>Session Inactivity Preference</p>
                          <span>Set your activity Preference.</span>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="max-w-[350px]">
                          <Select
                            defaultValue="30"
                            placeholder={
                              <>
                                <span className="text-slate-400">
                                  Select a Country...
                                </span>
                              </>
                            }
                            size="lg"
                            // onChange={handleChange}
                            options={[
                              {
                                value: "1",
                                label: "1 Day",
                              },
                              {
                                value: "7",
                                label: "7 Week",
                              },
                              {
                                value: "30",
                                label: "1 Month",
                              },
                            ]}
                            className={"min-h-[38px] w-full"}
                            disabled
                            suffixIcon={
                              <>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                  <BsChevronDown
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </>
                            }
                          />
                        </div>
                        {/* <button
                          className="btn_default"
                          // loading={ButtonLoader}
                        >
                          {auth === true ? 'Disable' : 'Enable'}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="row separate__between"></div> */}
                {/* <div className="row">
                <div className="col-lg-9">
                  <div className="d-flex setting_grd">
                    <p>Configure Alert</p>
                    <span>
                      Secure your account with 2FA security. When it is
                      activated you will need to enter not only password, but
                      also a special code using your mobile
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    className="btn_default"
                    // onClick={handleOpenModal}

                    loading={ButtonLoader}
                  >
                    Enable
                  </button>
                </div>
              </div> */}
              </div>
            </div>
          </Tab>
          {/* <Tab eventKey="billing" title="Billing">
          <div className="User__Details__Card">
            <div className="Details__Card__Body my-2.5">
              <div>
                <Subscription />
              </div>
            </div>
            <div className="Details__Card__head mt-2.5 border-t border-level-1">
              <h2 className="strtgy__ttl">Payment Methods</h2>
            </div>
            <div className="Details__Card__Body my-2.5">

              <div>
                <PaymentMethod />
              </div>
            </div>

            <div className="Details__Card__head mt-2.5 border-t border-level-1">
              <h2 className="strtgy__ttl">Billing Address</h2>
            </div>
            <div className="Details__Card__Body my-2.5">

              <div>
                <BillingAddress />
              </div>
            </div>
            <div className="Details__Card__head mt-2.5 border-t border-level-1">
              <h2 className="strtgy__ttl">Billing History</h2>
            </div>
            <div className="Details__Card__Body px-0 pb-0 pt-0">
              <div>
                <TableComponent dataSource={dataSource} columns={columns} />
              </div>
            </div>
          </div>
        </Tab> */}
        </Tabs>
      </Form>
    </>
  );
}

export default UserDetailsTab;
