import env from "./env";

const API_URL = {
  LOGINURL_USER: `${env.BASE_URL}/user/signin`,
  LOGINURL_ADMIN: `${env.BASE_URL}/admin/signin`,

  //Signup
  SIGNUP_USER: `${env.BASE_URL}/admin/add-user`,
  RESEND_USER_EMAIL_VERIFY: `${env.BASE_URL}/admin/resend-email-verify`,

  // User Dashboard
  GET_ALL_REPORTS: `${env.BASE_URL}/admin/all-requests`,
  GET_FILE_REPORT: `${env.BASE_URL}/admin/get-requests-list`,
  DELETE_REPORTS: `${env.BASE_URL}/admin/delete-requests`,
  GET_USER_BY_ID: `${env.BASE_URL}/admin/user-by-id`,
  UPDATE_TRADER: `${env.BASE_URL}/admin/update-user`,
  UPDATE_ADMIN_USER: `${env.BASE_URL}/admin/update-admin-user`,
  UPDATE_STATUS: `${env.BASE_URL}/admin/update-user-status`,
  CHANGE_EMAIL: `${env.BASE_URL}/admin/change-user-email`,
  CHANGE_PASSWORD: `${env.BASE_URL}/admin/change-user-pass`,
  USER_FORGET_PASSWORD: `${env.BASE_URL}/admin/forgot-password-mail`,
  USER_RESET_PASSWORD: `${env.BASE_URL}/admin/reset-password`,
  USER_RESEND_FORGOT_PASSWORD: `${env.BASE_URL}/admin/resend-email-forgotpass`,

  // Admin Dashboard
  GET_ADMIN_BY_ID: `${env.BASE_URL}/admin/admin-by-id`,
  GET_USER_LIST: `${env.BASE_URL}/admin/user-list`,
  DELETE_USER: `${env.BASE_URL}/admin/delete-user`,
  ADMIN_FORGET_PASSWORD: `${env.BASE_URL}/admin/admin-forgot-password-mail`,
  ADMIN_RESET_PASSWORD: `${env.BASE_URL}/admin/admin-reset-password`,
  SET_PASSWORD: `${env.BASE_URL}/admin/set-admin-pass`,
  ADMIN_RESEND_FORGOT_PASSWORD: `${env.BASE_URL}/admin/admin-resend-email-forgotpass`,

  //strategy permission
  GET_STRATEGY_LIST: `${env.BASE_URL}/admin/admin-strategy-list`,
  GET_STRATEGY_BY_ID: `${env.BASE_URL}/admin/admin-strategy-detail`,
  GET_STRATEGY_VIRTUAL_Trade_BY_ID: `${env.BASE_URL}/admin/vt-list-by-strategy-id`,
  GET_STRATEGY_Live_Trade_BY_ID: `${env.BASE_URL}/admin/lt-list-by-strategy-id`,
  UPDATE_PERMISSION: `${env.BASE_URL}/admin/update-admin-strategy`,
  GET_SUBSCRIBED_STRATEGY: `${env.BASE_URL}/admin/user-subscribe-strategy-list?user_id`,

  //VTLT Reports
  VIRTUAL_TRADE: `${env.BASE_URL}/virtual-trade`,
  LIVE_TRADE: `${env.BASE_URL}/live-trade`,

  //HeatMap
  HEATMAP: `${env.BASE_URL}/user/p-and-l-list`,

  // user list (staff login) or Add user

  GET_STAFF_LIST: `${env.BASE_URL}/admin/admin-user-list?type=staff`,
  ADD_STAFF: `${env.BASE_URL}/admin/add-admin-user`,
  UPDATE_STAFF: `${env.BASE_URL}/admin/update-admin-user`,
  DELETE_STAFF: `${env.BASE_URL}/admin/delete-admin-user`,
  CHECK_STAFF_EMAIL: `${env.BASE_URL}/admin/admin-email-check`,
  CHECK_STAFF_EMAIL_LOGIN: `${env.BASE_URL}/admin/admin-login-email-check`,
  STAFF_USER_ACTIVITIES: `${env.BASE_URL}/admin/activity-log`,
  TRADER_USER_ACTIVITIES: `${env.BASE_URL}/user/activity-log`,

  TWOFA_AUTH: `${env.BASE_URL}/admin/verification/authentication/otp`,
  RESENDTWOFA_AUTH: `${env.BASE_URL}/admin/verification/authentication/resend-otp`,

  //change email
  UPDATE_ADMIN_EMAIL: `${env.BASE_URL}/admin/change-admin-email`,
  ADMIN_EMAIL_OTPSUBMIT: `${env.BASE_URL}/admin/verification/email/change-otp`,
  CHECK_ADMIN_MAIL: `${env.BASE_URL}/admin/email-check`,

  GET_BROKERS_LIST: `${env.BASE_URL}/brokers/credential`,

  PORTFOLIO_DETAILS: "/get-user-closed-orders-pnl",
  //Logout
  LOGOUTAPI: `${env.BASE_URL}/admin/logged/out`,

  //AddRole
  ADD_ROLE: `${env.BASE_URL}/admin/role`,
  UPDATE_ROLE: `${env.BASE_URL}/admin/role`,
  DELETE_ROLE: `${env.BASE_URL}/admin/role`,
  GET_ROLE: `${env.BASE_URL}/admin/role`,

  //email-verify
  CHECK_VERFIED_EMAIL: `${env.BASE_URL}/admin/admin-email-verify/`,
  VERIFY_EMAIL: `${env.BASE_URL}/admin/resend-admin-email-verify`,
  SET_PASSWORD_LINK: `${env.BASE_URL}/admin/admin-set-password-mail`,

  //restriction
  RESTRICATION_USER: `${env.BASE_URL}/admin/block-deactivate`,

  // Virtual trade live trade list

  GET_VT_TRADE_LIST: `${env.BASE_URL}/virtual-trade/admin/vt-trades`,

  GET_LT_TRADE_LIST: `${env.BASE_URL}/virtual-trade/admin/lt-trades`,

  //Plans list
  PLAN_LIST: `${env.BASE_URL}/plan/admin-plan-list`,
  USER_PLAN_LIST: `${env.BASE_URL}/payment/payment-list`,
  UPDATE_PLAN: `${env.BASE_URL}/plan/admin-plan-update`,

  // Transaction-list

  ACTIVE_PLANS: `${env.BASE_URL}/plan/active-plan-details`,

  //ticket support
  TICKET_LIST: `${env.BASE_URL}/ticket`,
  TICKET_Detail: `${env.BASE_URL}/ticket/replies-list`,
  TICKET_MESSAGE: `${env.BASE_URL}/ticket/replies`,
  UPDATE_TICKET_STATUS: `${env.BASE_URL}/ticket`,
};

export default API_URL;
