import { createSlice } from "@reduxjs/toolkit";

const popover = {
  open: false,
  openSort: false,
  isFiltered: false,
  isSorted: false,
  isFormUpdate: false,
  isSortingFormUpdate: false,
  filterCount: 0,
  // sortCount: 0,
  defaultCount: 0,
  defaultSortCount: 0,
};

const popOverSlice = createSlice({
  name: "popover",
  initialState: popover,
  reducers: {
    updatePopover: (state, { payload }) => {
      state.open = payload;
    },
    updateSortingPopover: (state, { payload }) => {
      state.openSort = payload;
    },
    updateisFiltered: (state, { payload }) => {
      state.isFiltered = payload;
    },
    updateisSorted: (state, { payload }) => {
      state.isSorted = payload;
    },
    updateForm: (state, { payload }) => {
      state.isFormUpdate = payload;
    },
    updateSortingForm: (state, { payload }) => {
      state.isSortingFormUpdate = payload;
    },
    updateFilterCount: (state, { payload }) => {
      state.filterCount = payload;
    },
    updateSortCount: (state, { payload }) => {
      state.sortCount = payload;
    },
    updateDefaultCount: (state, { payload }) => {
      state.defaultCount = payload;
    },
    updateDefaultSortCount: (state, { payload }) => {
      state.defaultSortCount = payload;
    },
  },
});

let popOverReducer = popOverSlice.reducer;

export const {
  updatePopover,
  updateSortingPopover,
  updateisFiltered,
  updateisSorted,
  updateForm,
  updateSortingForm,
  updateFilterCount,
  updateSortCount,
  updateDefaultCount,
  updateDefaultSortCount,
} = popOverSlice.actions;

export { popOverReducer };
