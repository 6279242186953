// import { useSelector } from 'react-redux';

import { isExpired } from "react-jwt";
import mydecodedTokenFunction from "../../Utils/decodedtoken";

export default function useDecodedToken() {
  const decodedToken = isExpired(localStorage.getItem("adminToken"))
    ? false
    : mydecodedTokenFunction(localStorage.getItem("adminToken"));
  return decodedToken;
}
