import React, { useState } from "react";
import { Form, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import UserDetailsHead from "./UserComponents/UserDetailsHead";
import UserDetailsTab from "./UserComponents/UserDetailsTab";
import Modal from "../../Component/Modal";
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from "../../Component/utils/DefaultClasses";
import Leftbar from "../../Component/Admin/Leftbar";
import mydecodedTokenFunction from "../../Utils/decodedtoken";
import useProfileView from "./hooks/useProfileView";
import UpdatePassword from "../../Component/Common/UpdatePassword";
import UpdateEmail from "../../Component/Common/UpdateEmail";

function ProfileView() {
  const userid = mydecodedTokenFunction(localStorage.getItem("adminToken"));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const { userDetails, refetch } = useProfileView();

  return (
    <>
      <Leftbar type={"admin"} />
      <div className="left__bar">
        <div className="small__container mb-5">
          <UserDetailsHead userData={userDetails} userid={userid} />
          <UserDetailsTab
            userData={userDetails}
            refetchUser={refetch}
            setIsModalVisible={setIsModalVisible}
            setIsEmailModalVisible={setIsEmailModalVisible}
          />
        </div>
        <div className="clearfix"></div>
      </div>
      {/* Change password modal */}
      <UpdatePassword
        props={{
          openModal: isModalVisible,
          setOpenModal: setIsModalVisible,
        }}
      />

      {/* Change Email id password */}
      <UpdateEmail
        props={{
          openModal: isEmailModalVisible,
          setOpenModal: setIsEmailModalVisible,
        }}
      />

      {/* <Modal
        open={isEmailModalVisible}
        setIsOpen={setIsEmailModalVisible}
        maxWidth={`w-full max-w-lg`}
        preventClosingOutsideClick
        // fullScreen="pl-0 pe-0 "
        header={
          <div className="flex flex-col justify-center py-2">
            <div className="w-full flex items-center">
              <span className="text-color-primary text-base font-medium">
                Enter Your Valid Email Address
              </span>
            </div>
          </div>
        }
        footer={null}
        // bodyHeight={'70vh'}
        bodyClass={
          "w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full"
        }
      >
        <div className="">
          <div className="row justify-content-center">
            <div className="col-12">
              <Form className="gap-3 space-y-3" layout="vertical">
                <Form.Item
                  label={
                    <>
                      <span>Current Email Address</span>
                    </>
                  }
                  name="currentEmail"
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    className={`${formClass} form-control`}
                    placeholder="Enter Current Email"
                    name="old_email"
                    // onChange={onChangeEmail}
                  />
                </Form.Item>

                <Form.Item
                  label="New Email Address"
                  name="newEmailAddress"
                  rules={[
                    {
                      required: true,
                      message: "New Email is required",
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    className={`${formClass} form-control`}
                    placeholder="Enter new email"
                    name="new_email"
                    // onChange={onChangeEmail}
                  />
                </Form.Item>

                <div className="d-flex align-items-center justify-end mt-4 gap-2">
                  <button
                    onClick={() => setIsEmailModalVisible(false)}
                    type="button"
                    className={`${btnSecondayClass}`}
                  >
                    Cancel
                  </button>
                  <button
                    className={`${btnPrimaryClass}`}
                    htmlType="submit"
                    // onClick={updateEmailID}
                    // loading={ButtonLoader}
                  >
                    Send Verification Email
                  </button>
                </div>
              </Form>
              <small className="mt-3 d-block text-red-2 text-[10px]">
                <InfoCircleOutlined className="me-2" />
                Wether you verify your email or not, from nect login you have to
                use your new email address.
              </small>
            </div>
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        footer={null}
        open={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
        wrapClassName="custm_modal relative"
        width={'480px'}
        centered
        closeIcon={
          <div className="flex">
            <div
              type="button"
              onClick={() => {
                setIsEmailModalVisible(false);
              }}
              className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
            >
              <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-3.5 w-3.5" />
            </div>
          </div>
        }
      >
        <div className="space-y-4 -mt-2">
          <div className="">
            <span className="text-color-primary text-base font-medium">
              Enter Your Valid Email Address
            </span>
          </div>
          <div className="">
            <div className="row justify-content-center">
              <div className="col-12">
                <Form className="gap-3 space-y-3" layout="vertical">
                  <Form.Item
                    label={
                      <>
                        <span>Current Email Address</span>
                      </>
                    }
                    name="currentEmail"
                    rules={[
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      className={`${formClass} form-control`}
                      placeholder="Enter Current Email"
                      name="old_email"
                      onChange={onChangeEmail}
                    />
                  </Form.Item>

                  <Form.Item
                    label="New Email Address"
                    name="newEmailAddress"
                    rules={[
                      {
                        required: true,
                        message: 'New Email is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      className={`${formClass} form-control`}
                      placeholder="Enter new email"
                      name="new_email"
                      onChange={onChangeEmail}
                    />
                  </Form.Item>

                  <div className="d-flex align-items-center mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="allBtns me-3"
                      onClick={updateEmailID}
                      loading={ButtonLoader}
                    >
                      Send Verification Email
                    </Button>
                    <a
                      onClick={() => setIsEmailModalVisible(false)}
                      style={{ color: '#8C7E7E' }}
                    >
                      Cancel
                    </a>
                  </div>
                </Form>
                <small
                  className="mt-4 d-block"
                  style={{ color: 'red', fontSize: '9px' }}
                >
                  {' '}
                  <InfoCircleOutlined className="me-2" />
                  Wether you verify your email or not, from nect login you have
                  to use your new email address.
                </small>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* <Modal
				open={alertConfigureModal}
				setIsOpen={handleCloseModal}
				maxWidth={`w-full max-w-lg`}
				preventClosingOutsideClick
				// fullScreen="pl-0 pe-0 "
				header={
					<div className="flex flex-col justify-center py-2">
						<div className="w-full flex items-center">
							<span className="text-color-primary text-base font-medium">
								Alert Configure
							</span>
						</div>
					</div>
				}
				footer={null}
				// bodyHeight={'70vh'}
				bodyClass={
					"w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full"
				}>
				<div style={{ borderRadius: "17px" }}>
					<p>Available Channels</p>
					<div className="row justify-content-center">
						<div className="d-flex row justify-content-between mt-3 mb-3">
							{imageTabs.map((tab) => (
								<div
									className="col-3 d-flex flex-column align-items-center"
									key={tab.key}>
									<Card
										style={{ border: "none" }}
										// className={` ${activeTab === tab.key ? 'active' : ''}`}
										onClick={() => handleTabChange(tab.key)}>
										<img
											className={`border p-1 ${
												activeTab === tab.key ? "active" : ""
											}`}
											// className="border p-1"
											style={{
												width: "94px",
												height: "94px",
												borderRadius: "50px",
												cursor: "pointer",
											}}
											src={tab.imageSrc}
											alt={tab.key}
											// style={{ width: '100%', height: '100%' }}
										/>
									</Card>
								</div>
							))}
						</div>
						<div className="col-12 mt-3">
							{activeTab && (
								<Card style={{ border: "none" }}>
									<p>
										{imageTabs.find((tab) => tab.key === activeTab).content}
									</p>
								</Card>
							)}
						</div>
						<div className="flex items-center gap-2 justify-content-end align-items-center">
							<div className="">
								<button
									className={`${btnSecondayClass}`}
									onClick={handleCloseModal}>
									Cancel
								</button>
							</div>
							<div className="">
								<button
									className={`${btnPrimaryClass}`}
									type="submit"
									onClick={handleCloseModal}>
									Save Changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal> */}
    </>
  );
}

export default React.memo(ProfileView);
