import { Link, useNavigate } from "react-router-dom";
import StrategyViewCard from "./StrategyViewCard";
import { btnSecondayClass } from "../../../Component/utils/DefaultClasses";
import Leftbar from "../../../Component/Admin/Leftbar";
import moment from "moment";
import { Dropdown, Skeleton, Tooltip } from "antd";
import useViewVTLTReport from "./hooks/useViewVTLTReport";
import ReportIcons from "./ReportIcons";
import PortfolioClosedTable from "../../../Utils/PortfolioClosedTable";
import CustomDatePicker from "../../../Component/Common/CustomDatePicker";
import { DATEFORMAT } from "../../../Utils/utils";
import { HiOutlineRefresh } from "react-icons/hi";
import { SpinnerBtn } from "../../../Component/utils/Icons";
import { MdOutlineUploadFile } from "react-icons/md";

const StrategyReportView = () => {
  let history = useNavigate();

  const {
    page,
    setPage,
    limit,
    setLimit,
    requestData,
    refetchReport,
    isLoadingReport,
    strategyCardData,
    toggleState,
    toggleTab,
    tradeData,
    totalTraderData,
    handleChangeDate,
    isLoadingTrade,
    rangePresets,
    refetchPNL,
    isLoadingPNL,
    refetchTrade,
    isRefetchingTrade,
    isRefetchingPNL,
    isRefetchingReport,
    exportOptions,
  } = useViewVTLTReport();

  // console.log("requestData", requestData);
  const { vt_inputs, status, updated_at, lt_inputs, strategyData } =
    requestData || {};

  return (
    <>
      <Leftbar type={"admin"} />

      <div className="left__bar">
        <div className="row bg__white p-2 mt-3 rounded align-items-center">
          <div className="col-lg-6 col-6">
            <div className="flex items-center gap-2">
              <div className="">
                <div className="flex">
                  <Link
                    // to={'/strategies'}
                    // className="btn__back"
                    className={`${btnSecondayClass} rounded-full py-2.5`}
                    onClick={() => {
                      history(-1);
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="">
                {isLoadingReport ? (
                  <>
                    <Skeleton.Input
                      active={true}
                      size={"medium"}
                      shape={"default"}
                      block={true}
                      width={5} // paragraph={{ rows: 1 }}
                    />
                  </>
                ) : (
                  <div>
                    <div className="flex items-center gap-1.5">
                      <>
                        <h2 className="strtgy__ttl">
                          {vt_inputs?.report_name ||
                            lt_inputs?.report_name ||
                            "-"}
                        </h2>
                        <span
                          className={`${
                            status ? "badge__green" : "bg-red-2/10 text-red-2"
                          } flex m-0 rounded-md px-1.5`}
                        >
                          <small>{status ? "Active" : "Inactive" || "-"}</small>
                        </span>
                      </>
                    </div>
                    <span class="primary__text__mute fs-7 time_update m-0 flex">
                      {/* 15-May-2024<span class="ml-2">4:23:50 PM</span> */}
                      Last update: {moment(updated_at)?.format(DATEFORMAT)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="flex justify-end items-center gap-2">
              <ReportIcons reportStatus={status} />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="row">
            <div className="col-12 px-0 py-2.5">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-3">
                {strategyCardData?.map((item, index) => (
                  <div>
                    <StrategyViewCard
                      icon={item?.icon}
                      title={item?.title}
                      subtitle={item?.subtitle}
                      isLoading={isLoadingReport}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row strtgy__table__box mt-3 flex flex-row justify-between">
          <div className="col-4 col-lg-5 ps-0 ">
            <ul className="strtgy__tbl">
              {/* <li
                onClick={() => toggleTab("closed")}
                className={toggleState === "closed" ? "active" : "tabs"}
              >
                <span>Closed Order</span>
              </li> */}
              <li
                onClick={() => toggleTab("trades")}
                className={toggleState === "trades" ? "active" : "tabs"}
              >
                <span>Trades</span>
              </li>
              {/* <li
                onClick={() => toggleTab("P&L")}
                className={toggleState === "P&L" ? "active" : "tabs"}
              >
                <span>P&L</span>
              </li> */}
            </ul>
          </div>
          <div className="col-4 col-lg-6 d-flex justify-content-end align-items-center gap-2">
            <CustomDatePicker
              onChange={handleChangeDate}
              presets={rangePresets}
            />
            {/* {totalTraderData > 0 && ( */}
            <div className="">
              <Tooltip title={"Export"}>
                <Dropdown
                  menu={{
                    items: exportOptions,
                  }}
                  placement="bottomRight"
                  overlayClassName={"dropdownOverlay dowpdownDark"}
                  trigger={["hover"]}
                  className={`${btnSecondayClass} w-[36px] h-[36px] relative`}
                >
                  <div onClick={(e) => e.preventDefault()}>
                    <MdOutlineUploadFile className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </div>
                </Dropdown>
              </Tooltip>
            </div>
            {/* )} */}
            <div>
              <Tooltip
                title={
                  isLoadingPNL || isLoadingReport || isLoadingTrade
                    ? "Refreshing"
                    : "Refresh"
                }
              >
                <button
                  className={`${btnSecondayClass} w-[36px] h-[36px] relative`}
                  disabled={isLoadingPNL || isLoadingReport || isLoadingTrade}
                  onClick={() => {
                    refetchReport();
                    refetchPNL();
                    refetchTrade();
                  }}
                >
                  {isRefetchingPNL ||
                  isRefetchingReport ||
                  isRefetchingTrade ? (
                    <SpinnerBtn />
                  ) : (
                    <HiOutlineRefresh className="text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  )}
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="tbl_data row mt-3">
          {/* <div
            className={
              toggleState === "closed" ? "tab-content-active" : "tabs-content"
            }
          >
            <div className="bg-color-base-1 dark:bg-color-base-1 border-[1px] border-t-[0px] border-level-4 rounded-b-lg">
              <div>
                {isMobile === false ? (
                  <PortfolioClosedTable from="closed" />
                ) : (
                  <ReportClosedTable
                  // paginationCall={paginationCall}
                  // mobileClosedOrders={mobileClosedOrders}
                  />
                )}
              </div>
            </div>
          </div> */}

          <div
            className={
              toggleState === "trades" ? "tab-content-active" : "tabs-content"
            }
          >
            {/* {isMobile === false ? ( */}
            <PortfolioClosedTable
              from="trades"
              tradeData={tradeData}
              pagination={{
                page,
                setPage,
                limit,
                setLimit,
                total: totalTraderData,
              }}
              isLoadingTrade={isLoadingTrade}
              additionalDetails={{
                reportName:
                  vt_inputs?.report_name || lt_inputs?.report_name || "-",
                strategyName: strategyData?.name || "-",
              }}
            />
            {/* ) : ( */}
            {/* // <ReportClosedTable
              // // paginationCall={paginationCall}
              // // mobileClosedOrders={mobileClosedOrders}
              // /> */}
            {/* )} */}
          </div>

          {/* <div
            className={
              toggleState === "P&L" ? "tab-content-active" : "tabs-content"
            }
          >
            <Heatmap from="report" tab={tab === "VT" ? "virtual" : "live"} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default StrategyReportView;
