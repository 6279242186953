import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import useDecodedToken from "../../utils/useDecodedToken";
import {
  getVTLTClosedOrderPNLBYID,
  getVTLTReportsBYID,
  getVTLTTradeBYID,
} from "../../helpers/api/StrategyPermission/strategyPermissions";

const useViewLTVTReportList = (id, tab) => {
  const decodedToken = useDecodedToken();
  const [VTpage, setVTPage] = useState(1);
  const [VTlimit, setVTlimit] = useState(10);
  const [LTpage, setLTPage] = useState(1);
  const [LTlimit, setLTlimit] = useState(10);
  const [VTselectedDate, setVTSelectedDate] = useState("today");
  const [LTselectedDate, setLTSelectedDate] = useState("today");

  const {
    data: VTReportList,
    refetch: refetchVTReport,
    isLoading: isLoadingVTReport,
    isRefetching: isRefetchingVTReport,
  } = useQuery({
    queryKey: ["VTReportList", id, VTpage, VTlimit, VTselectedDate],
    queryFn: async () => {
      if (decodedToken && id && tab === "VT") {
        return await getVTLTReportsBYID(
          id,
          VTpage,
          VTlimit,
          VTselectedDate,
          tab
        );
      } else {
        return null;
      }
    },
  });

  const {
    data: LTReportList,
    refetch: refetchLTReport,
    isLoading: isLoadingLTReport,
    isRefetching: isRefetchingLTReport,
  } = useQuery({
    queryKey: ["LTReportList", id, LTpage, LTlimit, LTselectedDate],
    queryFn: async () => {
      if (decodedToken && id && tab === "LT") {
        return await getVTLTReportsBYID(
          id,
          LTpage,
          LTlimit,
          LTselectedDate,
          tab
        );
      } else {
        return null;
      }
    },
  });

  return {
    VTpage,
    setVTPage,
    VTlimit,
    setVTlimit,
    VTselectedDate,
    setVTSelectedDate,
    VTReportList,
    refetchVTReport,
    isLoadingVTReport,
    LTpage,
    setLTPage,
    LTlimit,
    setLTlimit,
    LTselectedDate,
    setLTSelectedDate,
    LTReportList,
    refetchLTReport,
    isLoadingLTReport,
    isRefetchingReport:
      tab === "VT" ? isRefetchingVTReport : isRefetchingLTReport,
  };
};

const useVTLTClosedOrderPNL = (id, tab) => {
  const decodedToken = useDecodedToken();
  const [VTselectedDatePNL, setVTSelectedDatePNL] = useState("today");
  const [LTselectedDatePNL, setLTSelectedDatePNL] = useState("today");

  const {
    data: VTClosedOrderPNL,
    refetch: refetchVTClosedOrderPNL,
    isLoading: isLoadingVTClosedOrderPNL,
    isRefetching: isRefetchingVTClosedOrderPNL,
  } = useQuery({
    queryKey: ["VTClosedOrderPNL", id, VTselectedDatePNL, tab],
    queryFn: async () => {
      if (decodedToken && id && tab === "VT") {
        return await getVTLTClosedOrderPNLBYID(id, VTselectedDatePNL, tab);
      } else {
        return null;
      }
    },
  });

  //   console.log("VTClosedOrderPNL", VTClosedOrderPNL);

  const {
    data: LTClosedOrderPNL,
    refetch: refetchLTClosedOrderPNL,
    isLoading: isLoadingLTClosedOrderPNL,
    isRefetching: isRefetchingLTClosedOrderPNL,
  } = useQuery({
    queryKey: ["LTClosedOrderPNL", id, LTselectedDatePNL, tab],
    queryFn: async () => {
      if (decodedToken && id && tab === "LT") {
        return await getVTLTClosedOrderPNLBYID(id, LTselectedDatePNL, tab);
      } else {
        return null;
      }
    },
  });

  return {
    VTselectedDatePNL,
    setVTSelectedDatePNL,                                                                        
    VTClosedOrderPNL,
    refetchVTClosedOrderPNL,
    isLoadingVTClosedOrderPNL,
    LTselectedDatePNL,
    setLTSelectedDatePNL,
    LTClosedOrderPNL,
    refetchLTClosedOrderPNL,
    isLoadingLTClosedOrderPNL,
    isRefetchingPNL:
      tab === "VT"
        ? isRefetchingVTClosedOrderPNL
        : isRefetchingLTClosedOrderPNL,
  };
};

const useVTLTOrderTradeList = (id, tab) => {
  const decodedToken = useDecodedToken();
  const [VTpage, setVTPage] = useState(1);
  const [VTlimit, setVTlimit] = useState(10);
  const [LTpage, setLTPage] = useState(1);
  const [LTlimit, setLTlimit] = useState(10);
  const [VTselectedDatePNL, setVTSelectedDatePNL] = useState("today");
  const [LTselectedDatePNL, setLTSelectedDatePNL] = useState("today");

  const {
    data: VTOrderTrade,
    refetch: refetchVTOrderTrade,
    isLoading: isLoadingVTOrderTrade,
    isRefetching: isRefetchingVTOrderTrade,
  } = useQuery({
    queryKey: ["VTTrade", id, VTpage, VTlimit, VTselectedDatePNL, tab],
    queryFn: async () => {
      if (decodedToken && id && tab === "VT") {
        return await getVTLTTradeBYID(
          id,
          VTpage,
          VTlimit,
          VTselectedDatePNL,
          tab
        );
      } else {
        return null;
      }
    },
  });

  //   console.log("VTOrderTrade", VTOrderTrade);

  const {
    data: LTOrderTrade,
    refetch: refetchLTOrderTrade,
    isLoading: isLoadingLTOrderTrade,
    isRefetching: isRefetchingLTOrderTrade,
  } = useQuery({
    queryKey: ["LTTrade", id, LTpage, LTlimit, LTselectedDatePNL, tab],
    queryFn: async () => {
      if (decodedToken && id && tab === "LT") {
        return await getVTLTTradeBYID(
          id,
          LTpage,
          LTlimit,
          LTselectedDatePNL,
          tab
        );
      } else {
        return null;
      }
    },
  });

  if (tab === "VT") {
    return {
      page: VTpage,
      setPage: setVTPage,
      limit: VTlimit,
      setLimit: setVTlimit,
      selectedDate: VTselectedDatePNL,
      setSelectedDate: setVTSelectedDatePNL,
      tradeData: VTOrderTrade?.data?.orders_data,
      refetchData: refetchVTOrderTrade,
      isLoading: isLoadingVTOrderTrade,
      total: VTOrderTrade?.data?.total_orders,
      isRefetchingTrade: isRefetchingVTOrderTrade,
    };
  } else {
    return {
      page: LTpage,
      setPage: setLTPage,
      limit: LTlimit,
      setLimit: setLTlimit,
      selectedDate: LTselectedDatePNL,
      setSelectedDate: setLTSelectedDatePNL,
      tradeData: LTOrderTrade?.data?.orders_data,
      refetchData: refetchLTOrderTrade,
      isLoading: isLoadingLTOrderTrade,
      total: LTOrderTrade?.data?.count,
      isRefetchingTrade: isRefetchingLTOrderTrade,
    };
  }
};

export { useVTLTClosedOrderPNL, useViewLTVTReportList, useVTLTOrderTradeList };
