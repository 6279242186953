import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // For asynchronous actions

const initialState = {
  virtualTrades: [],
  selectedVirtualTrade: null,
  liveTrades: [],
  selectedLiveTrade: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VIRTUAL_TRADES':
      return { ...state, virtualTrades: action.payload };
    case 'SET_SELECTED_VIRTUAL_TRADE':
      return { ...state, selectedVirtualTrade: action.payload };
    case 'SET_LIVE_TRADES':
      return { ...state, liveTrades: action.payload };
    case 'SET_SELECTED_LIVE_TRADE':
      return { ...state, selectedLiveTrade: action.payload };
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware());
export default store;

// actions.js
export const setVirtualTrades = (trades) => ({
  type: 'SET_VIRTUAL_TRADES',
  payload: trades,
});

export const setSelectedVirtualTrade = (trade) => ({
  type: 'SET_SELECTED_VIRTUAL_TRADE',
  payload: trade,
});

export const setLiveTrades = (trades) => ({
  type: 'SET_LIVE_TRADES',
  payload: trades,
});

export const setSelectedLiveTrade = (trade) => ({
  type: 'SET_SELECTED_LIVE_TRADE',
  payload: trade,
});