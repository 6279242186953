import React, { useState } from "react";
import { Popover as AntdPopover } from "antd";
import { BsFunnel } from "react-icons/bs";
import { btnSecondayClass } from "./utils/DefaultClasses";
import { useDispatch, useSelector } from "react-redux";
import {
	updatePopover,
	updateSortingPopover,
} from "../redux/Filters/filterSort.slice";

export default function PopoverDropdown({ content, isSorting = false }) {
	const popover = useSelector((state) => state?.Popover);
	const {
		open,
		// openSort,
		isFiltered,
		// isSorted,
		filterCount,
		// defaultSortCount,
	} = popover;
	const dispatch = useDispatch();
	// console.log('filterCount', filterCount);
	const handleOpenChange = (newOpen) => {
		isSorting
			? dispatch(updateSortingPopover(newOpen))
			: dispatch(updatePopover(newOpen));
	};
	return (
		<AntdPopover
			content={<div className="p-1.5 min-w-[280px]">{content}</div>}
			// title="Title"
			trigger="click"
			open={open}
			arrow={false}
			placement={"bottomLeft"}
			onOpenChange={handleOpenChange}>
			<button
				className={`${btnSecondayClass} min-h-[34px] min-auto rounded-md relative font-normal max-w-[34px] min-w-[34px] mb-0 
         ${isFiltered || filterCount > 0 ? "border-green-700 border-1" : ""}`}
				//  dark:text-whiteNew-0 text-zinc-800 dark:bg-color-base-3 bg-zinc-200 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center w-[34px] h-[34px] text-base leading-tight duration-200 ease-in max-w-[34px] min-w-[34px] relative
			>
				{(isFiltered || filterCount > 0) && (
					<span className="flex w-[14px] h-[14px] bg-green-600 rounded-full -top-1 -right-1 absolute text-[10px] justify-center items-center text-whiteNew-0">
						{filterCount}
					</span>
				)}
				<BsFunnel className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm" />
			</button>
		</AntdPopover>
	);
}
