import { useState } from "react";
import LogoutModal from "../LogoutModal";
import Notification from "./Notification";
import LeftSideBar from "./LeftSideBar";
import useProfileView from "../../Pages/profile_view/hooks/useProfileView";

function Leftbar({ type }) {
  const [profileVisible, setProfileVisibility] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  useProfileView(); //Involing the userData API.

  const closeModal = (Component) => {
    if (Component === "Profile") {
      profileVisible ? setProfileVisibility(false) : setProfileVisibility(true);
      logoutModal && setLogoutModal(false);
      notificationVisible && setNotificationVisible(false);
    } else if (Component === "Notification") {
      notificationVisible
        ? setNotificationVisible(false)
        : setNotificationVisible(true);
      logoutModal && setLogoutModal(false);
      profileVisible && setProfileVisibility(false);
    } else if (Component === "Logout") {
      logoutModal ? setLogoutModal(false) : setLogoutModal(true);
      profileVisible && setProfileVisibility(false);
      notificationVisible && setNotificationVisible(false);
    }
  };

  return (
    <>
      <LeftSideBar closeModal={closeModal} type={type} />
      <Notification visible={notificationVisible} invisible={closeModal} />
      <LogoutModal open={logoutModal} close={closeModal} type="admin" />
    </>
  );
}

export default Leftbar;
