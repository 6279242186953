import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedUserData,
  setSelectedUser,
} from "../../../../../redux/UserDetails/userDetails.slice";
import useUpdateStaffUser from "../../../hooks/useUpdateStaff";
import useTradeUser from "../../../hooks/usetraderUser";
import { Form } from "antd";
import useUserPermission from "../../../../../Component/Common/hooks/useUserPermission";

const useProfileCard = (refetch) => {
  const [emailCheckmark, setEmailCheckmark] = useState(false);
  const [idCheckmark, setIdCheckmark] = useState(false);
  const [toggleState, setToggleState] = useState("overview");
  const userData = useSelector(selectedUserData);
  const { updateuserData } = useUpdateStaffUser(refetch);
  const { updateuserData: updateLoggedInUser } = useTradeUser(refetch);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    selectedUser,
    isOpen,
    isView,
    isPlanCovered,
    planDetail,
    loggedInUser,
  } = userData;
  console.log(planDetail,"planetail")
  const { isEditAccess, isCreateAccess } = useUserPermission();
  const Access = useMemo(() => {
    return {
      isCreate: loggedInUser
        ? isCreateAccess("trader_management")
        : isCreateAccess("user_management"),
      isEdit: loggedInUser
        ? isEditAccess("trader_management")
        : isEditAccess("user_management"),
    };
  }, [isCreateAccess, isEditAccess, loggedInUser]);

  const onCloseModal = useCallback(() => {
    form.resetFields();
    dispatch(
      setSelectedUser({
        selectedUser: {},
        isPlanCovered: false,
        isOpen: false,
        isView: false,
        loggedInUser: false,
      })
    );
    setEmailCheckmark(false);
    setIdCheckmark(false);
    setToggleState("overview");
  }, [dispatch, form]);

  const values = useMemo(() => {
    return {
      form,
      onCloseModal,
      isView,
      emailCheckmark,
      setEmailCheckmark,
      idCheckmark,
      setIdCheckmark,
      toggleState,
      setToggleState,
      selectedUser,
      updateuserData: isPlanCovered ? updateLoggedInUser : updateuserData,
      isOpen,
      planDetail,
      Access,
    };
  }, [
    form,
    onCloseModal,
    isView,
    isPlanCovered,
    emailCheckmark,
    setEmailCheckmark,
    idCheckmark,
    setIdCheckmark,
    toggleState,
    setToggleState,
    selectedUser,
    updateuserData,
    updateLoggedInUser,
    planDetail,

    isOpen,
    Access,
  ]);

  return values;
};

export default useProfileCard;
