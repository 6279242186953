import { Form } from "antd";
import { useCallback, useMemo } from "react";
import { updateUserDetails } from "../../helpers/api/userDetails/userDetails";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastSpecs } from "../../../Utils/utils";

export const useUpdatePassword = (refetchUser) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const insertNote = useCallback((data) => {
    const { payload } = data;
    return updateUserDetails(payload);
  }, []);

  const addCustomError = (from, message) => {
    form.setFields([
      {
        name: from,
        errors: [message],
      },
    ]);
  };

  const updateSecurityMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      if (res.data.status) {
        navigate("/login");
        localStorage.removeItem("adminToken");
      }
      if (res.data.message === "Incorrect Current Password.") {
        addCustomError("old_password", "The Current password is incorrect.");
      } else if (
        res.data.message ===
        "Current password and New password can not be the same."
      ) {
        addCustomError("old_password", res.data.message);
      }
      refetchUser();
    },
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const updateuserData = useCallback(
    (payload, from) => {
      updateSecurityMutation?.mutate({ payload, from });
    },
    [updateSecurityMutation]
  );

  const value = useMemo(() => {
    return {
      form,
      isLoading: updateSecurityMutation?.isPending,
      updateuserData,
    };
  }, [form, updateSecurityMutation?.isPending, updateuserData]);
  return value;
};
