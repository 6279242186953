import { Modal } from "antd";
import { btnPrimaryClass, btnSecondayClass } from "./utils/DefaultClasses";
import useLogout from "./Common/hooks/useLogout";

const LogoutModal = ({ open, close, type }) => {
  const { logoutUser, isLoading } = useLogout();

  const logOut = () => {
    logoutUser();
  };

  return (
    <Modal
      footer={null}
      open={open}
      onCancel={() => close("Logout")}
      centered
      title={<h3 className="">LogOut?</h3>}
    >
      <div>
        <p className="pb-4">Are you sure want to logout?</p>
        <div className="d-flex justify-start gap-2 align-items-center">
          <button
            onClick={() => close("Logout")}
            className={`${btnSecondayClass}`}
          >
            Cancel
          </button>
          <button
            className={`${btnPrimaryClass}`}
            onClick={() => logOut()}
            disabled={isLoading}
          >
            Logout
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
