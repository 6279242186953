import React from "react";
import { useSelector } from "react-redux";
import { updateReportPDFData } from "../../redux/ReportPdf.js/reportPDF.slice";
import TableComponent from "../TableComponent";
// const utc = require("dayjs/plugin/utc");
// dayjs.extend(utc);

const ReportPDF = ({ columns }) => {
  const datasource = useSelector(updateReportPDFData);

  return (
    <div className="StrategyTrades hidden">
      <TableComponent
        columns={columns}
        pagination={false}
        // currentPage={page}
        // setCurrentPage={setPage}
        // pageSize={limit}
        // setPageSize={setLimit}
        dataSource={datasource?.selectedReport}
        // loading={isLoadingTrade}
        // onChange={handleTableChange}
        // total={total}
        components={{
          table: (props) => <table {...props} id="StrategyTrades" />,
        }}
      />
    </div>
  );
};

export default React.memo(ReportPDF);
