import { Progress, Tooltip } from "antd";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

// export default function StatsUsageCard({
//   icon,
//   title,
//   percent,
//   acquire,
//   total,
//   strokeColor,
//   infoText,
//   subTitle,
//   size = 50,
// }) {
//   return (
//     <>
//       <div className="flex items-center border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden p-3.5">
//         <div className="flex gap-2 w-full">
//           <div>
//             <Progress
//               type="dashboard"
//               percent={percent}
//               strokeColor={strokeColor}
//               format={(percent) => {
//                 return (
//                   <>
//                     <span className="flex justify-center items-center">
//                       {icon}
//                     </span>
//                   </>
//                 );
//               }}
//               trailColor={'rgb(100 100 100 / 33%)'}
//               size={size}
//             />
//           </div>
//           <div>
//             <div>
//               <span className="text-color-primary font-semibold flex items-center gap-1">
//                 {title}
//                 {infoText ? (
//                   <span>
//                     <Tooltip title={infoText}>
//                       <AiOutlineQuestionCircle />
//                     </Tooltip>
//                   </span>
//                 ) : null}
//               </span>
//               <span className="text-color-secondary text-sm flex items-center gap-1">
//                 {subTitle ? (
//                   <>
//                     <span className="text-color-secondary/80 dark:text-color-secondary font-semibold">
//                       {subTitle}
//                     </span>
//                   </>
//                 ) : (
//                   <>
//                     <span className="text-color-secondary font-semibold">
//                       {total === '0' ? (
//                         <>
//                           <span className="text-red-2">
//                             No credits available
//                           </span>
//                         </>
//                       ) : (
//                         <>
//                           {acquire} / {total}
//                         </>
//                       )}
//                     </span>
//                     {total === '0' ? null : 'used'}
//                   </>
//                 )}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

export function StatsUsageCardSmall({
	icon,
	title,
	percent,
	acquire,
	total,
	strokeColor,
	infoText,
	subTitle,
	size = 50,
}) {
	return (
		<>
			<div className="flex items-center overflow-hidden p-1.5 relative">
				<div className="flex gap-2 w-full">
					<div>
						<Progress
							type="dashboard"
							percent={percent}
							strokeColor={strokeColor}
							format={(percent) => {
								return (
									<>
										<span className="flex justify-center items-center">
											{icon}
										</span>
									</>
								);
							}}
							trailColor={"rgb(100 100 100 / 33%)"}
							size={size}
						/>
					</div>
					<div>
						<div className="-space-y-1">
							<span className="text-color-primary font-semibold flex items-center gap-1 text-sm">
								{title}
								{infoText ? (
									<span>
										<Tooltip title={infoText}>
											<AiOutlineQuestionCircle />
										</Tooltip>
									</span>
								) : null}
							</span>
							<span className="text-color-secondary text-xs flex items-center gap-1">
								<span className="text-color-primary font-semibold">
									{/* {total === '0' ? (
                    <>
                      <span className="text-red-2">No credits available</span>
                    </>
                  ) : (
                    <>
                      {acquire} / {total}
                    </>
                  )} */}
								</span>
								{subTitle}
								{/* {total === '0' ? null : 'used'} */}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
