import VerificationInput from "react-verification-input";
import { formClass } from "../utils/DefaultClasses";

export default function OtpComponent({ props }) {
  const { udateOTP = () => {} } = props;
  return (
    <>
      <VerificationInput
        onChange={udateOTP}
        classNames={{
          container: "w-full",
          character: `${formClass} flex justify-center focus:border-blue-2`,
          characterInactive: "character--inactive",
          characterSelected: "character--selected",
          characterFilled: "character--filled",
        }}
      />
    </>
  );
}
