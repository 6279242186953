import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedReport: [],
};

const Slice = createSlice({
  name: "selectedReport",
  initialState,
  reducers: {
    updateReportPDF: (state, { payload }) => {
      state.selectedReport = payload;
    },
  },
});

let updateReportPDFReducer = Slice.reducer;

export const { updateReportPDF } = Slice.actions;

const updateReportPDFData = (state) => state.updateReportPdf;

export { updateReportPDFData, updateReportPDFReducer };
