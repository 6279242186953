import React, { useState } from "react";
import {
  afterBorder,
  btnSecondayClass,
} from "../../../../Component/utils/DefaultClasses";
import { shortenAddress } from "../../../../Component/Truncate";
import {
  AiFillCloseCircle,
  AiOutlineCheck,
  AiOutlineCopy,
  AiOutlineSetting,
} from "react-icons/ai";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { setSelectedUser } from "../../../../redux/UserDetails/userDetails.slice";
import { useDispatch } from "react-redux";
import UserProfileDrawer from "./userProfileDrawer";
import { DATEFORMAT } from "../../../../Utils/utils";

// function UserProfileCard({ userData, activePlanDetails }) {

const UserProfileCard = ({ refetch, userData, activePlanDetails }) => {
  const [emailCheckmark, setEmailCheckmark] = useState(false);
  const [idCheckmark, setIdCheckmark] = useState(false);
  const dispatch = useDispatch();
  const formattedDate = (date) => {
    if (!date) return "Not available";
    return (
      <div className="flex flex-col">
        <span>{moment(date).format(DATEFORMAT)}</span>
        {/* <span>{moment(date).format(TIMEFORMAT)}</span> */}
      </div>
    );
  };
  console.log(activePlanDetails, "search");

  return (
    <>
      <div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl">
        <div className="flex gap-3">
          <div className="w-[50px] h-[50px] xl:w-[50px] xl:h-[50px] rounded-md overflow-hidden shrink-0">
            <img
              src={`https://eu.ui-avatars.com/api/?name=${userData?.first_name}+${userData?.last_name}&size=250`}
              className="userProfileImg"
              alt="userImage"
            />
          </div>
          <div className="flex flex-col justify-between gap-2 w-full">
            <div className="flex justify-between">
              <div className="flex flex-col ">
                <div className="w-full">
                  <div className="flex gap-2 items-center">
                    <h5 className="text-lg text-color-primary m-0">
                      {`${userData?.first_name} ${userData?.last_name}`}
                    </h5>
                    {activePlanDetails?.subscriptionPlanData?.plan_name ||
                    userData?.features_data?.plan_name ? (
                      <div>
                        <span
                          className={`px-1.5 py-0.5 rounded-md text-sm ${
                            userData.block
                              ? "bg-red-2/15 text-red-2"
                              : userData.deactivate
                              ? "bg-red-2/15 text-red-2"
                              : userData.email_verify &&
                                userData?.password?.length > 0
                              ? "bg-blue-2/15 text-blue-2"
                              : "bg-red-2/15 text-red-2"
                          }`}
                        >
                          {userData.block
                            ? "Blocked"
                            : userData.deactivate
                            ? "Deactivated"
                            : userData.email_verify &&
                              userData?.password?.length > 0
                            ? "Active"
                            : "Inactive"}
                        </span>
                        {/* <span className="bg-green-2/15 text-green-3 font-medium py-0.5 px-1.5 rounded-md text-xs">
                          {activePlanDetails?.subscriptionPlanData?.plan_name ||
                            userData?.features_data?.plan_name}
                        </span> */}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  {/* <p className="text-sm text-color-secondary/70"> */}
                  <span className="bg-green-2/15 text-green-3 font-medium py-0.5 px-1.5 rounded-md text-xs">
                    {activePlanDetails?.subscriptionPlanData?.plan_name ||activePlanDetails?.subscriptionPlanData?.[0]?.plan_name ||
                      userData?.features_data?.plan_name}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <button
                    className={`${btnSecondayClass} py-2.5 w-[30px] h-[30px] text-sm relative`}
                    onClick={() => {
                      dispatch(
                        setSelectedUser({
                          selectedUser: userData,
                          isPlanCovered: true,
                          isOpen: true,
                          isView: false,
                          loggedInUser: true,
                          planDetail: activePlanDetails,
                        })
                      );
                    }}
                  >
                    <AiOutlineSetting className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-between align-items-center mt-4 gap-3.5">
            <ProfitLossCard
              title={"Overall P&L"}
              time={<span className="text-[10px]">30-Apr-2024 6:09:35 PM</span>}
              value={200}
            />
            <ProfitLossCard
              title={"Profit Trade"}
              time={<span className="text-[10px]">30-Apr-2024 6:09:35 PM</span>}
              value={200}
            />
            <ProfitLossCard
              title={"Loss Trade"}
              time={<span className="text-[10px]">30-Apr-2024 6:09:35 PM</span>}
              value={200}
            />
            <div className="p-3.5 border-[1px] border-dashed border-level-2 rounded-lg w-full">
							<h6
								className="d-flex align-items-center"
								style={{ color: "#ACABAB", fontWeight: "600" }}>
								130{" "}
								<ArrowDownOutlined className="ms-3" style={{ color: "red" }} />
							</h6>
							<p>Earnings</p>
						</div>
						<div className="p-3.5 border-[1px] border-dashed border-level-2 rounded-lg w-full">
							<h6
								className="d-flex align-items-center"
								style={{ color: "#ACABAB", fontWeight: "600" }}>
								500{" "}
								<ArrowUpOutlined
									className="ms-3"
									style={{ color: "#43A233" }}
								/>
							</h6>
							<p>Earnings</p>
						</div>
          </div> */}

        <div className="detials mt-4">
          {/* <div
						className={`d-flex justify-content-between align-items-center relative px-2.5 py-2.5 border-b border-dashed `}>
						<h4>Details</h4>
						<button
							href="#"
							onClick={() => setEdit(true)}
							className={edit ? "editIcon active" : "editIcon"}>
							Edit
						</button>
					</div> */}
          <div
            className={`d-flex justify-content-between align-items-center relative ${afterBorder}`}
          ></div>
          <div className="detailsDiv mt-6">
            <div className="flex flex-col gap-[18px] mt-6">
              <div className="accountDetails">
                <h6 className="font-medium text-[15px]">First Name</h6>
                <p className="text-sm">
                  {userData?.first_name ? userData?.first_name : "-"}
                </p>
              </div>
              <div className="accountDetails">
                <h6 className="font-medium text-[15px]">Last Name</h6>
                <p className="text-sm">
                  {userData?.last_name ? userData?.last_name : "-"}
                </p>
              </div>

              <div className="accountDetails">
                <h6 className="font-medium text-[15px]">Account Type </h6>
                <p className="text-sm">
                  {userData?.last_name ? userData?.signup_type : "-"}
                </p>
              </div>
              <div className="accountDetails">
                <div className="flex items-center gap-2">
                  <h6 className="font-medium text-[15px]">Email Id</h6>
                  <span
                    className={`flex items-center gap-1 text-xs px-1.5 py-0.5 rounded-md ${
                      userData?.email_verify && userData?.password?.length > 0
                        ? "text-blue-2 bg-blue-2/10"
                        : "text-red-2 bg-red-2/10"
                    }`}
                  >
                    {userData?.email_verify &&
                    userData?.password?.length > 0 ? (
                      <BsFillPatchCheckFill />
                    ) : (
                      <AiFillCloseCircle />
                    )}
                    {userData?.email_verify && userData?.password?.length > 0
                      ? "Verified"
                      : "Not Verified"}
                  </span>
                </div>
                <p className="text-sm flex items-center gap-1.5">
                  {userData?.email ? userData?.email : "-"}
                  {emailCheckmark ? (
                    <span className="text-green-2 cursor-pointer d-flex items-center align-middle gap-1 text-xs">
                      <AiOutlineCheck />
                      <span> Copied</span>
                    </span>
                  ) : (
                    <span className="text-blue-2 cursor-pointer">
                      <CopyToClipboard text={userData?.email}>
                        <AiOutlineCopy
                          onClick={() => {
                            setEmailCheckmark(true);
                            setTimeout(() => {
                              setEmailCheckmark(false);
                            }, 1000);
                          }}
                        />
                      </CopyToClipboard>
                    </span>
                  )}
                </p>
              </div>
              <div className="accountDetails">
                <div className="flex items-center gap-2">
                  <h6 className="font-medium text-[15px]">Phone</h6>
                  <span className="flex items-center gap-1 text-xs text-red-2 bg-red-2/10 px-1.5 py-0.5 rounded-md">
                    <AiFillCloseCircle />
                    Not Verified
                  </span>
                </div>
                <p className="text-sm">{userData?.phone_no || "NA"}</p>
              </div>
              {/* <div className="accountDetails">
									<h6 className="font-medium">Language</h6>
									<p className="text-sm">
										{userData?.language ? userData?.language : "-"}
									</p>
								</div>
								<div className="accountDetails">
									<h6 className="font-medium">Last Paid</h6>
									<p className="text-sm">
										{userData?.last_paid ? userData?.last_paid : "-"}
									</p>
								</div>
								<div className="accountDetails">
									<h6 className="font-medium">Tax Id</h6>
									<p className="text-sm">TX-8674</p>
								</div> */}
              <div className="bg-blue-2/5 grid grid-cols-1 lg:grid-cols-3 gap-1 justify-between flex-wrap2 p-2.5 py-2.5 rounded-md border-[1px] border-blue-2/40 border-dashed -mx-3.5 -mb-3.5">
                <div className="accountDetails">
                  <h6 className="font-medium text-sm">Account Id:</h6>
                  <p className="text-[10px] xl:text-xs flex items-center gap-1.5">
                    {userData?._id ? shortenAddress(userData?._id, 4) : "-"}
                    {idCheckmark ? (
                      <span className="text-green-2 cursor-pointer d-flex items-center align-middle gap-1 text-xs">
                        <AiOutlineCheck />
                        <span>Copied</span>
                      </span>
                    ) : (
                      <span className="text-blue-2 cursor-pointer">
                        <CopyToClipboard text={userData?.userData?._id}>
                          <AiOutlineCopy
                            onClick={() => {
                              setIdCheckmark(true);
                              setTimeout(() => {
                                setIdCheckmark(false);
                              }, 1000);
                            }}
                          />
                        </CopyToClipboard>
                      </span>
                    )}
                    {/* <span className="text-blue-2 cursor-pointer">
                        <CopyToClipboard text={userData?._id}>
                          <AiOutlineCopy />
                        </CopyToClipboard>
                      </span> */}
                  </p>
                </div>
                <div className="-ml-6 accountDetails flex flex-col lg:items-center">
                  <div>
                    <h6 className="font-medium text-sm">Last Login:</h6>
                    <p className="text-[10px] xl:text-xs">
                      {userData?.last_login
                        ? formattedDate(userData?.last_login)
                        : "Not logged in yet"}
                    </p>
                  </div>
                </div>

                <div className="accountDetails flex flex-col lg:items-end">
                  <h6 className="font-medium text-sm">Register At:</h6>
                  <p className="text-[10px] xl:text-xs">
                    {formattedDate(userData?.created_at)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
				open={AdduserModal}
				setIsOpen={() => setAdduserModal(false)}
				footer={null}
				centered
				maxWidth={`w-full max-w-[1000px]`}
				bodyClass={
					"w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full max-h-[80vh] p-3.5"
				}
				header={
					<>
						<h3 className="font-medium text-lg">Edit User</h3>
					</>
				}>
				<div className=" relative">
					{" "}
					<div className="small__container">
						<UserDetailsHead
							userData={userData}
							userid={mydecodedTokenFunction(
								localStorage.getItem("adminToken"),
							)}
							overview={false}
						/>
						<UserDetailFormModal
							userData={userData}
							// refetchUser={refetch}
							setIsModalVisible={setIsModalVisible}
							setIsEmailModalVisible={setIsEmailModalVisible}
						/>
					</div>
				</div>
			</Modal> */}
      <UserProfileDrawer refetch={refetch} />
    </>
  );
};

export default React.memo(UserProfileCard);
