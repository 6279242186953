import { createSlice } from "@reduxjs/toolkit";
import { addRoleInitialData } from "../../Utils/utils";

const initialState = {
  data: addRoleInitialData(),
  selectionType: "",
  role_name: "",
  role_id: "",
};

const roleData = {
  data: {},
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserDetailsStore: (state, { payload }) => {
      state.data = payload?.data;
      state.selectionType = payload?.selectionType;
      state.role_name = payload?.role_name;
      state.role_id = payload?.role_id;
    },
  },
});

const RoleListslice = createSlice({
  name: "userRoleList",
  initialState: roleData,
  reducers: {
    updateUserRoleListStore: (state, { payload }) => {
      state.data = payload;
    },
  },
});

let addRoleReducer = slice.reducer;
let RoleListReducer = RoleListslice.reducer;

export const { updateUserDetailsStore } = slice.actions;
const addRoleInitialState = (state) => state.addRole;

export const { updateUserRoleListStore } = RoleListslice.actions;
const UserRoleList = (state) => state.roleList;

export { addRoleReducer, addRoleInitialState, RoleListReducer, UserRoleList };
