export const formClass =
	"placeholder:text-slate-400 bg-whiteNew-0 dark:bg-color-gray-100 text-color-primary focus:shadow-soft focus:text-color-primary focus-within:text-color-primary font-normal focus:outline-none text-sm focus:ring-0 focus:border-color-primaryColor1 dark:focus:border-color-gray-100 dark:ring-color-gray-200 disabled:bg-component-gray-1 dark:disabled:bg-gray-2/20 disabled:font-normal disabled:text-gray-1 dark:focus:bg-color-gray-200 dark:focus-within:bg-color-gray-200 disabled:border-component-gray-1 placeholder:font-normal flex w-full items-center rounded-md p-2.5 py-[6px] border-[1px] border-level-1 dark:border-color-gray-100 hover:border-level-2 focus-within:shadow-soft focus-within:hover:shadow-soft focus-within:border-level-2 dark:focus-within:border-color-gray-200 duration-0 disabled:cursor-not-allowed text-color-primary darkInputPassword";

export const btnPrimaryClass =
	"relative flex justify-center items-center gap-1 bg-color-primaryColor1 hover:bg-color-primaryColor2 backdrop-blur-lg shadow-slate-600 duration-200 ease-in py-[6px] px-2.5 rounded-md text-whiteNew-0 hover:shadow-xs hover:shadow-slate-200 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-color-primaryColor2 cursor-pointer mb-0";
export const btnSuccessClass =
	"relative flex justify-center items-center gap-1 bg-green-3 hover:bg-green-3 hover:bg-opacity-95 backdrop-blur-lg shadow-slate-600 duration-200 ease-in py-[6px] px-2.5 rounded-md text-whiteNew-0 hover:shadow-xs hover:shadow-slate-200 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-green-3 cursor-pointer mb-0";
export const btnPrimaryClassStep3 =
	"relative flex justify-center items-center gap-1 bg-sky-600 backdrop-blur-lg hover:bg-sky-700 shadow-slate-600 duration-200 ease-in p-2.5 py-1.5 rounded-md text-whiteNew-0 hover:shadow-xs hover:shadow-slate-200 border-[1px] border-sky-600 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-sky-600";
export const btnSecondayClass =
	"flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 bg-component-gray-1 dark:bg-component-gray-1 hover:bg-component-gray-3 dark:hover:bg-component-gray-2 gap-1.5 rounded-[6px] cursor-pointer disabled:pointer-events-none  relative text-neutral-950 dark:text-whiteNew-0 py-1.5 px-2.5 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-inherit mb-0";

// export const afterBorder = `after:absolute after:content-[''] dark:after:bg-zinc-700 after:bg-indigo-300 after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 after:bg-color-base-2`;
export const afterBorder = `after:absolute after:content-['']  after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 after:bg-level-2`;
export const afterBorder2 = `after:absolute after:content-[''] after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10`;
export const afterBorderLeft = `relative after:absolute after:content-[''] after:bottom-0 after:-left-0 after:w-1 after:h-full after:flex after:z-10 after:bg-level-2 relative`;
export const afterBorderRight = `relative after:absolute relative after:content-[''] after:bg-level-2 after:bottom-0 after:-right-0 after:w-1 after:h-full after:flex after:z-10`;
export const afterBorderTop = `after:absolute after:content-[''] relative after:bg-level-2 after:top-0 after:left-0 after:h-1 after:w-full after:flex after:z-10`;
export const afterBorderTop2 = `after:absolute after:content-[''] after:top-0 after:left-0 after:h-1 after:w-full after:flex after:z-10`;
