import React from "react";
import { Drawer as AntDrawer } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { afterBorder, btnSecondayClass } from "../utils/DefaultClasses";

export default function Drawer({
	title,
	placement,
	children,
	width,
	className,
	autoFocus,
	afterOpenChange,
	bodyStyle,
	closeIcon,
	contentWrapperStyle,
	destroyOnClose = false,
	extra,
	footer,
	footerStyle,
	forceRender,
	getContainer,
	headerStyle,
	height,
	keyboard,
	mask,
	maskClosable,
	maskStyle,
	push,
	rootClassName,
	rootStyle,
	style,
	size,
	open,
	setOpen,
	zIndex,
	onClose,
	subHeader,
	subHeadingPadding,
	subHeaderClass,
}) {
	//   const [open, setOpen] = useState(false);
	if (open) {
		// $('html').removeProp('overflow');
		// $('html, body').css('overflow', 'hidden');
		// $('html, .sticky-header').css('width', 'calc(100% - 14px)');
	} else {
		// $('html, .sticky-header').css('width', '100%');
		// $('html').css('overflow', 'auto');
	}
	function onCloseDrawer() {
		// $('html, .sticky-header').css('width', '100%');
		// $('html').css('overflow', 'auto');
	}
	return (
		<>
			{/* <button type="primary" onClick={showDrawer}>
        Open
      </button> */}
			<AntDrawer
				title={
					<>
						<div
							className={`flex items-center gap-2 relative min-h-[30px] after:min-h-[28px] after:top-0`}>
							<div className="w-full text-color-primary mr-3">{title}</div>
						</div>
					</>
				}
				destroyOnClose={destroyOnClose}
				// headerStyle={{ borderBottomWidth: !title && '0' }}
				styles={{
					header: {
						...headerStyle,
						borderBottomWidth: !title && "0",
						paddingLeft: "10px",
					},
					mask: maskStyle,
				}}
				placement={placement ? placement : "right"}
				onClose={() => {
					onClose();
					onCloseDrawer();
				}}
				open={open}
				width={width}
				className={`${className}`}
				rootClassName={"drawer-root-className"}
				footer={footer ? <>{footer}</> : null}
				closeIcon={
					<div className="absolute top-[18px] right-3 z-10 flex">
						<div
							type="button"
							onClick={onClose}
							className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}>
							<AiOutlineClose className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover h-3.5 w-3.5" />
						</div>
					</div>
				}
				zIndex={zIndex}>
				{subHeader ? (
					<>
						<div className="sticky top-0 z-20 h-[26px]">
							<div
								className={`-mx-6 relative -top-[25px] z-30 bg-color-base-1  ${
									subHeaderClass ? subHeaderClass : " py-1.5"
								} ${afterBorder}`}>
								<div
									className={`flex ml-[4px] ${
										subHeadingPadding ? subHeadingPadding : "py-2.5 px-3 "
									}`}>
									{subHeader}
								</div>
							</div>
						</div>
					</>
				) : null}
				<div className={`${subHeader && "pt-[5px]"}`}>
					<div
						style={{
							marginLeft: subHeader ? "1.5rem" : null,
						}}
						className="h-full">
						{children}
					</div>
				</div>
			</AntDrawer>
		</>
	);
}
