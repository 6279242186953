import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { toastSpecs } from "../../../Utils/utils";
import { updateStrategyAPI } from "../../helpers/api/StrategyPermission/strategyPermissions";

const useUpdateStrategy = (refetch, refetchList) => {
  const queryClient = useQueryClient();
  const insertNote = useCallback((payload) => {
    return updateStrategyAPI(payload);
  }, []);
  const refetchData = () => {
    queryClient.invalidateQueries(["StrategyPermissionList"]);
  };

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      refetch();
      refetchList && refetchData();
      return toast.success("Updated Successfully.", toastSpecs);
    },
    onError: (err) => {
      console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });
  const onUpdate = useCallback(
    (payload) => {
      mutation.mutate(payload);
    },
    [mutation]
  );

  const value = useMemo(() => {
    return {
      onUpdate,
      isLoading: mutation?.isPending,
    };
  }, [onUpdate, mutation?.isPending]);

  return value;
};

export default useUpdateStrategy;
