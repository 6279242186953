import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: {},
};

const userPermissionsSlice = createSlice({
  name: "userPermission",
  initialState,
  reducers: {
    updateUserPermissions: (state, { payload }) => {
      state.permissions = payload;
    },
  },
});

let userPermissionReducer = userPermissionsSlice.reducer;

export const { updateUserPermissions } = userPermissionsSlice.actions;

const userPermissionData = (state) => state.userPermission;

export { userPermissionData, userPermissionReducer };
