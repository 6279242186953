import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastSpecs } from "../../../../Utils/utils";
import { updateTicketStatus } from "../../../../Component/helpers/api/TicketSupport/ticket";
import { setSelectedTicket } from "../../../../redux/Tickets/tickets.slice";
import { useDispatch } from "react-redux";

const useTicketRightSideBar = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const insertNote = useCallback(
    (status) => {
      const formData = new FormData();
      formData.append("status", status);
      formData.append("ticket_id", id);

      // console.log('attachments', attachments, formData);
      return updateTicketStatus(formData);
    },
    [id]
  );

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      //   return toast.success('Ticket created successfully.', toastSpecs);
      // console.log("RES", res);
      dispatch(setSelectedTicket(res?.data?.data));
      queryClient.invalidateQueries({ queryKey: ["ticketSupport"] });
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(
    (status) => {
      // console.log("onSubmit", status);
      mutation.mutate(status);
    },
    [mutation]
  );

  const options = useMemo(() => {
    return [
      {
        value: "Open",
        label: "Open",
        disabled: mutation.isPending,
      },
      {
        value: "In Progress",
        label: "In Progress",
        disabled: mutation.isPending,
      },
      {
        value: "Closed",
        label: "Close",
        disabled: mutation.isPending,
      },
    ];
  }, [mutation.isPending]);

  const values = useMemo(() => {
    return {
      onSubmit,
      isLoading: mutation.isPending,
      options,
    };
  }, [onSubmit, mutation.isPending, options]);

  return values;
};

export default useTicketRightSideBar;
