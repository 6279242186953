import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import ProfitLossCard from "../../Pages/Admin/Dashboard/user_detail_compo/ProfitLossCard";
import { MdKeyboardArrowDown, MdOutlineAutoGraph } from "react-icons/md";
import { btnSecondayClass } from "../utils/DefaultClasses";
import { Dropdown, Select, Switch } from "antd";
import { Link, useParams } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { FcDeleteDatabase } from "react-icons/fc";
import {
	getLiveTrades,
	getPortfolio,
	getVirtualTrades,
} from "../helpers/api/userDetails/userDetails";
import { toast } from "react-toastify";
import moment from "moment";
import NoDataFoundV2 from "../NoDataFoundV2"; 
import { DATEFORMAT, toastSpecs } from "../../Utils/utils";

function UserTradeProfile({ dropDown, accountCreatedAt }) {
	let { id } = useParams();
	const trades = [
		{
			key: "0",
			label: <span className="d-flex align-items-center">Virtual Trade</span>,
			onClick: () => {
				handleChange("virtual-trade");
				setSelectedItem("Virtual Trade");
			},
		},
		{
			key: "1",
			label: <span className="d-flex align-items-center">Live Trade</span>,
			onClick: () => {
				handleChange("live-trade");
				setSelectedItem("Live Trade");
			},
		},
	];

	const [selectedItem, setSelectedItem] = useState("Virtual Trade");
	const [totalPortfolioView, setTotalPortfolioView] = useState({});
	const [virtualData, setVirtualData] = useState([]);
	const [liveData, setLiveData] = useState([]);
	const [virtualPaginationTotal, setVirtualPaginationTotal] = useState(0);
	const [livePaginationTotal, setLivepaginationTotal] = useState(0);

	const [isVirtualDataAvailable, setIsVirtualDataAvailable] = useState(false);
	const [isLiveDataAvailable, setIsLiveDataAvailable] = useState(false);

	useEffect(() => {
		setIsVirtualDataAvailable(virtualData.length > 0);
	}, [virtualData]);

	useEffect(() => {
		setIsLiveDataAvailable(liveData.length > 0);
	}, [liveData]);

	const columnsTrade = [
		{
			title: "Report Name",
			dataIndex: "ReportName",
		},
		{
			title: "Strategy Name",
			dataIndex: "strategyData",
		},
		{
			title: "Created At",
			dataIndex: "CreatedAt",
		},
		{
			title: "Max Cap",
			dataIndex: "MaxCap",
		},
		{
			title: "No. of orders",
			dataIndex: "Orders",
		},
		{
			title: "Mode",
			dataIndex: "status",
		},
		{
			title: "Action",
			dataIndex: "Action",
		},
	];

	const handleChange = (value) => {
		// console.log(value);
		setSelectedItem(value);
		portfolioDetails.mutate(value, id);
	};

	useEffect(() => {
		portfolioDetails.mutate("virtual-trade", id);
		virtualTrades.mutate();
		liveTrades.mutate();
	}, []);

	const virtualTrades = useMutation({
		mutationFn: (type) => getVirtualTrades(id),
		onSuccess: (res) => {
			setVirtualData(res?.data?.data);
			setVirtualPaginationTotal(res?.data?.dataCount);
			//   console.log(res.data.data);
		},
		onError: (err) => {
			return toast.error("something went wrong server error", toastSpecs);
		},
	});

	const liveTrades = useMutation({
		mutationFn: () => getLiveTrades(id),
		onSuccess: (res) => {
			setLiveData(res?.data?.data);
			setLivepaginationTotal(res?.data?.dataCount);
			//   console.log(res?.data?.data);
		},
		onError: (err) => {
			return toast.error("something went wrong server error", toastSpecs);
		},
	});

	const portfolioDetails = useMutation({
		mutationFn: (type) => getPortfolio(type, id),
		onSuccess: (res) => {
			setTotalPortfolioView(res.data);
		},
		onError: (err) => {
			return toast.error("something went wrong server error", toastSpecs);
		},
	});
    const handleToggle = (id, newStatus) => {
		// Add your logic here to handle the toggle button click
		console.log(`Toggled ${id} to ${newStatus}`);
	};
	const virualDataTrade = virtualData?.map((item, index) => ({
		key: index,
		ReportName: (
			<Link to={`/view-report/VT/${item?._id}`} className="text-blue-600 hover:underline">
			{item?.vt_inputs?.report_name}
		  </Link>
		),		strategyData: item?.strategyData?.name,
		CreatedAt: moment(item?.created_at).format(DATEFORMAT),
		MaxCap: item?.vt_inputs?.investment,
		Orders: 0,
		status: (
			<div className="flex items-center gap-1.5">
				<Switch
					checked={item?.status}
					onChange={(checked) => handleToggle(item?._id, checked)}
				/>
			</div>
		),
			
		Action: (
			<span className="flex">
				<Dropdown
					menu={{
						items: [
							{
								key: "1",
								label: (
									<Link
										to={`#`}
										className="d-flex align-items-center hover:no-underline">
										<EyeOutlined className="me-2" />
										View
									</Link>
								),
							},
							{
								key: "3",
								label: (
									<span href="#" className="d-flex align-items-center">
										<DeleteOutlined className="me-2" />
										Delete
									</span>
								),
								onClick: () => {},
							},
						],
					}}
					placement="bottomRight"
					overlayClassName={"dropdownOverlay dowpdownDark"}
					trigger={["click"]}
					className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer">
					<div onClick={(e) => e.preventDefault()}>
						Action
						<MdKeyboardArrowDown />
					</div>
				</Dropdown>
			</span>
		),
	}));

	const liveDataTrade = liveData?.map((item, index) => ({
		key: index,
		ReportName: (
			<Link to={`/view-report/LT/${item?._id}`} className="text-blue-600 hover:underline">
			  {item?.lt_inputs?.report_name}
			</Link>
		  ),		strategyData: item?.strategyData?.name,

		CreatedAt: moment(item?.created_at).format(DATEFORMAT),
		MaxCap: item?.lt_inputs?.investment,
		Orders: 0,
		status: (
			<div className="flex items-center gap-1.5">
				<Switch
					checked={item?.status}
					onChange={(checked) => handleToggle(item?._id, checked)}
				/>
			</div>
		),
			
		Action: (
			<span className="flex">
				<Dropdown
					menu={{
						items: [
							{
								key: "1",
								label: (
									<Link
										to={`#`}
										className="d-flex align-items-center hover:no-underline">
										<EyeOutlined className="me-2" />
										View
									</Link>
								),
							},
							{
								key: "3",
								label: (
									<span href="#" className="d-flex align-items-center">
										<DeleteOutlined className="me-2" />
										Delete
									</span>
								),
								onClick: () => {},
							},
						],
					}}
					placement="bottomRight"
					overlayClassName={"dropdownOverlay dowpdownDark"}
					trigger={["click"]}
					className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer">
					<div onClick={(e) => e.preventDefault()}>
						Action
						<MdKeyboardArrowDown />
					</div>
				</Dropdown>
			</span>
		),
	}));

	// const dataSourceTrade = [
	//   {
	//     key: "1",
	//     ReportName: "tf_test1",
	//     MaxCap: "10000",
	//     CreatedAt: "14 Dec 2020, 8:43 pm",
	//     Orders: "20",
	//     Status: (
	//       <div className="flex">
	//         <span className="bg-green-2/15 text-green-2 rounded-md flex py-0.5 px-2.5">
	//           Successful
	//         </span>
	//       </div>
	//     ),
	//     Action: (
	//       <span className="flex">
	//         <Dropdown
	//           menu={{
	//             items: [
	//               {
	//                 key: "1",
	//                 label: (
	//                   <Link
	//                     to={`#`}
	//                     className="d-flex align-items-center hover:no-underline"
	//                   >
	//                     <EyeOutlined className="me-2" />
	//                     View
	//                   </Link>
	//                 ),
	//               },
	//               // {
	//               // 	key: "2",
	//               // 	label: (
	//               // 		<span className="d-flex align-items-center">
	//               // 			<EditOutlined className="me-2" />
	//               // 			Edit
	//               // 		</span>
	//               // 	),
	//               // 	onClick: () => {},
	//               // },
	//               {
	//                 key: "3",
	//                 label: (
	//                   <span href="#" className="d-flex align-items-center">
	//                     <DeleteOutlined className="me-2" />
	//                     Delete
	//                   </span>
	//                 ),
	//                 onClick: () => {},
	//               },
	//             ],
	//           }}
	//           placement="bottomRight"
	//           overlayClassName={"dropdownOverlay dowpdownDark"}
	//           trigger={["click"]}
	//           className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
	//         >
	//           <div onClick={(e) => e.preventDefault()}>
	//             Action
	//             <MdKeyboardArrowDown />
	//           </div>
	//         </Dropdown>
	//       </span>
	//     ),
	//   },
	// ];

	console.log("totalPortfolioView", totalPortfolioView);

	return (
		<>
			<div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl space-y-5">
				<div className="d-flex align-items-center justify-content-between">
					<div>
						<div className="flex gap-2">
							<div className="">
								<span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
									<svg
										stroke="currentColor"
										fill="currentColor"
										strokeWidth="0"
										viewBox="0 0 256 256"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg"
										className="shrink-0">
										<path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
									</svg>
								</span>
							</div>
							<div className="">
								<div className="flex gap-2">
									<div className="flex flex-col gap-0 -space-y-1">
										<h2 className="strtgy__ttl">Portfolio Overview</h2>
										{/* accountCreatedAt */}
										<span className="text-sm">
											{moment(accountCreatedAt).format(DATEFORMAT)}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex items-center gap-2">
						<div className="flex gap-1.5">
							<h3 class="text-base font-semibold">Current P&amp;L : </h3>
							<h4 class="pnl__details fw-semibold ">
								<span class="dark:text-color-secondary">0.00</span>
							</h4>
						</div>
						<span className="flex">
							{/* <Select
                size={20}
                defaultValue={{
                  key: "0",
                  label: (
                    <span className="d-flex align-items-center">
                      Virtual Trade
                    </span>
                  ),
                  value: "Virtual",
                }}
                onChange={handleChange}
                style={{
                  width: 200,
                }}
                options={trades}
              /> */}
							<Dropdown
								menu={{ items: trades }}
								placement="bottomRight"
								overlayClassName={"dropdownOverlay dowpdownDark"}
								trigger={["click"]}
								className={`${btnSecondayClass} p-[2px] px-2 text-sm`}>
								<div
									onClick={(e) => {
										e.preventDefault();
									}}>
									<span>{selectedItem}</span>
									<MdKeyboardArrowDown />
								</div>
							</Dropdown>
						</span>
					</div>
				</div>

				<div className="mt-4 flex gap-3">
					<ProfitLossCard
						title={"Total P&L"}
						time={moment(accountCreatedAt).format(DATEFORMAT)}
						value={
							totalPortfolioView?.totalPnl
								? Number(totalPortfolioView?.totalPnl)
								: 0
						}
					/>
					{/* <ProfitLossCard
            title={"Total Investment"}
            time={"30-Apr-2024 6:09:35 PM"}
            value={0}
          /> */}
					<ProfitLossCard
						title={"Total Brokerage"}
						time={moment(accountCreatedAt).format(DATEFORMAT)}
						value={
							totalPortfolioView?.totalBrokerage
								? Number(totalPortfolioView?.totalBrokerage)
								: 0
						}
					/>
					<ProfitLossCard
						title={"Total Trades"}
						time={moment(accountCreatedAt).format(DATEFORMAT)}
						// time={<span className="text-[10px]">30-Apr-2024 6:09:35 PM</span>}
						value={
							totalPortfolioView?.totalTrades
								? Number(totalPortfolioView?.totalTrades)
								: 0
						}
					/>
					<ProfitLossCard
						title={"Profit Trades"}
						time={moment(accountCreatedAt).format(DATEFORMAT)}
						// time={<span className="text-[10px]">30-Apr-2024 6:09:35 PM</span>}
						value={
							totalPortfolioView?.profitTrade
								? Number(totalPortfolioView?.profitTrade)
								: 0
						}
					/>
					<ProfitLossCard
						title={"Loss Trades"}
						time={moment(accountCreatedAt).format(DATEFORMAT)}
						// time={<span className="text-[10px]">30-Apr-2024 6:09:35 PM</span>}
						value={
							totalPortfolioView?.lossTrade
								? Number(-totalPortfolioView?.lossTrade)
								: 0
						}
					/>
				</div>
			</div>
			<div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl space-y-5">
				<div className="d-flex align-items-center justify-content-between -mt-3">
					<div className="flex flex-col">
						<div className="text-base font-semibold pb-3.5">Live Trade</div>
						{/* <span class="primary__text__mute fs-7 time_update m-0 flex">
											30-Apr-2024<span class="ml-2">3:07:07 PM</span>
										</span> */}
					</div>

					{/* {isLiveDataAvailable && dropDown()} */}
				</div>
				<div
					className={`m-0 d-flex flex-col ${
						isLiveDataAvailable
							? "items-start justify-content-start"
							: "items-center justify-content-center"
					}`}>
					{isLiveDataAvailable ? (
						<TableComponent
							className="paymentTbl"
							dataSource={liveDataTrade}
							columns={columnsTrade}
							pagination={true}
							pageSize={5}
						/>
					) : (
						<div className=" text-center flex flex-col justify-center items-center h-full">
							<NoDataFoundV2
								title={"No Live trade is being running"}
								icon={<MdOutlineAutoGraph />}
								minHeight={"min-h-[280px]"}
								subTitle="once the Live trade setup is done and running data will be visible over here."
							/>
						</div>
					)}
				</div>
			</div>
			<div className="card border-[0px] border-level-2 shadow-lg shadow-level-4 p-6 rounded-xl space-y-5">
				<div className="d-flex align-items-center justify-content-between -mt-3">
					<div className="flex flex-col">
						<div className="text-base font-semibold pb-3.5">Virtual Trade</div>
						{/* <span class="primary__text__mute fs-7 time_update m-0 flex">
											30-Apr-2024<span class="ml-2">3:07:07 PM</span>
										</span> */}
					</div>
					{/* {isVirtualDataAvailable && dropDown()} */}
				</div>
				<div
					className={`m-0 d-flex flex-col ${
						isVirtualDataAvailable
							? "items-start justify-content-start"
							: "items-center justify-content-center"
					}`}>
					{isVirtualDataAvailable ? (
						<TableComponent
							className="paymentTbl"
							dataSource={virualDataTrade}
							columns={columnsTrade}
							pagination={true}
							pageSize={5}
						/>
					) : (
						<div className=" text-center flex flex-col justify-center items-center h-full ">
							<NoDataFoundV2
								minHeight={"min-h-[280px]"}
								title={"No virtual trade  is being running"}
								icon={<MdOutlineAutoGraph />}
								subTitle="once the virtual trade setup is done and running data will be visible over here."
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default React.memo(UserTradeProfile);
