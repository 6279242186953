import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { updateAdminEmail } from "../../helpers/api/Auth/Auth";
import mydecodedTokenFunction from "../../../Utils/decodedtoken";
import useTwoFactorAuth from "../../../Pages/Login/Admin-login/hooks/useTwoFactorAuth";
import { toastSpecs } from "../../../Utils/utils";

const useUpdateEmail = () => {
  const [form] = Form.useForm();
  const [showEmailVerify, setShowEmailVerify] = useState(false);
  const [emailId, setEmailId] = useState("");
  const userID = mydecodedTokenFunction(localStorage.getItem("adminToken"));

  const {
    form: otpForm,
    otpError,
    udateOTP,
    submitForm,
    resendOTP,
    isLoadingOTP,
  } = useTwoFactorAuth("changeEmail", emailId);

  const insertNote = useCallback(() => {
    const { old_email, new_email } = form.getFieldsValue() || emailId;

    const payload = {
      old_email: old_email,
      new_email: new_email,
      user_id: userID.id,
    };
    return updateAdminEmail(payload);
  }, [form, userID, emailId]);

  const addCustomError = (from, message) => {
    form.setFields([
      {
        name: from,
        errors: [message],
      },
    ]);
  };

  const updateEmailMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      if (res.data.status) {
        setShowEmailVerify(true);
        setEmailId(form.getFieldsValue());
      }
      if (res.data.message === "User not registered.") {
        addCustomError("old_email", "The Current email is incorrect.");
      }
      //   else if (
      //     res.data.message ===
      //     "Current password and New password can not be the same."
      //   ) {
      //     addCustomError("old_password", res.data.message);
      //   }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const updateEmail = useCallback(() => {
    updateEmailMutation?.mutate();
  }, [updateEmailMutation]);

  const value = useMemo(() => {
    return {
      form,
      showEmailVerify,
      isLoading: updateEmailMutation.isPending,
      setShowEmailVerify,
      updateEmail,
      otpForm,
      otpError,
      udateOTP,
      submitForm,
      resendOTP,
      isLoadingOTP,
    };
  }, [
    form,
    showEmailVerify,
    updateEmailMutation,
    updateEmail,
    otpForm,
    otpError,
    udateOTP,
    submitForm,
    resendOTP,
    isLoadingOTP,
  ]);

  return value;
};

export default useUpdateEmail;
